import { Box } from '@mui/material';
import { ReturnQueueTypeChip } from 'components/Chips';
import NeutralChip from 'components/Chips/NeutralChip';
import ListScreen from 'components/ListScreen';
import {
  BidWranglerReturnQueueDocument,
  ReturnQueueListEntryFragment,
  ReturnQueueStatus,
  useAllBiddersQuery,
  useAllInvoicesQuery,
  useAllReturnOptionsQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { formatEnum } from 'utils/general';
import { formatDateTime } from 'utils/time';
import {
  RETURN_QUEUE_STATUS_FILTER_ELEMENTS,
  RETURN_QUEUE_TYPE_FILTER_ELEMENTS,
} from './filters';

type Row = {
  row: ReturnQueueListEntryFragment;
};

const ReturnQueueListScreen: FunctionComponent = () => {
  const history = useHistory();
  return (
    <ListScreen
      title="BidWrangler Return Queue"
      query={BidWranglerReturnQueueDocument}
      onRowClick={(row: Row['row']) => {
        history.push(`/returnQueue/${row.id}`);
      }}
      columns={[
        {
          type: 'autocomplete',
          label: 'Reason',
          name: 'returnOptionByOptionId.name',
          makeFilterQuery: (value) => ({
            returnOptionByOptionId: {
              name: {
                includesInsensitive: value,
              },
            },
          }),
          useQuery: useAllReturnOptionsQuery,
          getOptionLabel: (item) => item.name,
          mapValueToFilter: (value) => ({
            name: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllReturnOptionsQuery>) =>
            result.data?.allReturnOptions?.nodes ?? [],
        },
        {
          label: 'Type',
          name: 'type',
          type: 'select',
          filterElements: RETURN_QUEUE_TYPE_FILTER_ELEMENTS,
          render: ({ row }: Row) => <ReturnQueueTypeChip type={row.type} />,
        },
        {
          type: 'autocomplete',
          label: 'Invoice',
          name: 'itemByItemId.invoiceByInvoiceId.uid',
          makeFilterQuery: (value) => ({
            itemByItemId: {
              invoiceByInvoiceId: {
                uid: {
                  includesInsensitive: value,
                },
              },
            },
          }),
          useQuery: useAllInvoicesQuery,
          getOptionLabel: (item) => item.uid,
          mapValueToFilter: (value) => ({
            uid: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllInvoicesQuery>) =>
            result.data?.allInvoices?.nodes ?? [],
        },
        {
          type: 'autocomplete',
          label: 'Customer',
          name: 'itemByItemId.bidderByBidderId.name',
          makeFilterQuery: (value) => ({
            itemByItemId: {
              bidderByBidderId: {
                name: {
                  includesInsensitive: value,
                },
              },
            },
          }),
          useQuery: useAllBiddersQuery,
          getOptionLabel: (item) => item.name,
          mapValueToFilter: (value) => ({
            name: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllBiddersQuery>) =>
            result.data?.allBidders?.nodes ?? [],
        },
        {
          label: 'Item',
          name: 'itemByItemId.number',
          makeFilterQuery: (value) => ({
            itemByItemId: {
              number: {
                equalTo: Number(value),
              },
            },
          }),
        },
        {
          label: 'Created By',
          name: 'userByCreatedBy.name',
          disableFilter: true,
          render: ({ row }: Row) => (
            <>
              <Box>{row?.userByCreatedBy?.name}</Box>
              <Box>{formatDateTime(row?.createdAt)}</Box>
            </>
          ),
        },
        {
          label: 'Status',
          name: 'status',
          type: 'select',
          filterElements: RETURN_QUEUE_STATUS_FILTER_ELEMENTS,
          defaultValue: ReturnQueueStatus.Open,
          render: ({ row }: Row) => (
            <NeutralChip text={formatEnum(row.status)} />
          ),
        },
      ]}
    />
  );
};

export default ReturnQueueListScreen;
