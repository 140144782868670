import ContentHeader from 'components/ContentHeader';
import Hyperlink from 'components/Hyperlink';
import LineItems from 'components/LineItems';
import { ViewScreen } from 'components/ViewScreen';
import { useGetPrinterQuery } from 'graphql/graphql-types';
import { useModal } from 'hooks/modal';
import React, { FunctionComponent, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { formatDateTime } from 'utils/time';
import PrinterForm from './PrinterForm';

const PrinterViewScreen: FunctionComponent = () => {
  const history = useHistory();
  const { visible, open, close } = useModal();
  const { id } = useParams<{ id: string }>();
  const { loading, data, error } = useGetPrinterQuery({
    variables: {
      id: Number(id),
    },
  });

  const printer = data?.printerById;
  const name = printer?.name ?? '';
  const locationName = printer?.locationByLocationId?.name ?? '';
  const locationId = printer?.locationId;
  const ipAddress = printer?.ipAddress;
  const port = printer?.port;
  const createdAt = printer?.createdAt;
  const updatedAt = printer?.updatedAt;

  const openLocation = useCallback(() => {
    if (locationId) {
      history.push(`/locations/${locationId}`);
    }
  }, [locationId]);

  return (
    <ViewScreen
      loading={loading}
      error={error}
      header={{
        leftProps: {
          title: name,
        },
        dividerProps: {
          hide: true,
        },
        bottomProps: {
          buttons: [
            {
              text: 'Edit',
              onClick: open,
              disabled: !id,
            },
          ],
        },
      }}
    >
      <ContentHeader title="Details" size="large" />
      <LineItems description="Name" value={name} />
      <LineItems
        description="Location"
        value={<Hyperlink text={locationName} onClick={openLocation} />}
      />
      <LineItems description="IP address" value={ipAddress} />
      <LineItems description="Port" value={port} />
      <LineItems description="Created" value={formatDateTime(createdAt)} />
      <LineItems description="Updated" value={formatDateTime(updatedAt)} />
      <PrinterForm
        visible={visible}
        type="update"
        printerId={Number(id)}
        handleClose={close}
        handleSuccess={close}
      />
    </ViewScreen>
  );
};

export default PrinterViewScreen;
