import { Box, Card, CardContent, Typography } from '@mui/material';
import { ViewScreen } from 'components/ViewScreen';
import React, { FunctionComponent } from 'react';
import UserPasswordForm from './UserPasswordForm';

const ProfileScreen: FunctionComponent = () => (
  <ViewScreen
    loading={false}
    header={{
      leftProps: {
        title: 'Profile',
      },
      dividerProps: {
        hide: true,
      },
    }}
  >
    <Card
      variant="outlined"
      style={{ marginBottom: '2rem', minWidth: '40rem' }}
    >
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            flexGrow: 1,
            margin: '1rem 0 0',
          }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap', flexGrow: 1 }}>
            <Typography>Change Password</Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              flexGrow: 1,
              margin: '1.5rem 0 0rem',
            }}
          >
            <UserPasswordForm />
          </Box>
        </Box>
      </CardContent>
    </Card>
  </ViewScreen>
);

export default ProfileScreen;
