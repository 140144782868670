import { PieChart } from 'bizcharts';
import GraphTitle from 'components/GraphTitle';
import React, { FunctionComponent } from 'react';
import { formatPercent } from 'utils/math';

type Data = {
  name: string;
  value: number;
  percent: number;
};

type Props = {
  data: Data[];
};
const ReturnReportPieChart: FunctionComponent<Props> = ({ data }) => (
  <>
    <GraphTitle title="Return Categories Breakdown" />
    <PieChart
      height={400}
      data={data}
      meta={{
        percent: {
          formatter: (value: number) => formatPercent(value),
        },
      }}
      pieStyle={{
        lineWidth: 3,
        stroke: '#fff',
      }}
      autoFit
      title={{
        visible: false,
      }}
      radius={0.8}
      angleField="percent"
      colorField="name"
      padding="auto"
      label={{
        visible: true,
        type: 'spider',
        content: (d) =>
          `${d.name} (${d.value}) ${formatPercent(d.percent * 100)}`,
      }}
    />
  </>
);

export default ReturnReportPieChart;
