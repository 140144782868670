import ApolloTable, {
  Props as TableProps,
  SortTypes as TableSortTypes,
} from '@digitus-data/material-ui-table-apollo';
import makeStyles from '@mui/styles/makeStyles';
import Card from 'components/Card';
import StyledTableSortLabel from 'components/ListScreen/StyledTableSortLabel';
import TableEmptyComponent from 'components/TableEmptyComponent';
import React, { FunctionComponent } from 'react';

const useStlyes = makeStyles((theme) => ({
  bodyCell: {
    // fontSize: '0.8rem',
  },
  headerCell: {
    color: 'rgba(0,0,0,0.99)',
    backgroundColor: 'rgba(0,0,0,0.02)',
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'uppercase',
    fontSize: 12,
  },
}));

export const SortTypes = TableSortTypes;

const Table: FunctionComponent<TableProps> = ({ columns, ...tableProps }) => {
  const classes = useStlyes();
  const adjustedColumns = columns.map((column) => ({
    ...column,
    getColumnClasses: () => [classes.bodyCell],
    disableFilterIcons: true,
  }));

  return (
    <Card disableMargin disablePadding>
      <ApolloTable
        paperProps={{ variant: 'elevation', elevation: 1 }}
        tableProps={{ size: 'small' }}
        displayEmptyRows={false}
        getCellClasses={() => [classes.headerCell]}
        disableHeaderLoader
        tableSortLabelComponent={StyledTableSortLabel}
        renderEmptyComponent={() => (
          <TableEmptyComponent colSpan={adjustedColumns.length} />
        )}
        columns={adjustedColumns}
        rowsPerPage={50}
        rowsPerPageOptions={[
          { label: '25', value: 25 },
          { label: '50', value: 50 },
          { label: '75', value: 75 },
          { label: '100', value: 100 },
          { label: '300', value: 300 },
        ]}
        {...tableProps}
      />
    </Card>
  );
};

export default Table;
