import { UserRoles } from 'graphql/graphql-types';

export const ADMINISTRATOR = UserRoles.Administrator;
export const SECRETARY = UserRoles.Secretary;
export const EMPLOYEE = UserRoles.Employee;

export const doesUserHaveAccess = (
  userRole: UserRoles | undefined,
  accessRoles?: UserRoles[],
) => {
  if (accessRoles && userRole && !accessRoles.includes(userRole)) {
    return false;
  }
  return true;
};
