import {
  useClearAllUserNotificationsMutation,
  useClearUserNotificationMutation,
  UserNotificationEntryFragmentDoc,
} from 'graphql/graphql-types';
import { useCallback } from 'react';
import { logError } from 'utils/logging';

export const useClearNotification = () => {
  const [clear] = useClearUserNotificationMutation();

  const clearNotification = useCallback((notificationId: number) => {
    const handle = async () => {
      try {
        await clear({
          variables: {
            notificationId,
          },
          update(cache) {
            cache.writeFragment({
              id: cache.identify({
                __typename: 'UserNotification',
                id: notificationId,
              }),
              fragment: UserNotificationEntryFragmentDoc,
              data: {
                isCleared: true,
              },
            });
          },
        });
      } catch (e) {
        logError(e);
      }
    };
    if (notificationId) {
      handle();
    }
  }, []);

  return { clearNotification };
};

export const useClearAllNotifications = () => {
  const [clear] = useClearAllUserNotificationsMutation();

  const clearAllNotifications = useCallback(() => {
    const handle = async () => {
      try {
        await clear({
          update(cache) {
            cache.modify({
              fields: {
                allUserNotifications(existing = []) {
                  return { ...existing, nodes: [], totalCount: 0 };
                },
              },
            });
          },
        });
      } catch (e) {
        logError(e);
      }
    };
    handle();
  }, []);

  return { clearAllNotifications };
};
