import { ApolloError } from '@apollo/client';
import { Alert } from '@mui/material';
import CircleLoading from 'components/CircleLoading';
import React, { FunctionComponent } from 'react';

type Props = {
  loading: boolean;
  error?: ApolloError;
  called: boolean;
};
const SimpleReportView: FunctionComponent<Props> = ({
  loading,
  error,
  called,
  children,
}) => {
  if (!called) {
    return <Alert severity="info">Not run yet</Alert>;
  }

  if (loading) {
    return <CircleLoading />;
  }

  if (error) {
    return <Alert severity="error">{error?.message ?? 'General error'}</Alert>;
  }

  return <>{children}</>;
};

export default SimpleReportView;
