import { Box, Card, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  LivePickupQueueEntryFragment,
  useLivePickupQueueQuery,
} from 'graphql/graphql-types';
import { useQuery } from 'hooks/router';
import React, { FunctionComponent } from 'react';
import { NotPriorityIcon, PriorityIcon } from 'components/PickupIcons';
import AppBar from './components/AppBar';
import Table from './components/Table';

type Row = {
  row: LivePickupQueueEntryFragment;
};

const getFirstAndLastInitial = (fullName?: string | null) => {
  if (!fullName) {
    return '';
  }

  const parts = fullName.split(' ');

  if (parts.length === 1) {
    return parts[0];
  }

  return `${parts[0]} ${parts[parts.length - 1][0]}`;
};

const PickupLiveDashboardScreen: FunctionComponent = () => {
  const params = useQuery();
  const theme = useTheme();
  const locationName = params.get('name');
  const locationId = params.get('id');
  const { data } = useLivePickupQueueQuery({
    skip: !locationId,
    pollInterval: 3_000,
    variables: {
      locationId: Number(locationId),
    },
  });
  const isXL = useMediaQuery(theme.breakpoints.up('xl'));

  const liveData = data?.allLivePickupQueues?.nodes ?? [];

  const columns = [
    {
      label: '',
      name: 'priority',
      render: ({ row }: Row) =>
        row?.isPriority ? (
          <PriorityIcon fontSize={isXL ? 'large' : 'medium'} />
        ) : (
          <NotPriorityIcon fontSize={isXL ? 'large' : 'medium'} />
        ),
      width: '1%',
    },
    {
      label: 'Customer',
      name: 'customer',
      render: ({ row }: Row) =>
        getFirstAndLastInitial(row?.bidderByCustomerId?.name),
      width: '30%',
    },
    {
      label: 'Assigned',
      name: 'assigned',
      render: ({ row }: Row) =>
        getFirstAndLastInitial(row?.userByAssignedUserId?.name),
    },
  ];

  return (
    <Box
      style={{
        transform: isXL ? 'rotate(90deg)' : 'inherit',
        transformOrigin: isXL ? 'bottom left' : 'inherit',
        position: isXL ? 'absolute' : 'inherit',
        top: isXL ? '-100vw' : 0,
        left: isXL ? '0' : 0,
        height: isXL ? '100vw' : 'inherit',
        width: isXL ? '100vh' : 'inherit',
        color: '#fff',
        overflow: 'auto',
      }}
    >
      <AppBar text={`${locationName ? `${locationName} Pickup` : ''}`} />
      <Card variant="outlined" sx={{ margin: 5 }}>
        <Table
          columns={columns}
          rows={liveData}
          emptyMessage="---"
          size="medium"
        />
      </Card>
    </Box>
  );
};

export default PickupLiveDashboardScreen;
