import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { useDeletePickupQueueItemMutation } from 'graphql/graphql-types';
import React, { FunctionComponent, useCallback } from 'react';
import { logError } from 'utils/logging';

type Props = {
  id: number;
};

const DeletePickupItem: FunctionComponent<Props> = ({ id }) => {
  const [deleteItem] = useDeletePickupQueueItemMutation();

  const onClick = useCallback(async () => {
    if (id) {
      try {
        await deleteItem({
          variables: {
            id,
          },
          // remove item from cache
          update(cache) {
            const normalizedId = cache.identify({
              id,
              __typename: 'PickupQueue',
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });
      } catch (e) {
        logError(e);
      }
    }
  }, [id]);

  return (
    <IconButton size="small" onClick={onClick}>
      <DeleteIcon fontSize="small" />
    </IconButton>
  );
};

export default DeletePickupItem;
