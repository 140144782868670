import { UserRoles } from 'graphql/graphql-types';
import React from 'react';
import AuctionListScreen from 'screens/Auction/AuctionListScreen';
import AuctionPendingChangesListScreen from 'screens/Auction/AuctionPendingChangesListScreen';
import AuctionUpdateScreen from 'screens/Auction/AuctionUpdateScreen';
import AuctionViewScreen from 'screens/Auction/AuctionViewScreen';
import CustomerListScreen from 'screens/Customer/CustomerListScreen';
import CustomerViewScreen from 'screens/Customer/CustomerViewScreen';
import DownloadPaperlessAppScreen from 'screens/DownloadPaperlessAppScreen';
import HomeScreen from 'screens/HomeScreen';
import InvoiceListScreen from 'screens/Invoice/InvoiceListScreen';
import InvoiceViewScreen from 'screens/Invoice/InvoiceViewScreen';
import ItemListScreen from 'screens/Items/ItemListScreen';
import ItemViewScreen from 'screens/Items/ItemViewScreen';
import LocationListScreen from 'screens/Location/LocationListScreen';
import LocationViewScreen from 'screens/Location/LocationViewScreen';
import NotFoundScreen from 'screens/NotFoundScreen';
import PaymentListScreen from 'screens/Payment/PaymentListScreen';
import PaymentViewScreen from 'screens/Payment/PaymentViewScreen';
import PickupLiveDashboardScreen from 'screens/Pickup/PickupLiveDashboardScreen';
import PickupLiveScreen from 'screens/Pickup/PickupLiveScreen';
import PickupQueueListScreen from 'screens/Pickup/PickupQueueListScreen';
import PrinterListScreen from 'screens/Printer/PrinterListScreen';
import PrinterViewScreen from 'screens/Printer/PrinterViewScreen';
import ProfileScreen from 'screens/Profile/ProfileScreen';
import RackLocationEntryListScreen from 'screens/RackLocation/RackLocationEntryListScreen';
import RackLocationListScreen from 'screens/RackLocation/RackLocationListScreen';
import RackLocationMissingScreen from 'screens/RackLocation/RackLocationMissingScreen';
import AuctionSummaryReportScreen from 'screens/Report/AuctionSummaryReportScreen';
import ReportsScreen from 'screens/Report/ReportsScreen';
import ReturnReportScreen from 'screens/Report/ReturnReportScreen';
import TaxReportScreen from 'screens/Report/TaxReportScreen';
import ReturnQueueViewScreen from 'screens/ReturnQueue/ViewScreen';
import BidWranglerReturnQueueListScreen from 'screens/ReturnQueue/BidWrangler/ListScreen';
import ReturnSettingsScreen from 'screens/Setting/Return/ReturnSettingsScreen';
import SettingScreen from 'screens/SettingsScreen';
import SignInScreen from 'screens/SignInScreen';
import TransactionListScreen from 'screens/Transaction/TransactionListScreen';
import TransactionViewScreen from 'screens/Transaction/TransactionViewScreen';
import TruckloadListScreen from 'screens/Truckload/TruckloadListScreen';
import TruckloadViewScreen from 'screens/Truckload/TruckloadViewScreen';
import UserListScreen from 'screens/User/UserListScreen';
import UserViewScreen from 'screens/User/UserViewScreen';
import PicklistQueueScreen from 'screens/Picklist/PicklistQueueScreen';
import { ADMINISTRATOR } from 'utils/auth';
import ShippingQueueScreen from 'screens/Shipping/ShippingQueueScreen';
import MercuryReturnQueueListScreen from 'screens/ReturnQueue/Mercury/ListScreen';
import EmployeeStatsScreen from 'screens/EmployeeStatsScreen';

const ADMIN_ONLY = [ADMINISTRATOR];

export type RouteProps = {
  title: string;
  path: string;
  appWrapper?: 'simple' | 'full';
  component: React.FunctionComponent;
  exact?: boolean;
  accessRoles?: UserRoles[];
};

export const publicRoutes: RouteProps[] = [
  { path: '/signIn', component: SignInScreen, title: 'Sign in' },
  {
    path: '/downloadPaperlessApp',
    component: DownloadPaperlessAppScreen,
    title: 'Download',
  },
];

export const privateRoutes: RouteProps[] = [
  { path: '/', component: HomeScreen, title: 'Dashboard' },
  { path: '/404', component: NotFoundScreen, title: 'Not Found' },
  { path: '/profile', component: ProfileScreen, title: 'Profile' },
  { path: '/users/:id', component: UserViewScreen, title: 'Users' },
  { path: '/users', component: UserListScreen, title: 'Users' },
  { path: '/locations/:id', component: LocationViewScreen, title: 'Locations' },
  { path: '/locations', component: LocationListScreen, title: 'Locations' },
  { path: '/printers/:id', component: PrinterViewScreen, title: 'Printers' },
  { path: '/printers', component: PrinterListScreen, title: 'Printers' },
  {
    path: '/auctionChanges',
    component: AuctionPendingChangesListScreen,
    title: 'Auction Changes',
  },
  {
    path: '/auctions/:id/edit',
    component: AuctionUpdateScreen,
    title: 'Auctions',
  },
  {
    path: '/auctions/:id',
    component: AuctionViewScreen,
    exact: false,
    title: 'Auctions',
  },
  {
    path: '/auctions',
    component: AuctionListScreen,
    title: 'Auctions',
  },
  { path: '/invoices/:id', component: InvoiceViewScreen, title: 'Invoices' },
  { path: '/invoices', component: InvoiceListScreen, title: 'Invoices' },
  { path: '/items', component: ItemListScreen, title: 'Items' },
  { path: '/items/:itemId', component: ItemViewScreen, title: 'Items' },
  { path: '/payments', component: PaymentListScreen, title: 'Payments' },
  { path: '/payments/:id', component: PaymentViewScreen, title: 'Payments' },
  {
    path: '/transactions',
    component: TransactionListScreen,
    title: 'Transactions',
  },
  {
    path: '/transactions/:id',
    component: TransactionViewScreen,
    title: 'Transactions',
  },
  {
    path: '/customers/:id',
    component: CustomerViewScreen,
    exact: false,
    title: 'Customer',
  },
  { path: '/customers', component: CustomerListScreen, title: 'Customer' },
  { path: '/printers', component: PrinterListScreen, title: 'Printers' },
  { path: '/printers/:id', component: PrinterViewScreen, title: 'Printers' },
  {
    path: '/pickup/queue',
    component: PickupQueueListScreen,
    title: 'Pickup Queue',
  },
  { path: '/pickup/live', component: PickupLiveScreen, title: 'Live Pickup' },
  {
    path: '/pickup/liveDashboard',
    component: PickupLiveDashboardScreen,
    title: 'Live Pickup',
    appWrapper: 'simple',
  },
  {
    path: '/picklist/queue',
    component: PicklistQueueScreen,
    title: 'Picklist Queue',
  },
  {
    path: '/shipping/queue',
    component: ShippingQueueScreen,
    title: 'Shipping Queue',
  },
  {
    path: '/rackLocations/list',
    component: RackLocationListScreen,
    title: 'RackLocations',
  },
  {
    path: '/rackLocations/missing',
    component: RackLocationMissingScreen,
    title: 'RackLocations',
  },
  {
    path: '/rackLocations/:rackLocation',
    component: RackLocationEntryListScreen,
    title: 'RackLocations',
  },
  {
    path: '/reports',
    component: ReportsScreen,
    title: 'Reports',
    accessRoles: ADMIN_ONLY,
  },
  {
    path: '/reports/tax',
    component: TaxReportScreen,
    title: 'Tax Report',
    accessRoles: ADMIN_ONLY,
  },
  {
    path: '/reports/auctionSummary',
    component: AuctionSummaryReportScreen,
    title: 'Auction Summary Report',
    accessRoles: ADMIN_ONLY,
  },
  {
    path: '/reports/returns',
    component: ReturnReportScreen,
    title: 'Returns Report',
    accessRoles: ADMIN_ONLY,
  },
  {
    path: '/settings',
    component: SettingScreen,
    title: 'Settings',
  },
  {
    path: '/settings/returns',
    component: ReturnSettingsScreen,
    title: 'Return Options',
  },
  {
    path: '/returnQueue/bidwrangler',
    component: BidWranglerReturnQueueListScreen,
    title: 'Return Queue',
  },
  {
    path: '/returnQueue/mercury',
    component: MercuryReturnQueueListScreen,
    title: 'Mercury Return Queue',
  },
  {
    path: '/returnQueue/:id',
    component: ReturnQueueViewScreen,
    title: 'Return Queue',
  },
  {
    path: '/truckloads',
    component: TruckloadListScreen,
    title: 'Truckloads',
    accessRoles: ADMIN_ONLY,
  },
  {
    path: '/truckloads/:id',
    component: TruckloadViewScreen,
    title: 'Truckloads',
    accessRoles: ADMIN_ONLY,
  },
  {
    path: '/employeeStats',
    component: EmployeeStatsScreen,
    title: 'Employee Stats',
    accessRoles: ADMIN_ONLY,
  },
];
