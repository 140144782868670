import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { GetInvoiceByIdEntryFragment } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { formatCentsToDollars } from 'utils/money';

const useStyles = makeStyles(() => ({
  deletedRow: {
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  label: { color: '#757575' },
}));

const EmptyTableCell = styled(TableCell)(({}) => ({
  root: {
    border: 0,
  },
}));

const LabelTableCell = styled(TableCell)(({}) => ({
  root: {
    border: 0,
    paddingRight: 0,
  },
}));

const LabelTableCell2 = styled(TableCell)(({}) => ({
  root: {
    paddingRight: 0,
  },
}));

type Props = {
  invoice: GetInvoiceByIdEntryFragment | undefined;
};
const InvoiceAdjustmentsTable: FunctionComponent<Props> = ({ invoice }) => {
  const classes = useStyles();
  const adjustments = invoice?.invoiceAdjustmentsByInvoiceId?.nodes ?? [];

  return (
    <>
      <Table size="small">
        <TableBody>
          {adjustments.map(({ id, description, amount, type }) => (
            <TableRow key={id} style={{ backgroundColor: '#fafafa' }}>
              <TableCell colSpan={3} align="left">
                <Typography variant="body1">{type}</Typography>
                <Typography variant="body2">{description}</Typography>
              </TableCell>
              <TableCell colSpan={1} align="right">
                <Typography variant="body2" align="right">
                  {formatCentsToDollars(amount)}
                </Typography>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <EmptyTableCell colSpan={1} />
            <LabelTableCell colSpan={2}>
              <Typography variant="body2" align="right" component="div">
                <Box className={classes.label}>Items subtotal</Box>
              </Typography>
            </LabelTableCell>
            <EmptyTableCell
              colSpan={1}
              style={{ width: '100px', maxWidth: '100px', minWidth: '100px' }}
            >
              <Typography variant="body2" align="right" component="div">
                {formatCentsToDollars(invoice?.itemsSubtotal)}
              </Typography>
            </EmptyTableCell>
          </TableRow>
          <TableRow>
            <EmptyTableCell colSpan={1} />
            <LabelTableCell colSpan={2}>
              <Typography variant="body2" align="right" component="div">
                <Box className={classes.label}>Fees subtotal</Box>
              </Typography>
            </LabelTableCell>
            <EmptyTableCell colSpan={1}>
              <Typography variant="body2" align="right" component="div">
                {formatCentsToDollars(invoice?.feesSubtotal)}
              </Typography>
            </EmptyTableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1} />
            <LabelTableCell2 colSpan={2}>
              <Typography variant="subtitle2" align="right" component="div">
                <Box className={classes.label}>Total</Box>
              </Typography>
            </LabelTableCell2>
            <TableCell colSpan={1}>
              <Typography variant="subtitle2" align="right" component="div">
                {formatCentsToDollars(invoice?.total)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <EmptyTableCell colSpan={1} />
            <LabelTableCell colSpan={2}>
              <Typography variant="body2" align="right" component="div">
                <Box className={classes.label}>Credits used</Box>
              </Typography>
            </LabelTableCell>
            <EmptyTableCell colSpan={1}>
              <Typography variant="body2" align="right" component="div">
                {formatCentsToDollars(invoice?.bidderCreditsUsed)}
              </Typography>
            </EmptyTableCell>
          </TableRow>
          <TableRow>
            <EmptyTableCell colSpan={1} />
            <LabelTableCell colSpan={2}>
              <Typography variant="body2" align="right" component="div">
                <Box className={classes.label}>Payment used</Box>
              </Typography>
            </LabelTableCell>
            <EmptyTableCell colSpan={1}>
              <Typography variant="body2" align="right" component="div">
                {formatCentsToDollars(invoice?.paymentCreditsUsed)}
              </Typography>
            </EmptyTableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={1} />
            <LabelTableCell2 colSpan={2}>
              <Typography variant="body2" align="right" component="div">
                <Box className={classes.label}>Alternate payment used</Box>
              </Typography>
            </LabelTableCell2>
            <TableCell colSpan={1}>
              <Typography variant="body2" align="right" component="div">
                {formatCentsToDollars(invoice?.alternateCreditsUsed)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <EmptyTableCell colSpan={1} />
            <LabelTableCell colSpan={2}>
              <Typography variant="subtitle2" align="right" component="div">
                <Box className={classes.label}>Amount due</Box>
              </Typography>
            </LabelTableCell>
            <EmptyTableCell colSpan={1}>
              <Typography variant="subtitle2" align="right" component="div">
                {formatCentsToDollars(Number(invoice?.amountDue))}
              </Typography>
            </EmptyTableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default InvoiceAdjustmentsTable;
