import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import Icon from '@mui/material/Icon';
import InputAdornment from '@mui/material/InputAdornment';
import React from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';
import { ShowErrorFunc, showErrorOnChange, ShowErrorProps } from './Util';

type TextWrapperProps = FieldRenderProps<MuiTextFieldProps, HTMLElement>;

export function TextFieldWrapper(props: TextWrapperProps) {
  const {
    input: { name, value, type, onChange, onBlur, onFocus, ...restInput },
    meta,
    required,
    fullWidth = true,
    helperText,
    showError = showErrorOnChange,
    ...rest
  } = props;

  const { error, submitError } = meta;
  const isError = showError({ meta });

  return (
    <MuiTextField
      fullWidth={fullWidth}
      helperText={isError ? error || submitError : helperText}
      error={isError}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      name={name}
      value={value}
      type={type}
      required={required}
      inputProps={{ required, ...restInput }}
      {...rest}
    />
  );
}

export type MuiRffTextFieldProps = Partial<
  Omit<MuiTextFieldProps, 'onChange'>
> & {
  name: string;
  fieldProps?: Partial<FieldProps<any, any>>;
  showError?: ShowErrorFunc;
};

export function MuiRffTextField(props: MuiRffTextFieldProps) {
  const { name, type, fieldProps, ...rest } = props;

  return (
    <Field
      name={name}
      type={type}
      render={({ input, meta }) => (
        <TextFieldWrapper
          input={input}
          meta={meta}
          name={name}
          type={type}
          {...rest}
        />
      )}
      {...fieldProps}
    />
  );
}

type Props = {
  parseType?: 'integer' | 'decimal' | 'percentage' | 'money';
  validateInitialValue?: boolean;
  endAdornment?: React.ReactNode;
} & MuiRffTextFieldProps;

type ExtendedFieldProps = {
  parse?: (value: any, name: string) => any;
};

const validateInitialValuesShowErrorFunction = ({
  meta: { submitError, error },
}: ShowErrorProps): boolean => !!(submitError || error);

const parseDecimal = (value: string, maxDecimalLength = 2) => {
  let v = value.replace(/[^0-9.]/g, '');
  if (!v.length) {
    return v;
  }
  if (v[v.length - 1] === '.') {
    const m = v.match(/\./g);
    if (m && m.length > 1) {
      return v.slice(0, v.length - 1);
    }
    return v;
  }
  v = String(parseFloat(v));
  const r = v.split('.');

  if (r.length > 1 && r[1].length > maxDecimalLength) {
    return v.slice(0, v.length - 1);
  }
  return v;
};
const parsePercentage = (value: number) => {
  if (value > 100) {
    return 100;
  }
  if (value < 0) {
    return 0;
  }
  return value;
};

const TextField = ({ parseType, validateInitialValue, ...props }: Props) => {
  const extendedFieldProps: ExtendedFieldProps = {};
  const inputProps: any = {};
  if (props.endAdornment) {
    inputProps.endAdornment = props.endAdornment;
  }
  if (parseType === 'integer') {
    extendedFieldProps.parse = (value) => Number(value);
    inputProps.type = 'number';
  } else if (parseType === 'decimal' || parseType === 'money') {
    inputProps.type = 'number';
    inputProps.step = '1';
    extendedFieldProps.parse = (value) => parseFloat(parseDecimal(value));
    if (parseType === 'money') {
      inputProps.startAdornment = (
        <InputAdornment position="start">$</InputAdornment>
      );
    }
  } else if (parseType === 'percentage') {
    inputProps.type = 'number';
    inputProps.step = '1';
    inputProps.min = '0';
    inputProps.max = '100';
    extendedFieldProps.parse = (value) =>
      parsePercentage(parseFloat(parseDecimal(value, 3)));
    inputProps.endAdornment = (
      <InputAdornment position="end">
        <Icon>%</Icon>
      </InputAdornment>
    );
  }

  return (
    <MuiRffTextField
      fieldProps={extendedFieldProps}
      {...props}
      InputProps={inputProps}
      showError={
        validateInitialValue
          ? validateInitialValuesShowErrorFunction
          : undefined
      }
    />
  );
};

export default TextField;
