import { SortTypes } from '@digitus-data/material-ui-table-apollo';
import { Box } from '@mui/material';
import Button from 'components/Button';
import Table from 'components/Table';
import {
  BidderCreditHistoryDocument,
  BidderCreditHistoryEntryFragment,
} from 'graphql/graphql-types';
import { useModal } from 'hooks/modal';
import React, { FunctionComponent } from 'react';
import { formatCentsToDollars } from 'utils/money';
import CustomerCreditAdjustModal from './CustomerCreditAdjustModal';

type Row = {
  row: BidderCreditHistoryEntryFragment;
};
type Props = {
  customerId: number;
};
const CustomerCreditHistoryTable: FunctionComponent<Props> = ({
  customerId,
}) => {
  const { visible, open, close } = useModal();
  const condition = {
    bidderId: {
      equalTo: customerId,
    },
  };

  return (
    <>
      <Table
        renderActions={() => (
          <Box>
            <Button text="Adjust credit" onClick={open} />
          </Box>
        )}
        defaultSort={SortTypes.DESC}
        defaultOrder="createdAt"
        condition={condition}
        query={BidderCreditHistoryDocument}
        columns={[
          { label: 'Id', name: 'id', width: 50 },
          { label: 'Type', name: 'txType', width: 80 },
          {
            label: 'Invoice',
            name: 'invoiceByInvoiceId.uid',
          },
          {
            label: 'Auction',
            name: 'auctionByAuctionId.name',
          },
          {
            label: 'Credit',
            name: 'credit',
            render: ({ row }: Row) => formatCentsToDollars(row.credit),
            width: 100,
          },
          { label: 'Note', name: 'note' },
          {
            label: 'Time',
            name: 'createdAt',
            type: 'date',
            formatDateValue: 'MM/DD/YYYY h:mm A',
            width: 220,
          },
        ]}
      />
      <CustomerCreditAdjustModal
        customerId={customerId}
        visible={visible}
        handleClose={close}
      />
    </>
  );
};

export default CustomerCreditHistoryTable;
