import React, { FunctionComponent } from 'react';

export const CustomChartTooltip: FunctionComponent = ({ children }) => (
  <>{children}</>
);

export const CustomChartTooltipTitle: FunctionComponent<{
  title: string;
}> = ({ title }) => (
  <div className="g2-tooltip-title" style={{ marginBottom: '4px' }}>
    {title}
  </div>
);

export const CustomChartTooltipList: FunctionComponent = ({ children }) => (
  <ul className="g2-tooltip-list">{children}</ul>
);

export const CustomChartTooltipListItem: FunctionComponent<{
  name: string;
  value: string | number;
}> = ({ name, value }) => (
  <li className="g2-tooltip-list-item">
    <span className="g2-tooltip-name">{name}</span>
    <span className="g2-tooltip-value">{value}</span>
  </li>
);
