import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    // boxShadow: 'rgb(217 220 221 / 32%) 0px 8px 16px 6px',
    // borderRadius: '4px',
    padding: theme.spacing(3.5, 4),
    marginBottom: theme.spacing(5),
  },
  disablePadding: {
    padding: 0,
  },
  disableMargin: {
    margin: 0,
  },
}));

type Props = {
  children: ReactNode;
  disablePadding?: boolean;
  disableMargin?: boolean;
};
export default ({ children, disablePadding, disableMargin }: Props) => {
  const styles = useStyles();
  const classes = clsx([
    styles.root,
    disablePadding && styles.disablePadding,
    disableMargin && styles.disableMargin,
  ]);
  return <Box className={classes}>{children}</Box>;
};
