import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import {
  PicklistQueueStatus,
  useUpdatePicklistQueueMutation,
} from 'graphql/graphql-types';
import React from 'react';
import { logError } from 'utils/logging';

type Props = {
  id: string;
  disabled: boolean;
};

const Comp: React.FC<Props> = ({ id, disabled }) => {
  const [updateItem] = useUpdatePicklistQueueMutation();

  const onClick = React.useCallback(async () => {
    if (id && !disabled) {
      try {
        await updateItem({
          variables: {
            input: {
              id,
              picklistQueuePatch: {
                assignedUserId: null,
                step: null,
                stepValue: null,
                status: PicklistQueueStatus.Waiting,
              },
            },
          },
          // invalidate field from cache or else it will still be displayed on dashboard
          update(cache) {
            cache.modify({
              id: cache.identify({
                __typename: 'PicklistQueue',
                id,
              }),
              fields: {
                userByAssignedUserId: () => null,
                status: () => PicklistQueueStatus.Waiting,
              },
            });
            cache.modify({
              fields: {
                allPicklistQueues(existing, { readField }) {
                  return {
                    ...existing,
                    nodes: existing.nodes.filter(
                      (ref: any) => readField('id', ref) !== id,
                    ),
                  };
                },
              },
            });
          },
        });
      } catch (e) {
        logError(e);
      }
    }
  }, [id, disabled]);

  return (
    <IconButton
      size="small"
      sx={{ marginRight: 1 }}
      onClick={onClick}
      disabled={disabled}
    >
      <ClearIcon fontSize="small" />
    </IconButton>
  );
};

export default Comp;
