import React from 'react';
import { Box } from '@mui/material';

type Props = {
  data: any;
};
const JsonView = ({ data }: Props) => (
  <Box>
    <pre>{data}</pre>
  </Box>
);

export default JsonView;
