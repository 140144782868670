import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import CircleLoading from 'components/CircleLoading';
import { ReportGroupBy } from 'graphql/graphql-types';
import moment, { Moment } from 'moment';
import React, {
  FunctionComponent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

export type Option = {
  key: string;
  display: string;
  interval: ReportGroupBy;
  start: Moment;
  end: Moment;
};

const generateOptions = (): Option[] => {
  const newOptions: Option[] = [];

  newOptions.push({
    key: 'Month-to-date',
    display: 'Month to date',
    interval: ReportGroupBy.Week,
    start: moment().startOf('month'),
    end: moment().endOf('day'),
  });

  newOptions.push({
    key: 'Quarter-to-date',
    display: 'Quarter to date',
    interval: ReportGroupBy.Week,
    start: moment().quarter(moment().quarter()).startOf('quarter'),
    end: moment().endOf('day'),
  });

  newOptions.push({
    key: 'Year-to-date',
    display: 'Year to date',
    interval: ReportGroupBy.Week,
    start: moment().startOf('year'),
    end: moment().endOf('day'),
  });

  newOptions.push({
    key: 'Last-month',
    display: 'Last month',
    interval: ReportGroupBy.Week,
    start: moment().subtract(1, 'months').startOf('month'),
    end: moment().subtract(1, 'months').endOf('month'),
  });

  newOptions.push({
    key: 'Last-quarter',
    display: 'Last quarter',
    interval: ReportGroupBy.Week,
    start: moment().subtract(1, 'quarters').startOf('quarter'),
    end: moment().subtract(1, 'quarters').endOf('quarter'),
  });

  newOptions.push({
    key: 'Last-year',
    display: 'Last year',
    interval: ReportGroupBy.Month,
    start: moment().subtract(1, 'years').startOf('year'),
    end: moment().subtract(1, 'years').endOf('year'),
  });

  const dateStart = moment().subtract(1, 'months');
  const dateEnd = moment().subtract(7, 'months');

  while (dateEnd.diff(dateStart, 'months') <= 0) {
    const key = dateStart.clone().format('MMM YYYY');
    newOptions.push({
      key,
      display: key,
      interval: ReportGroupBy.Week,
      start: dateStart.clone().startOf('month'),
      end: dateStart.clone().endOf('month'),
    });
    dateStart.subtract(1, 'month');
  }

  return newOptions;
};

type Props = {
  onChange: (option: Option) => void;
};
const ReportPeriodSelect: FunctionComponent<Props> = ({ onChange }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<Option | null>(null);

  const options: Option[] = useMemo(() => generateOptions(), []);

  const onSelect = (option: Option) => {
    setSelected(option);
    onChange(option);
    setOpen(false);
  };

  useEffect(() => {
    if (options.length && loading) {
      setLoading(false);
      onSelect(options?.[0] ?? null);
    }
  }, [options, loading]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box flex={1}>
      {loading ? (
        <CircleLoading />
      ) : (
        <>
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            disableElevation
            size="small"
            variant="outlined"
          >
            {selected?.display ?? 'Select an option'}
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal={false}
            style={{ zIndex: 999 }}
            placement="bottom"
            modifiers={[
              {
                name: 'flip',
                enabled: false,
              },
              {
                name: 'arrow',
                enabled: true,
              },
            ]}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      {options.map((option) => (
                        <MenuItem
                          key={option.key}
                          onClick={() => onSelect(option)}
                          divider={option.key === 'Last-year'}
                        >
                          {option.display}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </Box>
  );
};

export default ReportPeriodSelect;
