import { Link } from '@mui/material';
import { TypographyVariant } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  root: {
    color: '#5469d4',
    '&:hover': {
      color: '#000000',
    },
  },
  bold: {
    fontWeight: 500,
  },
  inline: {
    textDecoration: 'underline dotted #5469d4',
  },
}));

type Props = {
  text: string | JSX.Element;
  onClick: () => void;
  type?: 'link' | 'inline';
  variant?: TypographyVariant;
  bold?: boolean;
};
const Hyperlink: FunctionComponent<Props> = ({
  text,
  onClick,
  type = 'link',
  variant = 'body1',
  bold,
}) => {
  const styles = useStyles();
  const classes = clsx([
    styles.root,
    type === 'inline' && styles.inline,
    bold && styles.bold,
  ]);
  return (
    <Link
      className={classes}
      component="button"
      variant={variant}
      underline={type === 'link' ? 'none' : 'always'}
      onClick={onClick}
    >
      {text}
    </Link>
  );
};

export default Hyperlink;
