import { Box } from '@mui/material';
import React from 'react';

export type Props = {
  text?: string;
  loading?: boolean;
};
export default ({ text, loading }: Props) => {
  if (loading) {
    return null;
  }
  return <Box>{text && text}</Box>;
};
