import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, IconButton } from '@mui/material';
import { ReturnQueueTypeChip } from 'components/Chips';
import ContentHeader from 'components/ContentHeader';
import CustomerAlertsModal from 'components/CustomerAlertsModal';
import Hyperlink from 'components/Hyperlink';
import ItemReturnModal from 'components/ItemReturn/ItemReturnModal';
import LineItems from 'components/LineItems';
import { ViewScreen } from 'components/ViewScreen';
import { useGetReturnQueueByIdQuery } from 'graphql/graphql-types';
import { useModal } from 'hooks/modal';
import React, { FunctionComponent, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { formatDateTime } from 'utils/time';
import Button from 'components/Button';
import { formatCentsToDollars } from 'utils/money';
import ReturnQueueStatusSelect from 'components/ReturnQueueStatusSelect';

const ReturnQueueViewScreen: FunctionComponent = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    visible: visibleItemReturn,
    open: openItemReturn,
    close: closeItemReturn,
  } = useModal();
  const {
    visible: visibleAlerts,
    open: openAlerts,
    close: closeAlerts,
  } = useModal();
  const { data, loading, refetch, error } = useGetReturnQueueByIdQuery({
    variables: {
      id,
    },
  });

  const returnQueue = data?.returnQueueById;
  const isMer = returnQueue?.isMer;
  const type = returnQueue?.type;
  const status = returnQueue?.status;
  const createdByUserId = returnQueue?.createdBy;
  const createdByUserName = returnQueue?.userByCreatedBy?.name ?? '';
  const createdAt = returnQueue?.createdAt;
  const completedAt = returnQueue?.completedAt;
  const completedByUserId = returnQueue?.completedBy;
  const completedByUserName = returnQueue?.userByCompletedBy?.name ?? '';
  const item = returnQueue?.itemByItemId;
  const invoice = item?.invoiceByInvoiceId;
  const invoiceUid = invoice?.uid ?? '';
  const invoiceId = invoice?.id;
  const itemId = item?.id;
  const itemNumber = item?.number ?? '';
  const itemDescription = item?.description;
  const amount = returnQueue?.amount;
  const note = returnQueue?.note;
  const reason = returnQueue?.returnOptionByOptionId?.name;
  const customerName = item?.bidderByBidderId?.name;
  const customerId = item?.bidderByBidderId?.id;
  const activeAlertNotesCount = item?.bidderByBidderId?.activeAlertNotesCount;

  const afterStatusChange = useCallback(() => {
    refetch?.();
  }, [refetch]);

  const openInvoice = useCallback(() => {
    if (invoiceId) {
      history.push(`/invoices/${invoiceId}`);
    }
  }, [invoiceId]);

  const openItem = useCallback(() => {
    if (itemId) {
      history.push(`/items/${itemId}`);
    }
  }, [itemId]);

  const openCreatedUser = useCallback(() => {
    if (createdByUserId) {
      history.push(`/users/${createdByUserId}`);
    }
  }, [createdByUserId]);

  const openCompletedUser = useCallback(() => {
    if (completedByUserId) {
      history.push(`/users/${completedByUserId}`);
    }
  }, [completedByUserId]);

  const openCustomer = useCallback(() => {
    if (customerId) {
      history.push(`/customers/${customerId}`);
    }
  }, [customerId]);

  return (
    <ViewScreen
      loading={loading}
      error={error}
      header={{
        dividerProps: {
          hide: true,
        },
        leftProps: {
          title: 'Return',
        },
        centerProps: {
          component: <ReturnQueueTypeChip type={type} />,
        },
        bottomProps: {
          component: !isMer ? (
            <ReturnQueueStatusSelect
              returnQueueId={id}
              defaultValue={status}
              afterChange={afterStatusChange}
            />
          ) : undefined,
        },
      }}
    >
      <ContentHeader title="Details" size="large" />
      <>
        {!isMer && (
          <Box marginBottom={2}>
            <Button text="Issue Return" onClick={openItemReturn} />
          </Box>
        )}
        <LineItems
          description="Type"
          value={<ReturnQueueTypeChip type={type} />}
        />
        <LineItems description="Reason" value={reason} />
        <LineItems description="Note" value={note} />
        <LineItems description="Amount" value={formatCentsToDollars(amount)} />
      </>
      <ContentHeader
        title="Relationships"
        size="large"
        containerProps={{ mt: 4 }}
      />
      {customerName ? (
        <LineItems
          description="Customer"
          value={
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              flexGrow={1}
            >
              <Box>
                <Hyperlink onClick={openCustomer} text={customerName} />
              </Box>
              {activeAlertNotesCount ? (
                <Box paddingLeft={1}>
                  <IconButton size="small" onClick={openAlerts}>
                    <PriorityHighIcon />
                  </IconButton>
                </Box>
              ) : null}
            </Box>
          }
        />
      ) : null}
      <LineItems
        description="Invoice"
        value={<Hyperlink text={invoiceUid} onClick={openInvoice} />}
      />
      <LineItems
        description="Number"
        value={<Hyperlink text={String(itemNumber)} onClick={openItem} />}
      />
      <LineItems description="Description" value={itemDescription} stacked />
      <ContentHeader title="Meta" size="large" containerProps={{ mt: 4 }} />
      <LineItems
        description="Created by"
        value={<Hyperlink text={createdByUserName} onClick={openCreatedUser} />}
      />
      <LineItems description="Created on" value={formatDateTime(createdAt)} />
      <LineItems
        description="Completed by"
        value={
          <Hyperlink text={completedByUserName} onClick={openCompletedUser} />
        }
      />
      <LineItems
        description="Completed on"
        value={formatDateTime(completedAt)}
      />
      <ItemReturnModal
        itemId={Number(itemId)}
        queueId={returnQueue?.id}
        open={visibleItemReturn}
        onClose={closeItemReturn}
        onSuccess={() => {
          refetch?.();
          closeItemReturn();
        }}
      />
      <CustomerAlertsModal
        isOpen={visibleAlerts}
        customerId={customerId}
        onOk={closeAlerts}
      />
    </ViewScreen>
  );
};

export default ReturnQueueViewScreen;
