import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import React, { FunctionComponent } from 'react';

type Props = {
  loading: boolean;
  allowChildrenRender?: boolean;
} & SkeletonProps;
const SkeletonWrapper: FunctionComponent<Props> = ({
  loading,
  allowChildrenRender,
  children,
  ...props
}) => {
  if (!allowChildrenRender) {
    return loading ? <Skeleton {...props} /> : <>{children}</>;
  }
  return loading ? (
    <Skeleton {...props}>
      <>{children}</>
    </Skeleton>
  ) : (
    <>{children}</>
  );
};

export default SkeletonWrapper;
