import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import { Stack } from '@mui/material';
import Button from 'components/Button';
import LocationSelect, { Option } from 'components/LocationSelect';
import { ViewScreen } from 'components/ViewScreen';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { openInNewTab } from 'utils/general';

const PickupLiveScreen: FunctionComponent = () => {
  const [location, setLocation] = useState<Option | null>(null);
  const handleStart = useCallback(() => {
    if (location?.id && location?.name) {
      openInNewTab(
        `/pickup/liveDashboard?id=${location.id}&name=${location.name}`,
      );
    }
  }, [location]);
  const onLocationChange = (option: Option) => setLocation(option);

  return (
    <ViewScreen
      loading={false}
      header={{
        leftProps: {
          title: 'Live Pickup',
        },
        bottomProps: {
          component: (
            <Stack direction="row" spacing={1}>
              <LocationSelect onChange={onLocationChange} />
              <Button
                disabled={!location?.id}
                startIcon={<PlayCircleFilledWhiteIcon />}
                onClick={handleStart}
                text="Start"
              />
            </Stack>
          ),
        },
      }}
    >
      <span />
    </ViewScreen>
  );
};

export default PickupLiveScreen;
