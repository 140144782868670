import ModalLoading from 'components/ModalLoading';
import React, { FunctionComponent } from 'react';

type Props = {
  ready: boolean;
};
const FormWrapper: FunctionComponent<Props> = ({ ready, children }) => {
  if (!ready) {
    return <ModalLoading />;
  }
  return <>{children}</>;
};

export default FormWrapper;
