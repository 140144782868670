import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button, { Props as ButtonProps } from 'components/Button';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingTop: 12,
  },
  leftButton: {
    marginLeft: theme.spacing(1),
  },
}));

type Props = {
  buttons?: ButtonProps[];
};
const ModalActions: FunctionComponent<Props> = ({ buttons }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      {buttons ? (
        <Box display="flex" alignItems="center" flexDirection="row">
          {buttons.map((props, i) => (
            <Box key={String(i)} className={classes.leftButton}>
              <Button key={`buttons-${i}`} {...props} />
            </Box>
          ))}
        </Box>
      ) : null}
    </Box>
  );
};

export default ModalActions;
