import ListScreen from 'components/ListScreen';
import React, { FunctionComponent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ItemRackLocationsDocument,
  ItemRackLocationEntryFragment,
} from 'graphql/graphql-types';
import { formatDateTime } from 'utils/time';

type Row = {
  row: ItemRackLocationEntryFragment;
};

const RackLocationEntryListScreen: FunctionComponent = () => {
  const history = useHistory();
  const { rackLocation } = useParams<{ rackLocation: string }>();

  const condition = {
    rackLocation: {
      equalTo: rackLocation,
    },
  };

  return (
    <ListScreen
      title="Rack Location Items"
      query={ItemRackLocationsDocument}
      condition={condition}
      onRowClick={(row: Row['row']) => {
        history.push(`/invoices/${row.itemByItemId?.invoiceId}`);
      }}
      columns={[
        { label: 'Invoice', name: 'itemByItemId.invoiceByInvoiceId.uid' },
        { label: 'Item', name: 'itemByItemId.number' },
        { label: 'Auction', name: 'itemByItemId.auctionByAuctionId.name' },
        { label: 'Customer', name: 'itemByItemId.bidderByBidderId.name' },
        {
          label: 'Updated',
          name: 'rackLocation',
          render: ({ row }: Row) =>
            `${row.userByUpdatedBy?.name} on ${formatDateTime(row.updatedAt)}`,
        },
        { label: 'Relist Date', name: 'rackLocation' },
      ]}
    />
  );
};

export default RackLocationEntryListScreen;
