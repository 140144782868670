import { Box, Container } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    backgroundColor: theme.palette.common.white,
  },
  container: {
    padding: theme.spacing(3, 8),
    marginRight: 'auto',
    marginLeft: 'initial',
  },
}));

interface Props {
  children: React.ReactNode;
}

const AppContent = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth={false} className={classes.container}>
        <Box>{children}</Box>
      </Container>
    </main>
  );
};

export default AppContent;
