import { Box, CardContent, Grid, Typography } from '@mui/material';
import Button from 'components/Button';
import Card from 'components/Card';
import GraphTitle from 'components/GraphTitle';
import SkeletonWrapper from 'components/SkeletonWrapper';
import {
  ReportGroupBy,
  useDashboardAnalyticsQuery,
} from 'graphql/graphql-types';
import moment from 'moment';
import React, { FunctionComponent, useMemo } from 'react';
import ItemsSoldChart from 'screens/Report/components/ItemsSoldChart';

const HomeScreen: FunctionComponent = () => {
  const { data, loading, refetch } = useDashboardAnalyticsQuery({
    variables: {
      startDate: moment().startOf('year').toISOString(),
      endDate: moment().endOf('day').toISOString(),
      groupBy: ReportGroupBy.Week,
    },
    fetchPolicy: 'network-only',
    pollInterval: 60000,
  });

  const analytics = data?.dashboardAnalytics;
  const itemsSold = analytics?.numItemsSold;
  const itemsReturned = analytics?.numItemsReturned;
  const initItemsSoldData = data?.runItemSoldReport?.nodes ?? [];

  const itemsSoldData = useMemo(
    () =>
      initItemsSoldData?.map((d) => ({
        date: String(d.date),
        value: Number(d.count),
      })),
    [initItemsSoldData],
  );

  return (
    <Box>
      <Grid container>
        <Grid container justifyContent="flex-end">
          <Grid item style={{ paddingRight: 8 }}>
            <Button
              disabled={loading}
              onClick={() => refetch()}
              text="Refresh"
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card disableMargin>
            <SkeletonWrapper
              loading={loading}
              variant="rectangular"
              height={110}
            >
              <CardContent>
                <GraphTitle title="Total Items sold" />
                <Box display="flex" alignItems="flex-end">
                  <Box>
                    <Typography variant="h4">{itemsSold}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </SkeletonWrapper>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card disableMargin>
            <SkeletonWrapper
              loading={loading}
              variant="rectangular"
              height={110}
            >
              <CardContent>
                <GraphTitle title="Items Returned" />
                <Box display="flex" alignItems="flex-end">
                  <Box>
                    <Typography variant="h4">{itemsReturned}</Typography>
                  </Box>
                </Box>
              </CardContent>
            </SkeletonWrapper>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Card disableMargin>
            <SkeletonWrapper
              loading={loading}
              variant="rectangular"
              height={400}
            >
              <CardContent>
                <GraphTitle title="Items sold breakdown" />
                <ItemsSoldChart data={itemsSoldData} showTitle={false} />
              </CardContent>
            </SkeletonWrapper>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HomeScreen;
