import ReportPeriodSelect, { Option } from 'components/ReportPeriodSelect';
import ReportView from 'components/ReportView';
import { ViewScreen } from 'components/ViewScreen';
import {
  RunAuctionSummaryReportRecord,
  useRunAuctionSummaryReportLazyQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { logError } from 'utils/logging';
import { formatCentsToDollars } from 'utils/money';

type Row = {
  row: RunAuctionSummaryReportRecord;
};

const columns = [
  {
    label: 'Auction',
    name: 'auctionName',
    render: ({ row }: Row) => (row.auctionId ? row.auctionName : 'Total'),
  },
  {
    label: '# Total Items',
    name: 'totalItems',
    align: 'right' as const,
    render: ({ row }: Row) => row.countItems,
  },
  {
    label: '# Sold Items',
    name: 'totalSoldItems',
    align: 'right' as const,
    render: ({ row }: Row) => row.countItemsSold,
  },
  {
    label: '# Not Sold Items',
    name: 'totalUnsoldItems',
    align: 'right' as const,
    render: ({ row }: Row) => row.countItemsNotSold,
  },
  {
    label: 'Avg Price per Item',
    name: 'avgPricePerItem',
    align: 'right' as const,
    render: ({ row }: Row) => formatCentsToDollars(row.avgPricePerItem),
  },
  {
    label: 'Total Sales',
    name: 'totalSales',
    align: 'right' as const,
    render: ({ row }: Row) => formatCentsToDollars(row.totalSalePrice),
  },
  {
    label: 'Total Retail',
    name: 'totalRetail',
    align: 'right' as const,
    render: ({ row }: Row) => formatCentsToDollars(row.totalRetailPrice),
  },
  {
    label: 'Sales Percent',
    name: 'salesPercent',
    align: 'right' as const,
    render: ({ row }: Row) =>
      row.retailPercent ? `${row.retailPercent}%` : '',
  },
];

const AuctionSummaryReportScreen: FunctionComponent = () => {
  const [runQuery, { data, loading, error, called }] =
    useRunAuctionSummaryReportLazyQuery({
      fetchPolicy: 'network-only',
    });

  const onPeriodChange = ({ start, end }: Option) => {
    try {
      runQuery({
        variables: {
          startDate: start.toISOString(),
          endDate: end.toISOString(),
        },
      });
    } catch (e) {
      logError(e);
    }
  };

  const rows = data?.runAuctionSummaryReport?.nodes ?? [];

  return (
    <ViewScreen
      loading={false}
      header={{
        leftProps: {
          title: 'Auction Summary',
        },
        bottomProps: {
          component: <ReportPeriodSelect onChange={onPeriodChange} />,
        },
      }}
    >
      <ReportView
        loading={loading}
        error={error}
        called={called}
        rows={rows}
        columns={columns}
      />
    </ViewScreen>
  );
};

export default AuctionSummaryReportScreen;
