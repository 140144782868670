import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Radios } from 'components/Forms';
import EditorField from 'components/Forms/EditorField';
import TextField from 'components/Forms/TextField';
import { makeValidate } from 'components/Forms/Validation';
import { startSnackbar } from 'components/SnackBar';
import {
  AdjustBidderCreditsType,
  BidderCreditHistoryEntryFragmentDoc,
  useAdjustBidderCreditsMutation,
} from 'graphql/graphql-types';
import React, { FunctionComponent, useCallback } from 'react';
import { Form } from 'react-final-form';
import { mapEnumToArray, mapEnumToSelectOptions } from 'utils/graphql';
import { logError } from 'utils/logging';
import { makeWholeCents } from 'utils/money';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const formId = 'adjust-bidder-credit-form';

const TYPES = mapEnumToArray(AdjustBidderCreditsType);
const SELECT_TYPES = mapEnumToSelectOptions(AdjustBidderCreditsType);

const Schema = Yup.object({
  note: Yup.string().required('Required'),
  action: Yup.string().oneOf(TYPES).required(),
  amount: Yup.number()
    .typeError('Must be a number')
    .positive()
    .required('Required'),
});

const validate = makeValidate(Schema);

type Props = {
  customerId: number;
  visible: boolean;
  handleClose: () => void;
};
const CustomerCreditAdjustModal: FunctionComponent<Props> = ({
  customerId,
  visible,
  handleClose,
}) => {
  const [adjustBidderCredit] = useAdjustBidderCreditsMutation({
    update(cache, { data }) {
      cache.modify({
        fields: {
          allBidderCreditHistories(existingBidderCredits = []) {
            const newBidderCreditsRef = cache.writeFragment({
              data: data?.adjustBidderCredits?.bidderCreditHistory,
              fragment: BidderCreditHistoryEntryFragmentDoc,
            });
            return {
              ...existingBidderCredits,
              nodes: [...existingBidderCredits.nodes, newBidderCreditsRef],
            };
          },
        },
      });
      cache.modify({
        id: cache.identify({
          __typename: 'Bidder',
          id: customerId,
        }),
        fields: {
          creditBalance: () => {
            return (
              data?.adjustBidderCredits?.bidderByBidderId?.creditBalance ?? 0
            );
          },
        },
      });
    },
  });

  const handleSave = useCallback(() => {
    document
      .getElementById('collect-payment-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }, []);

  const onSubmit = useCallback(
    async (values: Asserts<typeof Schema>) => {
      try {
        await adjustBidderCredit({
          variables: {
            values: {
              bidderId: customerId,
              note: values.note,
              type: values.action as AdjustBidderCreditsType,
              amount: makeWholeCents(values.amount),
            },
          },
        });
        handleClose();
      } catch (e: any) {
        logError(e);
        startSnackbar(`Failed to add credit: ${e.message}`);
      }
    },
    [customerId],
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Adjust credits"
      open={visible}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Adjust credits</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, ...props }) => (
            <form id={formId} onSubmit={handleSubmit}>
              <Radios
                label="Action"
                name="action"
                required
                data={SELECT_TYPES}
              />
              <TextField
                required
                label="Amount"
                id="amount"
                name="amount"
                parseType="money"
                variant="outlined"
              />
              <EditorField
                required
                label="Note"
                id="note"
                name="note"
                config={{
                  view: {
                    menu: true,
                    md: true,
                    html: false,
                  },
                  canView: {
                    fullScreen: false,
                    hideMenu: false,
                  },
                }}
                plugins={[
                  'header',
                  'font-bold',
                  'font-italic',
                  'font-underline',
                  'font-strikethrough',
                  'list-unordered',
                  'list-ordered',
                  'table',
                  'clear',
                  'logger',
                ]}
              />
              <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  disabled={
                    props.submitting ||
                    props.pristine ||
                    props.hasValidationErrors ||
                    (props.hasSubmitErrors && !props.modifiedSinceLastSubmit)
                  }
                  type="submit"
                  onClick={handleSave}
                  color="primary"
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CustomerCreditAdjustModal;
