import DeleteIcon from '@mui/icons-material/Delete';
import NoteIcon from '@mui/icons-material/Note';
import {
  Avatar,
  Box,
  Card,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from 'components/Button';
import NeutralChip from 'components/Chips/NeutralChip';
import Markdown from 'components/Markdown';
import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import {
  CustomerNote,
  CustomerNotesConnection,
  useDeleteCustomerNoteMutation,
} from 'graphql/graphql-types';
import React, { FunctionComponent, useCallback, useState } from 'react';
import 'react-markdown-editor-lite/lib/index.css';
import { parseCustomerNoteType } from 'utils/graphql';
import { logError } from 'utils/logging';
import { formatDateTime } from 'utils/time';

const useStyles = makeStyles((theme) => ({
  listTitle: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  paymentDate: {
    color: theme.palette.grey[600],
  },
}));

type NoteProps = {
  note: CustomerNote;
  onDelete: (id: number) => void;
};

const NoteEntry = ({ note, onDelete }: NoteProps) => {
  const { id, note: text, type, expiresAt, createdAt, userByCreatedBy } = note;
  const secondary = `By ${userByCreatedBy?.name} on ${formatDateTime(
    createdAt,
  )}`;

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar variant="rounded">
          <NoteIcon />
        </Avatar>
      </ListItemAvatar>
      <Box>
        <Box>
          <ListItemText
            primary={<Markdown text={text} />}
            secondary={secondary}
          />
        </Box>
        {type || expiresAt ? (
          <Box>
            {type && (
              <Box paddingRight={1} component="span">
                <NeutralChip text={parseCustomerNoteType(type)} />
              </Box>
            )}
            {expiresAt && <NeutralChip text={`EXPIRES: ${expiresAt}`} />}
          </Box>
        ) : null}
      </Box>
      <ListItemSecondaryAction>
        <IconButton
          edge="end"
          aria-label="delete"
          onClick={() => onDelete(id)}
          size="large"
        >
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

type Props = {
  data: CustomerNotesConnection;
  customerId: number;
};
const NoteList: FunctionComponent<Props> = ({ customerId, data }) => {
  const classes = useStyles();
  const [deleteNote] = useDeleteCustomerNoteMutation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleCloseModal = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  const onDelete = async (id: number) => {
    const snackId = startSnackbar('Deleting note');
    try {
      await deleteNote({
        variables: {
          input: {
            id: Number(id),
          },
        },
        update(cache) {
          cache.modify({
            id: cache.identify({
              __typename: 'Bidder',
              id: customerId,
            }),
            fields: {
              customerNotesByCustomerId(existing, { readField }) {
                return {
                  ...existing,
                  nodes: existing.nodes.filter(
                    (ref: any) => readField('id', ref) !== id,
                  ),
                };
              },
            },
          });
        },
      });
      stopSnackbar('Note deleted', snackId);
    } catch (e) {
      logError(e);
      // @ts-ignore
      stopSnackbar(`Failed to delete note: ${e?.message}`, snackId);
    }
  };

  return (
    <Card sx={{ padding: 2 }}>
      <Button text="Add note" onClick={() => setIsOpenModal(true)} />
      <List sx={{ mt: 2 }}>
        {data && data?.nodes.length > 0 ? (
          data.nodes.map((note, i) => (
            <>
              <NoteEntry
                key={String(note.id)}
                note={{ ...note }}
                onDelete={onDelete}
              />
              {i !== data?.nodes.length - 1 && <Divider />}
            </>
          ))
        ) : (
          <Typography component="div">
            <Box className={classes.paymentDate}>No notes</Box>
          </Typography>
        )}
      </List>
    </Card>
  );
};

export default NoteList;
