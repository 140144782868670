import { Switch } from '@mui/material';
import { RoleChip } from 'components/Chips';
import ContentHeader from 'components/ContentHeader';
import LineItems from 'components/LineItems';
import { ViewScreen } from 'components/ViewScreen';
import { useGetUserQuery } from 'graphql/graphql-types';
import { useModal } from 'hooks/modal';
import { useUpdateUserActiveState } from 'hooks/user';
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { ADMINISTRATOR } from 'utils/auth';
import { formatEnum } from 'utils/general';
import { formatDateTime } from 'utils/time';
import UserForm from './UserForm';

const UserViewScreen: FunctionComponent = () => {
  const { visible, close, open } = useModal();
  const { id } = useParams<{ id: string }>();
  const { loading, data, error } = useGetUserQuery({
    variables: {
      id: Number(id),
    },
  });
  const { updateUserActiveState } = useUpdateUserActiveState(Number(id));

  const user = data?.userById;
  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const username = user?.username ?? '';
  const isActive = user?.isActive;
  const role = user?.role;
  const createdAt = user?.createdAt;
  const updatedAt = user?.updatedAt;
  const pincode = user?.pincode;

  return (
    <ViewScreen
      loading={loading}
      error={error}
      header={{
        leftProps: {
          title: `${firstName} ${lastName}`,
        },
        centerProps: {
          component: <RoleChip role={role} />,
        },
        dividerProps: {
          hide: true,
        },
        rightProps: {
          buttons: [
            {
              text: 'Edit',
              onClick: open,
              disabled: !id,
              accessRoles: [ADMINISTRATOR],
            },
          ],
        },
      }}
    >
      <ContentHeader title="Details" size="large" />
      <LineItems description="Username" value={username} />
      <LineItems description="First name" value={firstName} />
      <LineItems description="Last name" value={lastName} />
      <LineItems description="PIN" value={pincode} />
      <LineItems
        description="Active"
        value={
          <Switch
            size="small"
            checked={!!isActive}
            onChange={(e) => updateUserActiveState(e.target.checked)}
          />
        }
      />
      <LineItems description="Role" value={formatEnum(role)} />
      <LineItems description="Created" value={formatDateTime(createdAt)} />
      <LineItems description="Updated" value={formatDateTime(updatedAt)} />
      <UserForm
        visible={visible}
        type="update"
        userId={Number(id)}
        handleClose={close}
        handleSuccess={close}
      />
    </ViewScreen>
  );
};

export default UserViewScreen;
