import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  InputAdornment,
  Paper,
  Popover,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ConfirmDelete from 'components/Alerts/ConfirmAlert';
import ImageEditor from 'components/ImageEditor';
import { errorNotification } from 'components/SnackBar';
import Table, { SortTypes } from 'components/Table';
import {
  AllAuctionItemEntryFragment,
  AllAuctionItemsDocument,
  useUpdateItemByIdMutation,
} from 'graphql/graphql-types';
import { useItemModification } from 'hooks/item';
import { useItemImageModification } from 'hooks/itemImage';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatCentsToDollars, makeWholeCents } from 'utils/money';
import CopyItemImageModal from './CopyItemImageModal';

type Row = {
  // eslint-disable-next-line react/no-unused-prop-types
  row: AllAuctionItemEntryFragment;
};
const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  deletedRow: {
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  container: {},
}));

type InlineState = {
  open: boolean;
  itemId: null | number;
  type: null | 'input' | 'textarea';
  key: string | null;
  value: string | null;
  startAdornment: string | null;
};

type Props = {
  auctionId: number;
};
const ItemsTable: FunctionComponent<Props> = ({ auctionId }) => {
  const history = useHistory();
  const classes = useStyles();
  const inputRef = React.useRef<HTMLInputElement>();
  const [popperAnchor, setPopperAnchor] = useState<HTMLDivElement | null>(null);
  const [inlineState, setInlineState] = useState<InlineState>({
    open: false,
    itemId: null,
    type: null,
    key: null,
    value: null,
    startAdornment: null,
  });
  const [inlineValue, setInlineValue] = useState<null | undefined | string>(
    null,
  );
  const [deleteItemId, setDeleteItemId] = useState<string | null>(null);
  const setTextInputRef = (element: HTMLInputElement) => {
    inputRef.current = element;
  };

  const [updateItem] = useUpdateItemByIdMutation();

  const { updateItem: updateItem2 } = useItemModification();
  const {
    handleCopyItemImage,
    handleCloseCopyModal,
    copyItemImageId,
    image,
    handleUpload,
    handleCloseEditImageModal,
  } = useItemImageModification();

  // const handleOpenEdit = useCallback((id) => {
  //   if (id) {
  //     setUpdateItemId(id);
  //   }
  // }, []);

  const handleDeleteItem = async (itemId: string, isDeleted: boolean) => {
    await updateItem({
      variables: {
        id: Number(itemId),
        values: { isDeleted },
      },
      update(cache) {
        const newAuction = {
          __typename: 'Auction',
          id: auctionId,
        };
        cache.modify({
          id: cache.identify(newAuction),
          fields: {
            isPushed() {
              return false;
            },
          },
        });
      },
    });
  };

  const onAgreeDeleteItem = useCallback(() => {
    if (deleteItemId) {
      handleDeleteItem(deleteItemId, true);
    }
    setDeleteItemId(null);
  }, [deleteItemId]);

  const onDiscardDeleteItem = useCallback(() => {
    setDeleteItemId(null);
  }, [deleteItemId]);

  const clearInlineState = () =>
    setInlineState({
      open: false,
      itemId: null,
      type: null,
      key: null,
      value: null,
      startAdornment: null,
    });

  const submitInline = useCallback(() => {
    const { itemId, key } = inlineState;
    if (itemId && key) {
      let values = null;
      if (key === 'description') {
        values = {
          description: inlineValue,
        };
      }
      if (key === 'category') {
        values = {
          category: inlineValue,
        };
      }
      if (key === 'retailPrice') {
        const retailPrice = inlineValue
          ? makeWholeCents(Number(inlineValue))
          : 0;
        if (Number.isNaN(retailPrice)) {
          errorNotification('Retail price should be a number');
          return;
        }
        values = {
          retailPrice,
        };
      }
      if (key === 'condition') {
        values = {
          condition: inlineValue,
        };
      }
      if (values) {
        updateItem2(itemId, {
          ...values,
        });
      }
      clearInlineState();
    }
  }, [inlineState, inlineValue]);

  const handlePopperClick =
    (
      itemId: InlineState['itemId'],
      type: InlineState['type'],
      key: InlineState['key'],
      value: InlineState['value'],
      startAdornment: InlineState['startAdornment'] = null,
    ) =>
    (event: React.MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      setPopperAnchor(event.currentTarget);
      setInlineState({ open: true, itemId, type, key, value, startAdornment });
      setInlineValue(value);
    };

  const condition = {
    auctionId: {
      equalTo: auctionId,
    },
    not: {
      and: [
        {
          isDeleted: {
            equalTo: true,
          },
        },
        {
          isPushed: {
            equalTo: true,
          },
        },
      ],
    },
  };

  const popperId = inlineState.open ? 'item-inline-edit-action' : undefined;

  return (
    <Box className={classes.container}>
      <Popover
        id={popperId}
        open={inlineState.open}
        anchorEl={popperAnchor}
        onClose={clearInlineState}
      >
        <Paper>
          <Card>
            <CardContent>
              <TextField
                inputRef={setTextInputRef}
                id="inline-textfield"
                size="small"
                variant="outlined"
                multiline={inlineState?.type === 'textarea'}
                defaultValue={inlineState.value}
                onChange={(e) => setInlineValue(e.target.value)}
                InputProps={{
                  startAdornment: inlineState.startAdornment ? (
                    <InputAdornment position="start">
                      {inlineState.startAdornment}
                    </InputAdornment>
                  ) : undefined,
                }}
              />
            </CardContent>
            <CardActions>
              <Button size="small" onClick={clearInlineState}>
                Cancel
              </Button>
              <Button size="small" onClick={submitInline}>
                Save
              </Button>
            </CardActions>
          </Card>
        </Paper>
      </Popover>
      <Table
        query={AllAuctionItemsDocument}
        condition={condition}
        defaultOrder="number"
        defaultSort={SortTypes.ASC}
        getRowClasses={({ isDeleted }: any) =>
          isDeleted ? [classes.deletedRow] : []
        }
        rowsPerPage={100}
        rowsPerPageOptions={[
          { label: '100', value: 100 },
          { label: '300', value: 300 },
          { label: '500', value: 500 },
          { label: '750', value: 750 },
        ]}
        columns={[
          {
            label: 'Number',
            name: 'number',
            type: 'integer',
            width: 1,
            onClick: (row: Row['row']) => history.push(`/items/${row.id}`),
            render: ({ row }: Row) => row.number,
          },
          {
            label: 'Description',
            name: 'description',
            width: 500,
            render: ({ row }: Row) => (
              <Typography
                onClick={handlePopperClick(
                  row.id,
                  'textarea',
                  'description',
                  String(row.description),
                )}
                style={{
                  minHeight: 25,
                  minWidth: 25,
                }}
                variant="body2"
              >
                {row.description}
              </Typography>
            ),
          },
          {
            label: 'Price',
            name: 'retailPrice',
            type: 'integer',
            makeFilterQuery: (value) => ({
              retailPrice: {
                equalTo: value ? makeWholeCents(value) : value,
              },
            }),
            render: ({ row }: Row) => (
              <Typography
                onClick={handlePopperClick(
                  row.id,
                  'input',
                  'retailPrice',
                  formatCentsToDollars(row.retailPrice, '0,0.00'),
                  '$',
                )}
                style={{
                  minHeight: 25,
                  minWidth: 25,
                }}
                variant="body2"
              >
                {formatCentsToDollars(row.retailPrice)}
              </Typography>
            ),
          },
          {
            label: 'Condition',
            name: 'condition',
            render: ({ row }: Row) => (
              <Typography
                onClick={handlePopperClick(
                  row.id,
                  'input',
                  'condition',
                  String(row.condition),
                )}
                style={{
                  minHeight: 25,
                  minWidth: 25,
                }}
                variant="body2"
              >
                {row.condition}
              </Typography>
            ),
          },
          {
            label: 'Category',
            name: 'category',
            render: ({ row }: Row) => (
              <Typography
                onClick={handlePopperClick(
                  row.id,
                  'input',
                  'category',
                  String(row.category),
                )}
                style={{
                  minHeight: 25,
                  minWidth: 25,
                }}
                variant="body2"
              >
                {row.category}
              </Typography>
            ),
          },
          {
            label: 'Tax Exempt',
            name: 'isTaxExempt',
            render: ({ row }: Row) => (
              <Switch
                size="small"
                checked={!!row.isTaxExempt}
                // @ts-ignore
                onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  updateItem2(row.id, { isTaxExempt: e.target.checked });
                }}
              />
            ),
          },
          {
            label: 'Actions',
            type: 'actions',
            width: 176,
            actions: [
              ({ isQueuedPushChange, isProcessingPushChange }: Row['row']) => {
                const yes = isQueuedPushChange || isProcessingPushChange;
                return (
                  <IconButton
                    key={yes ? 'not-synced' : 'synced'}
                    aria-label={yes ? 'not-synced' : 'synced'}
                    disabled
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    size="large"
                  >
                    {yes ? (
                      <CloudUploadIcon className={classes.warning} />
                    ) : (
                      <CloudDoneIcon className={classes.success} />
                    )}
                  </IconButton>
                );
              },
              // {
              //   name: 'edit',
              //   onClick: ({ id }: Row['row']) => {
              //     handleOpenEdit(id);
              //   },
              // },
              ({ isDeleted, id, isPushed }: Row['row']) => {
                const wasAlreadyPushed = isPushed;
                return (
                  <IconButton
                    key={isDeleted ? 'restore' : 'delete'}
                    aria-label={isDeleted ? 'restore' : 'delete'}
                    disabled={wasAlreadyPushed}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isDeleted) {
                        handleDeleteItem(String(id), false);
                      } else {
                        setDeleteItemId(String(id));
                      }
                    }}
                    size="large"
                  >
                    {isDeleted ? (
                      <RestoreFromTrashIcon color="secondary" />
                    ) : (
                      <DeleteIcon />
                    )}
                  </IconButton>
                );
              },
            ],
          },
        ]}
      />
      <ConfirmDelete
        title="Delete item"
        description="Do you want to delete this item?"
        agreeText="Yes"
        disagreeText="No"
        isOpen={!!deleteItemId}
        onAgree={onAgreeDeleteItem}
        onDiscard={onDiscardDeleteItem}
      />
      <ImageEditor
        image={image}
        onSubmit={handleUpload}
        onClose={handleCloseEditImageModal}
      />
      <CopyItemImageModal
        auctionId={Number(auctionId)}
        isOpen={!!copyItemImageId}
        handleCopyItemImage={handleCopyItemImage}
        handleCloseCopyModal={handleCloseCopyModal}
      />
    </Box>
  );
};

export default ItemsTable;
