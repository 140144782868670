import DateUtil from '@date-io/moment';

const dateFn = new DateUtil();

export const formatDateTime = (
  datetime: string | null | undefined,
  format = 'ddd, MMM DD, YYYY h:mm A',
) => (datetime ? dateFn.moment(datetime).format(format) : '');

export const formatDate = (
  datetime: string | null | undefined,
  format = 'MMM DD, YYYY',
) => (datetime ? dateFn.moment(datetime).format(format) : '');

export const displayElapsedTime = (seconds: number | null | undefined) => {
  if (!seconds) {
    return '';
  }
  return dateFn.moment.duration(seconds, 'seconds').humanize();
};

export const displayElapsedTimeFromTimestamp = (
  ts: string | null | undefined,
) => {
  if (!ts) {
    return '';
  }
  const now = dateFn.moment();
  const then = dateFn.moment(ts);
  return dateFn.moment
    .duration(then.diff(now, 'seconds'), 'seconds')
    .humanize();
};
