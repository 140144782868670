import React from 'react';
import { toast } from 'react-toastify';
import LoadingSnackbar from './LoadingSnackbar';

const STYLE: React.CSSProperties = {
  backgroundColor: '#224d8f',
};

export const generalNotification = (message: string) =>
  toast.info(
    <LoadingSnackbar message={message} loading={false} showIcon={false} />,
    {
      autoClose: 3000,
      closeButton: true,
      closeOnClick: true,
      style: STYLE,
    },
  );

export const errorNotification = (message: string) =>
  toast.error(
    <LoadingSnackbar message={message} loading={false} showIcon={false} />,
    {
      autoClose: 5000,
      closeButton: true,
      closeOnClick: true,
    },
  );

export const successNotification = (message: string) =>
  toast.success(
    <LoadingSnackbar message={message} loading={false} showIcon={false} />,
    {
      autoClose: 5000,
      closeButton: true,
      closeOnClick: true,
    },
  );

export const startSnackbar = (message: string) =>
  toast(<LoadingSnackbar message={message} loading />, {
    autoClose: false,
    closeButton: false,
    closeOnClick: false,
    draggable: false,
    style: STYLE,
  });

export const stopSnackbar = (message: string, snackId: string | number) =>
  toast.update(snackId, {
    render: <LoadingSnackbar message={message} loading={false} />,
    autoClose: 3000,
    closeButton: false,
    closeOnClick: true,
    draggable: false,
  });
