import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import NoteIcon from '@mui/icons-material/Note';
import NeutralChip from 'components/Chips/NeutralChip';
import Markdown from 'components/Markdown';
import { CustomerNoteEntryFragment } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import 'react-markdown-editor-lite/lib/index.css';
import { parseCustomerNoteType } from 'utils/graphql';
import { formatDateTime } from 'utils/time';

type Props = {
  note: CustomerNoteEntryFragment;
};

const CustomerNote: FunctionComponent<Props> = ({ note }) => {
  const { note: text, type, expiresAt, createdAt, userByCreatedBy } = note;
  const secondary = `By ${userByCreatedBy?.name} on ${formatDateTime(
    createdAt,
  )}`;

  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar variant="rounded">
          <NoteIcon />
        </Avatar>
      </ListItemAvatar>
      <Box>
        <Box>
          <ListItemText
            primary={<Markdown text={text} />}
            secondary={secondary}
          />
        </Box>
        {type || expiresAt ? (
          <Box>
            {type && (
              <Box paddingRight={1} component="span">
                <NeutralChip text={parseCustomerNoteType(type)} />
              </Box>
            )}
            {expiresAt && <NeutralChip text={`EXPIRES: ${expiresAt}`} />}
          </Box>
        ) : null}
      </Box>
    </ListItem>
  );
};

export default CustomerNote;
