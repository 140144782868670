import { Box } from '@mui/material';
import Button, { Props as ButtonProps } from 'components/Button';
import React, { ReactElement } from 'react';
import { useStyles } from './styles';

export type Props = {
  component?: ReactElement;
  buttons?: ButtonProps[];
};
export default ({ component, buttons }: Props) => {
  const classes = useStyles();
  return (
    <Box display="flex" flexWrap="wrap" alignItems="center">
      {component ?? null}
      {buttons
        ? buttons.map((props, i) => (
            <Box key={`buttons-${i}`} className={classes.rightButton}>
              <Button {...props} />
            </Box>
          ))
        : null}
    </Box>
  );
};
