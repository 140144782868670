import {
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PaymentOverallStatusChip } from 'components/Chips';
import NeutralChip from 'components/Chips/NeutralChip';
import CircleLoading from 'components/CircleLoading';
import ContentHeader from 'components/ContentHeader';
import { useGetPaymentsByInvoiceIdQuery } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { formatCentsToDollars } from 'utils/money';
import { formatDateTime } from 'utils/time';

const useStyles = makeStyles((theme: Theme) => ({
  listTitle: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  listItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  chipStatus: {
    backgroundColor: '#cbf4c9',
    borderRadius: '4px',
    color: '#0e6245',
    fontWeight: 500,
    marginLeft: theme.spacing(1),
  },
  paymentAmount: {
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },
  paymentDate: {
    color: theme.palette.grey[600],
  },
}));

type Props = {
  invoiceId: number;
};
const InvoicePaymentList: FunctionComponent<Props> = ({ invoiceId }) => {
  const classes = useStyles();
  const history = useHistory();
  const { loading, data, error } = useGetPaymentsByInvoiceIdQuery({
    variables: { id: Number(invoiceId) },
  });

  const payments = data?.allPayments?.nodes ?? [];

  return (
    <Box paddingBottom={4}>
      <ContentHeader title="Payments" divider size="large" />
      {loading && <CircleLoading />}
      {!loading && !payments.length && !error && (
        <Typography className={classes.listTitle} component="div">
          <Box className={classes.paymentDate}>No payments</Box>
        </Typography>
      )}
      {!loading && !!payments.length && !error && (
        <List>
          {payments.map((payment) => (
            <ListItem
              className={classes.listItem}
              key={String(payment?.id)}
              button
              onClick={() => history.push(`/payments/${payment?.id}`)}
            >
              <ListItemAvatar>
                <NeutralChip
                  text={
                    payment?.cardNumber === '' ? 'UNK' : payment?.cardNumber
                  }
                />
              </ListItemAvatar>
              <Box>
                <Box display="flex" alignItems="center">
                  <Typography variant="subtitle1">
                    <Box
                      className={classes.paymentAmount}
                    >{`${formatCentsToDollars(payment?.amount)} USD`}</Box>
                  </Typography>
                  <PaymentOverallStatusChip {...payment} />
                </Box>
                <Box>
                  <Typography variant="body2" component="div">
                    <Box className={classes.paymentDate}>
                      {formatDateTime(payment?.createdAt)}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default InvoicePaymentList;
