import { TextField } from '@mui/material';
import { DateTimePicker as MUIDateTimePicker } from '@mui/lab';
import React from 'react';
import { Field } from 'react-final-form';

const showError = ({
  meta: { submitError, dirtySinceLastSubmit, error, touched, modified },
}: any) =>
  !!(
    ((submitError && !dirtySinceLastSubmit) || error) &&
    (touched || modified)
  );

const KeyboardDateTimePickerWrapper = (props: any) => {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    dateFunsUtils,
    ...rest
  } = props;

  const { error, submitError } = meta;
  const isError = showError({ meta });

  const { helperText, ...lessRest } = rest;

  const inputProps = {
    ...restInput,
    error: isError,
  };

  return (
    <MUIDateTimePicker
      fullWidth
      autoOk
      disablePast
      helperText={isError ? error || submitError : helperText}
      error={isError}
      onChange={(e: any) => onChange(e)}
      name={name}
      value={(value as any) === '' ? null : value}
      inputProps={inputProps}
      {...lessRest}
      renderInput={(renderInputProps: any) => (
        <TextField
          variant="outlined"
          fullWidth
          {...renderInputProps}
          error={isError}
        />
      )}
    />
  );
};

const DateTimePicker = (props: any) => {
  const { name, fieldProps, ...rest } = props;

  return (
    <Field
      name={name as any}
      render={(fieldRenderProps) => (
        <KeyboardDateTimePickerWrapper {...fieldRenderProps} {...rest} />
      )}
      {...fieldProps}
    />
  );
};

export default DateTimePicker;
