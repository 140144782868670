import React from 'react';
import { Box } from '@mui/material';
import Modal from 'components/Modal';
import ModalActions from 'components/ModalActions';
import ModalLoading from 'components/ModalLoading';
import {
  JobQueueItemEntryFragment,
  useJobQueueItemsLazyQuery,
} from 'graphql/graphql-types';
import { formatDateTime } from 'utils/time';
import { useHistory } from 'react-router-dom';
import { SimpleTable } from './Table';
import Hyperlink from './Hyperlink';

type Row = {
  row: JobQueueItemEntryFragment;
};

type Payload = {
  payload: {
    [key: string]: any;
  }
};

const MaybeAddItemOnClick: React.FC<Payload> = ({payload }) => {
  const history = useHistory();
  const itemId = payload?.itemId;
  const Comp = <pre>{JSON.stringify(payload)}</pre>;
  if (itemId) {
    return (
      <Hyperlink text={Comp} onClick={() => history.push(`/items/${itemId}`)} />
    );
  }
  return <pre>{JSON.stringify(payload)}</pre>;
};

const columns = [
  {
    label: 'ID',
    name: 'id',
    width: 1,
    render: ({ row }: Row) => row.id,
  },
  {
    label: 'Payload',
    name: 'payload',
    render: ({ row }: Row) => <MaybeAddItemOnClick payload={row.payload} />
  },
  {
    label: 'Completed',
    name: 'completed',
    align: 'right' as const,
    render: ({ row }: Row) =>
      formatDateTime(row?.completedAt, 'ddd, MMM DD, YYYY h:mm:ss A'),
  },
];

type Props = {
  isVisible: boolean;
  jobQueueId: number | undefined;
  onDismiss: () => void;
};
const Comp: React.FC<Props> = ({ isVisible, jobQueueId, onDismiss }) => {
  const [get, { data, loading }] = useJobQueueItemsLazyQuery();
  const rows = data?.allJobQueueItems?.nodes ?? [];

  React.useEffect(() => {
    if (isVisible && jobQueueId) {
      get({
        variables: {
          jobQueueId,
        },
      });
    }
  }, [isVisible, jobQueueId]);

  if (!isVisible) {
    return null;
  }

  return (
    <Modal
      open={isVisible}
      title="Failed Job Queue Items"
      handleClose={onDismiss}
      maxWidth="md"
    >
      <React.Suspense fallback={<ModalLoading />}>
        {!loading ? (
          <Box>
            <SimpleTable columns={columns} rows={rows} />
            <ModalActions
              buttons={[
                {
                  text: 'Close',
                  onClick: onDismiss,
                },
              ]}
            />
          </Box>
        ) : null}
      </React.Suspense>
    </Modal>
  );
};

export default Comp;
