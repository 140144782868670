import { Alert, AlertTitle, Typography } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { formatDateTime } from 'utils/time';

type Props = {
  note: string;
  by?: string | undefined | null;
  at?: string | undefined | null;
};
const SetAsideNote: FunctionComponent<Props> = ({ note, by, at }) => (
  <Alert severity="info">
    <AlertTitle>Set Aside Item</AlertTitle>
    <Typography>{note}</Typography>
    <Typography variant="caption">{`By ${by ?? 'Unknown'} at ${formatDateTime(
      at,
    )}`}</Typography>
  </Alert>
);

export default SetAsideNote;
