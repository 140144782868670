import InfoIcon from '@mui/icons-material/Info';
import { Box, Grid, Theme, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

type ValueAlign = 'left' | 'right' | 'center';

const useStyles = makeStyles((theme: Theme) => ({
  listContainer: {
    padding: theme.spacing(0.5, 0), // '4px 0px'
  },
  listLabel: {
    color: '#757575',
    minWidth: 180,
    flexWrap: 'wrap',
  },
  listItem: {
    color: '#212121',
    flexWrap: 'wrap',
  },
  listValue: ({
    stacked,
    valueAlign,
  }: { stacked?: boolean; valueAlign?: ValueAlign } = {}) => ({
    maxWidth: stacked ? undefined : 240,
    textAlign: valueAlign,
  }),
  listDescription: ({ stacked }: { stacked?: boolean } = {}) => ({
    maxWidth: stacked ? undefined : 240,
  }),
  tooltip: {
    fontSize: '1rem',
  },
}));

const Description = ({
  description,
  tooltip,
  stacked,
}: Pick<Props, 'description' | 'tooltip' | 'stacked'>) => {
  const classes = useStyles({ stacked });

  if (tooltip) {
    return (
      <Tooltip title={tooltip} arrow>
        <Typography component="div" className={classes.listLabel}>
          {description} <InfoIcon className={classes.tooltip} />
        </Typography>
      </Tooltip>
    );
  }
  return (
    <Typography component="div">
      <Box className={classes.listLabel}>{description}</Box>
    </Typography>
  );
};

type Props = {
  description: string;
  value: any;
  stacked?: boolean;
  tooltip?: string;
  valueAlign?: ValueAlign;
};

const LineItems = ({
  description,
  value,
  tooltip,
  stacked = false,
  valueAlign = 'left',
}: Props) => {
  const classes = useStyles({ stacked, valueAlign });

  return (
    <Grid container className={classes.listContainer}>
      <Grid
        item
        xs={12}
        sm={stacked ? undefined : 6}
        className={classes.listDescription}
      >
        <Description
          description={description}
          stacked={stacked}
          tooltip={tooltip}
        />
      </Grid>
      <Grid item xs={12} sm={stacked ? undefined : 6}>
        <Typography className={classes.listValue} component="div">
          <Box className={classes.listItem}>{value}</Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LineItems;
