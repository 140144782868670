import { ApolloError } from '@apollo/client';
import { Alert } from '@mui/material';
import CircleLoading from 'components/CircleLoading';
import { SimpleTable, SimpleTableProps } from 'components/Table';
import React, { FunctionComponent } from 'react';

type Props = {
  loading: boolean;
  error?: ApolloError;
  called: boolean;
} & SimpleTableProps;
const ReportView: FunctionComponent<Props> = ({
  loading,
  error,
  called,
  rows,
  columns,
}) => {
  if (!called) {
    return <Alert severity="info">Not run yet</Alert>;
  }
  if (loading) {
    return <CircleLoading />;
  }
  if (error) {
    return <Alert severity="error">{error?.message ?? 'General error'}</Alert>;
  }

  return <SimpleTable columns={columns} rows={rows} />;
};

export default ReportView;
