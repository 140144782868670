import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import TextField from 'components/Forms/TextField';
import { makeValidate } from 'components/Forms/Validation';
import { useSetAsideItemMutation } from 'graphql/graphql-types';
import React, { FunctionComponent, useCallback } from 'react';
import { Form } from 'react-final-form';
import { logError } from 'utils/logging';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const Schema = Yup.object({
  note: Yup.string().required('Required'),
});
const validate = makeValidate(Schema);

type Props = {
  itemId: number;
  isOpen: boolean;
  handleClose: () => void;
};
const ItemSetAsideModal: FunctionComponent<Props> = ({
  itemId,
  isOpen,
  handleClose,
}) => {
  const [commit] = useSetAsideItemMutation();

  const submit = useCallback(
    (values: Asserts<typeof Schema>) => {
      try {
        commit({
          variables: {
            input: {
              itemId,
              note: values.note,
            },
          },
        });
        handleClose();
      } catch (e) {
        logError(e);
      }
    },
    [itemId],
  );

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Set Aside Item"
      open={isOpen}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Set Aside Item</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          initialValues={{
            note: '',
          }}
          validate={validate}
          render={({ handleSubmit, ...props }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                required
                variant="outlined"
                label="Note"
                id="note"
                name="note"
                multiline
                rows={8}
              />
              <DialogActions>
                <Button onClick={handleClose}>close</Button>
                <Button
                  disabled={
                    props.submitting ||
                    props.pristine ||
                    props.hasValidationErrors ||
                    (props.hasSubmitErrors && !props.modifiedSinceLastSubmit)
                  }
                  color="primary"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default ItemSetAsideModal;
