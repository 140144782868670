import { Chip } from '@mui/material';
import { blue } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  status: {
    backgroundColor: blue[50],
    borderRadius: 5,
    color: blue[900],
    fontWeight: 500,
  },
}));

type Props = {
  text: string;
};
const BlueChip: FunctionComponent<Props> = ({ text }) => {
  const classes = useStyles();

  return <Chip size="small" label={text} className={classes.status} />;
};

export default BlueChip;
