import { Box, Card as MuiCard, Grid, Tab, Tabs } from '@mui/material';
import ConfirmAlert from 'components/Alerts/ConfirmAlert';
import AuctionProcessInvoicePaymentsModal from 'components/AuctionProcessInvoicePaymentsModal';
import AuctionPushModal from 'components/AuctionPushModal';
import Card from 'components/Card';
import CollapsableAlert from 'components/CollapsableAlert';
import ContentHeader from 'components/ContentHeader';
import Hyperlink from 'components/Hyperlink';
import JobQueue from 'components/JobQueue';
import LineItemProgress from 'components/LineItemProgress';
import LineItems from 'components/LineItems';
import { ViewScreen } from 'components/ViewScreen';
import { useGetAuctionByIdQuery } from 'graphql/graphql-types';
import {
  useHandleCommitAuction,
  useHandleEmailInvoices,
  useHandleProcessInvoices,
} from 'hooks/auction';
import { useModal } from 'hooks/modal';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import {
  Link,
  Route,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import ItemsTable from 'screens/Items/ItemsTable';
import { openInNewTab } from 'utils/general';
import { formatDateTime } from 'utils/time';
import AuctionInvoicesTable from './AuctionInvoicesTable';
import AuctionViewImagesList from './AuctionViewImagesList';

const isBidWranglerPushedDisabled = (
  descriptionFull: string | undefined | null,
  descriptionShort: string | undefined | null,
  startAt: string | undefined | null,
  endAt: string | undefined | null,
  pickupStartAt: string | undefined | null,
  pickupEndAt: string | undefined | null,
  images: number | undefined,
  isPulled: boolean | undefined,
  isPushing: boolean | undefined,
) => {
  const errors = [];

  const isDisabled =
    !descriptionFull ||
    !descriptionShort ||
    !startAt ||
    !endAt ||
    !pickupStartAt ||
    !pickupEndAt ||
    images === 0 ||
    isPulled ||
    isPushing;

  if (!descriptionFull) {
    errors.push('Full description is missing');
  }
  if (!descriptionShort) {
    errors.push('Short description is missing');
  }
  if (!startAt) {
    errors.push('Auction start time is missing');
  }
  if (!endAt) {
    errors.push('Auction end time is missing');
  }
  if (!pickupStartAt) {
    errors.push('Auction pickup start time is missing');
  }
  if (!pickupEndAt) {
    errors.push('Auction pickup end time is missing');
  }
  if (images === 0) {
    errors.push('At least 1 featured image is required');
  }
  if (isPulled) {
    errors.push('Auction has already beed pulled from BidWrangler');
  }
  if (isPushing) {
    errors.push('Auction is already pushing to BidWrangler');
  }

  return {
    isDisabled,
    errors,
  };
};

const AuctionViewScreen: FunctionComponent = () => {
  const routeMatch = useRouteMatch([
    '/auctions/:id/items',
    '/auctions/:id/invoices',
    '/auctions/:id/jobs',
    '/auctions/:id',
  ]);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    visible: visiblePushToBWModal,
    open: openPushToBWModal,
    close: closePushToBWModal,
  } = useModal();
  const {
    visible: visibleProcessInvoicePaymentsModal,
    open: openProcessInvoicePaymentsModal,
    close: closeProcessInvoicePaymentsModal,
  } = useModal();
  const {
    visible: visibleEmailInvoicesModal,
    open: openEmailInvoicesModal,
    close: closeEmailInvoicesModal,
  } = useModal();
  const { processInvoicesLoading, handleProcessInvoices } =
    useHandleProcessInvoices(id);
  const { emailInvoicesLoading, handleEmailInvoices } =
    useHandleEmailInvoices(id);
  const { commitAuctionLoading, handleCommitAuction } =
    useHandleCommitAuction(id);

  const { loading, data, refetch, error } = useGetAuctionByIdQuery({
    pollInterval: 30 * 1000,
    variables: {
      id: Number(id),
    },
  });

  const currentTab = routeMatch?.path;

  const auction = data?.auctionById;
  const bwId = auction?.bwId;
  const invId = auction?.invId;
  const merId = auction?.merId;
  const isMer = !!merId;

  const goToBw = useCallback(() => {
    if (bwId) {
      openInNewTab(
        `https://bid.onlineliquidationauction.com/admin/auction/${bwId}`,
      );
    }
  }, [bwId]);

  const goToInvAuction = useCallback(() => {
    if (invId) {
      openInNewTab(`https://olaapps.com/auction-${invId}`);
    }
  }, [invId]);

  const goToEdit = useCallback(() => {
    if (id) {
      history.push(`/auctions/${id}/edit`);
    }
  }, [id]);

  const goToLocation = useCallback(() => {
    if (auction?.locationByLocationId?.id) {
      history.push(`/locations/${auction.locationByLocationId.id}`);
    }
  }, [auction?.locationByLocationId]);

  const images = auction?.images.nodes ?? [];
  const countPaidInvoices = auction?.countPaidInvoices;
  const countTotalInvoices = auction?.countTotalInvoices;
  const percentPaidInvoices = auction?.percentPaidInvoices;
  const countCompletedInvoices = auction?.countCompletedInvoices;
  const percentCompletedInvoices = auction?.percentCompletedInvoices;
  const countTotalItems = auction?.countTotalItems;
  const countSoldItems = auction?.countSoldItems;
  const perecentSoldItems = auction?.percentSoldItems;
  const countQueuedItemsForPush = auction?.countQueuedItemsForPush;
  const holdItems = auction?.lpudNumDays ?? 0;

  const { isDisabled: pushToBwDisabled, errors: pushToBwErrors } =
    isBidWranglerPushedDisabled(
      auction?.descriptionFull,
      auction?.descriptionShort,
      auction?.startAt,
      auction?.endAt,
      auction?.pickupStartAt,
      auction?.pickupEndAt,
      images.length,
      auction?.isPulled,
      auction?.isPushing,
    );

  const bottomButtons = useMemo(() => {
    if (isMer) {
      return [
        {
          text: 'Inventory',
          onClick: goToInvAuction,
          disabled: !invId,
        },
      ];
    }
    return [
      {
        text: 'BidWrangler',
        onClick: goToBw,
        disabled: !bwId,
      },
      {
        text: 'Inventory',
        onClick: goToInvAuction,
        disabled: !invId,
      },
    ];
  }, [isMer, bwId, invId, merId]);

  const rightButtons = useMemo(() => {
    if (isMer) {
      return [];
    }
    return [
      {
        text: 'Push to BidWrangler',
        onClick: openPushToBWModal,
        loading: commitAuctionLoading || auction?.isPushing,
        disabled: pushToBwDisabled,
      },
      {
        text: 'Charge Invoices',
        onClick: openProcessInvoicePaymentsModal,
        loading: processInvoicesLoading || auction?.isProcessingInvoicePayments,
        disabled: !auction?.isPulled || auction?.isProcessingInvoicePayments,
      },
      {
        text: 'Email Invoices',
        onClick: openEmailInvoicesModal,
        loading: emailInvoicesLoading || auction?.isEmailingInvoices,
        disabled:
          !auction?.isPulled ||
          auction?.isProcessingInvoicePayments ||
          auction?.isEmailingInvoices ||
          !auction?.processInvoicePaymentsExecCount,
      },
      {
        text: 'Edit',
        onClick: goToEdit,
      },
    ];
  }, [
    isMer,
    pushToBwDisabled,
    commitAuctionLoading,
    auction?.isPushing,
    auction?.isPulled,
    processInvoicesLoading,
    auction?.isProcessingInvoicePayments,
    emailInvoicesLoading,
    auction?.isEmailingInvoices,
    auction?.processInvoicePaymentsExecCount,
    id,
  ]);

  return (
    <ViewScreen
      loading={loading}
      error={error}
      header={{
        dividerProps: {
          hide: true,
        },
        leftProps: {
          title: String(auction?.name ?? ''),
        },
        bottomProps: {
          buttons: bottomButtons,
        },
        rightProps: {
          buttons: rightButtons,
          // buttons: [
          //   {
          //     text: 'Push to BidWrangler',
          //     onClick: openPushToBWModal,
          //     loading: commitAuctionLoading || auction?.isPushing,
          //     disabled: pushToBwDisabled,
          //   },
          //   {
          //     text: 'Charge Invoices',
          //     onClick: openProcessInvoicePaymentsModal,
          //     loading:
          //       processInvoicesLoading || auction?.isProcessingInvoicePayments,
          //     disabled:
          //       !auction?.isPulled || auction?.isProcessingInvoicePayments,
          //   },
          //   {
          //     text: 'Email Invoices',
          //     onClick: openEmailInvoicesModal,
          //     loading: emailInvoicesLoading || auction?.isEmailingInvoices,
          //     disabled:
          //       !auction?.isPulled ||
          //       auction?.isProcessingInvoicePayments ||
          //       auction?.isEmailingInvoices ||
          //       !auction?.processInvoicePaymentsExecCount,
          //   },
          //   {
          //     text: 'Edit',
          //     onClick: goToEdit,
          //   },
          //   {
          //     text: 'Image Manager',
          //     href: `/auctions/${id}/imageManager`,
          //   },
          // ],
        },
      }}
    >
      <Card disableMargin disablePadding>
        <Box paddingBottom={2}>
          {!isMer && countQueuedItemsForPush ? (
            <CollapsableAlert
              text={`${countQueuedItemsForPush} pending item changes for BidWrangler`}
            />
          ) : null}
          {!isMer && !auction?.isPulled && pushToBwDisabled ? (
            <CollapsableAlert
              text="BidWrangler push is disabled"
              body={
                <ul style={{ margin: 0 }}>
                  {pushToBwErrors.map((e) => (
                    <li key={e}>{e}</li>
                  ))}
                </ul>
              }
            />
          ) : null}
        </Box>
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          <Tab
            label="Details"
            value="/auctions/:id"
            to={`/auctions/${id}`}
            component={Link}
          />
          <Tab
            label="Items"
            value="/auctions/:id/items"
            to={`/auctions/${id}/items`}
            component={Link}
          />
          <Tab
            label="Invoices"
            value="/auctions/:id/invoices"
            to={`/auctions/${id}/invoices`}
            component={Link}
          />
          <Tab
            label="Jobs"
            value="/auctions/:id/jobs"
            to={`/auctions/${id}/jobs`}
            component={Link}
          />
        </Tabs>
      </Card>

      {/* Nested Routes. */}
      <Route exact path="/auctions/:id">
        <MuiCard
          sx={{
            padding: 4,
          }}
        >
          <Grid container spacing={4} style={{ paddingBottom: 40 }}>
            <Grid item xs={4}>
              <LineItemProgress
                description="Paid invoices"
                text={`${countPaidInvoices} / ${countTotalInvoices}`}
                progress={percentPaidInvoices}
              />
            </Grid>
            <Grid item xs={4}>
              <LineItemProgress
                description="Completed invoices"
                text={`${countCompletedInvoices} / ${countTotalInvoices}`}
                progress={percentCompletedInvoices}
              />
            </Grid>
            <Grid item xs={4}>
              <LineItemProgress
                description="Sold items"
                text={`${countSoldItems} / ${countTotalItems}`}
                progress={perecentSoldItems}
              />
            </Grid>
            {!isMer && (
              <Grid item xs={12}>
                <LineItems
                  description="Short description"
                  value={auction?.descriptionShort}
                  stacked
                />
                <LineItems
                  description="Full description"
                  value={auction?.descriptionFull}
                  stacked
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={6}>
              <LineItems
                description="Location"
                value={
                  <Hyperlink
                    text={String(auction?.locationByLocationId?.name ?? '')}
                    onClick={goToLocation}
                  />
                }
              />
              {!isMer && (
                <>
                  <LineItems
                    description="Premium"
                    value={`${auction?.premium}%`}
                  />
                  <LineItems description="Tax" value={`${auction?.tax}%`} />
                  <LineItems description="Tagline" value={auction?.tagline} />
                </>
              )}
            </Grid>
            {!isMer && (
              <Grid item xs={12} sm={12} md={6}>
                <LineItems description="Timezone" value={auction?.timezone} />
                <LineItems
                  description="Auction start date"
                  value={formatDateTime(auction?.startAt)}
                />
                <LineItems
                  description="Auction end date"
                  value={formatDateTime(auction?.endAt)}
                />
                <LineItems
                  description="Pickup start date"
                  value={formatDateTime(auction?.pickupStartAt)}
                />
                <LineItems
                  description="Pickup end date"
                  value={formatDateTime(auction?.pickupEndAt)}
                />
                <LineItems
                  description="Hold items for"
                  value={`${holdItems} days`}
                />
              </Grid>
            )}
            {!isMer && (
              <Grid item xs={12} sm={12} md={6}>
                <LineItems
                  description="Last push at"
                  value={formatDateTime(auction?.lastPushAt)}
                />
                <LineItems
                  description="Last pull at"
                  value={formatDateTime(auction?.lastPullAt)}
                />
              </Grid>
            )}
            {!isMer && (
              <Grid item xs={12} sm={12} md={6}>
                <LineItems
                  description="Last push error"
                  value={auction?.lastPushError ?? '-'}
                />
                <LineItems
                  description="Last pull error"
                  value={auction?.lastPullError ?? '-'}
                />
              </Grid>
            )}
          </Grid>
          {!isMer && (
            <ContentHeader title="Featured images" divider size="large" />
          )}
          {!isMer && (
            <AuctionViewImagesList images={images} refetch={refetch} />
          )}
        </MuiCard>
      </Route>
      <Route exact path="/auctions/:id/items">
        <ItemsTable auctionId={Number(id)} />
      </Route>
      <Route exact path="/auctions/:id/invoices">
        <AuctionInvoicesTable auctionId={Number(id)} />
      </Route>
      <Route exact path="/auctions/:id/jobs">
        <JobQueue auctionId={Number(id)} />
      </Route>

      {/* Modals. */}
      <AuctionPushModal
        isOpen={visiblePushToBWModal}
        onClose={closePushToBWModal}
        onOk={(publish) => {
          handleCommitAuction(publish);
          closePushToBWModal();
        }}
      />
      <AuctionProcessInvoicePaymentsModal
        auctionId={id}
        isOpen={visibleProcessInvoicePaymentsModal}
        onClose={closeProcessInvoicePaymentsModal}
        onOk={() => {
          handleProcessInvoices();
          closeProcessInvoicePaymentsModal();
        }}
      />
      <ConfirmAlert
        isOpen={visibleEmailInvoicesModal}
        title="Email Customer Invoices"
        description="This will send out invoice emails for this auction. Are you sure you want to do this?"
        onAgree={() => {
          handleEmailInvoices();
          closeEmailInvoicesModal();
        }}
        onDiscard={closeEmailInvoicesModal}
        agreeText="Yes"
        disagreeText="Cancel"
      />
    </ViewScreen>
  );
};

export default AuctionViewScreen;
