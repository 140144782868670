import Button from 'components/Button';
import { RoleChip } from 'components/Chips';
import { GrayDot, GreenDot } from 'components/Dot';
import ListScreen from 'components/ListScreen';
import { AllUsersDocument, AllUsersEntryFragment } from 'graphql/graphql-types';
import { useModal } from 'hooks/modal';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { ADMINISTRATOR } from 'utils/auth';
import { USER_ROLES_FILTER_ELEMENTS } from './filters';
import UserForm from './UserForm';

type TableActionsProps = {
  createOnClick: () => void;
};
const TableActions = ({ createOnClick }: TableActionsProps) => (
  <Button
    color="primary"
    text="Create"
    onClick={createOnClick}
    accessRoles={[ADMINISTRATOR]}
  />
);

type Row = {
  row: AllUsersEntryFragment;
};
const UserListScreen: FunctionComponent = () => {
  const history = useHistory();
  const { visible, open, close } = useModal();

  return (
    <>
      <ListScreen
        title="Users"
        query={AllUsersDocument}
        headerExtra={<TableActions createOnClick={open} />}
        onRowClick={(row: Row['row']) => {
          history.push(`/users/${row.id}`);
        }}
        columns={[
          {
            label: 'Username',
            name: 'username',
          },
          {
            label: 'First Name',
            name: 'firstName',
          },
          {
            label: 'Last Name',
            name: 'lastName',
          },
          {
            label: 'Role',
            name: 'role',
            type: 'select',
            filterElements: USER_ROLES_FILTER_ELEMENTS,
            render: ({ row }: Row) => <RoleChip role={row.role} />,
          },
          {
            label: 'Active',
            name: 'isActive',
            type: 'boolean',
            render: ({ row }: Row) =>
              row.isActive ? <GreenDot /> : <GrayDot />,
          },
        ]}
      />
      <UserForm
        visible={visible}
        type="create"
        handleClose={close}
        handleSuccess={close}
      />
    </>
  );
};

export default UserListScreen;
