import { GrayDot, GreenDot } from 'components/Dot';
import LinearProgress from 'components/LinearProgress';
import Table from 'components/Table';
import {
  JobsByAuctionIdDocument,
  JobsByAuctionIdEntryFragment,
} from 'graphql/graphql-types';
import { useSelectedValueModal } from 'hooks/modal';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { formatDateTime } from 'utils/time';
import JobQueueModal from './JobQueueModal';

type Row = {
  row: JobsByAuctionIdEntryFragment;
};
type Props = {
  auctionId?: string | number;
};
const JobQueue: FunctionComponent<Props> = ({ auctionId }) => {
  const [condition, setCondition] = useState<any>(null);
  const { selectedValue, open, close } = useSelectedValueModal<number>();

  useEffect(() => {
    if (auctionId) {
      setCondition({
        payload: {
          contains: {
            auctionId: Number(auctionId),
          },
        },
      });
    }
  }, [auctionId]);

  if (!condition) {
    return null;
  }

  return (
    <>
      <Table
        query={JobsByAuctionIdDocument}
        condition={condition}
        onRowClick={({ id }: Row['row']) => open(id)}
        columns={[
          {
            label: 'Type',
            name: 'job',
          },
          {
            label: 'Time',
            name: 'updatedAt',
            disableFilter: true,
            render: ({ row }: Row) => formatDateTime(row.updatedAt),
          },

          {
            label: 'Progress',
            name: 'progress',
            disableFilter: true,
            render: ({ row }: Row) => (
              <LinearProgress
                progress={
                  (row.jobCount &&
                    ((row.failCount + row.successCount) / row.jobCount) *
                      100) ||
                  (row.isCompleted ? 100 : 0)
                }
              />
            ),
          },
          {
            label: 'Completed',
            name: 'isCompleted',
            type: 'boolean',
            width: 10,
            render: ({ row }: Row) =>
              row.isCompleted ? <GreenDot /> : <GrayDot />,
          },
          {
            label: 'Success',
            name: 'stats',
            disableFilter: true,
            width: 10,
            render: ({ row }: Row) =>
              row.jobCount ? `${row.successCount}/${row.jobCount}` : '',
          },
          {
            label: 'Failure',
            name: 'stats',
            disableFilter: true,
            width: 10,
            render: ({ row }: Row) =>
              row.jobCount ? `${row.failCount}/${row.jobCount}` : '',
          },
        ]}
      />
      <JobQueueModal
        isVisible={!!selectedValue}
        jobQueueId={selectedValue}
        onDismiss={close}
      />
    </>
  );
};

export default JobQueue;
