import LinearWithValueLabel from 'components/LinearProgress';
import ListScreen from 'components/ListScreen';
import { SortTypes } from 'components/Table';
import {
  AllAuctionsDocument,
  AllAuctionsEntryFragment,
  useAllLocationsQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

type Row = {
  row: AllAuctionsEntryFragment;
};

const AuctionListScreen: FunctionComponent = () => {
  const history = useHistory();
  return (
    <ListScreen
      title="Auctions"
      query={AllAuctionsDocument}
      defaultOrder="endAt"
      defaultSort={SortTypes.DESC}
      onRowClick={(row: Row['row']) => {
        history.push(`/auctions/${row.id}`);
      }}
      rowsPerPage={25}
      rowsPerPageOptions={[{ label: '25', value: 25 }]}
      columns={[
        { label: 'Name', name: 'name', width: 250 },
        {
          type: 'autocomplete',
          label: 'Location',
          name: 'locationByLocationId.name',
          makeFilterQuery: (value) => ({
            locationByLocationId: {
              name: {
                includesInsensitive: value,
              },
            },
          }),
          useQuery: useAllLocationsQuery,
          getOptionLabel: (item) => item.name,
          mapValueToFilter: (value) => ({
            name: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllLocationsQuery>) =>
            result?.data?.allLocations?.nodes ?? [],
        },
        {
          label: 'Start',
          name: 'startAt',
          type: 'date',
          formatDateValue: 'MM/DD/YYYY h:mm A',
          width: 220,
        },
        {
          label: 'End',
          name: 'endAt',
          type: 'date',
          formatDateValue: 'MM/DD/YYYY h:mm A',
          width: 250,
        },
        {
          label: 'Items',
          name: 'items',
          type: 'integer',
          render: ({ row }: Row) =>
            `${row.countSoldItems}/${row.countTotalItems}`,
          disableFilter: true,
        },
        {
          label: 'Paid Invoices',
          name: 'percentPaidInvoices',
          width: 250,
          render: ({ row }: Row) => (
            <>
              {`${row.countPaidInvoices} / ${row.countTotalInvoices}`}
              <LinearWithValueLabel progress={row.percentPaidInvoices} />
            </>
          ),
          disableFilter: true,
        },
        {
          label: 'Completed Invoices',
          name: 'percentCompletedInvoices',
          width: 250,
          render: ({ row }: Row) => (
            <>
              {`${row.countCompletedInvoices} / ${row.countTotalInvoices}`}
              <LinearWithValueLabel progress={row.percentCompletedInvoices} />
            </>
          ),
          disableFilter: true,
        },
      ]}
    />
  );
};

export default AuctionListScreen;
