import lowerCase from 'lodash.lowercase';
import startCase from 'lodash.startcase';
import moment, { isMoment, Moment } from 'moment';

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
};

export const formatItemNote = (note: string | undefined | null) =>
  (note ?? '').replace(/(<([^>]+)>)/gi, '');

export const formatEnum = (text: string | number | undefined | null) => {
  if (!text) {
    return '';
  }
  return startCase(lowerCase(String(text)));
};

export const formatPercentage = (value: string | number | undefined | null) =>
  `${value ?? 0}%`;

type FillAuctionTemplateProps = {
  auctionName?: string;
  auctionEnd?: string | Moment;
  pickupStart?: string | Moment;
  pickupEnd?: string | Moment;
  pickupLocation?: string;
  premium?: string;
  tax?: string;
};
export const fillAuctionTemplate = (
  text: string,
  {
    auctionName,
    auctionEnd,
    pickupStart,
    pickupEnd,
    pickupLocation,
    premium,
    tax,
  }: FillAuctionTemplateProps,
) => {
  let t = String(text);

  if (auctionName && auctionName.trim() !== '') {
    t = t.replace('{AUCTION_NAME}', auctionName);
  }

  if (auctionEnd) {
    const auctionEndMoment = isMoment(auctionEnd)
      ? auctionEnd
      : moment(auctionEnd);
    t = t.replace(
      '{AUCTION_END_DATETIME}',
      auctionEndMoment.format('ddd, MMM Do [at] h:mmA'),
    );
  }

  if (pickupStart && pickupEnd) {
    const pickupStartMoment = isMoment(pickupStart)
      ? pickupStart
      : moment(pickupStart);
    const pickupEndMoment = isMoment(pickupEnd) ? pickupEnd : moment(pickupEnd);
    const isSame = pickupStartMoment.isSame(pickupEndMoment, 'day');
    let pickupString = '';
    if (isSame) {
      pickupString = pickupEndMoment
        .format('ddd, MMM Do [between] [pickupStartTime] - hA')
        .replace('pickupStartTime', pickupStartMoment.format('hA'));
    } else {
      pickupString = `${pickupStartMoment.format(
        'ddd, MMM Do hA',
      )} to ${pickupEndMoment.format('ddd, MMM Do hA')}`;
    }
    t = t.replace('{PICKUP_START_END_DATETIME}', pickupString);
  }

  if (pickupLocation) {
    t = t.replace('{PICKUP_LOCATION}', pickupLocation);
  }

  if (premium) {
    t = t.replace('{PREMIUM_FEE_PERCENT}', `${premium}%`);
  }

  if (tax) {
    t = t.replace('{TAX_FEE_PERCENT}', `${tax}%`);
  }

  return String(t);
};

export const downloadFile = (url: string, fileName: string) => {
  // Create a link and set the URL using `createObjectURL`
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.download = fileName;

  // It needs to be added to the DOM so it can be clicked
  document.body.appendChild(link);
  link.click();

  // To make this work on Firefox we need to wait
  // a little while before removing it.
  setTimeout(() => {
    link?.parentNode?.removeChild(link);
  }, 0);
};

