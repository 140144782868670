import { useState } from 'react';

export const useModal = () => {
  const [visible, setVisible] = useState(false);

  const open = () => {
    setVisible(true);
  };

  const close = () => {
    setVisible(false);
  };

  return { visible, open, close };
};

export function useSelectedValueModal<T>() {
  const [selectedValue, setSelectedValue] = useState<T | undefined>(undefined);

  const open = (value: T) => {
    setSelectedValue(value);
  };

  const close = () => {
    setSelectedValue(undefined);
  };

  return { selectedValue, open, close };
}
