import ListScreen from 'components/ListScreen';
import {
  MissingRackLocationListDocument,
  MissingRackLocationListEntryFragment,
  useAllAuctionsQuery,
  useAllBiddersQuery,
  useAllInvoicesQuery,
  useAllLocationsQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

type Row = {
  row: MissingRackLocationListEntryFragment;
};

const RackLocationMissingScreen: FunctionComponent = () => {
  const history = useHistory();
  return (
    <ListScreen
      title="Missing Rack Locations"
      query={MissingRackLocationListDocument}
      onRowClick={(row: Row['row']) => {
        history.push(`/items/${row.itemId}`);
      }}
      columns={[
        {
          type: 'autocomplete',
          label: 'Auction',
          name: 'auction',
          makeFilterQuery: (value) => ({
            auction: {
              includesInsensitive: value,
            },
          }),
          useQuery: useAllAuctionsQuery,
          getOptionLabel: (item) => item.name,
          mapValueToFilter: (value) => ({
            name: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllAuctionsQuery>) =>
            result.data?.allAuctions?.nodes ?? [],
        },

        {
          type: 'autocomplete',
          label: 'Customer',
          name: 'customer',
          makeFilterQuery: (value) => ({
            customer: {
              includesInsensitive: value,
            },
          }),
          useQuery: useAllBiddersQuery,
          getOptionLabel: (item) => item.name,
          mapValueToFilter: (value) => ({
            name: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllBiddersQuery>) =>
            result.data?.allBidders?.nodes ?? [],
        },
        { label: 'Item', name: 'itemNumber' },
        {
          type: 'autocomplete',
          label: 'Invoice',
          name: 'invoiceUid',
          makeFilterQuery: (value) => ({
            invoiceUid: {
              includesInsensitive: value,
            },
          }),
          useQuery: useAllInvoicesQuery,
          getOptionLabel: (item) => item.uid,
          mapValueToFilter: (value) => ({
            uid: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllInvoicesQuery>) =>
            result.data?.allInvoices?.nodes ?? [],
        },
        {
          type: 'autocomplete',
          label: 'Location',
          name: 'location',
          makeFilterQuery: (value) => ({
            location: {
              includesInsensitive: value,
            },
          }),
          useQuery: useAllLocationsQuery,
          getOptionLabel: (item) => item.name,
          mapValueToFilter: (value) => ({
            name: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllLocationsQuery>) =>
            result.data?.allLocations?.nodes ?? [],
        },
      ]}
    />
  );
};

export default RackLocationMissingScreen;
