import { Stack } from '@mui/material';
import Autocomplete from 'components/Forms/Autocomplete';
import DateTimePicker from 'components/Forms/DateTimePicker';
import TextField from 'components/Forms/TextField';
import { makeValidate } from 'components/Forms/Validation';
import Modal from 'components/Modal';
import ModalActions from 'components/ModalActions';
import ModalLoading from 'components/ModalLoading';
import { errorNotification } from 'components/SnackBar';
import {
  GetLocationsDocument,
  GetUsersDocument,
  useGetTruckloadLazyQuery,
  useUpdateTruckloadMutation,
} from 'graphql/graphql-types';
import moment from 'moment';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Form } from 'react-final-form';
import { logError } from 'utils/logging';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const schema = Yup.object({
  loadIdentifier: Yup.string().required('Required'),
  facilityName: Yup.string().required('Required'),
  receivingLocation: Yup.object({
    id: Yup.number().required('Required'),
    name: Yup.string().required('Required'),
  }),
  dateReceived: Yup.string().required('Required'),
  itemsOnManifest: Yup.number()
    .typeError('Must be a number')
    .min(0)
    .required('Required'),
  createdBy: Yup.object({
    id: Yup.number().required('Required'),
    name: Yup.string().required('Required'),
  }),
});

type Schema = Asserts<typeof schema>;
const validate = makeValidate(schema);

type Props = {
  visible: boolean;
  type: 'create' | 'update';
  truckloadId: string | undefined;
  handleClose: () => void;
  handleSuccess: () => void;
};
const TruckloadForm: FunctionComponent<Props> = ({
  type,
  visible,
  truckloadId,
  handleClose,
  handleSuccess,
}) => {
  const [ready, setReady] = useState(false);
  const [initialValues, setInitialValues] = useState<Schema>();
  const [get, { data }] = useGetTruckloadLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [update] = useUpdateTruckloadMutation();

  useEffect(() => {
    if (visible && type === 'create') {
      setReady(true);
    }
    if (visible && type === 'update' && truckloadId) {
      get({
        variables: {
          id: truckloadId,
        },
      });
    }
  }, [visible, type, truckloadId]);

  useEffect(() => {
    if (data?.truckloadById) {
      const truckload = data.truckloadById;
      setInitialValues({
        loadIdentifier: truckload.loadIdentifier,
        facilityName: truckload.facilityName,
        receivingLocation: {
          id: Number(truckload.locationByReceivingLocation?.id),
          name: String(truckload.locationByReceivingLocation?.name),
        },
        // @ts-ignore
        dateReceived: moment(truckload.dateReceived) ?? '',
        itemsOnManifest: truckload.itemsOnManifest,
        createdBy: {
          id: Number(truckload.userByCreatedBy?.id),
          name: String(truckload.userByCreatedBy?.name),
        },
      });
      setReady(true);
    }
  }, [data]);

  const onSubmit = useCallback(
    async (values: Schema) => {
      if (truckloadId) {
        try {
          await update({
            variables: {
              input: {
                id: truckloadId,
                truckloadPatch: {
                  loadIdentifier: values.loadIdentifier,
                  facilityName: values.facilityName,
                  receivingLocation: values.receivingLocation.id,
                  dateReceived: values.dateReceived,
                  itemsOnManifest: Number(values.itemsOnManifest),
                  createdBy: Number(values.createdBy.id),
                },
              },
            },
          });
          handleSuccess();
        } catch (e: any) {
          logError(e);
          errorNotification(e?.message ?? 'Unknown error');
        }
      }
    },
    [truckloadId],
  );

  return (
    <Modal
      open={visible}
      title={type === 'create' ? 'Create Truckload' : 'Edit Truckload'}
      handleClose={handleClose}
      maxWidth="sm"
    >
      {!ready && <ModalLoading />}
      {ready && (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          render={({
            handleSubmit,
            initialValues: formInitialValues,
            ...props
          }) => (
            <form>
              <Stack>
                <TextField
                  required
                  label="Load Identifier"
                  id="loadIdentifier"
                  name="loadIdentifier"
                  variant="outlined"
                />
                <TextField
                  required
                  label="Facility Name"
                  id="facilityName"
                  name="facilityName"
                  variant="outlined"
                />
                <TextField
                  required
                  label="Items on Manifest"
                  id="itemsOnManifest"
                  name="itemsOnManifest"
                  parseType="integer"
                  variant="outlined"
                />
                <Autocomplete
                  label="Receiving Location"
                  name="receivingLocation"
                  required
                  query={GetLocationsDocument}
                  defaultValue={formInitialValues?.receivingLocation}
                  getOptionValue={(option: any) => ({
                    id: option.id,
                    name: option.name,
                  })}
                  getOptionLabel={(option: any) => option?.name}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.id === value?.id
                  }
                />
                <DateTimePicker
                  required
                  label="Date Received"
                  name="dateReceived"
                  disablePast={false}
                />
                <Autocomplete
                  label="Created By"
                  name="createdBy"
                  required
                  query={GetUsersDocument}
                  defaultValue={formInitialValues?.createdBy}
                  getOptionValue={(option: any) => ({
                    id: option.id,
                    name: option.name,
                  })}
                  getOptionLabel={(option: any) => option?.name}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option?.id === value?.id
                  }
                />
              </Stack>
              <ModalActions
                buttons={[
                  {
                    text: 'Cancel',
                    onClick: handleClose,
                    variant: 'text',
                  },
                  {
                    text: 'Save',
                    onClick: handleSubmit,
                    type: 'submit',
                    disabled:
                      props.submitting ||
                      props.pristine ||
                      props.hasValidationErrors ||
                      (props.hasSubmitErrors && !props.modifiedSinceLastSubmit),
                    variant: 'contained',
                    color: 'primary',
                  },
                ]}
              />
            </form>
          )}
        />
      )}
    </Modal>
  );
};

export default TruckloadForm;
