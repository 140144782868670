import { Box, Card, Stack, Typography } from '@mui/material';
import LocationSelect, {
  Option as LocationOption,
} from 'components/LocationSelect';
import { SimpleTable } from 'components/Table';
import { ViewScreen } from 'components/ViewScreen';
import {
  PicklistQueueItemEntryFragment,
  PicklistQueueStatus,
} from 'graphql/graphql-types';
import { usePicklistQueueByLocation } from 'hooks/picklistQueue';
import React, { useEffect, useState } from 'react';
import { displayElapsedTimeFromTimestamp, formatDateTime } from 'utils/time';
import ItemExpanded from './components/ItemExpanded';

const columns = [
  {
    label: 'Customer',
    name: 'customer',
    render: ({ row }: Row) => row?.customerName ?? '',
    width: '25%',
  },
  {
    label: 'Assigned',
    name: 'assigned',
    render: ({ row }: Row) => row?.assignedName ?? '',
    width: '25%',
  },
  {
    label: 'Elapsed',
    name: 'elapsed',
    render: ({ row }: Row) => displayElapsedTimeFromTimestamp(row.inQueueAt),
    width: '25%',
    align: 'right' as const,
  },
  {
    label: 'Schedule Time',
    name: 'scheduleTime',
    render: ({ row }: Row) => formatDateTime(row?.scheduleTime),
    width: '25%',
    align: 'right' as const,
  },
];

type Row = {
  row: PicklistQueueItemEntryFragment;
};

const Comp: React.FC = () => {
  const {
    handleStart,
    assigned,
    assignedCount,
    unassigned,
    unassignedCount,
    totalCount,
  } = usePicklistQueueByLocation();
  const [location, setLocation] = useState<LocationOption | null>(null);
  useEffect(() => {
    const id = location?.id;
    if (id) {
      handleStart(id);
    }
  }, [location]);
  const onLocationChange = (option: LocationOption) => setLocation(option);
  const locationId = location?.id;

  return (
    <ViewScreen
      loading={false}
      header={{
        leftProps: {
          title: 'Picklist Queue',
        },
        bottomProps: {
          component: (
            <Stack direction="row" spacing={1}>
              <LocationSelect onChange={onLocationChange} />
            </Stack>
          ),
        },
        dividerProps: {
          hide: true,
        },
      }}
    >
      <Box>
        <Box style={{ marginBottom: '2rem' }}>
          <Card variant="outlined" style={{ marginBottom: '1rem' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyItems: 'space-between',
                padding: '1rem 1rem 1rem',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>Unassigned</Typography>
                <Typography>{unassignedCount}</Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>Assigned</Typography>
                <Typography>{assignedCount}</Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Typography>Total</Typography>
                <Typography>{totalCount}</Typography>
              </Box>
            </div>
          </Card>
          <Card variant="outlined" style={{ marginBottom: '1rem' }}>
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'space-between',
                padding: '1rem 1rem 1rem',
              }}
            >
              <Typography>Unassigned</Typography>
              <Typography>{unassignedCount}</Typography>
            </div>
            <SimpleTable
              columns={columns}
              rows={unassigned}
              emptyMessage="Unassigned queue is empty"
              expandRow={(data: Row['row']) => (
                <ItemExpanded
                  locationId={locationId}
                  row={data}
                  isAssigned={false}
                  status={{
                    equalTo: PicklistQueueStatus.Waiting,
                  }}
                />
              )}
            />
            <Box
              style={{
                backgroundColor: '#f7fafc',
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                paddingTop: '1rem',
              }}
            />
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'space-between',
                padding: '1rem 1rem 1rem',
              }}
            >
              <Typography>Assigned</Typography>
              <Typography>{assignedCount}</Typography>
            </div>
            <SimpleTable
              columns={columns}
              rows={assigned}
              emptyMessage="Assigned queue is empty"
              expandRow={(data: Row['row']) => (
                <ItemExpanded
                  locationId={locationId}
                  row={data}
                  isAssigned={Boolean(true)}
                  status={{
                    equalTo: PicklistQueueStatus.WorkingOn,
                  }}
                />
              )}
            />
          </Card>
        </Box>
      </Box>
    </ViewScreen>
  );
};

export default Comp;
