import numeral from 'numeral';

export const formatCurrency = (amount: number, format = '$0,0.00'): string =>
  numeral(amount).format(format);

export const formatCentsToDollars = (cents: any, format = '$0,0.00') => {
  if (Number.isNaN(cents)) {
    return '';
  }
  return formatCurrency(cents / 100, format);
};

export const makeWholeCents = (number: number, makeCents = true): number =>
  Math.trunc(makeCents ? number * 100 : number);
