import { ItemHistoryType } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { formatEnum } from 'utils/general';

export const formatRackLocation = (value: string | undefined | null) => {
  if (value === null || value === undefined) {
    return 'Not set';
  }
  if (value === '') {
    return 'Cleared';
  }
  return value;
};

export const formatBin = (value: string | undefined | null) => {
  if (value === null || value === undefined) {
    return 'Not set';
  }
  if (value === '') {
    return 'Cleared';
  }
  return value;
};

type Props = {
  type: 'old' | 'new';
  value: string | undefined | null;
  valueType: ItemHistoryType | undefined | null;
};
const ItemHistoryItem: FunctionComponent<Props> = ({
  type,
  valueType,
  value,
}) => {
  if (!valueType) {
    return null;
  }

  if (valueType === ItemHistoryType.RackLocation) {
    return <>{formatRackLocation(value)}</>;
  }

  if (valueType === ItemHistoryType.Bin) {
    return <>{formatRackLocation(value)}</>;
  }

  if (valueType === ItemHistoryType.NotFound) {
    if (type === 'old') {
      return <></>;
    }
    return <>{type === 'new' ? value : ''}</>;
  }

  return <>{formatEnum(value)}</>;
};

export default ItemHistoryItem;
