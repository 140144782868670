import React, { FunctionComponent } from 'react';
import { Field } from 'react-final-form';

type Props = {
  when: string;
  is: (value: any) => boolean;
};
const Condition: FunctionComponent<Props> = ({ when, is, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => (is(value) ? children : null)}
  </Field>
);

export default Condition;
