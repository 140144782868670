import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import { useDeletePicklistQueueMutation } from 'graphql/graphql-types';
import React from 'react';
import { logError } from 'utils/logging';

type Props = {
  id: string;
};

const Comp: React.FC<Props> = ({ id }) => {
  const [deleteItem] = useDeletePicklistQueueMutation();

  const onClick = React.useCallback(async () => {
    if (id) {
      try {
        await deleteItem({
          variables: {
            input: {
              id,
            },
          },
          // remove item from cache
          update(cache) {
            cache.evict({
              id: cache.identify({
                id,
                __typename: 'PicklistQueue',
              }),
            });
            cache.gc();
          },
        });
      } catch (e) {
        logError(e);
      }
    }
  }, [id]);

  return (
    <IconButton size="small" onClick={onClick}>
      <DeleteIcon fontSize="small" />
    </IconButton>
  );
};

export default Comp;
