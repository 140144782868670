import AccountCircle from '@mui/icons-material/AccountCircle';
import Logout from '@mui/icons-material/Logout';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Settings from '@mui/icons-material/Settings';
import {
  Badge,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import NotificationDrawer from 'components/NotificationDrawer';
import {
  useCurrentUserQuery,
  useGetMyNotificationsQuery,
} from 'graphql/graphql-types';
import { useLogout } from 'hooks/auth';
import { useModal } from 'hooks/modal';
import React from 'react';
import { useHistory } from 'react-router-dom';

const StyledBadge = styled(Badge)(({ theme }) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    backgroundColor: red[500],
    color: 'white',
  },
}));

export default function MenuListComposition() {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data: myNotificationsData } = useGetMyNotificationsQuery({
    fetchPolicy: 'cache-only',
  });

  // We check the node length here. Typically we would check the totalCount but
  // since this query only checks cache, the totalCount will not reflect the accurate
  // length of nodes instead we use the length of node entries to get the count.
  const totalMyNotifications =
    myNotificationsData?.allUserNotifications?.nodes?.length ?? 0;

  const anchorRef = React.useRef<HTMLButtonElement>(null);
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const logout = useLogout();
  const { data } = useCurrentUserQuery();
  const {
    visible: notificationsVisible,
    open: openNotifications,
    close: closeNotifications,
  } = useModal();
  const user = data?.currentUser;
  const username = user?.username ?? '';

  const goProfile = () => history.push('/profile');

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    logout();
    handleClose();
  };

  return (
    <div>
      <>
        <Box
          sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
        >
          <IconButton
            onClick={(e: any) => {
              e.stopPropagation();
              openNotifications();
            }}
          >
            <StyledBadge badgeContent={totalMyNotifications}>
              <NotificationsIcon />
            </StyledBadge>
          </IconButton>
          <Tooltip title="Account settings">
            <IconButton onClick={handleClick} sx={{ ml: 2 }}>
              <AccountCircle />
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem disabled sx={{ opacity: '100 !important' }}>
            {username}
          </MenuItem>
          <Divider />
          <MenuItem onClick={goProfile}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Profile
          </MenuItem>
          <MenuItem onClick={handleSignOut}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </>
      <NotificationDrawer
        isVisible={notificationsVisible}
        onClose={closeNotifications}
      />
    </div>
  );
}
