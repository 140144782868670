import { ApolloError } from '@apollo/client';
import { Box } from '@mui/material';
import CenterLoading from 'components/CenterLoading';
import React from 'react';
import Footer, { Props as FooterProps } from './Footer';
import Header, { Props as HeaderProps } from './Header';
import { useStyles } from './styles';

type ViewScreenProps = {
  loading: boolean;
  error?: ApolloError;
  header: HeaderProps;
  footer?: FooterProps;
  children: any;
};
export const ViewScreen = ({
  loading,
  error,
  children,
  header,
  footer,
}: ViewScreenProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Header {...header} loading={loading} />
      {loading && <CenterLoading />}
      {!loading && error && <p>Error</p>}
      {!loading && !error ? children : null}
      <Footer {...footer} loading={loading} />
    </Box>
  );
};

export default ViewScreen;
