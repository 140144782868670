import { Axis, Chart, Line, Point, Tooltip } from 'bizcharts';
import GraphTitle from 'components/GraphTitle';
import React, { FunctionComponent } from 'react';
import { formatNumber } from 'utils/math';

const scale = {
  date: {
    type: 'cat',
    nice: false,
  },
  value: {
    type: 'linear-strict',
    alias: 'Amount',
    formatter: (value: number) => formatNumber(value),
  },
};

type Data = {
  date: string;
  value: number;
};

type Props = {
  showTitle: boolean;
  data: Data[];
};
const ItemsSoldChart: FunctionComponent<Props> = ({ showTitle, data }) => (
  <>
    {showTitle && <GraphTitle title="Items sold" />}
    <Chart
      height={400}
      data={data}
      autoFit
      scale={scale}
      padding="auto"
      interactions={['active-region']}
    >
      <Line
        position="date*value"
        shape="smooth"
        style={{
          lineDash: [5, 5],
        }}
      />
      <Axis
        name="date"
        label={{
          rotate: 0.5,
          autoRotate: true,
          style: { textAlign: 'start' },
        }}
      />
      <Point position="date*value" />
      <Tooltip shared showCrosshairs />
    </Chart>
  </>
);

export default ItemsSoldChart;
