import { Typography } from '@mui/material';
import Modal from 'components/Modal';
import ModalActions from 'components/ModalActions';
import ModalLoading from 'components/ModalLoading';
import { useTotalAuctionUnpaidInvoicesLazyQuery } from 'graphql/graphql-types';
import React, { FunctionComponent, useEffect, useState } from 'react';

type Props = {
  auctionId: string | number | null;
  isOpen: boolean;
  onClose: () => void;
  onOk: () => void;
};
const AuctionProcessInvoicePaymentsModal: FunctionComponent<Props> = ({
  auctionId,
  isOpen,
  onClose,
  onOk,
}) => {
  const [ready, setReady] = useState(false);
  const [get, { data }] = useTotalAuctionUnpaidInvoicesLazyQuery();

  useEffect(() => {
    if (isOpen && auctionId) {
      get({
        variables: {
          auctionId: Number(auctionId),
        },
      });
    }
  }, [isOpen, auctionId]);

  useEffect(() => {
    if (typeof data?.totalAuctionUnpaidInvoices === 'number') {
      setReady(true);
    }
  }, [data]);

  return (
    <Modal
      open={isOpen}
      title="Charge Invoice Payments"
      handleClose={onClose}
      maxWidth="sm"
    >
      {!ready && <ModalLoading />}
      {ready && (
        <>
          <Typography>
            {`There are ${data?.totalAuctionUnpaidInvoices} unpaid invoices for this auction.`}
          </Typography>
          <br />
          <Typography>
            This action will charge the unpaid invoices. Are you sure you want
            to do this?
          </Typography>
          <ModalActions
            buttons={[
              {
                text: 'Cancel',
                onClick: onClose,
              },
              {
                text: 'Yes',
                onClick: onOk,
              },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

export default AuctionProcessInvoicePaymentsModal;
