import { PaymentOverallStatusChip } from 'components/Chips';
import ListScreen from 'components/ListScreen';
import { SortTypes } from 'components/Table';
import {
  AllPaymentsDocument,
  PaymentEntryFragment,
  useAllBiddersQuery,
  useAllInvoicesQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { formatCentsToDollars } from 'utils/money';
import { formatDateTime } from 'utils/time';
import { PAYMENT_CHARGE_STATUS_FILTER_ELEMENTS } from './filters';

type Row = {
  row: PaymentEntryFragment;
};

const PaymentListScreen: FunctionComponent = () => {
  const history = useHistory();

  return (
    <ListScreen
      title="Payments"
      query={AllPaymentsDocument}
      onRowClick={(row: Row['row']) => {
        history.push(`/payments/${row.id}`);
      }}
      defaultOrder="createdAt"
      defaultSort={SortTypes.DESC}
      columns={[
        {
          type: 'autocomplete',
          label: 'Customer',
          name: 'invoiceByInvoiceId.bidderByBidderId.name',
          makeFilterQuery: (value) => ({
            invoiceByInvoiceId: {
              bidderByBidderId: {
                name: {
                  includesInsensitive: value,
                },
              },
            },
          }),
          useQuery: useAllBiddersQuery,
          getOptionLabel: (item) => item.name,
          mapValueToFilter: (value) => ({
            name: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllBiddersQuery>) =>
            result.data?.allBidders?.nodes ?? [],
        },
        {
          type: 'autocomplete',
          label: 'Invoice',
          name: 'invoiceByInvoiceId.uid',
          makeFilterQuery: (value) => ({
            invoiceByInvoiceId: {
              uid: {
                includesInsensitive: value,
              },
            },
          }),
          useQuery: useAllInvoicesQuery,
          getOptionLabel: (item) => item.uid,
          mapValueToFilter: (value) => ({
            uid: {
              includesInsensitive: value,
            },
          }),
          getOptions: (result: ReturnType<typeof useAllInvoicesQuery>) =>
            result.data?.allInvoices?.nodes ?? [],
        },
        {
          label: 'Card',
          name: 'cardNumber',
        },
        {
          label: 'Amount',
          name: 'amount',
          type: 'decimal',
          render: ({ row }: Row) => formatCentsToDollars(row.amount),
        },
        {
          label: 'Time',
          name: 'createdAt',
          render: ({ row }: Row) => formatDateTime(row.createdAt),
          type: 'date',
          formatDateValue: 'MM/DD/YYYY h:mm A',
          width: 250,
        },
        {
          label: 'Status',
          name: 'status',
          type: 'select',
          filterElements: PAYMENT_CHARGE_STATUS_FILTER_ELEMENTS,
          render: ({ row }: Row) => <PaymentOverallStatusChip {...row} />,
          makeFilterQuery: (value) => ({
            chargeStatus: { equalTo: value },
          }),
        },
      ]}
    />
  );
};

export default PaymentListScreen;
