import { readFileFromUrl } from 'components/Forms/ImageEditorField/utils';
import { RootCache } from 'config/ApolloClient';
import {
  AllItemImagesDocument,
  AllItemImagesQuery,
  AllItemImagesQueryVariables,
  ItemImageEntryFragment,
  ItemImageEntryFragmentDoc,
  useCopyItemImageMutation,
  useDeleteItemImageMutation,
  useResortItemImagesMutation,
  useReuploadItemImageMutation,
  useSetPrimaryItemImageMutation,
  useUploadItemImageMutation,
} from 'graphql/graphql-types';
import { useCallback, useState } from 'react';
import { logError } from 'utils/logging';

export const useItemImageModification = () => {
  const [image, setImage] = useState<null | string>(null);
  const [editingItemImageId, setEditingItemImageId] = useState<number | null>(
    null,
  );
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [copyItemImageId, setCopyItemImageId] = useState<number | null>(null);

  const [uploadItemImages] = useUploadItemImageMutation();

  const [resortItemImages] = useResortItemImagesMutation();

  const [setPrimaryItemImage] = useSetPrimaryItemImageMutation();

  const [reuploadItemImage] = useReuploadItemImageMutation();

  const [deleteItemImage] = useDeleteItemImageMutation();

  const [copyItemImage] = useCopyItemImageMutation();

  const handleSetPrimaryImage = useCallback(
    async (itemImageId: number, isPrimary: boolean) => {
      try {
        // get cached image so we can perform optimistic response
        const cachedImage = RootCache.readFragment<ItemImageEntryFragment>({
          id: RootCache.identify({
            __typename: 'ItemImage',
            id: itemImageId,
          }),
          fragment: ItemImageEntryFragmentDoc,
        });

        if (cachedImage) {
          setPrimaryItemImage({
            variables: { isPrimary, itemImageId },
            // handle quick optimistic response
            optimisticResponse: {
              __typename: 'Mutation',
              updateItemImageById: {
                itemImage: {
                  ...cachedImage,
                  isPrimary,
                },
              },
            },
          });
        }
      } catch (e) {
        logError(e);
      }
    },
    [],
  );

  const handleDeleteImage = useCallback(
    async (itemImageId: number, itemId: string) => {
      try {
        await deleteItemImage({
          variables: { itemImageId },
          update: (cache, { data }) => {
            const allItemImagesData = cache.readQuery<
              AllItemImagesQuery,
              AllItemImagesQueryVariables
            >({
              query: AllItemImagesDocument,
              variables: { itemId: Number(itemId) },
            });

            cache.writeQuery<AllItemImagesQuery, AllItemImagesQueryVariables>({
              query: AllItemImagesDocument,
              variables: { itemId: Number(itemId) },
              data: {
                allItemImages: {
                  nodes: (allItemImagesData?.allItemImages?.nodes ?? []).filter(
                    ({ id: imageId }) =>
                      imageId !== data?.updateItemImageById?.itemImage?.id,
                  ),
                },
              },
            });
          },
        });
      } catch (e) {
        logError(e);
      }
    },
    [],
  );

  const handleUploadImage = useCallback(async (itemId: number, file: Blob) => {
    try {
      await uploadItemImages({
        variables: { itemId: Number(itemId), image: file },

        update: (cache, mutationResult) => {
          const allItemImagesData = cache.readQuery<
            AllItemImagesQuery,
            AllItemImagesQueryVariables
          >({
            query: AllItemImagesDocument,
            variables: { itemId: Number(itemId) },
          });

          const newItemImage =
            mutationResult?.data?.uploadItemsImage?.itemImage;
          if (newItemImage) {
            cache.writeQuery<AllItemImagesQuery, AllItemImagesQueryVariables>({
              query: AllItemImagesDocument,
              variables: { itemId: Number(itemId) },
              data: {
                allItemImages: {
                  nodes: [
                    ...(allItemImagesData?.allItemImages?.nodes ?? []),
                    newItemImage,
                  ],
                },
              },
            });
          }
        },
      });
    } catch (e) {
      logError(e);
    }
  }, []);

  const handleReuploadImage = useCallback(
    async (itemImageId: number, file: Blob) => {
      await reuploadItemImage({
        variables: { itemImageId, image: file },
      });
    },
    [],
  );

  const handleResortImages = useCallback(
    async (itemId: string, newItemImages: ItemImageEntryFragment[]) => {
      try {
        await resortItemImages({
          variables: {
            itemId: Number(itemId),
            images: newItemImages.map(({ id: itemImageId }, i) => ({
              itemImageId,
              sort: i,
            })),
          },
          update: (cache, { data }) => {
            const success = data?.resortItemImages?.resortResult?.success;
            if (success) {
              cache.writeQuery<AllItemImagesQuery, AllItemImagesQueryVariables>(
                {
                  query: AllItemImagesDocument,
                  variables: { itemId: Number(itemId) },
                  data: {
                    allItemImages: {
                      nodes: newItemImages.map((itemImage, i) => ({
                        ...itemImage,
                        sort: i,
                      })),
                    },
                  },
                },
              );
            }
          },
          optimisticResponse: {
            resortItemImages: {
              resortResult: { success: true },
            },
          },
        });
      } catch (e) {
        logError(e);
      }
    },
    [],
  );

  const handleCopyItemImage = useCallback(
    async (toItemId: number) => {
      if (copyItemImageId) {
        try {
          await copyItemImage({
            variables: { itemImageId: copyItemImageId, toItemId },
            update: (cache, mutationResult) => {
              const allItemImagesData = cache.readQuery<
                AllItemImagesQuery,
                AllItemImagesQueryVariables
              >({
                query: AllItemImagesDocument,
                variables: { itemId: Number(copyItemImageId) },
              });

              const newItemImage =
                mutationResult?.data?.copyItemsImage?.itemImage;

              if (newItemImage) {
                cache.writeQuery<
                  AllItemImagesQuery,
                  AllItemImagesQueryVariables
                >({
                  query: AllItemImagesDocument,
                  variables: { itemId: Number(toItemId) },
                  data: {
                    allItemImages: {
                      nodes: [
                        ...(allItemImagesData?.allItemImages?.nodes ?? []),
                        newItemImage,
                      ],
                    },
                  },
                });
              }
            },
          });
          setCopyItemImageId(null);
        } catch (e) {
          logError(e);
        }
      }
    },
    [copyItemImageId],
  );

  const handleOpenCopyModal = useCallback((itemImageId: number) => {
    setCopyItemImageId(itemImageId);
  }, []);

  const handleCloseCopyModal = useCallback(() => {
    setCopyItemImageId(null);
  }, []);

  const handleCloseEditImageModal = useCallback(() => {
    setImage(null);
    setEditingItemImageId(null);
  }, []);

  const handleUpload = useCallback(
    async (newImage: Blob) => {
      try {
        setImage(null);

        if (editingItemImageId) {
          await handleReuploadImage(editingItemImageId, newImage);
        } else if (editingItemId) {
          await handleUploadImage(Number(editingItemId), newImage);
        }
        setEditingItemImageId(null);
        setEditingItemId(null);
      } catch (e) {
        logError(e);
      }
    },
    [editingItemImageId, editingItemId],
  );

  const handleEditImage = useCallback(
    async (imageUrl: string, itemImageId: number, itemId: string) => {
      const imageDataUrl = await readFileFromUrl(imageUrl);
      if (imageDataUrl) {
        setImage(imageDataUrl);
        setEditingItemImageId(itemImageId);
        setEditingItemId(itemId);
      }
    },
    [],
  );

  return {
    handleResortImages,
    handleCopyItemImage,
    handleSetPrimaryImage,
    handleReuploadImage,
    handleUploadImage,
    handleDeleteImage,
    handleOpenCopyModal,
    handleCloseCopyModal,
    copyItemImageId,
    handleCloseEditImageModal,
    handleUpload,
    handleEditImage,
    setImage,
    image,
    setEditingItemId,
  };
};
