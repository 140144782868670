import { useInitialAuth } from 'hooks/auth';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { privateRoutes, publicRoutes } from './routes';

export default () => {
  useInitialAuth();
  return (
    <Switch>
      {publicRoutes.map(({ path, component: Component }) => (
        <Route key={path} exact path={path}>
          <Component />
        </Route>
      ))}
      {privateRoutes.map(
        ({
          path,
          title,
          component: Component,
          exact = true,
          appWrapper,
          accessRoles,
        }) => (
          <PrivateRoute
            key={path}
            exact={exact}
            path={path}
            title={title}
            appWrapper={appWrapper}
            accessRoles={accessRoles}
          >
            <Component />
          </PrivateRoute>
        ),
      )}
      {/* Catch all or 404 */}
      <Route path="*">
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};
