import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ExpandLess from '@mui/icons-material/ExpandLess';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ExpandMore from '@mui/icons-material/ExpandMore';
import GavelIcon from '@mui/icons-material/Gavel';
import HomeIcon from '@mui/icons-material/Home';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PersonIcon from '@mui/icons-material/Person';
import ReceiptIcon from '@mui/icons-material/Receipt';
import SettingsIcon from '@mui/icons-material/Settings';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import ViewListIcon from '@mui/icons-material/ViewList';
import Badge from '@mui/material/Badge';
import Collapse from '@mui/material/Collapse';
import { blue, red } from '@mui/material/colors';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import makeStyles from '@mui/styles/makeStyles';
import { UserRoles } from 'graphql/graphql-types';
import React, { FunctionComponent, ReactNode } from 'react';
import { LinkProps, useHistory, useLocation } from 'react-router-dom';
import { ADMINISTRATOR, doesUserHaveAccess } from 'utils/auth';
import { ListItemButton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: '#3F5673',
    fontSize: '18px',
    minWidth: '48px',
    '&.Mui-selected': {
      color: '#3D8EF2',
    },
  },
  listButton: {
    // backgroundColor: theme.palette.background.paper,
    backgroundClip: 'border-box',
    borderRadius: '4px',
    padding: theme.spacing(0.3, 1),
    margin: theme.spacing(0.5, 1),
    color: 'rgba(0,0,0,0.8)',
    width: '224px',
    '&.Mui-selected': {
      color: 'rgb(0,0,0)',
      fontWeight: 500,
      backgroundColor: 'rgba(0,0,0,0.08)',
    },
  },
  listButtonText: {
    fontSize: '14px',
    '&.Mui-selected': {
      color: '#3D8EF2',
    },
  },
  badgeIcon: {
    justifyContent: 'flex-end',
    paddingRight: '12px',
  },
}));

interface ListLinkProps {
  to: string;
  icon?: React.FC<{ className: string }>;
  text: string;
  exact?: boolean;
  badge?: string | number;
}

export const ListLink = ({
  to,
  icon: IconComponent,
  text,
  exact = false,
  badge,
}: ListLinkProps & LinkProps) => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const isSelected = exact
    ? pathname === to
    : pathname.includes(to) && pathname.startsWith(to);
  const history = useHistory();

  return (
    <ListItemButton
      onClick={() => {
        history.push(to);
      }}
      selected={isSelected}
      className={classes.listButton}
    >
      <ListItemIcon>
        {IconComponent && <IconComponent className={classes.icon} />}
      </ListItemIcon>
      <ListItemText
        primary={text}
        classes={{
          primary: classes.listButtonText,
        }}
      />
      {badge !== undefined && (
        <ListItemIcon className={classes.badgeIcon}>
          <Badge
            badgeContent={badge}
            showZero={false}
            componentsProps={{
              badge: {
                style: {
                  backgroundColor: badge === 0 ? blue[500] : red[500],
                  color: 'white',
                },
              },
            }}
          />
        </ListItemIcon>
      )}
    </ListItemButton>
  );
};

interface CollapsedListProps {
  text: string;
  icon?: React.FC<{ className: string }>;
  children: ReactNode;
}

export const CollapsedList: React.FunctionComponent<CollapsedListProps> = ({
  text,
  icon: IconComponent,
  children,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick} className={classes.listButton}>
        <ListItemIcon>
          {IconComponent && <IconComponent className={classes.icon} />}
        </ListItemIcon>
        <ListItemText
          primary={text}
          classes={{
            primary: classes.listButtonText,
          }}
        />
        {open ? (
          <ExpandLess className={classes.icon} />
        ) : (
          <ExpandMore className={classes.icon} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
};

type NavItemProps = {
  userRole: UserRoles | undefined;
};

type MainListItemsProps = {
  totalReturnQueue: number;
} & NavItemProps;

export const MainListItems: FunctionComponent<MainListItemsProps> = ({
  totalReturnQueue,
}) => (
  <div>
    <ListLink text="Dashboard" to="/" icon={HomeIcon} exact />
    <CollapsedList text="Tasks" icon={AssignmentIcon}>
      <ListLink
        text="BidWrangler"
        to="/returnQueue/bidwrangler"
        badge={totalReturnQueue}
      />
      <ListLink text="Mercury" to="/returnQueue/mercury" />
    </CollapsedList>
    <CollapsedList text="Auction" icon={GavelIcon}>
      <ListLink text="List" to="/auctions" />
      <ListLink text="Changes" to="/auctionChanges" />
    </CollapsedList>
    <ListLink text="Invoices" to="/invoices" icon={ReceiptIcon} />
    <ListLink text="Payments" to="/payments" icon={MonetizationOnIcon} />
    <ListLink
      text="Transactions"
      to="/transactions"
      icon={SwapHorizontalCircleIcon}
    />
    <ListLink text="Items" to="/items" icon={InventoryIcon} />
    <ListLink text="Customers" to="/customers" icon={PersonIcon} />
    <CollapsedList text="Pickup" icon={ViewListIcon}>
      <ListLink text="Queue" to="/pickup/queue" />
      <ListLink text="Live" to="/pickup/live" />
    </CollapsedList>
    <CollapsedList text="Picklist" icon={ViewListIcon}>
      <ListLink text="Queue" to="/picklist/queue" />
    </CollapsedList>
    <CollapsedList text="Shipping" icon={ViewListIcon}>
      <ListLink text="Queue" to="/shipping/queue" />
    </CollapsedList>
  </div>
);

export const SecondaryListItems: FunctionComponent<NavItemProps> = ({
  userRole,
}) => {
  const hasAccess = doesUserHaveAccess(userRole, [ADMINISTRATOR]);
  return (
    <div>
      <ListLink text="Settings" to="/settings" icon={SettingsIcon} />
      {hasAccess && (
        <>
          <ListLink text="Reports" to="/reports" icon={AssessmentIcon} />
          <ListLink
            text="Truckloads"
            to="/truckloads"
            icon={LocalShippingIcon}
          />
          <ListLink
            text="Employee Stats"
            to="/employeeStats"
            icon={QueryStatsIcon}
          />
        </>
      )}
    </div>
  );
};

export const TertiaryListItems: FunctionComponent<NavItemProps> = () => (
  <div>
    <CollapsedList text="Rack Locations" icon={AssessmentIcon}>
      <ListLink text="List" to="/rackLocations/list" />
      <ListLink text="Missing" to="/rackLocations/missing" />
    </CollapsedList>
  </div>
);
