import { Box, TableCell, TableRow, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  empty: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

type Props = {
  colSpan: number;
  text?: string;
};
export default ({ colSpan, text = 'No data to display' }: Props) => {
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.empty} align="center" colSpan={colSpan}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
        >
          <Typography>{text}</Typography>
        </Box>
      </TableCell>
    </TableRow>
  );
};
