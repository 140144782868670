import { Chip } from '@mui/material';
import { brown } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  status: {
    backgroundColor: brown[50],
    borderRadius: 5,
    color: brown[900],
    fontWeight: 500,
  },
}));

type Props = {
  text: string;
  renderNullIfEmpty?: boolean;
  onClick?: () => void;
};
const NeutralChip: FunctionComponent<Props> = ({
  text,
  renderNullIfEmpty = true,
  onClick,
}) => {
  const classes = useStyles();
  return !text && renderNullIfEmpty ? null : (
    <Chip
      size="small"
      label={text}
      className={classes.status}
      clickable={onClick !== undefined}
      onClick={onClick}
    />
  );
};

export default NeutralChip;
