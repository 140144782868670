import { ViewScreen } from 'components/ViewScreen';
import { EmployeeStatFragmentFragment } from 'graphql/graphql-types';
import React, { FunctionComponent, useEffect, useState } from 'react';
import LocationSelect, {
  Option as LocationOption,
} from 'components/LocationSelect';
import { Paper, Stack } from '@mui/material';
import { useEmployeeStatsByLocation } from 'hooks/employeeStats';
import { SimpleTable } from 'components/Table';

type Row = {
  row: EmployeeStatFragmentFragment;
};

const topColumns = [
  {
    label: '',
    name: 'employee',
    span: 1,
    align: 'center' as const,
    borderRight: true,
  },
  {
    label: 'Daily Items',
    name: 'daily',
    span: 3,
    align: 'center' as const,
    borderRight: true,
  },
  {
    label: 'Last 60 Minutes Items',
    name: 'last60Minutes',
    span: 3,
    align: 'center' as const,
  },
];

const columns = [
  {
    label: 'Employee',
    name: 'employeeName',
    render: ({ row }: Row) => row?.employeeName,
    borderRight: true,
  },
  {
    label: 'PL',
    name: 'dailyPicklistItems',
    align: 'center' as const,
    render: ({ row }: Row) => row?.dailyPicklistItems,
  },
  {
    label: 'PU',
    name: 'dailuPickupItems',
    align: 'center' as const,
    render: ({ row }: Row) => row?.dailyPickupItems,
  },
  {
    label: 'TO',
    name: 'dailyTotalItems',
    align: 'center' as const,
    render: ({ row }: Row) => row?.dailyTotalItems,
    borderRight: true,
  },
  {
    label: 'PL',
    name: 'last60PicklistItems',
    align: 'center' as const,
    render: ({ row }: Row) => row?.last60PicklistItems,
  },
  {
    label: 'PU',
    name: 'last60PickupItems',
    align: 'center' as const,
    render: ({ row }: Row) => row?.last60PickupItems,
  },
  {
    label: 'TO',
    name: 'last60TotalItems',
    align: 'center' as const,
    render: ({ row }: Row) => row?.last60TotalItems,
  },
];

const EmployeeStatsScreen: FunctionComponent = () => {
  const { handleStart, data } = useEmployeeStatsByLocation();
  const [location, setLocation] = useState<LocationOption | null>(null);
  useEffect(() => {
    const id = location?.id;
    if (id) {
      handleStart(id);
    }
  }, [location]);
  const onLocationChange = (option: LocationOption) => setLocation(option);

  return (
    <ViewScreen
      loading={false}
      header={{
        leftProps: {
          title: 'Employee Stats',
        },
        dividerProps: {
          hide: true,
        },
        bottomProps: {
          component: (
            <Stack direction="row" spacing={1}>
              <LocationSelect onChange={onLocationChange} />
            </Stack>
          ),
        },
      }}
    >
      <Paper variant="outlined" square>
        <SimpleTable
          topColumns={topColumns}
          columns={columns}
          rows={data}
          emptyMessage={
            location?.id ? 'No stats to display' : 'Select a location'
          }
        />
      </Paper>
    </ViewScreen>
  );
};

export default EmployeeStatsScreen;
