import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MarkdownIt from 'markdown-it';
import React from 'react';
import { Field } from 'react-final-form';
import MdEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import styled from 'styled-components';

const mdParser = new MarkdownIt(/* Markdown-it options */);

const showError = ({
  meta: { submitError, dirtySinceLastSubmit, error, touched, modified },
}: any) =>
  !!(
    ((submitError && !dirtySinceLastSubmit) || error) &&
    (touched || modified)
  );

const StyledInputLabel = styled(InputLabel)(({}) => ({
  formControl: {
    position: 'initial',
    marginBottom: 4,
  },
}));

const EditorFieldWrapper = (props: any) => {
  const {
    input: { name, onChange, value, ...restInput },
    meta,
    plugins,
    config,
    ...rest
  } = props;

  const { error, active } = meta;
  const isError = showError({ meta });

  const handleEditorChange = ({ text }: any) => {
    onChange(text);
  };

  const { label, required, id } = rest;
  return (
    <FormControl fullWidth error={isError}>
      <StyledInputLabel
        shrink
        focused={active}
        required={required}
        error={isError}
        htmlFor={id}
      >
        {label}
      </StyledInputLabel>
      <div {...restInput}>
        <MdEditor
          id={id}
          value={value}
          plugins={plugins}
          config={config}
          style={{ height: '300px' }}
          renderHTML={(text) => mdParser.render(text)}
          onChange={handleEditorChange}
        />
      </div>

      {isError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const EditorField = (props: any) => {
  const { name, fieldProps, ...rest } = props;

  return (
    <Field
      name={name as any}
      render={(fieldRenderProps) => (
        <EditorFieldWrapper {...fieldRenderProps} {...rest} />
      )}
      {...fieldProps}
    />
  );
};

export default EditorField;
