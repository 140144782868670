import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import NeutralChip from 'components/Chips/NeutralChip';
import ItemHistoryItem from 'components/ItemHistoryItem';
import ModalLoading from 'components/ModalLoading';
import { SimpleTable } from 'components/Table';
import {
  ItemHistoryEntryFragment,
  useItemHistoryLazyQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { formatEnum } from 'utils/general';
import { formatDateTime } from 'utils/time';

type Row = {
  row: ItemHistoryEntryFragment;
};

const columns = [
  {
    label: 'Type',
    name: 'type',
    render: ({ row }: Row) => <NeutralChip text={formatEnum(row?.type)} />,
    width: '15%',
  },
  {
    label: 'Old',
    name: 'oldValue',
    render: ({ row }: Row) => (
      <ItemHistoryItem type="old" valueType={row?.type} value={row.oldValue} />
    ),
    width: '20%',
  },
  {
    label: 'New',
    name: 'newValue',
    render: ({ row }: Row) => (
      <ItemHistoryItem type="new" valueType={row?.type} value={row.newValue} />
    ),
    width: '20%',
  },
  {
    label: 'User',
    name: 'userByCreatedBy.name',
    render: ({ row }: Row) => row?.createdByUser,
    width: '15%',
  },
  {
    label: 'Time',
    name: 'createdAt',
    render: ({ row }: Row) => formatDateTime(row?.createdAt),
    align: 'right' as const,
    width: '30%',
  },
];

type Props = {
  itemId: number;
  isOpen: boolean;
  handleClose: () => void;
};
const ItemHistoryModal: FunctionComponent<Props> = ({
  itemId,
  isOpen,
  handleClose,
}) => {
  const [ready, setReady] = useState(false);
  const [get, { data }] = useItemHistoryLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (itemId && isOpen) {
      get({
        variables: {
          itemId,
        },
      });
    }
  }, [itemId, isOpen]);

  useEffect(() => {
    if (data?.getItemHistory?.nodes) {
      setReady(true);
    }
  });

  const histories = data?.getItemHistory?.nodes ?? [];

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Item History"
      open={isOpen}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Item History</DialogTitle>
      <DialogContent>
        {!ready && <ModalLoading />}
        {ready && (
          <SimpleTable
            columns={columns}
            rows={histories}
            emptyMessage="No history"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemHistoryModal;
