export const getItem = async (key: string) => {
  try {
    const jsonValue = localStorage.getItem(key);
    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // TODO log error
    return null;
  }
};

export const setItem = async (key: string, value: Record<string, any>) => {
  try {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem(key, jsonValue);
    return true;
  } catch (e) {
    // TODO log error
    return false;
  }
};
