import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#224D8F',
    },
    secondary: {
      main: '#121212',
    },
    background: {
      default: '#ffffff',
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        margin: 'normal',
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'normal',
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'secondary',
      },
    },
  },
});

export default responsiveFontSizes(theme);
