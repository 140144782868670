import { Grid, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Image from 'components/Image';
import { ItemImageEntryFragment } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imgWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.text.secondary,
      minWidth: '160px',
      minHeight: '160px',
      position: 'relative',
    },
    img: {
      maxWidth: '160px',
      height: 'auto',
    },
  }),
);

const Item = ({ publicUrl }: ItemImageEntryFragment) => {
  const classes = useStyles();
  return (
    <div className={classes.imgWrap}>
      <Image src={publicUrl} height={160} width={160} />
    </div>
  );
};

type Props = {
  images: ItemImageEntryFragment[];
  emptyMessage?: string;
};

const ImagesList: FunctionComponent<Props> = ({
  images,
  emptyMessage = 'None',
}) => (
  <Grid container spacing={4}>
    {images.length ? (
      images.map((image) => (
        <Grid key={`item-${image.id}`} item xs={2}>
          <Item {...image} />
        </Grid>
      ))
    ) : (
      <Grid item>
        <Typography>{emptyMessage}</Typography>
      </Grid>
    )}
  </Grid>
);

export default ImagesList;
