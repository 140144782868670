import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React from 'react';

type Props = {
  label?: string;
  defaultValue?: string | null;
  getOptionLabel: (option: any) => string;
  value: any;
  onChangeValue?: (value: any) => void;
  options: any[];
  width?: number;
};
export default ({
  label = '',
  options,
  getOptionLabel,
  value,
  onChangeValue,
  width = 150,
}: Props) => {
  // @ts-ignore
  const onChange = (_, newValue) => onChangeValue(newValue);

  return (
    <Autocomplete
      options={options}
      value={value}
      onChange={onChange}
      size="small"
      disableClearable
      getOptionLabel={getOptionLabel}
      style={{ width }}
      renderInput={(params) => {
        const inputProps = {
          ...params.inputProps,
          style: {
            padding: 3,
          },
        };
        return (
          <TextField
            {...params}
            inputProps={inputProps}
            label={label}
            variant="outlined"
            FormHelperTextProps={{}}
          />
        );
      }}
    />
  );
};
