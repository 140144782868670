import ClearIcon from '@mui/icons-material/Clear';
import { IconButton } from '@mui/material';
import { useClearPickupQueueItemMutation } from 'graphql/graphql-types';
import React, { FunctionComponent, useCallback } from 'react';
import { logError } from 'utils/logging';

type Props = {
  id: number;
  disabled: boolean;
};

const ClearPickupItem: FunctionComponent<Props> = ({ id, disabled }) => {
  const [clearItem] = useClearPickupQueueItemMutation();

  const onClick = useCallback(async () => {
    if (id && !disabled) {
      try {
        await clearItem({
          variables: {
            id,
          },
          // invalidate field from cache or else it will still be displayed on dashboard
          update(cache) {
            cache.modify({
              id: cache.identify({
                __typename: 'PickupQueue',
                id,
              }),
              fields: {
                userByAssignedUserId: () => null,
              },
            });
          },
        });
      } catch (e) {
        logError(e);
      }
    }
  }, [id, disabled]);

  return (
    <IconButton
      size="small"
      sx={{ marginRight: 1 }}
      onClick={onClick}
      disabled={disabled}
    >
      <ClearIcon fontSize="small" />
    </IconButton>
  );
};

export default ClearPickupItem;
