import { SortTypes } from '@digitus-data/material-ui-table-apollo';
import NeutralChip from 'components/Chips/NeutralChip';
import PaymentStatusChip from 'components/PaymentStatusChip';
import Table from 'components/Table';
import {
  AllInvoiceEntryFragment,
  AllInvoicesDocument,
  useAllAuctionsQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { formatCentsToDollars } from 'utils/money';

type Row = {
  row: AllInvoiceEntryFragment;
};
type Props = {
  customerId: number;
};
const CustomerInvoiceTable: FunctionComponent<Props> = ({ customerId }) => {
  const history = useHistory();
  const condition = {
    bidderId: {
      equalTo: customerId,
    },
  };

  return (
    <Table
      defaultSort={SortTypes.DESC}
      defaultOrder="lastPickupDate"
      onRowClick={({ id }: Row['row']) => history.push(`/invoices/${id}`)}
      condition={condition}
      query={AllInvoicesDocument}
      columns={[
        { label: 'UID', name: 'uid', width: '140px' },
        {
          type: 'autocomplete',
          label: 'Auction',
          name: 'auctionByAuctionId.name',
          makeFilterQuery: (value) => ({
            auctionByAuctionId: {
              name: {
                includesInsensitive: value,
              },
            },
          }),
          useQuery: useAllAuctionsQuery,
          getOptionLabel: (item) => item.name,
          mapValueToFilter: (value) => ({
            name: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllAuctionsQuery>) =>
            result.data?.allAuctions?.nodes ?? [],
        },
        {
          label: 'Items',
          name: 'total',
          type: 'integer',
          disableFilter: true,
          render: ({ row: { itemsCountActive } }: Row) => itemsCountActive,
        },
        {
          label: 'Total',
          name: 'total',
          type: 'integer',
          disableFilter: true,
          render: ({ row }: Row) => formatCentsToDollars(row.total),
        },
        {
          label: 'Balance',
          name: 'amountDue',
          type: 'integer',
          disableFilter: true,
          render: ({ row }: Row) => formatCentsToDollars(row.amountDue),
        },
        {
          label: 'Payment',
          name: 'isPaid',
          type: 'boolean',
          render: ({ row }: Row) => (
            <PaymentStatusChip type={row.isPaid ? 'paid' : 'unpaid'} />
          ),
        },
        {
          label: 'Completed',
          name: 'isCompleted',
          type: 'boolean',
        },
        {
          label: 'Returns',
          name: 'countTotalReturns',
          type: 'integer',
          render: ({ row }: Row) =>
            row?.countTotalReturns !== undefined &&
            row?.countTotalReturns !== null &&
            row?.countTotalReturns > 0 ? (
              <NeutralChip text={String(row?.countTotalReturns)} />
            ) : null,
        },
        {
          label: 'Last Pickup Date',
          name: 'lastPickupDate',
          type: 'date',
          formatDateValue: 'MM/DD/YYYY',
          width: 220,
        },
      ]}
    />
  );
};

export default CustomerInvoiceTable;
