import { useShippingQueueByLocationLazyQuery } from 'graphql/graphql-types';
import React from 'react';

export const useShippingQueueByLocation = () => {
  const [isRunning, setIsRunning] = React.useState(false);
  const [fetch, { data, loading, error, refetch, stopPolling }] =
    useShippingQueueByLocationLazyQuery({
      pollInterval: 2 * 1000,
    });
  const handleStart = (locationId: number) => {
    setIsRunning(true);
    fetch({
      variables: {
        locationId,
      },
    });
  };
  const handleStop = () => {
    setIsRunning(false);
    stopPolling();
  };
  const assigned = data?.shippingAssignedItems?.nodes ?? [];
  const unassigned = data?.shippingUnassignedItems?.nodes ?? [];

  return {
    fetch,
    handleStart,
    isRunning,
    handleStop,
    loading,
    error,
    refetch,
    assigned,
    unassigned,
  };
};
