import { Box } from '@mui/material';
import AppInitializing from 'components/App/AppInitializing';
import AppWrapper from 'components/App/AppWrapper';
import { useAuth } from 'hooks/auth';
import { useUpdateTitle } from 'hooks/general';
import React, { FunctionComponent } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { doesUserHaveAccess } from 'utils/auth';
import { useCurrentUserQuery } from 'graphql/graphql-types';
import { RouteProps as RouteProps2 } from './routes';

type Props = {
  title: string;
  appWrapper?: 'simple' | 'full';
} & Pick<RouteProps2, 'accessRoles'> &
  RouteProps;
const PrivateRoute: FunctionComponent<Props> = ({
  title,
  appWrapper,
  accessRoles,
  children,
  ...rest
}) => {
  const { isAuthorized, isInitialized } = useAuth();
  const { data: user } = useCurrentUserQuery({
    // we have to query once the user has been authenticated and initialized
    skip: !isAuthorized || !isInitialized,
  });
  const userRole = user?.currentUser?.role;

  useUpdateTitle(title);

  return (
    <Route
      {...rest}
      render={() => {
        if (!isInitialized) {
          return <AppInitializing />;
        }

        if (!isAuthorized) {
          return <Redirect to="/signIn" />;
        }

        if (!userRole) {
          return <AppInitializing />;
        }

        if (!doesUserHaveAccess(userRole, accessRoles)) {
          return <Redirect to="/404" />;
        }

        if (appWrapper === 'simple') {
          return <Box>{children}</Box>;
        }

        return <AppWrapper>{children}</AppWrapper>;
      }}
    />
  );
};

export default PrivateRoute;
