import { Box } from '@mui/material';
import CustomerNote from 'components/CustomerNote';
import Modal from 'components/Modal';
import ModalActions from 'components/ModalActions';
import ModalLoading from 'components/ModalLoading';
import {
  CustomerNoteEntryFragment,
  useAllCustomerActionsLazyQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent, useEffect, useState } from 'react';

type Props = {
  isOpen: boolean;
  customerId: string | number | null | undefined;
  onOk: () => void;
};
const CustomerActionsModal: FunctionComponent<Props> = ({
  isOpen,
  customerId,
  onOk,
}) => {
  const [ready, setReady] = useState(false);
  const [get, { data }] = useAllCustomerActionsLazyQuery();

  useEffect(() => {
    if (customerId && isOpen) {
      get({
        variables: {
          filter: {
            customerId: {
              equalTo: Number(customerId),
            },
          },
        },
      });
    }
  }, [customerId, isOpen]);

  useEffect(() => {
    if (data?.allCustomerActionsViews?.nodes) {
      setReady(true);
    }
  });

  const notes = data?.allCustomerActionsViews?.nodes ?? [];

  return (
    <Modal
      open={isOpen}
      title="Customer actions"
      handleClose={onOk}
      maxWidth="md"
    >
      <Box paddingTop={2}>
        {!ready && <ModalLoading />}
        {ready && (
          <>
            {notes.map((note) => (
              <CustomerNote
                key={String(note.id)}
                note={note as CustomerNoteEntryFragment}
              />
            ))}
            <ModalActions
              buttons={[
                {
                  text: 'Ok',
                  onClick: onOk,
                },
              ]}
            />
          </>
        )}
      </Box>
    </Modal>
  );
};

export default CustomerActionsModal;
