import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import * as Yup from 'yup';
import { makeValidate, Radios } from 'components/Forms';
import EditorField from 'components/Forms/EditorField';
import ConfirmDelete from 'components/Alerts/ConfirmAlert';

const schema = Yup.object().shape({
  reason: Yup.string().required(),
});

const validate = makeValidate(schema);

const INVOICE_ONLY = 'invoiceOnly';
const INVOICE_AND_AUCTION = 'invoiceAndAuction';

export default function AskDeleteItemModal({
  onAgree,
  isOpen,
  onDiscard,
}: any) {
  const handleSave = useCallback(() => {
    document
      .getElementById('collect-payment-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }, []);

  return (
    <>
      <ConfirmDelete
        title="Do you want to delete this item?"
        isOpen={isOpen}
        onAgree={handleSave}
        onDiscard={onDiscard}
        content={
          <Form
            onSubmit={onAgree}
            // @ts-ignore
            initialValues={{ deleteType: INVOICE_ONLY }}
            validate={validate}
            render={({ handleSubmit }) => (
              <form id="collect-payment-form" onSubmit={handleSubmit}>
                <Radios
                  label="Delete from:"
                  name="deleteType"
                  required
                  data={[
                    { label: 'Invoice only', value: INVOICE_ONLY },
                    {
                      label: 'Invoice and auction',
                      value: INVOICE_AND_AUCTION,
                    },
                  ]}
                />
                <EditorField
                  required
                  label="Reason"
                  id="reason"
                  name="reason"
                  config={{
                    view: {
                      menu: true,
                      md: true,
                      html: false,
                    },
                    canView: {
                      fullScreen: false,
                      hideMenu: false,
                    },
                  }}
                  plugins={[
                    'header',
                    'font-bold',
                    'font-italic',
                    'font-underline',
                    'font-strikethrough',
                    'list-unordered',
                    'list-ordered',
                    'table',
                    'clear',
                    'logger',
                  ]}
                />
              </form>
            )}
          />
        }
      />
    </>
  );
}
