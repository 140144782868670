import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import TextField from 'components/Forms/TextField';
import {
  AllInvoiceItemsDocument,
  useMarkInvoicePaidMutation,
} from 'graphql/graphql-types';
import { makeValidate } from 'components/Forms/Validation';
import React, { FunctionComponent, useCallback } from 'react';
import { Form } from 'react-final-form';
import { logError } from 'utils/logging';
import type { Asserts } from 'yup';
import * as Yup from 'yup';
import { startSnackbar, stopSnackbar } from './SnackBar';

const Schema = Yup.object({
  note: Yup.string().required('Required'),
});
type CreateSchema = Asserts<typeof Schema>;
const validate = makeValidate(Schema);

type Props = {
  invoiceId: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};
const InvoiceMarkPaidModal: FunctionComponent<Props> = ({
  invoiceId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [commit] = useMarkInvoicePaidMutation();

  const onSubmit = useCallback(
    async (values: CreateSchema) => {
      const snackId = startSnackbar('Marking invoice as paid');
      try {
        await commit({
          variables: {
            invoiceId,
            note: values.note,
          },
          refetchQueries: [
            {
              query: AllInvoiceItemsDocument,
              variables: {
                filter: {
                  invoiceId: {
                    equalTo: invoiceId,
                  },
                  and: [
                    {
                      not: {
                        isDeleted: {
                          equalTo: true,
                        },
                      },
                    },
                  ],
                },
              },
            },
          ],
        });
        stopSnackbar('Marked invoice as paid', snackId);
        onSuccess();
      } catch (e) {
        logError(e);
        stopSnackbar(`Failed to mark invoice as paid: ${e.message}`, snackId);
      }
    },
    [invoiceId],
  );

  return (
    <Dialog fullScreen={false} fullWidth maxWidth="xs" open={isOpen}>
      <DialogTitle>Mark Invoice as Paid</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={{
            note: '',
          }}
          render={({ handleSubmit }) => (
            <form>
              <Typography>
                This will mark the invoice and items as paid.
              </Typography>
              <TextField
                required
                label="Note"
                id="note"
                name="note"
                variant="outlined"
              />
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  color="primary"
                  autoFocus
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default InvoiceMarkPaidModal;
