import Typography, { TypographyProps } from '@mui/material/Typography';
import React from 'react';

type Props = {
  error: string | null;
} & TypographyProps;

const FormError = ({ error, ...props }: Props) =>
  error ? (
    <Typography gutterBottom align="center" color="error" {...props}>
      {error}
    </Typography>
  ) : null;

export default FormError;
