import { Props as TableProps } from '@digitus-data/material-ui-table-apollo';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ContentHeader from 'components/ContentHeader';
import Table from 'components/Table';
import TableEmptyComponent from 'components/TableEmptyComponent';
import React from 'react';
import StyledTableSortLabel from './StyledTableSortLabel';

const useStlyes = makeStyles((theme) => ({
  empty: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  contentWrap: {
    // boxShadow: 'rgb(217 220 221 / 32%) 0px 8px 16px 6px',
    // borderRadius: '4px',
  },
  contentHeader: {},
}));

interface Props extends TableProps {
  title: string;
  headerExtra?: React.ReactNode;
  children?: React.ReactNode;
}
export default ({ title, children, headerExtra, ...tableProps }: Props) => {
  const classes = useStlyes();
  return (
    <Box>
      <Box className={classes.contentHeader}>
        <ContentHeader
          title={title}
          divider={false}
          size="large"
          extra={headerExtra}
        />
      </Box>
      <Box className={classes.contentWrap}>
        <Table
          disableHeaderLoader
          tableSortLabelComponent={StyledTableSortLabel}
          renderEmptyComponent={() => (
            <TableEmptyComponent colSpan={tableProps.columns.length} />
          )}
          {...tableProps}
        />
      </Box>
      {children && <Box>{children}</Box>}
    </Box>
  );
};
