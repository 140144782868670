import { Annotation, Chart, Coordinate, Interval, Tooltip } from 'bizcharts';
import {
  CustomChartTooltip,
  CustomChartTooltipList,
  CustomChartTooltipListItem,
  CustomChartTooltipTitle,
} from 'components/CustomChartTooltip';
import GraphTitle from 'components/GraphTitle';
import React, { FunctionComponent } from 'react';
import { formatPercent } from 'utils/math';

const cols = {
  percent: {
    type: 'linear-strict',
    formatter: (value: number) => formatPercent(value),
  },
};

type Data = {
  user: string;
  percent: number;
  totalPickedUp: number;
  totalReturns: number;
};

type Props = {
  averageRate: number;
  data: Data[];
};
const ReturnReportEmployeeReturnRateChart: FunctionComponent<Props> = ({
  averageRate,
  data,
}) => (
  <>
    <GraphTitle title="User Return Rate" />
    <Chart
      height={400}
      data={data}
      scale={cols}
      autoFit
      padding="auto"
      interactions={['active-region']}
    >
      <Coordinate transpose />
      <Interval
        position="user*percent"
        adjust="dodge"
        label={[
          '*',
          {
            content: (d) => formatPercent(d.percent),
          },
        ]}
      />
      <Annotation.Line
        start={['start', averageRate]}
        end={['end', averageRate]}
        offsetX={0}
        offsetY={0}
        text={{
          position: 'start',
          autoRotate: false,
          content: `${formatPercent(averageRate)}`,
          offsetX: 0,
          offsetY: 20,
          rotate: Math.PI * -5,
          style: {
            fill: 'red',
          },
        }}
        style={{
          lineDash: [4, 4],
          stroke: 'red',
        }}
      />
      <Tooltip shared showContent showCrosshairs>
        {(title, items) => {
          const item = items?.[0];
          const returnRate = Number(item?.data?.percent ?? 0);
          const totalPickedUp = item?.data?.totalPickedUp ?? '';
          const totalReturns = item?.data?.totalReturns ?? '';
          return (
            <CustomChartTooltip>
              <CustomChartTooltipTitle title={title ?? ''} />
              <CustomChartTooltipList>
                <CustomChartTooltipListItem
                  name="Rate (returns / picked up)"
                  value={formatPercent(returnRate)}
                />
                <CustomChartTooltipListItem
                  name="Returns"
                  value={totalReturns}
                />
                <CustomChartTooltipListItem
                  name="Picked Up"
                  value={totalPickedUp}
                />
              </CustomChartTooltipList>
            </CustomChartTooltip>
          );
        }}
      </Tooltip>
    </Chart>
  </>
);

export default ReturnReportEmployeeReturnRateChart;
