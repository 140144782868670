import OkIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CircleLoading from 'components/CircleLoading';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles((theme) => ({
  loaderSpinner: {
    paddingRight: theme.spacing(1),
    paddingTop: '3px',
    paddingLeft: '4px',
  },
  loaderMessage: {},
}));

type Props = {
  showIcon?: boolean;
  ok?: boolean;
  loading: boolean;
  message: string;
};
const LoadingSnackbar: FunctionComponent<Props> = ({
  showIcon = true,
  ok = true,
  message,
  loading,
}) => {
  const classes = useStyles();
  return (
    <Box display="flex">
      <Box className={classes.loaderSpinner}>
        {loading && <CircleLoading size={16} />}
        {!loading && showIcon && ok && <OkIcon style={{ fontSize: 16 }} />}
        {!loading && showIcon && !ok && <ErrorIcon style={{ fontSize: 16 }} />}
      </Box>
      <Box flexGrow={1} className={classes.loaderMessage}>
        <Typography>{message}</Typography>
      </Box>
    </Box>
  );
};

export default LoadingSnackbar;
