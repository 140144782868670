import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TableEmptyComponent from 'components/TableEmptyComponent';
import React, { FunctionComponent } from 'react';

const generateStyle = (col: Column) => {
  const style: any = {};
  if (col?.borderRight) {
    style.borderRight = '1px solid rgba(224, 224, 224, 1)';
  }
  if (col?.width) {
    style.width = col.width;
  }
  return style;
};

type Column = {
  label: string;
  name: string;
  render?: ({ value, row }: any) => any;
  align?: 'right' | 'left' | 'center';
  width?: number | string;
  span?: number;
  borderRight?: boolean;
};

export type Props = {
  columns: Column[];
  topColumns?: Column[];
  rows: any[];
  expandRow?: ({ row }: any) => any;
  emptyMessage?: string;
  onRowClick?: (row: any) => void;
  size?: 'small' | 'medium';
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    // borderBottom: '1px solid #e0e0e0',
    // borderTop: '1px solid #e0e0e0',
    // maxHeight: '80vh',
    overflow: 'auto',
  },
  gridItemTable: {
    // borderRight: '1px solid #e0e0e0',
    flex: '1 1 60%',
    overflow: 'auto',
  },
  tableContainer: {
    height: '50vh',
    width: '100%',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    fontSize: 12,
    textTransform: 'uppercase',
  },
}));

type RowProps = {
  row: any;
} & Omit<Props, 'rows'>;
const Row: FunctionComponent<RowProps> = ({
  row,
  columns,
  onRowClick,
  expandRow,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        className={onRowClick ? classes.pointer : undefined}
        onClick={() => onRowClick?.(row)}
        // sx={{ '& > *': { borderBottom: 'unset' } }}
      >
        {expandRow ? (
          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowDownIcon fontSize="small" />
              ) : (
                <KeyboardArrowRightIcon fontSize="small" />
              )}
            </IconButton>
          </TableCell>
        ) : null}
        {columns.map((column) => {
          let value = row[column.name];
          if (column.render) {
            value = column.render({ value, row });
          }
          return (
            <TableCell
              key={column.name}
              align={column?.align}
              style={generateStyle(column)}
            >
              {value}
            </TableCell>
          );
        })}
      </TableRow>
      {expandRow ? (
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              paddingRight: 0,
              borderBottom: 0,
            }}
            colSpan={6}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box>{expandRow(row)}</Box>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  );
};

const SimpleTable: FunctionComponent<Props> = ({
  columns,
  topColumns = [],
  rows,
  expandRow,
  emptyMessage,
  onRowClick,
  size = 'small',
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.gridContainer}>
      <Box className={classes.gridItemTable}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" size={size}>
            <TableHead>
              {topColumns?.length > 0 &&
                topColumns.map((column) => (
                  <StyledTableCell
                    key={column.name}
                    align={column?.align}
                    style={generateStyle(column)}
                    colSpan={column.span}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              <TableRow>
                {expandRow ? (
                  <StyledTableCell
                    key="expand"
                    align="left"
                    style={{ width: '1%' }}
                    //  style={column.width ? { width: column.width } : {}}
                    //  colSpan={column.span}
                  />
                ) : null}
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.name}
                    align={column?.align}
                    style={generateStyle(column)}
                    colSpan={column.span}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 ? (
                <TableEmptyComponent
                  colSpan={columns.length + (expandRow ? 1 : 0)}
                  text={emptyMessage}
                />
              ) : (
                rows.map((row, i) => (
                  <Row
                    key={String(row?.id ?? i)}
                    columns={columns}
                    row={row}
                    expandRow={expandRow}
                    onRowClick={onRowClick}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default SimpleTable;
