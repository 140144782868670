import {
  PaymentChargeStatusChip,
  PaymentOverallStatusChip,
  QueuedProcessingStatusChip,
} from 'components/Chips';
import NeutralChip from 'components/Chips/NeutralChip';
import ContentHeader from 'components/ContentHeader';
import Hyperlink from 'components/Hyperlink';
import LineItems from 'components/LineItems';
import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import { ViewScreen } from 'components/ViewScreen';
import {
  useGetPaymentQuery,
  useVoidPaymentMutation,
} from 'graphql/graphql-types';
import React, { FunctionComponent, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { logError } from 'utils/logging';
import { formatCentsToDollars } from 'utils/money';
import { formatDateTime } from 'utils/time';
import PaymentTransactionList from './PaymentTransactionList';

const PaymentViewScreen: FunctionComponent = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useGetPaymentQuery({
    variables: {
      id: Number(id),
    },
  });
  const [voidPayment] = useVoidPaymentMutation();

  const paymentId = Number(id);
  const payment = data?.paymentById;
  const status = payment?.status;
  const chargeStatus = payment?.chargeStatus;
  const errorMessage = payment?.chargeStatusErrorMessage ?? '';
  const isCharged = payment?.isCharged;
  const amount = payment?.amount;
  const createdAt = payment?.createdAt;
  const invoiceId = payment?.invoiceByInvoiceId?.id;
  const invoiceUid = payment?.invoiceByInvoiceId?.uid ?? '';
  const customerId = payment?.invoiceByInvoiceId?.bidderByBidderId?.id;
  const customerName =
    payment?.invoiceByInvoiceId?.bidderByBidderId?.name ?? '';
  const cardNumber = payment?.cardNumber ?? '';
  const isVoided = payment?.isVoided;
  const processVoidStatus = payment?.processingVoidStatus;
  const isProcessingVoid =
    processVoidStatus === 'QUEUED' || processVoidStatus === 'PROCESSING';

  const handleVoidPayment = useCallback(async () => {
    if (paymentId) {
      const snackId = startSnackbar('Voiding payment');
      try {
        await voidPayment({
          variables: {
            id: paymentId,
          },
        });
        stopSnackbar('Queued voiding payment', snackId);
      } catch (e) {
        logError(e);
        stopSnackbar(`Failed to void payment: ${e.message}`, snackId);
      }
    }
  }, [paymentId]);

  const openInvoice = useCallback(() => {
    if (invoiceId) {
      history.push(`/invoices/${invoiceId}`);
    }
  }, [invoiceId]);

  const openCustomer = useCallback(() => {
    if (customerId) {
      history.push(`/customers/${customerId}`);
    }
  }, [customerId]);

  return (
    <ViewScreen
      loading={loading}
      error={error}
      header={{
        leftProps: {
          title: `Payment ${id}`,
        },
        centerProps: {
          component: payment ? (
            <PaymentOverallStatusChip {...payment} />
          ) : undefined,
        },
        bottomProps: {
          subtitle: formatCentsToDollars(amount),
          buttons: [
            {
              text: 'Void',
              onClick: handleVoidPayment,
              disabled: !isCharged || isVoided || isProcessingVoid,
            },
          ],
        },
        dividerProps: {
          hide: true,
        },
      }}
    >
      <ContentHeader title="Details" size="large" />
      <LineItems description="Time" value={formatDateTime(createdAt)} />
      <LineItems
        description="Customer"
        value={<Hyperlink text={customerName} onClick={openCustomer} />}
      />
      <LineItems
        description="Invoice"
        value={<Hyperlink text={invoiceUid} onClick={openInvoice} />}
      />
      <LineItems
        description="Card last 4 digits"
        value={<NeutralChip text={cardNumber} />}
      />
      <LineItems
        description="Queue status"
        value={<QueuedProcessingStatusChip status={status} />}
      />
      <LineItems
        description="Charge status"
        value={<PaymentChargeStatusChip status={chargeStatus} />}
      />
      <LineItems description="Charge error message" value={errorMessage} />
      <ContentHeader
        title="Transactions"
        size="large"
        containerProps={{ mt: 4 }}
      />
      <PaymentTransactionList paymentId={Number(id)} />
    </ViewScreen>
  );
};

export default PaymentViewScreen;
