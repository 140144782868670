import {
  ReturnQueueStatus,
  useUpdateReturnQueueStatusMutation,
} from 'graphql/graphql-types';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { getKeyByValue } from 'utils/object';
import Select from 'components/Select';

const options: string[] = [];

Object.entries(ReturnQueueStatus).forEach((value) => {
  options.push(value[0]);
});

type Props = {
  returnQueueId: string;
  defaultValue: ReturnQueueStatus | undefined;
  afterChange?: () => void;
};
const ReturnQueueStatusSelect: FunctionComponent<Props> = ({
  returnQueueId,
  defaultValue,
  afterChange,
}) => {
  const [initalized, setInitialized] = useState(false);
  const [value, setValue] = useState<string>('');
  const [update] = useUpdateReturnQueueStatusMutation();

  useEffect(() => {
    if (!initalized) {
      const newValue = getKeyByValue(ReturnQueueStatus, defaultValue);
      setValue(newValue ?? '');
      setInitialized(true);
    }
  }, [defaultValue, initalized]);

  useEffect(() => {
    const run = async () => {
      if (returnQueueId && value && initalized) {
        await update({
          variables: {
            id: returnQueueId,
            status: ReturnQueueStatus[value as keyof typeof ReturnQueueStatus],
          },
        });
        afterChange?.();
      }
    };
    run();
  }, [returnQueueId, value, initalized]);

  return (
    <Select
      getOptionLabel={(option) => option}
      value={value}
      options={options}
      defaultValue={defaultValue}
      onChangeValue={(v) => {
        setValue(v);
      }}
    />
  );
};

export default ReturnQueueStatusSelect;
