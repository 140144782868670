import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import EditorField from 'components/Forms/EditorField';
import SelectField from 'components/Forms/SelectField';
import TextField from 'components/Forms/TextField';
import { makeValidate } from 'components/Forms/Validation';
import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import { FORM_ERROR } from 'final-form';
import { useCreateInvoiceAdjustmentMutation } from 'graphql/graphql-types';
import React, { FunctionComponent, useCallback } from 'react';
import { Form } from 'react-final-form';
import { logError } from 'utils/logging';
import { makeWholeCents } from 'utils/money';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

export const TYPES = [
  {
    label: 'shipping',
    value: 'shipping',
  },
  {
    label: 'transfer',
    value: 'transfer',
  },
  {
    label: 'other',
    value: 'other',
  },
];

const formId = 'add-adjustment-form';

const Schema = Yup.object({
  description: Yup.string(),
  amount: Yup.number()
    .typeError('Must be a number')
    .positive()
    .required('Required'),
  type: Yup.string().required('Required'),
});

const validate = makeValidate(Schema);

type Props = {
  isOpen: boolean;
  onClose: () => void;
  invoiceId: number;
  refetchInvoice: () => void;
};

const AddAdjustmentModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  invoiceId,
  refetchInvoice,
}) => {
  const [createAdjustment] = useCreateInvoiceAdjustmentMutation();

  const handleAddAdjustment = useCallback(
    async ({ amount, description, type }: Asserts<typeof Schema>) => {
      const snackId = startSnackbar('Adding fee');
      try {
        onClose();
        await createAdjustment({
          variables: {
            values: {
              description,
              amount: makeWholeCents(amount),
              invoiceId,
              type,
            },
          },
        });
        await refetchInvoice();
        stopSnackbar('Fee added', snackId);
        return true;
      } catch (e) {
        logError(e);
        stopSnackbar(`Failed to add fee: ${e.message}`, snackId);
        return { [FORM_ERROR]: `Failed to add fee: ${e.message}` };
      }
    },
    [invoiceId],
  );

  const handleSave = useCallback(() => {
    document
      .getElementById(formId)
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Add fee</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={handleAddAdjustment}
          validate={validate}
          render={({ handleSubmit, ...props }) => (
            <form id={formId} onSubmit={handleSubmit}>
              <TextField
                required
                label="Amount"
                id="amount"
                name="amount"
                parseType="money"
                variant="outlined"
              />
              <SelectField
                required
                label="Type"
                id="type"
                name="type"
                items={TYPES}
                variant="outlined"
              />
              <EditorField
                label="Description"
                id="description"
                name="description"
                config={{
                  view: {
                    menu: true,
                    md: true,
                    html: false,
                  },
                  canView: {
                    fullScreen: false,
                    hideMenu: false,
                  },
                }}
                plugins={[
                  'header',
                  'font-bold',
                  'font-italic',
                  'font-underline',
                  'font-strikethrough',
                  'list-unordered',
                  'list-ordered',
                  'table',
                  'clear',
                  'logger',
                ]}
              />
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  disabled={
                    props.submitting ||
                    props.pristine ||
                    props.hasValidationErrors ||
                    (props.hasSubmitErrors && !props.modifiedSinceLastSubmit)
                  }
                  type="submit"
                  onClick={handleSave}
                  color="primary"
                >
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default AddAdjustmentModal;
