import MarkdownIt from 'markdown-it';
import React, { FunctionComponent } from 'react';
import 'react-markdown-editor-lite/lib/index.css';

const mdParser = new MarkdownIt({
  html: true,
  breaks: true,
  linkify: true,
});

const createMarkup = (html: string) => ({
  __html: html,
});

type Props = {
  text?: string;
};
const Markdown: FunctionComponent<Props> = ({ text }) => {
  if (!text) {
    return null;
  }
  const md = mdParser.render(text);
  // eslint-disable-next-line
  return <div dangerouslySetInnerHTML={createMarkup(md)} />;
};

export default Markdown;
