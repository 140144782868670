import Button from 'components/Button';
import ListScreen from 'components/ListScreen';
import {
  AllPrintersDocument,
  AllPrintersEntryFragment,
  useAllLocationsQuery,
} from 'graphql/graphql-types';
import { useModal } from 'hooks/modal';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { ADMINISTRATOR } from 'utils/auth';
import PrinterForm from './PrinterForm';

type Row = {
  row: AllPrintersEntryFragment;
};
interface TableActionsProps {
  createOnClick: () => void;
}

const TableActions = ({ createOnClick }: TableActionsProps) => (
  <Button
    color="primary"
    text="Create"
    onClick={createOnClick}
    accessRoles={[ADMINISTRATOR]}
  />
);

const PrinterListScreen: FunctionComponent = () => {
  const history = useHistory();
  const { visible, open, close } = useModal();

  return (
    <>
      <ListScreen
        title="Printers"
        query={AllPrintersDocument}
        headerExtra={<TableActions createOnClick={open} />}
        onRowClick={(row: Row['row']) => {
          history.push(`/printers/${row.id}`);
        }}
        columns={[
          {
            label: 'Name',
            name: 'name',
          },
          {
            type: 'autocomplete',
            label: 'Location',
            name: 'locationByLocationId.name',
            makeFilterQuery: (value) => ({
              locationByLocationId: {
                name: {
                  includesInsensitive: value,
                },
              },
            }),
            useQuery: useAllLocationsQuery,
            getOptionLabel: (item) => item.name,
            mapValueToFilter: (value) => ({
              name: { includesInsensitive: value },
            }),
            getOptions: (result: ReturnType<typeof useAllLocationsQuery>) =>
              result.data?.allLocations?.nodes ?? [],
          },
          {
            label: 'IP address',
            name: 'ipAddress',
          },
          {
            label: 'Port',
            name: 'port',
          },
        ]}
      />
      <PrinterForm
        visible={visible}
        type="create"
        handleClose={close}
        handleSuccess={close}
      />
    </>
  );
};

export default PrinterListScreen;
