import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import Autocomplete from 'components/Forms/Autocomplete';
import { makeValidate } from 'components/Forms/Validation';
import { SearchAuctionItemsDocument } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { Form } from 'react-final-form';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const Schema = Yup.object({
  itemId: Yup.number().required(),
});

const validate = makeValidate(Schema);

type Props = {
  auctionId: number;
  isOpen: boolean;
  handleCopyItemImage: (itemId: number) => Promise<void>;
  handleCloseCopyModal: () => void;
};

const CopyItemImageModal: FunctionComponent<Props> = ({
  auctionId,
  isOpen,
  handleCopyItemImage,
  handleCloseCopyModal,
}) => {
  const onSubmit = async (values: Asserts<typeof Schema>) => {
    if (values.itemId) {
      handleCopyItemImage(values.itemId);
    }
  };

  const condition = {
    auctionId,
  };

  return (
    <Dialog
      onClose={handleCloseCopyModal}
      aria-labelledby="simple-dialog-title"
      open={isOpen}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Copy Image to Item</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, ...props }) => (
            <form id="copy-item-image-form" onSubmit={handleSubmit}>
              <Autocomplete
                label="Select item"
                name="itemId"
                required
                size="small"
                query={SearchAuctionItemsDocument}
                condition={condition}
                defaultValue={null}
                getOptionValue={(option: any) => option.id}
                getOptionLabel={(option: any) =>
                  `(${option.number}) ${option.description}`
                }
              />
              <DialogActions>
                <Button onClick={handleCloseCopyModal}>Cancel</Button>
                <Button
                  disabled={
                    props.submitting ||
                    props.pristine ||
                    props.hasValidationErrors ||
                    (props.hasSubmitErrors && !props.modifiedSinceLastSubmit)
                  }
                  type="submit"
                  onClick={handleSubmit}
                  color="primary"
                >
                  Copy
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CopyItemImageModal;
