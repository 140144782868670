import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/WarningAmberOutlined';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import { orange } from '@mui/material/colors';
import React, { FunctionComponent, useState } from 'react';

type Props = {
  text: string;
  body?: JSX.Element;
  alert?: 'warning';
};
const CollapsableAlert: FunctionComponent<Props> = ({ text, body, alert }) => {
  const [expanded, setExpanded] = useState(false);
  const shouldHandleExpand = body !== undefined;

  const handleChange = (event: any, isExpanded: boolean) => {
    if (shouldHandleExpand) {
      setExpanded(isExpanded);
    }
  };

  return (
    <Accordion
      sx={{ backgroundColor: orange[50] }}
      expanded={expanded}
      onChange={handleChange}
      square
    >
      <AccordionSummary
        expandIcon={shouldHandleExpand ? <ExpandMoreIcon /> : undefined}
      >
        {alert ? (
          <Box paddingRight={1}>
            <WarningIcon sx={{ color: orange[500] }} />
          </Box>
        ) : null}
        <Typography>{text}</Typography>
      </AccordionSummary>
      {shouldHandleExpand ? <AccordionDetails>{body}</AccordionDetails> : null}
    </Accordion>
  );
};

export default CollapsableAlert;
