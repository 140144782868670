import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import EditorField from 'components/Forms/EditorField';
import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import {
  GetInvoiceNotesByInvoiceIdDocument,
  useCreateInvoiceNoteMutation,
} from 'graphql/graphql-types';
import { makeValidate } from 'components/Forms/Validation';
import React, { FunctionComponent, useCallback } from 'react';
import { Form } from 'react-final-form';
import { logError } from 'utils/logging';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  note: Yup.string().required(),
});

const validate = makeValidate(schema);

type Props = {
  open: boolean;
  invoiceId: number;
  onClose: () => void;
  onError?: () => void;
  onSuccess?: () => void;
};
const InvoiceNoteAddModal: FunctionComponent<Props> = ({
  open,
  invoiceId,
  onClose,
  onError,
  onSuccess,
}) => {
  const [createInvoiceNote] = useCreateInvoiceNoteMutation();

  const processSubmit = useCallback(
    async ({ note }) => {
      const snackId = startSnackbar('Adding note');
      try {
        await createInvoiceNote({
          variables: {
            values: {
              invoiceId,
              note,
            },
          },
          refetchQueries: [
            {
              query: GetInvoiceNotesByInvoiceIdDocument,
              variables: {
                id: Number(invoiceId),
              },
            },
          ],
        });
        stopSnackbar('Note added', snackId);
        onSuccess?.();
      } catch (e) {
        logError(e);
        stopSnackbar(`Failed to add note: ${e.message}`, snackId);
        onError?.();
      }
    },
    [invoiceId],
  );

  const handleSave = useCallback(() => {
    document
      .getElementById('add-invoice-note-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }, []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="Add Invoice Note"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Add Invoice Note</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={processSubmit}
          validate={validate}
          render={({ handleSubmit }) => (
            <form id="add-invoice-note-form" onSubmit={handleSubmit}>
              <EditorField
                required
                label="Note"
                id="note"
                name="note"
                config={{
                  view: {
                    menu: true,
                    md: true,
                    html: false,
                  },
                  canView: {
                    fullScreen: false,
                    hideMenu: false,
                  },
                }}
                plugins={[
                  'header',
                  'font-bold',
                  'font-italic',
                  'font-underline',
                  'font-strikethrough',
                  'list-unordered',
                  'list-ordered',
                  'table',
                  'clear',
                  'logger',
                ]}
              />
            </form>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} color="primary" autoFocus>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InvoiceNoteAddModal;
