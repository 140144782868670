import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    textTransform: 'uppercase',
    [theme.breakpoints.up('xl')]: {
      fontSize: 30,
      padding: 40,
    },
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    [theme.breakpoints.up('xl')]: {
      fontSize: 30,
      padding: 40,
    },
  },
}));

const EmptyText = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  [theme.breakpoints.up('xl')]: {
    fontSize: 30,
  },
}));

type Column = {
  label: string;
  name: string;
  render?: ({ value, row }: any) => any;
  align?: 'right' | 'left' | 'center';
  width?: number | string;
  span?: number;
};

export type Props = {
  columns: Column[];
  rows: any[];
  expandRow?: ({ row }: any) => any;
  emptyMessage: string;
  onRowClick?: (row: any) => void;
  size?: 'small' | 'medium';
};

const useStyles = makeStyles(() => ({
  gridContainer: {
    overflow: 'auto',
  },
  gridItemTable: {
    flex: '1 1 60%',
    overflow: 'auto',
  },
  tableContainer: {
    height: '50vh',
    width: '100%',
  },
  pointer: {
    cursor: 'pointer',
  },
}));

type RowProps = {
  row: any;
} & Omit<Props, 'rows'>;
const Row: FunctionComponent<RowProps> = ({ row, columns, onRowClick }) => {
  const classes = useStyles();

  return (
    <TableRow
      hover
      tabIndex={-1}
      className={onRowClick ? classes.pointer : undefined}
      onClick={() => onRowClick?.(row)}
    >
      {columns.map((column) => {
        let value = row[column.name];
        if (column.render) {
          value = column.render({ value, row });
        }
        return (
          <StyledTableCell key={column.name} align={column?.align}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              flexGrow={1}
            >
              {value}
            </Box>
          </StyledTableCell>
        );
      })}
    </TableRow>
  );
};

const SimpleTable: FunctionComponent<Props> = ({
  columns,
  rows,
  expandRow,
  onRowClick,
  size = 'small',
  emptyMessage,
}) => {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.gridContainer}>
      <Box className={classes.gridItemTable}>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table" size={size}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.name}
                    align={column?.align}
                    style={column.width ? { width: column.width } : {}}
                    colSpan={column.span}
                  >
                    {column.label}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 ? (
                <TableRow>
                  <TableCell align="center" colSpan={3}>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      flexGrow={1}
                    >
                      <EmptyText>{emptyMessage}</EmptyText>
                    </Box>
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((row, i) => (
                  <Row
                    key={String(row?.id ?? i)}
                    columns={columns}
                    row={row}
                    expandRow={expandRow}
                    onRowClick={onRowClick}
                    emptyMessage={emptyMessage}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default SimpleTable;
