import Icon from '@mui/icons-material/Clear';
import { Chip, Tooltip } from '@mui/material';
import { red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  status: {
    backgroundColor: red[50],
    borderRadius: 5,
    color: red[900],
    fontWeight: 500,
  },
}));

type Props = {
  label?: string;
  icon?: boolean;
  toolTipMessage?: string;
};
const FailChip: FunctionComponent<Props> = ({
  label = 'Failed',
  icon = false,
  toolTipMessage,
}) => {
  const classes = useStyles();

  if (toolTipMessage) {
    return (
      <Tooltip title={toolTipMessage} arrow>
        <Chip
          size="small"
          label={label}
          className={classes.status}
          icon={icon ? <Icon /> : undefined}
        />
      </Tooltip>
    );
  }

  return (
    <Chip
      size="small"
      label={label}
      className={classes.status}
      icon={icon ? <Icon /> : undefined}
    />
  );
};

export default FailChip;
