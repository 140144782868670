import { Typography } from '@mui/material';
import LinearWithValueLabel from 'components/LinearProgress';
import LineItems from 'components/LineItems';
import React, { FunctionComponent } from 'react';

type Props = {
  description: string;
  text: string;
  progress: number;
};
const LineItemProgress: FunctionComponent<Props> = ({
  description,
  text,
  progress,
}) => (
  <LineItems
    description={description}
    value={
      <>
        <Typography>{text}</Typography>
        <LinearWithValueLabel progress={progress} />
      </>
    }
    stacked
  />
);

export default LineItemProgress;
