import ListIcon from '@mui/icons-material/List';
import { Box, IconButton } from '@mui/material';
import CustomerActionsModal from 'components/CustomerActionsModal';
import CustomerAlertsModal from 'components/CustomerAlertsModal';
import PaymentStatusChip from 'components/PaymentStatusChip';
import Table from 'components/Table';
import {
  AllAuctionInvoicesDocument,
  AllAuctionInvoicesEntryFragment,
  useAllBiddersQuery,
} from 'graphql/graphql-types';
import { useSelectedValueModal } from 'hooks/modal';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { formatCentsToDollars } from 'utils/money';

type Row = {
  // eslint-disable-next-line react/no-unused-prop-types
  row: AllAuctionInvoicesEntryFragment;
};

type Props = {
  auctionId: number;
};

const AuctionInvoicesTable: FunctionComponent<Props> = ({ auctionId }) => {
  const history = useHistory();
  const {
    selectedValue: selectedAction,
    open: openActions,
    close: closeActions,
  } = useSelectedValueModal<number>();
  const {
    selectedValue: selectedAlert,
    open: openAlerts,
    close: closeAlerts,
  } = useSelectedValueModal<number>();
  const condition = {
    auctionId: {
      equalTo: auctionId,
    },
  };

  return (
    <Box>
      <Table
        query={AllAuctionInvoicesDocument}
        condition={condition}
        // defaultOrder="invoiceUid"
        // defaultSort={SortTypes.ASC}
        onRowClick={({ invoiceId }: Row['row']) =>
          history.push(`/invoices/${invoiceId}`)
        }
        rowsPerPage={100}
        rowsPerPageOptions={[
          { label: '100', value: 100 },
          { label: '300', value: 300 },
          { label: '500', value: 500 },
          { label: '750', value: 750 },
        ]}
        columns={[
          {
            label: 'Id',
            name: 'invoiceUid',
            render: ({ row }: Row) => row?.invoiceUid,
          },
          {
            type: 'autocomplete',
            label: 'Customer',
            name: 'customerName',
            render: ({ row }: Row) => row?.customerName,
            makeFilterQuery: (value) => ({
              customerName: {
                includesInsensitive: value,
              },
            }),
            useQuery: useAllBiddersQuery,
            getOptionLabel: (item) => item.name,
            mapValueToFilter: (value) => ({
              name: { includesInsensitive: value },
            }),
            getOptions: (result: ReturnType<typeof useAllBiddersQuery>) =>
              result.data?.allBidders?.nodes ?? [],
          },
          {
            disableFilter: true,
            label: 'Actions',
            name: 'customerActiveActionNotesCount',
            render: ({ row }: Row) => {
              const customerId = row?.customerId;
              return row?.customerActiveActionNotesCount && customerId ? (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    openActions(customerId);
                  }}
                >
                  <ListIcon />
                </IconButton>
              ) : null;
            },
          },
          {
            disableFilter: true,
            label: 'Alerts',
            name: 'customerActiveAlertNotesCount',
            render: ({ row }: Row) => {
              const customerId = row?.customerId;
              return row?.customerActiveAlertNotesCount && customerId ? (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    openAlerts(customerId);
                  }}
                >
                  <ListIcon />
                </IconButton>
              ) : null;
            },
          },
          {
            label: 'Items',
            name: 'itemCount',
            type: 'integer',
            render: ({ row }: Row) => row?.itemCount,
          },
          {
            label: 'Total',
            name: 'total',
            type: 'integer',
            render: ({ row }: Row) => formatCentsToDollars(row.total),
          },
          {
            label: 'Balance',
            name: 'amountDue',
            render: ({ row }: Row) => (
              <Box>
                <Box display="inline" marginRight={1}>
                  {formatCentsToDollars(row.amountDue)}
                </Box>
                <PaymentStatusChip
                  type={row.amountDue > 0 ? 'unpaid' : 'paid'}
                />
              </Box>
            ),
          },
        ]}
      />
      <CustomerActionsModal
        isOpen={!!selectedAction}
        customerId={selectedAction}
        onOk={closeActions}
      />
      <CustomerAlertsModal
        isOpen={!!selectedAlert}
        customerId={selectedAlert}
        onOk={closeAlerts}
      />
    </Box>
  );
};

export default AuctionInvoicesTable;
