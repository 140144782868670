import { Alert, Box } from '@mui/material';
import Button from 'components/Button';
import TextField from 'components/Forms/TextField';
import { makeValidate } from 'components/Forms/Validation';
import FormWrapper from 'components/FormWrapper';
import { generalNotification } from 'components/SnackBar';
import { FORM_ERROR } from 'final-form';
import { useChangeUserPasswordMutation } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { Form } from 'react-final-form';
import { getCodeFromError } from 'utils/graphql';
import { logError } from 'utils/logging';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const Schema = Yup.object({
  oldPassword: Yup.string().required('Enter your current password'),
  newPassword: Yup.string()
    .min(8, 'Must be at least 8 characters')
    .required('Enter your new password'),
  newPasswordConfirm: Yup.string()
    .oneOf(
      [Yup.ref('newPassword'), null],
      'Password confirmation must match new password',
    )
    .required('Required'),
});
type DataSchema = Asserts<typeof Schema>;
const validate = makeValidate(Schema);

const UserPasswordForm: FunctionComponent = () => {
  const [commit] = useChangeUserPasswordMutation();

  const onSubmit = async (data: DataSchema, form: any) => {
    try {
      await commit({
        variables: {
          input: {
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
          },
        },
      });
      setTimeout(() => form.restart(), 20);
      generalNotification('Updated password');
    } catch (e: any) {
      const errcode = getCodeFromError(e);
      if (errcode === 'WEAKP') {
        return {
          newPassword: 'Password is too weak',
        };
      }
      if (errcode === 'CREDS') {
        return {
          oldPassword: 'Incorrect password',
        };
      }
      logError(e);
      return { [FORM_ERROR]: 'Something went wrong' };
    }
    return {};
  };

  return (
    <FormWrapper ready>
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={(props) => (
          <form>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'wrap',
                  flexGrow: 1,
                }}
              >
                <Box sx={{ margin: '0 0 1rem' }}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Current password"
                    name="oldPassword"
                    autoComplete="currentPassword"
                    size="small"
                    style={{ margin: '0', minWidth: '20rem' }}
                  />
                </Box>
                <Box sx={{ margin: '0 0 1rem' }}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="New password"
                    name="newPassword"
                    size="small"
                    style={{ margin: '0', minWidth: '20rem' }}
                  />
                </Box>
                <Box sx={{ margin: '0 0 1rem' }}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Confirm new password"
                    name="newPasswordConfirm"
                    size="small"
                    style={{ margin: '0', minWidth: '20rem' }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexGrow: 1,
                  marginLeft: '1rem',
                  // marginTop: '0.5rem',
                }}
              >
                <Button
                  text="Set New Password"
                  type="submit"
                  disabled={
                    props.submitting ||
                    props.pristine ||
                    props.hasValidationErrors ||
                    (props.hasSubmitErrors && !props.modifiedSinceLastSubmit)
                  }
                  fullWidth
                  onClick={props.handleSubmit}
                  style={{ height: '40px' }}
                />
              </Box>
            </Box>
            {props.submitError && (
              <Alert severity="error">{props.submitError}</Alert>
            )}
          </form>
        )}
      />
    </FormWrapper>
  );
};

export default UserPasswordForm;
