import { Box, Card, Stack, Typography } from '@mui/material';
import LocationSelect, {
  Option as LocationOption,
} from 'components/LocationSelect';
import { SimpleTable } from 'components/Table';
import { ViewScreen } from 'components/ViewScreen';
import {
  ShippingQueueItemEntryFragment,
  ShippingQueueStatus,
} from 'graphql/graphql-types';
import { useShippingQueueByLocation } from 'hooks/shippingQueue';
import React, { useEffect, useState } from 'react';
import { displayElapsedTimeFromTimestamp } from 'utils/time';
import ItemExpanded from './components/ItemExpanded';

const columns = [
  {
    label: 'Customer',
    name: 'customer',
    render: ({ row }: Row) => row?.customerName ?? '',
    width: '30%',
  },
  {
    label: 'Assigned',
    name: 'assigned',
    render: ({ row }: Row) => row?.assignedName ?? '',
    width: '25%',
  },
  {
    label: 'Elapsed',
    name: 'elapsed',
    render: ({ row }: Row) => displayElapsedTimeFromTimestamp(row.inQueueAt),
    width: '20%',
  },
];

type Row = {
  row: ShippingQueueItemEntryFragment;
};

const Comp: React.FC = () => {
  const { handleStart, assigned, unassigned } = useShippingQueueByLocation();
  const [location, setLocation] = useState<LocationOption | null>(null);
  useEffect(() => {
    const id = location?.id;
    if (id) {
      handleStart(id);
    }
  }, [location]);
  const onLocationChange = (option: LocationOption) => setLocation(option);
  const locationId = location?.id;

  return (
    <ViewScreen
      loading={false}
      header={{
        leftProps: {
          title: 'Shipping Queue',
        },
        bottomProps: {
          component: (
            <Stack direction="row" spacing={1}>
              <LocationSelect onChange={onLocationChange} />
            </Stack>
          ),
        },
        dividerProps: {
          hide: true,
        },
      }}
    >
      <Box>
        <Box style={{ marginBottom: '2rem' }}>
          <Card variant="outlined" style={{ marginBottom: '1rem' }}>
            <Typography style={{ padding: '1rem 1rem 1rem' }}>
              Unassigned
            </Typography>
            <SimpleTable
              columns={columns}
              rows={unassigned}
              emptyMessage="Unassigned queue is empty"
              expandRow={(data: Row['row']) => (
                <ItemExpanded
                  locationId={locationId}
                  row={data}
                  isAssigned={false}
                  status={{
                    equalTo: ShippingQueueStatus.Waiting,
                  }}
                />
              )}
            />
            <Box
              style={{
                backgroundColor: '#f7fafc',
                borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                paddingTop: '1rem',
              }}
            />
            <Typography style={{ padding: '1rem 1rem 1rem' }}>
              Assigned
            </Typography>
            <SimpleTable
              columns={columns}
              rows={assigned}
              emptyMessage="Assigned queue is empty"
              expandRow={(data: Row['row']) => (
                <ItemExpanded
                  locationId={locationId}
                  row={data}
                  isAssigned={Boolean(true)}
                  status={{
                    equalTo: ShippingQueueStatus.WorkingOn,
                  }}
                />
              )}
            />
          </Card>
        </Box>
      </Box>
    </ViewScreen>
  );
};

export default Comp;
