import { ColumnChart } from 'bizcharts';
import GraphTitle from 'components/GraphTitle';
import React, { FunctionComponent } from 'react';
import { formatPercent } from 'utils/math';

type Data = {
  date: string;
  percent: number;
};

type Props = {
  data: Data[];
};
const CompanyReturnRateChart: FunctionComponent<Props> = ({ data }) => (
  <>
    <GraphTitle title="Company Return Rate" />
    <ColumnChart
      height={400}
      data={data}
      meta={{
        percent: {
          alias: 'Percent',
          type: 'linear-strict',
          formatter: (value: number) => formatPercent(value),
        },
      }}
      autoFit
      title={{
        visible: false,
      }}
      xField="date"
      xAxis={{
        label: {
          rotate: 0.5,
          autoRotate: true,
          style: { textAlign: 'start' },
        },
      }}
      yField="percent"
      padding="auto"
      label={{
        visible: true,
        content: (d) => formatPercent(d.percent),
      }}
    />
  </>
);

export default CompanyReturnRateChart;
