import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Box, Stack } from '@mui/material';
import Button from 'components/Button';
import ReportPeriodSelect, { Option } from 'components/ReportPeriodSelect';
import SimpleReportView from 'components/SimpleReportView';
import { errorNotification, generalNotification } from 'components/SnackBar';
import { ViewScreen } from 'components/ViewScreen';
import {
  useDownloadReturnReportMutation,
  useRunReturnsReportLazyQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { logError } from 'utils/logging';
import CompanyReturnRateChart from './components/CompanyReturnRateChart';
import ItemsSoldChart from './components/ItemsSoldChart';
import ReturnReportEmployeeReturnRateChart from './components/ReturnReportEmployeeReturnRateChart';
import ReturnReportPieChart from './components/ReturnReportPieChart';

const ReturnReport: FunctionComponent = () => {
  const [runQuery, { data, loading, error, called, variables }] =
    useRunReturnsReportLazyQuery({
      fetchPolicy: 'network-only',
    });
  const [downloadReport] = useDownloadReturnReportMutation();

  const initItemsSoldData = data?.runItemSoldReport?.nodes ?? [];
  const initCompanyReturnRateReport =
    data?.runCompanyReturnRateReport?.nodes ?? [];
  const initPieData = data?.runReturnReportPie?.nodes ?? [];
  const initEmployeeReturnRatesData =
    data?.runReturnReportEmployeeReturnRates?.nodes ?? [];

  const onPeriodChange = ({ start, end, interval }: Option) => {
    try {
      runQuery({
        variables: {
          startDate: start.toISOString(),
          endDate: end.toISOString(),
          groupBy: interval,
        },
      });
    } catch (e) {
      logError(e);
    }
  };

  const handleDownload = useCallback(async () => {
    if (variables?.endDate && variables?.startDate) {
      try {
        generalNotification('Queued report for download');
        await downloadReport({
          variables: {
            input: {
              startDate: variables?.startDate,
              endDate: variables?.endDate,
            },
          },
        });
      } catch (e: any) {
        logError(e);
        errorNotification(e?.message ?? 'Unknown error');
      }
    }
  }, [variables]);

  const itemsSoldData = useMemo(
    () =>
      initItemsSoldData?.map((d) => ({
        date: String(d.date),
        value: Number(d.count),
      })),
    [initItemsSoldData],
  );

  const companyReturnRatesData = useMemo(
    () =>
      initCompanyReturnRateReport.map((d) => ({
        date: String(d.date),
        percent: Number(d.percent),
      })),
    [initCompanyReturnRateReport],
  );

  const pieData = useMemo(
    () =>
      initPieData.map((n) => ({
        name: String(n.reason),
        value: Number(n.num),
        percent: Number(n.percent),
      })),
    [initPieData],
  );

  const employeeReturnRatesData = useMemo(
    () =>
      initEmployeeReturnRatesData.map((n) => ({
        user: String(n.employeeName),
        totalPickedUp: Number(n.totalPickedUp),
        totalReturns: Number(n.totalReturns),
        percent: Number(n.percent),
      })),
    [initEmployeeReturnRatesData],
  );

  const averageEmployeeReturnRate = useMemo(
    () => Number(initEmployeeReturnRatesData?.[0]?.avgPercent) ?? 0,
    [initEmployeeReturnRatesData],
  );

  return (
    <ViewScreen
      loading={false}
      header={{
        leftProps: {
          title: 'Returns Summary',
        },
        bottomProps: {
          component: (
            <Stack direction="row" spacing={1}>
              <ReportPeriodSelect onChange={onPeriodChange} />
              <Button
                disabled={loading || !called}
                startIcon={<ArrowDownwardIcon />}
                onClick={handleDownload}
                text="Download CSV"
              />
            </Stack>
          ),
        },
      }}
    >
      <SimpleReportView loading={loading} error={error} called={called}>
        <Box>
          <ReturnReportPieChart data={pieData} />
        </Box>
        <Box sx={{ paddingTop: 16, paddingBottom: 16 }}>
          <ItemsSoldChart showTitle data={itemsSoldData} />
        </Box>
        <Box>
          <CompanyReturnRateChart data={companyReturnRatesData} />
        </Box>
        <Box sx={{ paddingTop: 16, paddingBottom: 16 }}>
          <ReturnReportEmployeeReturnRateChart
            data={employeeReturnRatesData}
            averageRate={averageEmployeeReturnRate}
          />
        </Box>
      </SimpleReportView>
    </ViewScreen>
  );
};

export default ReturnReport;
