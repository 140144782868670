import { Box, Typography } from '@mui/material';
import Button, { Props as ButtonProps } from 'components/Button';
import React, { ReactElement } from 'react';
import { useStyles } from './styles';

export type Props = {
  subtitle?: string;
  buttons?: ButtonProps[];
  component?: ReactElement;
};
export default ({ subtitle, buttons, component }: Props) => {
  const classes = useStyles({
    hasButtons: buttons !== null && buttons !== undefined,
  });
  return (
    <Box>
      {subtitle && (
        <Box
          display="flex"
          alignItems="center"
          className={classes.headerBottomSubtitleContainer}
        >
          <Typography variant="body1">
            <Box>{subtitle}</Box>
          </Typography>
        </Box>
      )}
      {buttons ? (
        <Box display="flex" alignItems="center" flexDirection="row">
          {buttons.map((props, i) => (
            <Box key={String(i)} className={classes.leftButton}>
              <Button key={`buttons-${i}`} {...props} />
            </Box>
          ))}
        </Box>
      ) : null}
      {component ?? null}
    </Box>
  );
};
