import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useRelistItemMutation } from 'graphql/graphql-types';
import React, { FunctionComponent, useCallback } from 'react';
import { logError } from 'utils/logging';

type Props = {
  itemId: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};
const ItemRelistModal: FunctionComponent<Props> = ({
  itemId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [commit] = useRelistItemMutation();

  const onSubmit = useCallback(async () => {
    try {
      await commit({
        variables: {
          input: {
            itemId,
          },
        },
      });
      onSuccess();
    } catch (e) {
      logError(e);
    }
  }, [itemId]);

  return (
    <Dialog
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Relist item</DialogTitle>
      <DialogContent>
        <Typography>
          Item will be marked as relisted. Do you want to proceed?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSubmit} color="primary">
          Relist
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemRelistModal;
