import {
  AppBar as AppBarMaterial,
  Box,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import logo from 'assets/images/logo-dark.png';
import PulsingBadge from 'components/PulsingBadge';
import React, { FunctionComponent } from 'react';
import { useTheme, styled } from '@mui/material/styles';

const ToolbarAdjusted = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.up('xl')]: {
    minHeight: 160,
  },
}));

const Img = styled('img')(({ theme }) => ({
  marginRight: 10,
  [theme.breakpoints.down('xl')]: {
    maxHeight: 35,
  },
  [theme.breakpoints.up('xl')]: {
    maxHeight: 85,
  },
}));

type Props = {
  text: string;
};
const AppBar: FunctionComponent<Props> = ({ text }) => {
  const theme = useTheme();
  const isXL = useMediaQuery(theme.breakpoints.up('xl'));

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBarMaterial position="static">
        <ToolbarAdjusted>
          <Stack direction="row" alignItems="center">
            <Img src={logo} alt="Logo" />
            <PulsingBadge
              circleHeight={isXL ? 25 : 10}
              circleWidth={isXL ? 25 : 10}
              circleMargin={isXL ? 25 : 10}
              large={isXL}
            />
            <Typography variant={isXL ? 'h3' : 'h6'}>{text}</Typography>
          </Stack>
        </ToolbarAdjusted>
      </AppBarMaterial>
    </Box>
  );
};

export default AppBar;
