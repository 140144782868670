import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import AppBar from './AppBar';
import AppContent from './AppContent';
import AppDrawer from './AppDrawer';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

interface Props {
  children: React.ReactNode;
}

const AppWrapper = ({ children }: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar />
      <AppDrawer />
      <AppContent>{children}</AppContent>
    </div>
  );
};

export default AppWrapper;
