import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Grid, ImageListItem, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Image from 'components/Image';
import {
  ItemImageEntryFragment,
  useSetPrimaryItemImageMutation,
} from 'graphql/graphql-types';
import React, { useCallback, useEffect, useState } from 'react';
import { logError } from 'utils/logging';

type SortableElementProps = {
  itemImage: ItemImageEntryFragment;
  refetch: () => void;
};

const SortableItem = ({ itemImage, refetch }: SortableElementProps) => {
  const [setPrimaryItemImage] = useSetPrimaryItemImageMutation();

  const handleDeleteImage = useCallback(async () => {
    try {
      await setPrimaryItemImage({
        variables: { isPrimary: false, itemImageId: itemImage.id },
        refetchQueries: [],
      });
      refetch();
    } catch (e) {
      logError(e);
    }
  }, [itemImage.id]);

  return (
    <Grid item xs={12} sm={6} md={4} lg={2}>
      <div>
        <Box>
          <ImageListItem
            style={{
              backgroundColor: '#f7fafc',
              boxShadow:
                '0px 2px 1px -1px #e3e8ee, 0px 1px 1px 0px #e3e8ee, 0px 1px 3px 0px #e3e8ee',
              borderRadius: '4px',
              margin: '0 1.25rem 1.25rem 0',
              padding: '0.2rem',
            }}
          >
            <Image src={itemImage?.publicUrl} height={175} width={175} />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
              style={{ padding: '0.75rem 0.5rem', width: '11rem' }}
            >
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleDeleteImage();
                }}
                aria-label="delete-image"
              >
                <DeleteIcon fontSize="small" style={{ color: '#3F5673' }} />
              </IconButton>
            </Box>
          </ImageListItem>
        </Box>
      </div>
    </Grid>
  );
};

type Props = {
  images: ItemImageEntryFragment[];
  refetch: () => void;
};
const ImagesList = ({ images, refetch }: Props) => {
  const [items, setItems] = useState(images);
  useEffect(() => {
    setItems(images);
  }, [images]);

  return (
    <Grid container spacing={4}>
      {!items?.length ? (
        <Grid item xs={12}>
          <Box>
            <Typography component="div">
              Featured images are set by clicking on the star icon next to the
              image from the list of items.
            </Typography>
          </Box>
        </Grid>
      ) : (
        ''
      )}
      {items.map((itemImage) => (
        <SortableItem
          key={`item-${itemImage.id}`}
          itemImage={itemImage}
          refetch={refetch}
        />
      ))}
    </Grid>
  );
};

export default ImagesList;
