import { QueuedProcessingType, StatusType } from 'graphql/graphql-types';
import { mapEnumToFilterElements } from 'utils/graphql';

export const QUEUED_PROCESSING_FILTER_ELEMENTS = mapEnumToFilterElements(
  QueuedProcessingType,
);

export const PAYMENT_CHARGE_STATUS_FILTER_ELEMENTS = mapEnumToFilterElements(
  StatusType,
);
