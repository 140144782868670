import { SortTypes } from '@digitus-data/material-ui-table-apollo';
import { ItemStatusChip } from 'components/Chips';
import Table from 'components/Table';
import {
  AllItemsDocument,
  AllItemsEntryFragment,
  useAllAuctionsQuery,
  useAllInvoicesQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { formatCentsToDollars } from 'utils/money';
import { ITEM_OVERALL_PICKUP_STATUS_FILTER_ELEMENTS } from '../Items/filters';

type Row = {
  row: AllItemsEntryFragment;
};
type Props = {
  customerId: number;
};
const CustomerItemTable: FunctionComponent<Props> = ({ customerId }) => {
  const history = useHistory();
  const condition = {
    bidderId: {
      equalTo: customerId,
    },
  };

  return (
    <Table
      query={AllItemsDocument}
      condition={condition}
      onRowClick={({ id }: Row['row']) => history.push(`/items/${id}`)}
      defaultOrder="id"
      defaultSort={SortTypes.DESC}
      columns={[
        { label: 'Id', name: 'id', type: 'integer', width: 1 },
        { label: 'Number', name: 'number', type: 'integer', width: 1 },
        {
          label: 'Description',
          name: 'description',
          width: '400px',
        },
        {
          type: 'autocomplete',
          label: 'Auction',
          name: 'auctionByAuctionId.name',
          makeFilterQuery: (value) => ({
            auctionByAuctionId: {
              name: {
                includesInsensitive: value,
              },
            },
          }),
          useQuery: useAllAuctionsQuery,
          getOptionLabel: (item) => item.name,
          mapValueToFilter: (value) => ({
            name: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllAuctionsQuery>) =>
            result.data?.allAuctions?.nodes ?? [],
        },
        {
          type: 'autocomplete',
          label: 'Invoice',
          name: 'invoiceByInvoiceId.uid',
          makeFilterQuery: (value) => ({
            invoiceByInvoiceId: {
              uid: {
                includesInsensitive: value,
              },
            },
          }),
          useQuery: useAllInvoicesQuery,
          getOptionLabel: (item) => item.uid,
          mapValueToFilter: (value) => ({
            uid: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllInvoicesQuery>) =>
            result.data?.allInvoices?.nodes ?? [],
        },
        {
          label: 'Bid',
          name: 'Bid',
          type: 'integer',
          disableFilter: true,
          render: ({ row }: Row) => formatCentsToDollars(row.bidAmount),
        },
        {
          label: 'Status',
          name: 'status',
          type: 'select',
          filterElements: ITEM_OVERALL_PICKUP_STATUS_FILTER_ELEMENTS,
          makeFilterQuery: (value) => ({
            overallPickupStatus: {
              equalTo: value,
            },
          }),
          render: ({ row }: Row) => (
            <ItemStatusChip status={row.overallPickupStatus} />
          ),
        },
      ]}
    />
  );
};

export default CustomerItemTable;
