import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CircleLoading from 'components/CircleLoading';
import React from 'react';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}));

export default () => {
  const classes = useStyles();

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      flexGrow={1}
    >
      <CircleLoading />
    </Box>
  );
};
