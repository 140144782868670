import ListScreen from 'components/ListScreen';
import { SortTypes } from 'components/Table';
import {
  AllTransactionsDocument,
  TransactionEntryFragment,
  useAllBiddersQuery,
  useAllInvoicesQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { formatEnum } from 'utils/general';
import { formatDateTime } from 'utils/time';
import { TRANSACTION_TYPE_FILTER_ELEMENTS } from './filters';

type Row = {
  row: TransactionEntryFragment;
};

const TransactionListScreen: FunctionComponent = () => {
  const history = useHistory();

  return (
    <ListScreen
      title="Transactions"
      query={AllTransactionsDocument}
      onRowClick={(row: Row['row']) => {
        history.push(`/transactions/${row.id}`);
      }}
      defaultOrder="createdAt"
      defaultSort={SortTypes.DESC}
      columns={[
        {
          label: 'Type',
          name: 'type',
          type: 'select',
          filterElements: TRANSACTION_TYPE_FILTER_ELEMENTS,
          render: ({ row }: Row) => formatEnum(row.type),
        },
        {
          type: 'autocomplete',
          label: 'Customer',
          name: 'paymentByPaymentId.invoiceByInvoiceId.bidderByBidderId.name',
          makeFilterQuery: (value) => ({
            paymentByPaymentId: {
              invoiceByInvoiceId: {
                bidderByBidderId: {
                  name: {
                    includesInsensitive: value,
                  },
                },
              },
            },
          }),
          useQuery: useAllBiddersQuery,
          getOptionLabel: (item) => item.name,
          mapValueToFilter: (value) => ({
            name: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllBiddersQuery>) =>
            result.data?.allBidders?.nodes ?? [],
        },
        {
          type: 'autocomplete',
          label: 'Invoice',
          name: 'paymentByPaymentId.invoiceByInvoiceId.uid',
          makeFilterQuery: (value) => ({
            paymentByPaymentId: {
              invoiceByInvoiceId: {
                uid: {
                  includesInsensitive: value,
                },
              },
            },
          }),
          useQuery: useAllInvoicesQuery,
          getOptionLabel: (item) => item.uid,
          mapValueToFilter: (value) => ({
            uid: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllInvoicesQuery>) =>
            result.data?.allInvoices?.nodes ?? [],
          width: 700,
        },
        {
          label: 'Time',
          name: 'createdAt',
          render: ({ row }: Row) => formatDateTime(row.createdAt),
          type: 'date',
          formatDateValue: 'MM/DD/YYYY h:mm A',
          width: 250,
        },
        {
          label: 'Status',
          name: 'status',
        },
      ]}
    />
  );
};

export default TransactionListScreen;
