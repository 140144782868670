import { yellow } from '@mui/material/colors';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';

const warningColor = yellow[200];

interface StyleProps {
  large: boolean;
  circleWidth: number;
  circleHeight: number;
  circleMargin: number;
}

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  warningBadge: {
    color: warningColor,
  },
  circle: ({ circleWidth, circleHeight, circleMargin }: StyleProps) => ({
    margin: circleMargin,
    width: circleWidth,
    height: circleHeight,
    borderRadius: '50%',
  }),
  warningCircleSmall: {
    backgroundColor: warningColor,
    boxShadow: `0 0 0 ${alpha(warningColor, 0.5)}`,
    animation: `pulsing-warning-small 1000ms ${theme.transitions.easing.easeOut} infinite`,
  },
  warningCircleLarge: {
    backgroundColor: warningColor,
    boxShadow: `0 0 0 ${alpha(warningColor, 0.5)}`,
    animation: `pulsing-warning-large 1000ms ${theme.transitions.easing.easeOut} infinite`,
  },
  '@global @keyframes pulsing-warning-small': {
    '0%': {
      boxShadow: `0 0 0 0 ${alpha(warningColor, 0.5)}`,
    },
    '70%': {
      boxShadow: `0 0 0 8px ${alpha(warningColor, 0.0)}`,
    },
    '100%': {
      boxShadow: `0 0 0 0 ${alpha(warningColor, 0.0)}`,
    },
  },
  '@global @keyframes pulsing-warning-large': {
    '0%': {
      boxShadow: `0 0 0 0 ${alpha(warningColor, 0.5)}`,
    },
    '70%': {
      boxShadow: `0 0 0 22px ${alpha(warningColor, 0.0)}`,
    },
    '100%': {
      boxShadow: `0 0 0 0 ${alpha(warningColor, 0.0)}`,
    },
  },
}));

type Props = StyleProps;

const PulsingBadge: FunctionComponent<Props> = ({
  circleWidth,
  circleHeight,
  circleMargin,
  large,
}) => {
  const classes = useStyles({
    circleWidth,
    circleHeight,
    circleMargin,
    large,
  });

  return (
    <span className={classes.container}>
      <div className={clsx(classes.badge, classes.warningBadge)}>
        <div
          className={clsx(
            classes.circle,
            large ? classes.warningCircleLarge : classes.warningCircleSmall,
          )}
        />
      </div>
    </span>
  );
};

export default PulsingBadge;
