import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import EditorField from 'components/Forms/EditorField';
import ModalLoading from 'components/ModalLoading';
import {
  ItemPaidStatus,
  useDeleteItemFromInvoiceMutation,
  useGetItemLazyQuery,
} from 'graphql/graphql-types';
import { makeValidate } from 'components/Forms/Validation';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Form } from 'react-final-form';
import { logError } from 'utils/logging';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const formId = 'delete-item-from-invoice-modal-form';

const Schema = Yup.object({
  reason: Yup.string().required('Required'),
});
const validate = makeValidate(Schema);

const initialValues = {
  reason: '',
};

type Props = {
  itemId: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};
const DeleteItemFromInvoiceModal: FunctionComponent<Props> = ({
  itemId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [ready, setReady] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [get, { data }] = useGetItemLazyQuery();
  const [commit] = useDeleteItemFromInvoiceMutation();

  useEffect(() => {
    if (isOpen && itemId) {
      get({
        variables: {
          id: itemId,
        },
      });
    }
  }, [itemId, isOpen]);

  useEffect(() => {
    if (data?.itemById) {
      const item = data?.itemById;

      if (item?.paidStatus === ItemPaidStatus.Paid) {
        setIsPaid(true);
      }

      setReady(true);
    }
  }, [data]);

  const onSubmit = useCallback(
    async (values: Asserts<typeof Schema>) => {
      try {
        await commit({
          variables: {
            input: {
              itemId,
              reason: values.reason,
            },
          },
          update(cache) {
            const normalizedId = cache.identify({
              id: itemId,
              __typename: 'Item',
            });
            cache.evict({ id: normalizedId });
            cache.gc();
          },
        });
        onSuccess();
      } catch (e) {
        logError(e);
      }
    },
    [itemId],
  );

  return (
    <Dialog
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Delete item</DialogTitle>
      <DialogContent>
        {!ready && <ModalLoading />}
        {ready && isPaid && (
          <>
            <Typography>
              Item is paid and not allowed to be deleted from invoice.
            </Typography>
            <DialogActions>
              <Button onClick={onClose}>Cancel</Button>
            </DialogActions>
          </>
        )}
        {ready && !isPaid && (
          <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validate}
            render={({ handleSubmit, valid }) => (
              <form id={formId} onSubmit={handleSubmit}>
                <Typography>Item will be deleted from invoice.</Typography>
                <EditorField
                  required
                  label="Reason"
                  id="reason"
                  name="reason"
                  config={{
                    view: {
                      menu: true,
                      md: true,
                      html: false,
                    },
                    canView: {
                      fullScreen: false,
                      hideMenu: false,
                    },
                  }}
                  plugins={[
                    'header',
                    'font-bold',
                    'font-italic',
                    'font-underline',
                    'font-strikethrough',
                    'list-unordered',
                    'list-ordered',
                    'table',
                    'clear',
                    'logger',
                  ]}
                />
                <DialogActions>
                  <Button onClick={onClose}>Cancel</Button>
                  <Button
                    disabled={!ready || !valid}
                    type="submit"
                    onClick={handleSubmit}
                    color="primary"
                  >
                    Delete
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DeleteItemFromInvoiceModal;
