import { Box } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import React, { FunctionComponent } from 'react';

const SIZE = 12;

export const GreenDot: FunctionComponent = () => (
  <Box
    sx={{
      height: SIZE,
      width: SIZE,
      backgroundColor: green[500],
      borderRadius: '50%',
      display: 'inline-block',
    }}
  />
);

export const GrayDot: FunctionComponent = () => (
  <Box
    sx={{
      height: SIZE,
      width: SIZE,
      backgroundColor: grey[500],
      borderRadius: '50%',
      display: 'inline-block',
    }}
  />
);
