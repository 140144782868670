import { Alert, InputAdornment } from '@mui/material';
import Modal from 'components/Modal';
import ModalActions from 'components/ModalActions';
import ModalLoading from 'components/ModalLoading';
import { Switches } from 'components/Forms/Switches';
import TextField from 'components/Forms/TextField';
import { makeValidate } from 'components/Forms/Validation';
import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import {
  GetReturnOptionsDocument,
  GetReturnsByItemIdDocument,
  ReturnOptionEntryFragment,
  ReturnQueueType,
  useGetItemReturnDetailsLazyQuery,
  useReturnItemMutation,
} from 'graphql/graphql-types';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Form } from 'react-final-form';
import { logError } from 'utils/logging';
import { makeWholeCents } from 'utils/money';
import type { Asserts } from 'yup';
import * as Yup from 'yup';
import Autocomplete from 'components/Forms/Autocomplete';

const NoteSchema = Yup.lazy((_, { parent }) =>
  parent.reason?.requireNote
    ? Yup.string().required('Required')
    : Yup.string().notRequired(),
);
const RefundPaymentSchema = Yup.lazy((_, { parent }) =>
  Yup.number()
    .typeError('Must be a number')
    .positive()
    .min(0)
    .max(parent.paymentsAvailable ?? 0)
    .required('Required'),
);
const RefundAlternatePaymentSchema = Yup.lazy((_, { parent }) =>
  Yup.number()
    .typeError('Must be a number')
    .positive()
    .min(0)
    .max(parent.altPaymentsAvailable ?? 0)
    .required('Required'),
);

const Schema = Yup.object({
  note: NoteSchema,
  reason: Yup.object()
    .shape({
      id: Yup.string().required('Required'),
      name: Yup.string().required('Required'),
      requireNote: Yup.boolean().required('Required'),
    })
    .nullable(),
  refundCredit: Yup.number()
    .typeError('Must be a number')
    .positive()
    .min(0)
    .required('Required'),
  paymentsAvailable: Yup.number()
    .typeError('Must be a number')
    .positive()
    .min(0)
    .required('Required'),
  refundPayment: RefundPaymentSchema,
  altPaymentsAvailable: Yup.number()
    .typeError('Must be a number')
    .positive()
    .min(0)
    .required('Required'),
  refundAlternatePayment: RefundAlternatePaymentSchema,
  sendEmail: Yup.boolean().required(),
  hasPaymentSettled: Yup.boolean().default(false).required(),
});

type ReturnSchema = Asserts<typeof Schema>;
const validate = makeValidate(Schema);

type InfoMessageProps = {
  hasPaymentSettled: boolean,
  paymentsAvailable: number
}
const InfoMessage: FunctionComponent<InfoMessageProps> = ({
  hasPaymentSettled,
  paymentsAvailable
}) => {
  if (paymentsAvailable === 0) {
    return (
      <Alert severity="warning">
        Payment refund is not available: no amount left to refund.
      </Alert>
    )
  }
  if (paymentsAvailable > 0 && !hasPaymentSettled) {
    return (
      <Alert severity="warning">
        Payment refund is not available: payment is pending settlement from payment processor.
      </Alert>
    )
  }
  return null;
}

type Props = {
  itemId: number;
  queueId?: string;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};
const ItemReturnModal: FunctionComponent<Props> = ({
  itemId,
  queueId,
  open,
  onClose,
  onSuccess,
}) => {
  const [ready, setReady] = useState(false);
  const [initialValues, setInitialValues] = useState<ReturnSchema>();
  const [get, { data }] = useGetItemReturnDetailsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [returnItem] = useReturnItemMutation({
    refetchQueries: [
      {
        query: GetReturnsByItemIdDocument,
        variables: {
          id: Number(itemId),
        },
      },
    ],
  });

  useEffect(() => {
    if (open && itemId) {
      get({ variables: { itemId: Number(itemId), returnQueueId: queueId } });
    }
  }, [open, itemId, queueId]);

  useEffect(() => {
    if (data?.getItemReturnDetails) {
      const item = data?.getItemReturnDetails?.item;
      const returnData = data?.getItemReturnDetails?.returnQueue;
      const paymentAvailable =
        (item?.paymentCreditsAvailableForRefund ?? 0) / 100;
      const altPaymentAvailable =
        (item?.alternatePaymentCreditsAvailableForRefund ?? 0) / 100;

      setInitialValues({
        note: returnData?.note ?? '',
        paymentsAvailable: paymentAvailable,
        altPaymentsAvailable: altPaymentAvailable,
        hasPaymentSettled: item?.hasPaymentTransactionSettled ?? false,
        reason:
          returnData?.returnOptionByOptionId?.id &&
            returnData?.returnOptionByOptionId?.name
            ? {
              id: returnData?.returnOptionByOptionId?.id,
              name: returnData?.returnOptionByOptionId?.name,
              requireNote: returnData?.returnOptionByOptionId?.requireNote,
            }
            : undefined,
        refundCredit:
          returnData?.type === ReturnQueueType.Credit
            ? Number(returnData?.amount) / 100
            : 0,
        refundPayment:
          returnData?.type === ReturnQueueType.Refund
            ? Number(returnData?.amount) / 100
            : 0,
        refundAlternatePayment: 0,
        sendEmail: returnData?.sendEmail ?? true,
      });
      setReady(true);
    }
  }, [data]);

  const onSubmit = useCallback(
    async (values: Asserts<typeof Schema>) => {
      const toastId = startSnackbar('Processing return');
      try {
        await returnItem({
          variables: {
            input: {
              itemId,
              queueId,
              optionId: values.reason.id,
              note: values.note,
              creditAmount: makeWholeCents(values.refundCredit),
              paymentAmount: makeWholeCents(values.refundPayment),
              alternatePaymentAmount: makeWholeCents(
                values.refundAlternatePayment,
              ),
              sendEmail: values.sendEmail,
            },
          },
        });
        stopSnackbar('Queued return for processing', toastId);
        onSuccess();
      } catch (e: any) {
        stopSnackbar(`Error processing return ${e?.message}`, toastId);
        logError(e);
      }
    },
    [itemId],
  );

  return (
    <Modal
      open={open}
      title={`Issue Return for Item ${data?.getItemReturnDetails?.item?.number ?? ''
        }`}
      handleClose={onClose}
      maxWidth="md"
    >
      {!ready && <ModalLoading />}
      {ready && (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          render={({
            handleSubmit,
            initialValues: formInitialValues,
            ...props
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                required
                variant="outlined"
                label="Credit refund"
                id="refundCredit"
                name="refundCredit"
                parseType="money"
                type="number"
                validateInitialValue
              />
              <InfoMessage
                hasPaymentSettled={formInitialValues.hasPaymentSettled}
                paymentsAvailable={formInitialValues.paymentsAvailable}
              />
              <TextField
                required
                variant="outlined"
                label="Payment refund"
                id="refundPayment"
                name="refundPayment"
                parseType="money"
                type="number"
                disabled={
                  formInitialValues.paymentsAvailable === 0 ||
                  !formInitialValues.hasPaymentSettled
                }
                endAdornment={
                  <InputAdornment position="end">{`/ $${formInitialValues.paymentsAvailable}`}</InputAdornment>
                }
                validateInitialValue
              />
              <TextField
                required
                variant="outlined"
                label="Alternate Payment refund"
                id="refundAlternatePayment"
                name="refundAlternatePayment"
                parseType="money"
                type="number"
                disabled={formInitialValues.altPaymentsAvailable === 0}
                endAdornment={
                  <InputAdornment position="end">{`/ $${formInitialValues.altPaymentsAvailable}`}</InputAdornment>
                }
              />
              <Autocomplete
                label="Reason"
                name="reason"
                required
                id="reason"
                query={GetReturnOptionsDocument}
                defaultValue={formInitialValues.reason}
                getOptionValue={(option: ReturnOptionEntryFragment) => ({
                  id: option.id,
                  name: option.name,
                  requireNote: option.requireNote,
                })}
                getOptionLabel={(option: ReturnOptionEntryFragment) =>
                  option.name
                }
                isOptionEqualToValue={(
                  option: ReturnOptionEntryFragment,
                  value: any,
                ) => option?.id === value?.id}
              />
              <TextField
                required={props.values.reason?.requireNote}
                variant="outlined"
                label="Note"
                id="note"
                name="note"
                helperText="Reason and note will be sent to customer if send email is checked. Double check that its appropriate."
                multiline
                rows={4}
              />
              <Switches
                name="sendEmail"
                required
                data={{ label: 'Send email to customer', value: true }}
              />
              <ModalActions
                buttons={[
                  {
                    text: 'Cancel',
                    onClick: onClose,
                    variant: 'text',
                  },
                  {
                    text: 'Return',
                    onClick: handleSubmit,
                    type: 'submit',
                    disabled:
                      props.submitting ||
                      props.hasValidationErrors ||
                      (props.hasSubmitErrors && !props.modifiedSinceLastSubmit),
                    variant: 'contained',
                    color: 'primary',
                  },
                ]}
              />
            </form>
          )}
        />
      )}
    </Modal>
  );
};

export default ItemReturnModal;
