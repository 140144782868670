import { Box, Grid, Stack, Typography } from '@mui/material';
import CopyTextButton from 'components/CopyTextButton';
import SelectField from 'components/Forms/SelectField';
import TextField from 'components/Forms/TextField';
import { makeValidate } from 'components/Forms/Validation';
import Modal from 'components/Modal';
import ModalActions from 'components/ModalActions';
import ModalLoading from 'components/ModalLoading';
import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import {
  LocationListEntryFragmentDoc,
  useCreateLocationMutation,
  useGetLocationLazyQuery,
  useUpdateLocationMutation,
} from 'graphql/graphql-types';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Form } from 'react-final-form';
import { logError } from 'utils/logging';
import type { Asserts } from 'yup';
import * as Yup from 'yup';
import {
  STATES,
  STATES_VALIDATION,
  TIMEZONES,
  TIMEZONES_VALIDATION,
} from './LocationFormPresetValues';

const RequiredPositiveNumberSchema = Yup.number()
  .typeError('Must be a number')
  .positive('Must be a positive number')
  .required('Required');

const RequiredMin0NumberSchema = Yup.number()
  .typeError('Must be a number')
  .min(0)
  .required('Required');

const Schema = Yup.object({
  invoicePrefix: Yup.string(),
  name: Yup.string().required('Required'),
  premium: RequiredPositiveNumberSchema,
  tax: RequiredPositiveNumberSchema,
  addressMain: Yup.string().required('Required'),
  addressSecondary: Yup.string(),
  timezone: Yup.string().oneOf(TIMEZONES_VALIDATION).required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().oneOf(STATES_VALIDATION).required('Required'),
  zipCode: Yup.string().required('Required'),
  shortDescription: Yup.string().required('Required'),
  fullDescription: Yup.string().required('Required'),
  lpudMon: RequiredMin0NumberSchema,
  lpudTue: RequiredMin0NumberSchema,
  lpudWed: RequiredMin0NumberSchema,
  lpudThu: RequiredMin0NumberSchema,
  lpudFri: RequiredMin0NumberSchema,
  lpudSat: RequiredMin0NumberSchema,
  lpudSun: RequiredMin0NumberSchema,
});

type LocationSchema = Asserts<typeof Schema>;
const validate = makeValidate(Schema);

type Props = {
  visible: boolean;
  type: 'create' | 'update';
  locationId?: number;
  handleClose: () => void;
  handleSuccess: () => void;
};
const LocationForm: FunctionComponent<Props> = ({
  type,
  visible,
  locationId,
  handleClose,
  handleSuccess,
}) => {
  const [ready, setReady] = useState(false);
  const [initialValues, setInitialValues] = useState<LocationSchema>();
  const [get, { data }] = useGetLocationLazyQuery();

  useEffect(() => {
    if (visible && type === 'create') {
      setReady(true);
    }
    if (visible && type === 'update' && locationId) {
      get({
        variables: {
          id: locationId,
        },
      });
    }
  }, [visible, type, locationId]);

  useEffect(() => {
    if (data?.locationById) {
      const location = data.locationById;
      setInitialValues({
        invoicePrefix: String(location?.invoicePrefix),
        name: location.name,
        premium: Number(location.premium),
        tax: Number(location.tax),
        addressMain: location.addressMain,
        addressSecondary: location.addressSecondary,
        timezone: location.timezone,
        city: location.city,
        state: location.state,
        zipCode: location.zipCode,
        shortDescription: location.shortDescription,
        fullDescription: location.fullDescription,
        lpudMon: location.lpudMon,
        lpudTue: location.lpudTue,
        lpudWed: location.lpudWed,
        lpudThu: location.lpudThu,
        lpudFri: location.lpudFri,
        lpudSat: location.lpudSat,
        lpudSun: location.lpudSun,
      });
      setReady(true);
    }
  }, [data]);

  const [createLocation] = useCreateLocationMutation({
    update(cache, { data: locationData }) {
      cache.modify({
        fields: {
          allLocations(existing = []) {
            const newRef = cache.writeFragment({
              data: locationData?.createLocation?.location,
              fragment: LocationListEntryFragmentDoc,
            });
            return { ...existing, nodes: [...existing.nodes, newRef] };
          },
        },
      });
    },
  });
  const [updateLocation] = useUpdateLocationMutation({
    update(cache, { data: locationData }) {
      cache.modify({
        fields: {
          allLocations(existing = []) {
            const newRef = cache.writeFragment({
              data: locationData?.updateLocationById?.location,
              fragment: LocationListEntryFragmentDoc,
            });
            return { ...existing, nodes: [...existing.nodes, newRef] };
          },
        },
      });
    },
  });

  const onSubmit = useCallback(
    async (values: LocationSchema) => {
      const snackId = startSnackbar(
        `${locationId ? 'Saving' : 'Creating'} location`,
      );
      try {
        if (locationId) {
          await updateLocation({
            variables: {
              id: Number(locationId),
              values,
            },
          });
          handleSuccess();
          stopSnackbar('Saved location', snackId);
        } else {
          await createLocation({
            variables: {
              values,
            },
          });
          handleSuccess();
          stopSnackbar('Created location', snackId);
        }
      } catch (e) {
        logError(e);
        stopSnackbar(
          `Failed to ${locationId ? 'save' : 'create'} location: ${e.message}`,
          snackId,
        );
      }
    },
    [locationId],
  );

  return (
    <Modal
      open={visible}
      title={type === 'create' ? 'New Location' : 'Update Location'}
      handleClose={handleClose}
      maxWidth="md"
    >
      {!ready && <ModalLoading />}
      {ready && (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          render={({
            handleSubmit,
            initialValues: formInitialValues,
            ...props
          }) => (
            <form>
              <Typography variant="h6">Details</Typography>
              <TextField
                required
                label="Name"
                id="name"
                name="name"
                variant="outlined"
              />
              <TextField
                required={false}
                label="Invoice prefix"
                id="invoicePrefix"
                name="invoicePrefix"
                variant="outlined"
              />
              <TextField
                required
                label="Address"
                id="addressMain"
                name="addressMain"
                variant="outlined"
              />
              <TextField
                label="Secondary address"
                id="addressSecondary"
                name="addressSecondary"
                variant="outlined"
              />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    required
                    label="City"
                    id="city"
                    name="city"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <SelectField
                    required
                    label="State"
                    id="state"
                    name="state"
                    items={STATES}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    required
                    label="Zip Code"
                    id="zipCode"
                    name="zipCode"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <SelectField
                required
                label="Timezone"
                id="timezone"
                name="timezone"
                items={TIMEZONES}
                variant="outlined"
              />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    required
                    label="Premium"
                    id="premium"
                    name="premium"
                    parseType="percentage"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    required
                    label="Tax"
                    id="tax"
                    name="tax"
                    parseType="percentage"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Typography variant="h6" sx={{ pt: 2 }}>
                Description Templates
              </Typography>
              <Stack>
                <Box sx={{ paddingTop: 2, paddingBottom: 2 }}>
                  <Typography>
                    Use the following placeholders for the templates below
                  </Typography>
                </Box>
                <Box>
                  <CopyTextButton data="{AUCTION_NAME}">
                    <Typography>{`{AUCTION_NAME}`}</Typography>
                  </CopyTextButton>
                </Box>
                <Box>
                  <CopyTextButton data="{AUCTION_END_DATETIME}">
                    <Typography>{`{AUCTION_END_DATETIME}`}</Typography>
                  </CopyTextButton>
                </Box>
                <Box>
                  <CopyTextButton data="{PICKUP_START_END_DATETIME}">
                    <Typography>{`{PICKUP_START_END_DATETIME}`}</Typography>
                  </CopyTextButton>
                </Box>
                <Box>
                  <CopyTextButton data="{PICKUP_LOCATION}">
                    <Typography>{`{PICKUP_LOCATION}`}</Typography>
                  </CopyTextButton>
                </Box>
                <Box>
                  <CopyTextButton data="{PREMIUM_FEE_PERCENT}">
                    <Typography>{`{PREMIUM_FEE_PERCENT}`}</Typography>
                  </CopyTextButton>
                </Box>
                <Box>
                  <CopyTextButton data="{TAX_FEE_PERCENT}">
                    <Typography>{`{TAX_FEE_PERCENT}`}</Typography>
                  </CopyTextButton>
                </Box>
                <TextField
                  required
                  label="Short Description"
                  id="shortDescription"
                  name="shortDescription"
                  multiline
                  rows={4}
                  variant="outlined"
                />
                <TextField
                  required
                  label="Full Description"
                  id="fullDescription"
                  name="fullDescription"
                  multiline
                  rows={4}
                  variant="outlined"
                />
              </Stack>
              <Typography variant="h6" sx={{ pt: 2 }}>
                Last Pickup Date Settings
              </Typography>
              <Grid item xs={2}>
                <TextField
                  required
                  label="Monday"
                  id="lpudMon"
                  name="lpudMon"
                  parseType="integer"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  required
                  label="Tuesday"
                  id="lpudTue"
                  name="lpudTue"
                  parseType="integer"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  required
                  label="Wednesday"
                  id="lpudWed"
                  name="lpudWed"
                  parseType="integer"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  required
                  label="Thursday"
                  id="lpudThu"
                  name="lpudThu"
                  parseType="integer"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  required
                  label="Friday"
                  id="lpudFri"
                  name="lpudFri"
                  parseType="integer"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  required
                  label="Saturday"
                  id="lpudSat"
                  name="lpudSat"
                  parseType="integer"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  required
                  label="Sunday"
                  id="lpudSun"
                  name="lpudSun"
                  parseType="integer"
                  variant="outlined"
                />
              </Grid>
              <ModalActions
                buttons={[
                  {
                    text: 'Cancel',
                    onClick: handleClose,
                    variant: 'text',
                  },
                  {
                    text: 'Save',
                    onClick: handleSubmit,
                    type: 'submit',
                    disabled:
                      props.submitting ||
                      props.pristine ||
                      props.hasValidationErrors ||
                      (props.hasSubmitErrors && !props.modifiedSinceLastSubmit),
                    variant: 'contained',
                    color: 'primary',
                  },
                ]}
              />
            </form>
          )}
        />
      )}
    </Modal>
  );
};

export default LocationForm;
