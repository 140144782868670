import {
  UpdateItemByBwIdInput,
  useUpdateItemByIdMutation,
} from 'graphql/graphql-types';
import { useCallback } from 'react';
import { logError } from 'utils/logging';

export const useItemModification = () => {
  const [update] = useUpdateItemByIdMutation();

  const updateItem = useCallback(
    (itemId: string | number, values: UpdateItemByBwIdInput['itemPatch']) => {
      try {
        update({
          variables: {
            id: Number(itemId),
            values,
          },
        });
      } catch (e) {
        logError(e);
      }
    },
    [],
  );

  return {
    updateItem,
  };
};
