import { Box, Dialog, DialogProps, Slide } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TransitionProps } from '@mui/material/transitions';
import React, { forwardRef, FunctionComponent } from 'react';
import ContentHeader from './ContentHeader';

const Transition = forwardRef(
  (props: TransitionProps, ref: React.Ref<unknown>) => (
    // @ts-ignore
    <Slide direction="up" ref={ref} {...props} />
  ),
);

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: 24,
    },
    header: {},
    content: {},
  }),
);

type Props = {
  open: boolean;
  title: string;
  handleClose: () => void;
  fullScreen?: DialogProps['fullScreen'];
  fullWidth?: DialogProps['fullWidth'];
  maxWidth?: DialogProps['maxWidth'];
};
const Modal: FunctionComponent<Props> = ({
  open,
  title,
  handleClose,
  children,
  fullScreen = false,
  fullWidth = true,
  maxWidth = 'md',
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <Box className={classes.container}>
        <Box className={classes.header}>
          <ContentHeader title={title} size="large" divider={false} />
        </Box>
        <Box className={classes.content}>{children}</Box>
      </Box>
    </Dialog>
  );
};

export default Modal;
