import ButtonBase from '@mui/material/ButtonBase';
import SkeletonWrapper from 'components/SkeletonWrapper';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { openInNewTab } from 'utils/general';

type Props = {
  src: string | undefined | null;
  height: number;
  width: number;
};
const Image: FunctionComponent<Props> = ({ src, height, width }) => {
  const [loading, setLoading] = useState(true);
  const [sourceUrl, setSourceUrl] = useState<string | null>(null);

  useEffect(() => {
    if (src) {
      setSourceUrl(src);
    }
  }, [src]);

  const onClick = useCallback(() => {
    if (sourceUrl) {
      openInNewTab(sourceUrl);
    }
  }, [sourceUrl]);

  const onLoad = () => {
    setLoading(false);
  };

  return (
    <SkeletonWrapper
      loading={loading}
      variant="rectangular"
      height={height}
      width={width}
      allowChildrenRender
    >
      {sourceUrl && (
        <ButtonBase onClick={onClick}>
          <img
            src={sourceUrl}
            alt="auction item"
            draggable={false}
            onLoad={onLoad}
            style={{
              maxWidth: `${width}px`,
              maxHeight: `${height}px`,
            }}
          />
        </ButtonBase>
      )}
    </SkeletonWrapper>
  );
};

export default Image;
