import { FormControlLabel, Switch, Typography } from '@mui/material';
import Modal from 'components/Modal';
import ModalActions from 'components/ModalActions';
import React, { FunctionComponent, useCallback, useState } from 'react';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onOk: (publish: boolean) => void;
};
const AuctionPushModal: FunctionComponent<Props> = ({
  isOpen,
  onClose,
  onOk,
}) => {
  const [publish, setPublish] = useState(true);

  const onYes = useCallback(() => {
    onOk(publish);
  }, [publish]);

  return (
    <Modal
      open={isOpen}
      title="Push to BidWrangler"
      handleClose={onClose}
      maxWidth="sm"
    >
      <Typography>
        This will push the current auction to BidWrangler. Are you sure you want
        to do this?
      </Typography>
      <br />
      <FormControlLabel
        label="Publish auction"
        control={
          <Switch
            checked={publish}
            size="small"
            onChange={(e) => setPublish(e.target.checked)}
          />
        }
      />
      <ModalActions
        buttons={[
          {
            text: 'Cancel',
            onClick: onClose,
          },
          {
            text: 'Yes',
            onClick: onYes,
          },
        ]}
      />
    </Modal>
  );
};

export default AuctionPushModal;
