import React from 'react';
import { Box, Typography } from '@mui/material';
import { useStyles } from './styles';

export type Props = {
  title: string;
};
export default ({ title }: Props) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box className={classes.titleContainer}>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
