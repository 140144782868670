import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import {
  UserEntryFragmentDoc,
  useUpdateUserActiveStateMutation,
} from 'graphql/graphql-types';
import { useCallback } from 'react';
import { logError } from 'utils/logging';

export const useUpdateUserActiveState = (userId: number) => {
  const [runCommit] = useUpdateUserActiveStateMutation({
    update(cache, { data }) {
      cache.modify({
        fields: {
          allUsers(existing = []) {
            const newRef = cache.writeFragment({
              data: data?.updateUserActiveState?.user,
              fragment: UserEntryFragmentDoc,
            });
            return {
              ...existing,
              nodes: [...existing.nodes, newRef],
            };
          },
        },
      });
    },
  });

  const updateUserActiveState = useCallback(
    (activeState: boolean) => {
      const commit = async () => {
        const snackId = startSnackbar('Updating user active state');
        try {
          await runCommit({
            variables: {
              id: Number(userId),
              isActive: activeState,
            },
          });
          stopSnackbar('Updated user active state', snackId);
        } catch (e) {
          logError(e);
          stopSnackbar(
            `Failed to update user active state: ${e.message}`,
            snackId,
          );
        }
      };
      if (userId) {
        commit();
      }
    },
    [userId],
  );

  return { updateUserActiveState };
};

export default useUpdateUserActiveState;
