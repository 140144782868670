import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from '@mui/material/styles';
import ApolloClient from 'config/ApolloClient';
import React, { FunctionComponent } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RootRouter from 'routes/RootRouter';
import styled from 'styled-components';
import theme from 'theme';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const StyledToastContainer = styled(ToastContainer).attrs({
  toastClassName: 'toast',
})`
  .toast {
    border-radius: 4px;
    box-shadow: 0px 2px 10px 2px rgba(4, 18, 88, 0.3);
    color: #ffffff;
    min-height: auto;
  }
  .Toastify__toast-body {
    padding: 0;
  }
`;

const App: FunctionComponent = () => (
  <Router>
    <ApolloProvider client={ApolloClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RootRouter />
          <StyledToastContainer
            position="bottom-center"
            hideProgressBar
            transition={Slide}
            autoClose={false}
            closeOnClick={false}
            toastClassName="toast"
          />
        </ThemeProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  </Router>
);

export default App;
