import { SortTypes } from '@digitus-data/material-ui-table-apollo';
import ListIcon from '@mui/icons-material/List';
import { IconButton } from '@mui/material';
import NeutralChip from 'components/Chips/NeutralChip';
import CustomerNotesModal from 'components/CustomerNotesModal';
import ListScreen from 'components/ListScreen';
import PaymentStatusChip from 'components/PaymentStatusChip';
import {
  AllInvoiceEntryFragment,
  AllInvoicesDocument,
  useAllAuctionsQuery,
  useAllBiddersQuery,
} from 'graphql/graphql-types';
import { useSelectedValueModal } from 'hooks/modal';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formatCentsToDollars } from 'utils/money';

type Row = {
  row: AllInvoiceEntryFragment;
};

const InvoiceListScreen: React.FC = () => {
  const history = useHistory();
  const { selectedValue, open, close } = useSelectedValueModal<number>();
  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const toggleSelectedRows = (toggleId: number) => {
    if (selectedRows.includes(toggleId)) {
      setSelectedRows(selectedRows.filter((id) => id !== toggleId));
    } else {
      setSelectedRows([...selectedRows, toggleId]);
    }
  };

  return (
    <>
      <ListScreen
        title="Invoices"
        query={AllInvoicesDocument}
        onRowClick={({ id }: Row['row']) => history.push(`/invoices/${id}`)}
        defaultOrder="lastPickupDate"
        defaultSort={SortTypes.DESC}
        columns={[
          {
            type: 'checkbox',
            width: 10,
            onClick: (row: any) => {
              toggleSelectedRows(row.id);
            },
            onClickAll: (checked, nodes) => {
              if (nodes && checked) {
                setSelectedRows(nodes.map((e) => e.id));
              } else {
                setSelectedRows([]);
              }
            },
            getIsChecked: (row) => selectedRows.includes(row.id),
            getIsCheckedAll: (nodes) =>
              nodes && nodes.length
                ? isEqual(sortBy(nodes?.map((n) => n.id)), sortBy(selectedRows))
                : false,
          },
          { label: 'UID', name: 'uid', width: '140px' },
          {
            type: 'autocomplete',
            label: 'Auction',
            name: 'auctionByAuctionId.name',
            makeFilterQuery: (value) => ({
              auctionByAuctionId: {
                name: {
                  includesInsensitive: value,
                },
              },
            }),
            useQuery: useAllAuctionsQuery,
            getOptionLabel: (item) => item.name,
            mapValueToFilter: (value) => ({
              name: { includesInsensitive: value },
            }),
            getOptions: (result: ReturnType<typeof useAllAuctionsQuery>) =>
              result.data?.allAuctions?.nodes ?? [],
          },
          {
            type: 'autocomplete',
            label: 'Customer',
            name: 'bidderByBidderId.name',
            makeFilterQuery: (value) => ({
              bidderByBidderId: {
                name: {
                  includesInsensitive: value,
                },
              },
            }),
            useQuery: useAllBiddersQuery,
            getOptionLabel: (item) => item.name,
            mapValueToFilter: (value) => ({
              name: { includesInsensitive: value },
            }),
            getOptions: (result: ReturnType<typeof useAllBiddersQuery>) =>
              result.data?.allBidders?.nodes ?? [],
          },
          {
            type: 'autocomplete',
            label: 'Email',
            name: 'bidderByBidderId.email',
            makeFilterQuery: (value) => ({
              bidderByBidderId: {
                email: {
                  includesInsensitive: value,
                },
              },
            }),
            useQuery: useAllBiddersQuery,
            getOptionLabel: (item) => item.email,
            mapValueToFilter: (value) => ({
              email: { includesInsensitive: value },
            }),
            getOptions: (result: ReturnType<typeof useAllBiddersQuery>) =>
              result.data?.allBidders?.nodes ?? [],
          },
          {
            label: 'Notes',
            name: 'customerNotes',
            disableFilter: true,
            render: ({ row }: Row) => {
              const customerId = row?.bidderByBidderId?.id;
              return row?.bidderByBidderId?.customerNotesByCustomerId
                ?.totalCount && customerId ? (
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    open(customerId);
                  }}
                >
                  <ListIcon />
                </IconButton>
              ) : null;
            },
          },
          {
            label: 'Items',
            name: 'total',
            type: 'integer',
            disableFilter: true,
            render: ({ row: { itemsCountActive } }: Row) => itemsCountActive,
          },
          {
            label: 'Total',
            name: 'total',
            type: 'integer',
            disableFilter: true,
            render: ({ row }: Row) => formatCentsToDollars(row.total),
          },
          {
            label: 'Balance',
            name: 'amountDue',
            type: 'integer',
            disableFilter: true,
            render: ({ row }: Row) => formatCentsToDollars(row.amountDue),
          },
          {
            label: 'Payment',
            name: 'isPaid',
            type: 'boolean',
            render: ({ row }: Row) => (
              <PaymentStatusChip type={row.isPaid ? 'paid' : 'unpaid'} />
            ),
          },
          {
            label: 'Completed',
            name: 'isCompleted',
            type: 'boolean',
            render: ({ row }: Row) => (row.isCompleted ? 'Yes' : 'No'),
          },
          {
            label: 'Returns',
            name: 'countTotalReturns',
            type: 'integer',
            render: ({ row }: Row) =>
              row?.countTotalReturns !== undefined &&
              row?.countTotalReturns !== null &&
              row?.countTotalReturns > 0 ? (
                <NeutralChip text={String(row?.countTotalReturns)} />
              ) : null,
          },
          {
            label: 'Last Pickup Date',
            name: 'lastPickupDate',
            type: 'date',
            formatDateValue: 'MM/DD/YYYY',
            width: 220,
          },
        ]}
      />
      <CustomerNotesModal
        isOpen={!!selectedValue}
        customerId={selectedValue}
        onOk={close}
      />
    </>
  );
};

export default InvoiceListScreen;
