import { Box, Grid, Switch } from '@mui/material';
import { ItemPaidStatusChip, ItemStatusChip } from 'components/Chips';
import ContentHeader from 'components/ContentHeader';
import Hyperlink from 'components/Hyperlink';
import ImagesList from 'components/ImagesList';
import { formatBin, formatRackLocation } from 'components/ItemHistoryItem';
import LineItems from 'components/LineItems';
import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import { ViewScreen } from 'components/ViewScreen';
import {
  useGetItemQuery,
  useUpdateItemByIdMutation,
} from 'graphql/graphql-types';
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useMemo,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { formatPercentage, openInNewTab } from 'utils/general';
import { logError } from 'utils/logging';
import { formatCentsToDollars } from 'utils/money';
import { formatDateTime } from 'utils/time';

const ItemViewScreen: FunctionComponent = () => {
  const history = useHistory();
  const { itemId } = useParams<{ itemId: string }>();
  const { loading, data, error, refetch } = useGetItemQuery({
    variables: {
      id: Number(itemId),
    },
    fetchPolicy: 'network-only',
  });

  const [updateItem] = useUpdateItemByIdMutation();

  const onSubmitItemChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const snackId = startSnackbar('Updating item');
      try {
        await updateItem({
          variables: {
            id: Number(itemId),
            values: {
              isTaxExempt: e.target.checked,
            },
          },
        });
        await refetch();
        stopSnackbar('Updated item', snackId);
      } catch (submitError: any) {
        logError(submitError);
        stopSnackbar(`Failed to update item: ${submitError.message}`, snackId);
      }
    },
    [itemId],
  );

  const item = data?.itemById;
  const merId = item?.merId;
  const status = item?.overallPickupStatus ?? null;
  const bwItemId = item?.bwId;
  const auction = item?.auctionByAuctionId;
  const auctionId = item?.auctionId;
  const auctionName = String(auction?.name);

  // item details
  const images = item?.itemImagesByItemId?.nodes ?? [];
  const invId = item?.invId;
  const number = String(item?.number ?? '');
  const description = String(item?.description);
  const premium = auction?.premium;
  const tax = auction?.tax;
  const retailPrice = formatCentsToDollars(item?.retailPrice);
  const condition = item?.condition;
  const externalHyperlink = item?.externalHyperlink ?? '';
  const bwWonBidderId = item?.bwWonBidderId;
  const bwWonAmount = item?.bwWonAmount;
  const isTaxExempt = item?.isTaxExempt;
  const paidStatus = item?.paidStatus;
  const rackLocation = formatRackLocation(
    item?.itemRackLocationByItemId?.rackLocation,
  );
  const bin = formatBin(item?.itemRackLocationByItemId?.bin);

  // inventory api details
  const truckId = item?.truckId;
  const invDateCreated = item?.invDateCreated;
  const invDateScanned = item?.invDateScanned;
  const invCreatedByUserName = item?.invCreatedByUserName;
  const category = item?.category;
  const olasku = item?.olasku;

  const invoice = item?.invoiceByInvoiceId;
  const invoiceUid = invoice?.uid;
  const invoiceId = invoice?.id;

  const bidder = invoice?.bidderByBidderId;
  const bidderName = bidder?.name;
  const bidderId = bidder?.id;

  const openInvoice = useCallback(() => {
    if (invoiceId) {
      history.push(`/invoices/${invoiceId}`);
    }
  }, [invoiceId]);

  const openBidder = useCallback(() => {
    if (invoiceId) {
      history.push(`/customers/${bidderId}`);
    }
  }, [bidderId]);

  const openAuction = useCallback(() => {
    if (auctionId) {
      history.push(`/auctions/${auctionId}`);
    }
  }, [auctionId]);

  const openBwItem = useCallback(() => {
    if (bwItemId) {
      openInNewTab(
        `https://bid.onlineliquidationauction.com/admin/item/${bwItemId}`,
      );
    }
  }, [bwItemId]);

  const openMerItem = useCallback(() => {
    if (merId) {
      // TODO
    }
  }, [merId]);

  const openInvItem = useCallback(() => {
    if (invId) {
      openInNewTab(`https://olaapps.com/item-${invId}`);
    }
  }, [invId]);

  const openExternalHyperlink = useCallback(() => {
    if (externalHyperlink) {
      openInNewTab(externalHyperlink);
    }
  }, [externalHyperlink]);

  const buttons = useMemo(() => {
    if (merId) {
      return [
        {
          text: 'Mercury',
          onClick: openMerItem,
          disabled: !merId,
        },
        {
          text: 'Inventory',
          onClick: openInvItem,
          disabled: !invId,
        },
      ];
    }
    return [
      {
        text: 'BidWrangler',
        onClick: openBwItem,
        disabled: !bwItemId,
      },
      {
        text: 'Inventory',
        onClick: openInvItem,
        disabled: !invId,
      },
    ];
  }, [bwItemId, invId, merId]);

  return (
    <ViewScreen
      loading={loading}
      error={error}
      header={{
        dividerProps: {
          hide: true,
        },
        leftProps: {
          title: `Item ${itemId}`,
        },
        centerProps: {
          component: <ItemStatusChip status={status} />,
        },
        bottomProps: {
          buttons,
        },
      }}
    >
      <ContentHeader title="Details" size="large" />
      <LineItems description="Number" value={number} stacked />
      <LineItems description="Description" value={description} stacked />
      <LineItems description="Retail price" value={retailPrice} stacked />
      <LineItems description="Condition" value={condition} stacked />
      <Box pb={4} />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6}>
          <LineItems
            description="Payment status"
            value={<ItemPaidStatusChip status={paidStatus} />}
          />
          <LineItems
            description="Auction"
            value={<Hyperlink onClick={openAuction} text={auctionName ?? ''} />}
          />
          <LineItems
            description="Customer"
            value={<Hyperlink onClick={openBidder} text={bidderName ?? ''} />}
          />
          <LineItems
            description="Invoice"
            value={<Hyperlink onClick={openInvoice} text={invoiceUid ?? ''} />}
          />
          <LineItems description="Inventory ID" value={invId} />
          <LineItems description="Truck ID" value={truckId} />
          <LineItems
            description="Tax exempt"
            value={
              <Switch
                size="small"
                checked={!!isTaxExempt}
                onChange={onSubmitItemChange}
              />
            }
          />
          <LineItems
            description="External hyperlink"
            value={
              externalHyperlink ? (
                <Hyperlink
                  onClick={openExternalHyperlink}
                  text={externalHyperlink}
                />
              ) : (
                '-'
              )
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <LineItems description="Premium" value={formatPercentage(premium)} />
          <LineItems description="Tax" value={formatPercentage(tax)} />
          <LineItems description="BW Customer ID" value={bwWonBidderId} />
          <LineItems
            description="Bid Amount"
            value={formatCentsToDollars(bwWonAmount)}
          />
          <LineItems description="Rack" value={rackLocation} />
          <LineItems description="Bin" value={bin} />
        </Grid>
      </Grid>
      <Box paddingTop={10} />
      <ContentHeader title="Images" divider size="large" />
      <ImagesList images={images} />
      <Box paddingTop={10} />
      <ContentHeader title="Inventory" divider size="large" />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6}>
          <LineItems description="Category" value={category} />
          <LineItems description="OLA SKU" value={olasku} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <LineItems
            description="Created"
            value={`By ${invCreatedByUserName} at ${formatDateTime(
              invDateCreated,
            )}`}
          />
          <LineItems
            description="Scanned"
            value={formatDateTime(invDateScanned)}
          />
        </Grid>
      </Grid>
    </ViewScreen>
  );
};

export default ItemViewScreen;
