import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import TextField from 'components/Forms/TextField';
import { makeValidate } from 'components/Forms/Validation';
import { errorNotification } from 'components/SnackBar';
import text from 'constants/text';
import { useLoginByUsernameAndPassword } from 'hooks/auth';
import React, { FunctionComponent } from 'react';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#224D8F',
    flexGrow: 1,
    minHeight: '100vh',
  },
  container: {
    minHeight: '100vh',
  },
  textBox: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(6),
    margin: 'auto',
    maxWidth: '460px',
    minWidth: '460px',
  },
  buttonSignIn: {
    marginTop: theme.spacing(2),
  },
}));

const Schema = Yup.object({
  username: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});
const validate = makeValidate(Schema);

export const formFields: any[] = [
  <TextField
    key="username"
    autoFocus
    required
    variant="outlined"
    label="Username"
    id="Username"
    type="text"
    name="username"
    autoComplete="username"
  />,
  <TextField
    required
    key="password"
    variant="outlined"
    label="Password"
    id="password"
    type="password"
    name="password"
    autoComplete="current-password"
  />,
];

const SignInScreen: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { loginByUsernameAndPassword } = useLoginByUsernameAndPassword();

  const onSubmit = async ({ username, password }: Asserts<typeof Schema>) => {
    try {
      const { error } = await loginByUsernameAndPassword(username, password);
      if (error) {
        errorNotification('Log in failed');
        return { password: text.passwordIncorrect };
      }
      history.push('/');
    } catch (e) {
      errorNotification('Log in failed');
    }
    return {};
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={7}>
              <Paper className={classes.paper} variant="outlined">
                <Form
                  onSubmit={onSubmit}
                  validate={validate}
                  render={(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <Grid container direction="column">
                        <Box className={classes.textBox}>
                          <Typography variant="h5">Sign in</Typography>
                          <Typography>to continue to Paperless</Typography>
                        </Box>
                        {formFields.map((item, idx) => (
                          <Grid item key={idx}>
                            {item}
                          </Grid>
                        ))}
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={
                            props.submitting ||
                            props.pristine ||
                            props.hasValidationErrors ||
                            (props.hasSubmitErrors &&
                              !props.modifiedSinceLastSubmit)
                          }
                          className={classes.buttonSignIn}
                        >
                          Sign In
                        </Button>
                      </Grid>
                    </form>
                  )}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default SignInScreen;
