import { Box, Grid, Toolbar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ConfirmAlert from 'components/Alerts/ConfirmAlert';
import Button from 'components/Button';
import { ItemStatusChip } from 'components/Chips';
import NeutralChip from 'components/Chips/NeutralChip';
import { formatBin, formatRackLocation } from 'components/ItemHistoryItem';
import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import { SimpleTable } from 'components/Table';
import {
  AllInvoiceItemsEntryFragment,
  GetInvoiceByIdEntryFragment,
  useAllInvoiceItemsQuery,
  useChargeInvoicePaymentMutation,
  useUpdateItemByIdMutation,
} from 'graphql/graphql-types';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { logError } from 'utils/logging';
import { formatCentsToDollars } from 'utils/money';
import AddAdjustmentModal from './AddAdjustmentModal';
import AskDeleteItemModal from './AskDeleteItemModal';
import InvoiceAdjustmentsTable from './InvoiceAdjustmentsTable';
import ItemDetailsPanel from './ItemDetailsPanel';

type Row = {
  row: AllInvoiceItemsEntryFragment;
};

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    borderBottom: '1px solid #e0e0e0',
    borderTop: '1px solid #e0e0e0',
    maxHeight: '80vh',
    overflow: 'auto',
  },
  gridItemTable: {
    borderRight: '1px solid #e0e0e0',
    flex: '1 1 60%',
    overflow: 'auto',
  },
  gridItemDetails: {
    flex: '1 1 40%',
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  warning: {
    color: theme.palette.warning.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  deletedRow: {
    opacity: 0.6,
    '&:hover': {
      opacity: 1,
    },
  },
  tableContainer: {
    height: '50vh',
    width: '100%',
  },
  buttonFee: { marginRight: theme.spacing(1) },
  toolbarContainer: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  toolbarTitle: {
    flex: '1 1 100%',
  },
  chipStatus: {
    backgroundColor: '#cbf4c9',
    borderRadius: '4px',
    color: '#0e6245',
    fontWeight: 500,
    marginLeft: theme.spacing(1),
  },
}));

const columns = [
  {
    label: '#',
    name: 'number',
    width: 1,
  },
  { label: 'Description', name: 'description' },
  {
    label: 'Retail',
    name: 'retailPrice',
    render: ({ row }: Row) => formatCentsToDollars(row.retailPrice),
  },
  { label: 'Condition', name: 'condition' },
  {
    label: 'Rack',
    name: 'rackLocation',
    render: ({ row }: Row) =>
      formatRackLocation(row.itemRackLocationByItemId?.rackLocation),
  },
  {
    label: 'Bin',
    name: 'bin',
    render: ({ row }: Row) => formatBin(row.itemRackLocationByItemId?.bin),
  },
  {
    label: 'Returns',
    name: 'returns',
    align: 'center' as const,
    render: ({ row }: Row) =>
      row?.invoiceItemReturnsByInvoiceItemId?.totalCount > 0 ? (
        <NeutralChip
          text={String(row?.invoiceItemReturnsByInvoiceItemId?.totalCount)}
        />
      ) : null,
  },
  {
    label: 'Status',
    name: 'overallPickupStatus',
    align: 'center' as const,
    render: ({ row }: Row) => (
      <ItemStatusChip status={row.overallPickupStatus} />
    ),
  },
  {
    label: 'Bid',
    name: 'bidAmount',
    align: 'right' as const,
    render: ({ row }: Row) => formatCentsToDollars(row.bidAmount),
  },
  {
    label: 'Total',
    name: 'totalAmount',
    align: 'right' as const,
    render: ({ row }: Row) => formatCentsToDollars(row.totalAmount),
  },
];

const INVOICE_AND_AUCTION = 'invoiceAndAuction';

type Props = {
  invoiceId: number;
  invoice: GetInvoiceByIdEntryFragment | undefined;
  refetchInvoice: () => Promise<any>;
  isMer: boolean;
};
const InvoiceItemsTable: FunctionComponent<Props> = ({
  invoiceId,
  invoice,
  refetchInvoice,
  isMer,
}) => {
  const classes = useStyles();
  const [deleteItemId, setDeleteItemId] = useState<string | null>(null);
  const [isOpenAddAdjustment, setIsOpenAddAdjustment] = useState(false);
  const [itemDetailsItemId, setItemDetailsItemId] = useState<null | number>(
    null,
  );
  const [updateItem] = useUpdateItemByIdMutation();
  const [chargePayment] = useChargeInvoicePaymentMutation();
  const [processPaymentConfirmIsOpen, setProcessPaymentConfirmIsOpen] =
    useState(false);

  const condition = {
    invoiceId: {
      equalTo: invoiceId,
    },
    isDeleted: {
      equalTo: false,
    },
    isDeletedFromInvoice: {
      equalTo: false,
    },
  };

  const { data, refetch: refetchItems } = useAllInvoiceItemsQuery({
    variables: { filter: condition },
  });

  const items = data?.allItems?.nodes ?? [];

  useEffect(() => {
    if (items.length && !itemDetailsItemId && items?.[0]) {
      setItemDetailsItemId(items?.[0]?.id);
    }
  }, [items, itemDetailsItemId]);

  const handleDeleteItem = async (
    itemId: string,
    isDeleted: boolean,
    reason = '',
    deleteType = null,
  ) => {
    await updateItem({
      variables: {
        id: Number(itemId),
        values: {
          isDeleted: deleteType === INVOICE_AND_AUCTION,
          isDeletedFromInvoice: isDeleted,
          deletedFromInvoiceReason: reason,
        },
      },
    });
    await refetchInvoice();
  };

  const handleChargePayment = useCallback(async () => {
    const snackId = startSnackbar('Starting payment process');
    try {
      await chargePayment({
        variables: {
          invoiceId,
        },
        // optimisticResponse: {
        //   __typename: 'Mutation',
        //   chargeInvoicePayment: {
        //     invoice: {
        //       id: invoiceId,
        //       isProcessingPayment: true,
        //     },
        //   },
        // },
      });
      await refetchInvoice();
      stopSnackbar('Queued payment process', snackId);
    } catch (e: any) {
      logError(e);
      stopSnackbar(`Failed to charge payment: ${e.message}`, snackId);
    }
  }, [invoiceId]);

  const onAgreeDeleteItem = useCallback(
    async (values) => {
      if (deleteItemId) {
        const snackId = startSnackbar('Deleting item');
        try {
          await handleDeleteItem(
            deleteItemId,
            true,
            values.reason,
            values.deleteType,
          );
          stopSnackbar('Item deleted', snackId);
        } catch (e: any) {
          logError(e);
          stopSnackbar(`Failed to delete item: ${e.message}`, snackId);
        }
      }
      setDeleteItemId(null);
    },
    [deleteItemId],
  );

  const onDeleteItemComplete = useCallback(() => {
    refetchInvoice();
    refetchItems();
    setItemDetailsItemId(null);
  }, [refetchInvoice, refetchItems]);

  const refreshInvoice = useCallback(() => {
    refetchInvoice();
  }, [refetchInvoice]);

  const onDiscardDeleteItem = useCallback(() => {
    setDeleteItemId(null);
  }, [deleteItemId]);

  const handleCloseAdjustmentModal = useCallback(() => {
    setIsOpenAddAdjustment(false);
  }, []);

  return (
    <>
      <Toolbar className={classes.toolbarContainer}>
        <Grid item container alignItems="center">
          <Grid item>
            <Typography
              className={classes.toolbarTitle}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {`Amount Due: ${formatCentsToDollars(
                Number(invoice?.amountDue),
              )}`}
            </Typography>
          </Grid>
        </Grid>
        {!isMer && (
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Grid item>
              <Button
                onClick={() => setIsOpenAddAdjustment(true)}
                text="Add fee"
              />
            </Grid>
            <Grid item>
              <Button
                disabled={
                  !invoice?.processPayment ||
                  Number(invoice?.amountDue) === 0 ||
                  invoice?.isProcessingPayment ||
                  !invoice?.canProceedPayment
                }
                loading={invoice?.isProcessingPayment}
                onClick={() => setProcessPaymentConfirmIsOpen(true)}
                text="Charge Payment"
              />
            </Grid>
          </Grid>
        )}
      </Toolbar>
      <Box display="flex" className={classes.gridContainer}>
        <Box className={classes.gridItemTable}>
          <SimpleTable
            columns={columns}
            rows={items}
            emptyMessage="No items"
            onRowClick={(row) => setItemDetailsItemId(row.id)}
          />
          <InvoiceAdjustmentsTable invoice={invoice} />
        </Box>
        <Box className={classes.gridItemDetails}>
          <ItemDetailsPanel
            itemId={itemDetailsItemId}
            onDeleteItemComplete={onDeleteItemComplete}
            refreshInvoice={refreshInvoice}
            isMer={isMer}
          />
        </Box>
      </Box>
      <AskDeleteItemModal
        isOpen={!!deleteItemId}
        onAgree={onAgreeDeleteItem}
        onDiscard={onDiscardDeleteItem}
      />
      <AddAdjustmentModal
        invoiceId={invoiceId}
        isOpen={isOpenAddAdjustment}
        onClose={handleCloseAdjustmentModal}
        refetchInvoice={refetchInvoice}
      />
      <ConfirmAlert
        isOpen={processPaymentConfirmIsOpen}
        title="Charge Invoice Payment"
        description="This will attempt to charge the customer for this invoice. Are you sure you want to do this?"
        onAgree={() => {
          handleChargePayment();
          setProcessPaymentConfirmIsOpen(false);
        }}
        onDiscard={() => setProcessPaymentConfirmIsOpen(false)}
        agreeText="Yes"
        disagreeText="Cancel"
      />
    </>
  );
};

export default InvoiceItemsTable;
