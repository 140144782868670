import React, { FunctionComponent } from 'react';
import { MenuItem } from '@mui/material';
import { Select as MUISelectField, SelectProps } from './Select';

type Item = {
  value: any;
  label: any;
};

type Props = {
  items: Item[];
} & SelectProps;

const SelectField: FunctionComponent<Props> = ({ items, ...props }) => (
  <MUISelectField {...props}>
    {items.map(({ value, label }, i) => (
      <MenuItem key={i} value={value}>
        {label}
      </MenuItem>
    ))}
  </MUISelectField>
);

export default SelectField;
