import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';
import ConfirmAlert from 'components/Alerts/ConfirmAlert';
import ListScreen from 'components/ListScreen';
import { SortTypes } from 'components/Table';
import {
  AuctionPendingChangesEntryFragment,
  AuctionsPendingChangesDocument,
  TotalAuctionChangesDocument,
  useDeleteAuctionPushByAuctionIdMutation,
} from 'graphql/graphql-types';
import { useSelectedValueModal } from 'hooks/modal';
import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

type Row = {
  row: AuctionPendingChangesEntryFragment;
};

const AuctionPendingChangesListScreen: FunctionComponent = () => {
  const history = useHistory();
  const { selectedValue, open, close } = useSelectedValueModal();
  const [commitDelete] = useDeleteAuctionPushByAuctionIdMutation({
    refetchQueries: [
      AuctionsPendingChangesDocument,
      TotalAuctionChangesDocument,
    ],
  });

  const deleteEntry = useCallback(() => {
    if (selectedValue) {
      commitDelete({
        variables: { input: { auctionId: Number(selectedValue) } },
      });
    }
    close();
  }, [selectedValue]);

  return (
    <>
      <ListScreen
        title="Auction Changes"
        query={AuctionsPendingChangesDocument}
        defaultOrder="endAt"
        defaultSort={SortTypes.DESC}
        rowsPerPage={25}
        rowsPerPageOptions={[{ label: '25', value: 25 }]}
        columns={[
          {
            label: 'Name',
            name: 'name',
            onClick: (row: Row['row']) => history.push(`/auctions/${row.id}`),
          },
          {
            label: 'Queued',
            name: 'items',
            type: 'integer',
            render: ({ row }: Row) => row.countQueuedItemsForPush,
            width: 100,
            disableFilter: true,
          },
          {
            label: 'Processing',
            name: 'processing',
            type: 'integer',
            render: ({ row }: Row) => row.countProcessingItemsForPush,
            width: 100,
            disableFilter: true,
          },
          {
            label: '',
            name: 'actions',
            disableFilter: true,
            width: '5%',
            render: ({ row }: Row) => (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    open(Number(row.id));
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            ),
          },
        ]}
      />
      <ConfirmAlert
        isOpen={!!selectedValue}
        title="Delete Auction Changes"
        description="All pending auction changes will be deleted for this selected auction? Are you sure you want to do this?"
        onAgree={deleteEntry}
        onDiscard={close}
        agreeText="Yes"
        disagreeText="Cancel"
      />
    </>
  );
};

export default AuctionPendingChangesListScreen;
