import { ItemStatusChip } from 'components/Chips';
import ListScreen from 'components/ListScreen';
import { SortTypes } from 'components/Table';
import {
  AllItemsDocument,
  AllItemsEntryFragment,
  useAllAuctionsQuery,
  useAllBiddersQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { formatCentsToDollars } from 'utils/money';
import { ITEM_OVERALL_PICKUP_STATUS_FILTER_ELEMENTS } from './filters';

type Row = {
  row: AllItemsEntryFragment;
};

const ItemListScreen: FunctionComponent = () => {
  const history = useHistory();

  return (
    <ListScreen
      title="Items"
      query={AllItemsDocument}
      onRowClick={({ id }: Row['row']) => history.push(`/items/${id}`)}
      defaultOrder="id"
      defaultSort={SortTypes.DESC}
      columns={[
        { label: 'Number', name: 'number', type: 'integer', width: 1 },
        {
          label: 'Description',
          name: 'description',
          width: '200px',
        },
        {
          type: 'autocomplete',
          label: 'Auction',
          name: 'auctionByAuctionId.name',
          width: 200,
          makeFilterQuery: (value) => ({
            auctionByAuctionId: {
              name: {
                includesInsensitive: value,
              },
            },
          }),
          useQuery: useAllAuctionsQuery,
          getOptionLabel: (item) => item.name,
          mapValueToFilter: (value) => ({
            name: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllAuctionsQuery>) =>
            result.data?.allAuctions?.nodes ?? [],
        },
        {
          type: 'autocomplete',
          label: 'Customer',
          name: 'invoiceByInvoiceId.bidderByBidderId.name',
          width: 200,
          makeFilterQuery: (value) => ({
            invoiceByInvoiceId: {
              bidderByBidderId: {
                name: {
                  includesInsensitive: value,
                },
              },
            },
          }),
          useQuery: useAllBiddersQuery,
          getOptionLabel: (item) => item.name,
          mapValueToFilter: (value) => ({
            name: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllBiddersQuery>) =>
            result.data?.allBidders?.nodes ?? [],
        },
        {
          label: 'Rack',
          name: 'itemRackLocationByItemId.rackLocation',
          makeFilterQuery: (value) => ({
            itemRackLocationByItemId: {
              rackLocation: {
                includesInsensitive: value,
              },
            },
          }),
          width: 150,
        },
        {
          label: 'Bin',
          name: 'itemRackLocationByItemId.bin',
          makeFilterQuery: (value) => ({
            itemRackLocationByItemId: {
              bin: {
                includesInsensitive: value,
              },
            },
          }),
          width: 125,
        },
        {
          label: 'Bid',
          name: 'Bid',
          type: 'integer',
          disableFilter: true,
          render: ({ row }: Row) => formatCentsToDollars(row.bidAmount),
        },
        {
          label: 'Status',
          name: 'status',
          type: 'select',
          filterElements: ITEM_OVERALL_PICKUP_STATUS_FILTER_ELEMENTS,
          makeFilterQuery: (value) => ({
            overallPickupStatus: {
              equalTo: value,
            },
          }),
          render: ({ row }: Row) => (
            <ItemStatusChip status={row.overallPickupStatus} />
          ),
        },
      ]}
    />
  );
};

export default ItemListScreen;
