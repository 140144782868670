import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import TextField from 'components/Forms/TextField';
import { makeValidate } from 'components/Forms/Validation';
import { errorNotification } from 'components/SnackBar';
import { useDownloadPaperlessAppMutation } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { Form } from 'react-final-form';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#224D8F',
    flexGrow: 1,
    minHeight: '100vh',
  },
  container: {
    minHeight: '100vh',
  },
  textBox: {
    marginBottom: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(6),
    margin: 'auto',
    // maxWidth: 380,
    // minWidth: 380,
  },
  buttonSignIn: {
    marginTop: theme.spacing(2),
  },
}));

const Schema = Yup.object({
  password: Yup.string().required('Required'),
});
const validate = makeValidate(Schema);

const DownloadPaperlessAppScreen: FunctionComponent = () => {
  const classes = useStyles();
  const [download] = useDownloadPaperlessAppMutation();

  const onSubmit = async ({ password }: Asserts<typeof Schema>) => {
    try {
      const result = await download({
        variables: { input: { password } },
      });
      const data = result?.data?.downloadPaperlessApp;
      const url = data?.downloadUrl;
      const version = data?.version ?? 'latest';
      if (url) {
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', `PaperlessApp-${version}.apk`);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      } else {
        errorNotification('Failed');
      }
    } catch (e) {
      errorNotification('Failed');
    }
    return {};
  };

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.container}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={7}>
              <Paper className={classes.paper} variant="outlined">
                <Form
                  onSubmit={onSubmit}
                  validate={validate}
                  render={(props) => (
                    <form onSubmit={props.handleSubmit}>
                      <Grid container direction="column">
                        <Box className={classes.textBox}>
                          <Typography variant="h5">Enter password</Typography>
                          <Typography>to download Paperless</Typography>
                        </Box>
                        <TextField
                          required
                          key="password"
                          variant="outlined"
                          label="Password"
                          id="password"
                          type="password"
                          name="password"
                        />
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={
                            props.submitting ||
                            props.pristine ||
                            props.hasValidationErrors ||
                            (props.hasSubmitErrors &&
                              !props.modifiedSinceLastSubmit)
                          }
                          className={classes.buttonSignIn}
                        >
                          Download
                        </Button>
                      </Grid>
                    </form>
                  )}
                />
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default DownloadPaperlessAppScreen;
