import ListScreen from 'components/ListScreen';
import { SortTypes } from 'components/Table';
import {
  AllBiddersDocument,
  BidderEntryFragment,
  useAllBiddersQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

type Row = {
  row: BidderEntryFragment;
};

const CustomerListScreen: FunctionComponent = () => {
  const history = useHistory();

  return (
    <ListScreen
      title="Customers"
      query={AllBiddersDocument}
      displayEmptyRows={false}
      onRowClick={(row: Row['row']) => {
        history.push(`/customers/${row.id}`);
      }}
      defaultOrder="id"
      defaultSort={SortTypes.DESC}
      columns={[
        { label: 'Id', name: 'id', type: 'integer', width: 1 },
        { label: 'First Name', name: 'firstName' },
        { label: 'Last Name', name: 'lastName' },
        {
          type: 'autocomplete',
          label: 'Email',
          name: 'email',
          makeFilterQuery: (value) => ({
            email: {
              includesInsensitive: value,
            },
          }),
          useQuery: useAllBiddersQuery,
          getOptionLabel: (item) => item.email,
          mapValueToFilter: (value) => ({
            email: { includesInsensitive: value },
          }),
          getOptions: (result: ReturnType<typeof useAllBiddersQuery>) =>
            result.data?.allBidders?.nodes ?? [],
        },
        {
          label: 'Phone Number',
          name: 'phoneNumber',
          render: ({ row }: Row) => row.phoneNumber ?? row.mobileNumber ?? '',
        },
      ]}
    />
  );
};

export default CustomerListScreen;
