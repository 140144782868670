import Condition from 'components/Forms/Condition';
import SelectField from 'components/Forms/SelectField';
import TextField from 'components/Forms/TextField';
import { makeValidate } from 'components/Forms/Validation';
import Modal from 'components/Modal';
import ModalActions from 'components/ModalActions';
import ModalLoading from 'components/ModalLoading';
import { generalNotification } from 'components/SnackBar';
import {
  useCreateAdministratorMutation,
  useCreateEmployeeMutation,
  useCreateSecretaryMutation,
  useGetUserLazyQuery,
  UserEntryFragmentDoc,
  UserRoles,
  useUpdateUserMutation,
} from 'graphql/graphql-types';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Form } from 'react-final-form';
import {
  getCodeFromError,
  mapEnumToArray,
  mapEnumToSelectOptions,
} from 'utils/graphql';
import { logError } from 'utils/logging';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const ROLES = mapEnumToArray(UserRoles).filter((r) => r !== UserRoles.Customer);
const SELECT_ROLES = mapEnumToSelectOptions(UserRoles).filter(
  (r) => r.value !== UserRoles.Customer,
);

const Schema = Yup.object({
  type: Yup.string().default('create').required(),
  username: Yup.string().required('Required'),
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  role: Yup.string().oneOf(ROLES).required('Required'),
  password: Yup.string().when(['role', 'type'], {
    is: (role: UserRoles, type: Props['type']) =>
      type === 'create' &&
      (role === UserRoles.Administrator || role === UserRoles.Secretary),
    then: Yup.string().required('Required').min(8),
    otherwise: Yup.string().min(8).notRequired().nullable(true),
  }),
  pin: Yup.string().when(['role', 'type'], {
    is: (role: UserRoles, type: Props['type']) =>
      type === 'create' && role === UserRoles.Employee,
    then: Yup.string().required('Required').min(4),
    otherwise: Yup.string().min(4).notRequired().nullable(true),
  }),
});

type UserSchema = Asserts<typeof Schema>;
const validate = makeValidate(Schema);

type Props = {
  visible: boolean;
  type: 'create' | 'update';
  userId?: number;
  handleClose: () => void;
  handleSuccess: () => void;
};
const UserForm: FunctionComponent<Props> = ({
  type,
  visible,
  userId,
  handleClose,
  handleSuccess,
}) => {
  const [ready, setReady] = useState(false);
  const [initialValues, setInitialValues] = useState<UserSchema>();
  const [get, { data }] = useGetUserLazyQuery();
  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    if (visible && type === 'create') {
      setReady(true);
    }
    if (visible && type === 'update' && userId) {
      get({
        variables: {
          id: userId,
        },
      });
    }
  }, [visible, type, userId]);

  useEffect(() => {
    if (data?.userById) {
      const user = data.userById;
      setInitialValues({
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        password: undefined,
        pin: undefined,
        type: 'update',
      });
      setReady(true);
    }
  }, [data]);

  const [createSecretary] = useCreateSecretaryMutation({
    update(cache, { data: userData }) {
      cache.modify({
        fields: {
          allUsers(existing = []) {
            const newRef = cache.writeFragment({
              data: userData?.createSecretary?.user,
              fragment: UserEntryFragmentDoc,
            });
            return { ...existing, nodes: [...existing.nodes, newRef] };
          },
        },
      });
    },
  });

  const [createAdministrator] = useCreateAdministratorMutation({
    update(cache, { data: userData }) {
      cache.modify({
        fields: {
          allUsers(existing = []) {
            const newRef = cache.writeFragment({
              data: userData?.createAdministrator?.user,
              fragment: UserEntryFragmentDoc,
            });
            return { ...existing, nodes: [...existing.nodes, newRef] };
          },
        },
      });
    },
  });

  const [createEmployee] = useCreateEmployeeMutation({
    update(cache, { data: userData }) {
      cache.modify({
        fields: {
          allUsers(existing = []) {
            const newRef = cache.writeFragment({
              data: userData?.createEmployee?.user,
              fragment: UserEntryFragmentDoc,
            });
            return { ...existing, nodes: [...existing.nodes, newRef] };
          },
        },
      });
    },
  });

  const onSubmit = useCallback(
    async (values: UserSchema) => {
      try {
        if (type === 'update' && userId) {
          await updateUser({
            variables: {
              input: {
                userId,
                role: values.role as UserRoles,
                username: values.username,
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password,
                pin: values.pin,
              },
            },
          });
          handleSuccess();
          generalNotification('Updated user');
          return {};
        }
        if (type === 'create') {
          if (values.role === UserRoles.Administrator) {
            await createAdministrator({
              variables: {
                username: values.username,
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password ?? '',
                pinCode: values.pin,
              },
            });
            handleSuccess();
          }
          if (values.role === UserRoles.Secretary) {
            await createSecretary({
              variables: {
                username: values.username,
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password ?? '',
                pinCode: values.pin,
              },
            });
            handleSuccess();
          }
          if (values.role === UserRoles.Employee) {
            await createEmployee({
              variables: {
                username: values.username,
                firstName: values.firstName,
                lastName: values.lastName,
                pinCode: values.pin ?? '',
              },
            });
            handleSuccess();
          }
          generalNotification('Created user');
          return {};
        }
        return {};
      } catch (e: any) {
        logError(e);
        const errcode = getCodeFromError(e);

        if (errcode === 'NUUSE') {
          return { username: 'Username is already taken' };
        }
        if (errcode === 'NUPIN') {
          return {
            pin: 'Pin is already taken',
          };
        }
        if (errcode === 'WEAKP') {
          return {
            password: e.message,
          };
        }

        return {};
      }
    },
    [type, userId],
  );

  return (
    <Modal
      open={visible}
      title={type === 'create' ? 'New User' : 'Update User'}
      handleClose={handleClose}
      maxWidth="md"
    >
      {!ready && <ModalLoading />}
      {ready && (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          render={({ handleSubmit, values, ...props }) => (
            <form>
              <TextField
                required
                label="Username"
                id="username"
                name="username"
                variant="outlined"
              />
              <TextField
                required
                label="First name"
                id="firstName"
                name="firstName"
                variant="outlined"
              />
              <TextField
                required
                label="Last name"
                id="lastName"
                name="lastName"
                variant="outlined"
              />
              <SelectField
                required
                label="Role"
                id="role"
                name="role"
                items={SELECT_ROLES}
                variant="outlined"
              />
              <Condition
                when="role"
                is={(value) =>
                  value === UserRoles.Administrator ||
                  value === UserRoles.Secretary
                }
              >
                <TextField
                  required={
                    type === 'create' &&
                    (values.role === UserRoles.Administrator ||
                      values.role === UserRoles.Secretary)
                  }
                  label="Password"
                  id="password"
                  name="password"
                  variant="outlined"
                />
              </Condition>
              <TextField
                required={
                  type === 'create' && values.role === UserRoles.Employee
                }
                label="Pin"
                id="pin"
                name="pin"
                variant="outlined"
              />
              <ModalActions
                buttons={[
                  {
                    text: 'Cancel',
                    onClick: handleClose,
                    variant: 'text',
                  },
                  {
                    text: 'Save',
                    onClick: handleSubmit,
                    type: 'submit',
                    disabled:
                      props.submitting ||
                      props.pristine ||
                      props.hasValidationErrors ||
                      (props.hasSubmitErrors && !props.modifiedSinceLastSubmit),
                    variant: 'contained',
                    color: 'primary',
                  },
                ]}
              />
            </form>
          )}
        />
      )}
    </Modal>
  );
};

export default UserForm;
