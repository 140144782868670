import CreditCardIcon from '@mui/icons-material/CreditCard';
import {
  Avatar,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NeutralChip from 'components/Chips/NeutralChip';
import SuccessChip from 'components/Chips/SuccessChip';
import CircleLoading from 'components/CircleLoading';
import {
  TransactionType,
  useGetTransactionsByPaymentIdQuery,
} from 'graphql/graphql-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { formatCentsToDollars } from 'utils/money';
import { formatDateTime } from 'utils/time';

const useStyles = makeStyles((theme: Theme) => ({
  listTitle: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  listItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  chipStatus: {
    backgroundColor: '#cbf4c9',
    borderRadius: '4px',
    color: '#0e6245',
    fontWeight: 500,
    marginLeft: theme.spacing(1),
  },
  paymentAmount: {
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },
  paymentDate: {
    color: theme.palette.grey[600],
  },
}));

export default ({ paymentId }: { paymentId: number }) => {
  const classes = useStyles();
  const history = useHistory();
  const { loading, data, error } = useGetTransactionsByPaymentIdQuery({
    variables: { id: Number(paymentId) },
  });

  const transactions = data?.allTransactions?.nodes ?? [];

  return (
    <>
      {loading && <CircleLoading />}
      {!loading && data && !error && (
        <List>
          {transactions && transactions?.length > 0 ? (
            transactions.map((transaction, i) => {
              const type = transaction?.type;
              return (
                <ListItem
                  className={classes.listItem}
                  key={String(transaction?.id ?? i)}
                  button
                  onClick={() =>
                    history.push(`/transactions/${transaction?.id}`)
                  }
                >
                  <ListItemAvatar>
                    <Avatar variant="rounded">
                      <CreditCardIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <Box>
                    <Box display="flex" alignItems="center">
                      <Typography variant="subtitle1">
                        <Box
                          className={classes.paymentAmount}
                        >{`${formatCentsToDollars(
                          transaction?.amount,
                        )} USD`}</Box>
                      </Typography>
                      {type === TransactionType.Void && (
                        <NeutralChip text="Void" />
                      )}
                      {type === TransactionType.Charge && (
                        <SuccessChip label="Charge" />
                      )}
                      {type === TransactionType.Refund && (
                        <NeutralChip text="Refund" />
                      )}
                      {type === TransactionType.Credit && (
                        <NeutralChip text="Credit" />
                      )}
                    </Box>
                    <Box>
                      <Typography variant="body2">
                        <Box className={classes.paymentDate}>
                          {formatDateTime(transaction?.createdAt)}
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </ListItem>
              );
            })
          ) : (
            <Typography>No transactions</Typography>
          )}
        </List>
      )}
    </>
  );
};
