import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import ContentHeader from 'components/ContentHeader';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

function Item({
  title,
  description,
  onClick,
}: {
  title: string;
  description: string;
  onClick: () => void;
}) {
  return (
    <Card
      sx={{
        border: '0',
        borderLeft: '2px solid #ddd',
        borderRadius: '0',
        maxWidth: 345,
        '&:hover': { borderLeft: '2px solid #3f5672' },
      }}
      variant="outlined"
    >
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {title}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const ReportsScreen: FunctionComponent = () => {
  const history = useHistory();

  const goTax = () => history.push('/reports/tax');
  const goAuctionSummary = () => history.push('/reports/auctionSummary');
  const goReturns = () => history.push('/reports/returns');

  return (
    <Box>
      <Box>
        <ContentHeader title="Reports" divider={false} size="large" />
      </Box>
      <Item
        title="Tax"
        description="View total tax due on all transactions"
        onClick={goTax}
      />
      <Item
        title="Auction Summary"
        description="View breakdown of sales by auction"
        onClick={goAuctionSummary}
      />
      <Item
        title="Returns"
        description="View breakdown of item returns"
        onClick={goReturns}
      />
    </Box>
  );
};

export default ReportsScreen;
