import { ViewScreen } from 'components/ViewScreen';
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import AuctionForm from './AuctionForm';

const AuctionUpdateScreen: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <ViewScreen
      loading={false}
      header={{ leftProps: { title: 'Edit Auction' } }}
    >
      <AuctionForm auctionId={Number(id)} />
    </ViewScreen>
  );
};

export default AuctionUpdateScreen;
