import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import ContentHeader from 'components/ContentHeader';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

const SettingScreen: FunctionComponent = () => {
  const history = useHistory();

  const goUsers = () => history.push('/users');
  const goLocations = () => history.push('/locations');
  const goPrinters = () => history.push('/printers');
  const goReturns = () => history.push('/settings/returns');

  return (
    <Box>
      <Box>
        <ContentHeader
          title="Settings & Configuration"
          divider={false}
          size="large"
        />
      </Box>
      <Card
        sx={{
          border: '0',
          borderLeft: '2px solid #ddd',
          borderRadius: '0',
          maxWidth: 345,
          '&:hover': { borderLeft: '2px solid #3f5672' },
        }}
        variant="outlined"
      >
        <CardActionArea onClick={goUsers}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              Users
            </Typography>
            <Typography variant="body1" color="text.secondary">
              User & role management
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card
        sx={{
          border: '0',
          borderLeft: '2px solid #ddd',
          borderRadius: '0',
          maxWidth: 345,
          '&:hover': { borderLeft: '2px solid #3f5672' },
        }}
        variant="outlined"
      >
        <CardActionArea onClick={goLocations}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              Locations
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Location, premium, and tax settings
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card
        sx={{
          border: '0',
          borderLeft: '2px solid #ddd',
          borderRadius: '0',
          maxWidth: 345,
          '&:hover': { borderLeft: '2px solid #3f5672' },
        }}
        variant="outlined"
      >
        <CardActionArea onClick={goPrinters}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              Printers
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Printer settings
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card
        sx={{
          border: '0',
          borderLeft: '2px solid #ddd',
          borderRadius: '0',
          maxWidth: 345,
          '&:hover': { borderLeft: '2px solid #3f5672' },
        }}
        variant="outlined"
      >
        <CardActionArea onClick={goReturns}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              Returns
            </Typography>
            <Typography variant="body1" color="text.secondary">
              Configure options for item returns
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

export default SettingScreen;
