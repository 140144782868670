import { Box, Grid, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ConfirmAlert from 'components/Alerts/ConfirmAlert';
import Button from 'components/Button';
import Autocomplete from 'components/Forms/Autocomplete';
import DateTimePicker from 'components/Forms/DateTimePicker';
import FormError from 'components/Forms/FormError';
import TextField from 'components/Forms/TextField';
import { makeValidate } from 'components/Forms/Validation';
import ModalLoading from 'components/ModalLoading';
import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import {
  GetLocationsDocument,
  useGetAuctionByIdLazyQuery,
  useGetLastPickupDateConfigForAuctionLazyQuery,
  useUpdateAuctionMutation,
} from 'graphql/graphql-types';
import { useModal } from 'hooks/modal';
import moment from 'moment';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Form, useField, useForm } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { fillAuctionTemplate } from 'utils/general';
import { logError } from 'utils/logging';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: `${theme.spacing(0)} ${theme.spacing(0)}`,
  },
}));

const RequiredMin0NumberSchema = Yup.number()
  .typeError('Must be a number')
  .min(0)
  .required('Required');

const Schema = Yup.object({
  name: Yup.string().required('Required'),
  number: Yup.string().required('Required'),
  location: Yup.object({
    id: Yup.number().required('Required'),
    name: Yup.string().required('Required'),
    fullAddress: Yup.string().required('Required'),
  }),
  descriptionShort: Yup.string().required('Required'),
  descriptionFull: Yup.string().required('Required'),
  tagline: Yup.string(),
  premium: Yup.number().typeError('Must be a number').required('Required'),
  tax: Yup.number().typeError('Must be a number').required('Required'),
  startAt: Yup.string().required('Required'),
  endAt: Yup.string().required('Required'),
  pickupStartAt: Yup.string().required('Required'),
  pickupEndAt: Yup.string().required('Required'),
  templateDescriptionShort: Yup.string().required('Required'),
  templateDescriptionFull: Yup.string().required('Required'),
  lpudNumDays: RequiredMin0NumberSchema,
});

type AuctionSchema = Asserts<typeof Schema>;
const validate = makeValidate(Schema);

const WatchPickupEndDateAndLocation = () => {
  const [get] = useGetLastPickupDateConfigForAuctionLazyQuery();
  const form = useForm();
  const {
    input: { value: pickupEndAt },
    meta: { dirty: isPickupEndAtDirty, valid: isPickupEndAtValid },
  } = useField('pickupEndAt');
  const {
    input: { value: locationId },
    meta: { dirty: isLocationIdDirty, valid: isLocationIdValid },
  } = useField('location.id');

  useEffect(() => {
    const handle = async () => {
      try {
        const data = await get({
          variables: {
            // @ts-ignore
            timestamp: pickupEndAt?.toISOString?.(),
            locationId,
          },
        });
        if (data?.data?.getLastPickupDateConfigForAuction !== null) {
          form.change(
            'lpudNumDays',
            data?.data?.getLastPickupDateConfigForAuction,
          );
        }
      } catch (e) {
        logError(e);
      }
    };

    if (
      pickupEndAt &&
      locationId &&
      ((isPickupEndAtDirty && isPickupEndAtValid) ||
        (isLocationIdDirty && isLocationIdValid))
    ) {
      handle();
    }
  }, [
    pickupEndAt,
    locationId,
    isPickupEndAtDirty,
    isPickupEndAtValid,
    isLocationIdDirty,
    isLocationIdValid,
  ]);

  return null;
};

const createValuesObject = (values: AuctionSchema) => ({
  auctionName: values.name,
  auctionEnd: values.endAt,
  pickupStart: values.pickupStartAt,
  pickupEnd: values.pickupEndAt,
  pickupLocation: values.location.fullAddress,
  premium: String(values.premium),
  tax: String(values.tax),
});

type Props = {
  auctionId: number;
};
const AuctionForm: FunctionComponent<Props> = ({ auctionId }) => {
  const [ready, setReady] = useState(false);
  const { visible, open, close } = useModal();
  const [initialValues, setInitialValues] = useState<AuctionSchema>();
  const classes = useStyles();
  const history = useHistory();
  const [get, { data }] = useGetAuctionByIdLazyQuery();
  const [updateAuction] = useUpdateAuctionMutation();

  useEffect(() => {
    if (auctionId) {
      get({
        variables: {
          id: auctionId,
        },
      });
    }
  }, [auctionId]);

  useEffect(() => {
    if (data?.auctionById) {
      const auction = data?.auctionById;
      setInitialValues({
        name: auction.name,
        number: String(auction.number),
        location: {
          id: Number(auction.locationByLocationId?.id),
          name: String(auction.locationByLocationId?.name),
          fullAddress: String(auction.locationByLocationId?.fullAddress),
        },
        descriptionShort: auction.descriptionShort,
        descriptionFull: auction.descriptionFull,
        tagline: auction.tagline,
        premium: auction.premium,
        tax: auction.tax,
        // @ts-ignore
        startAt: moment(auction.startAt) ?? '',
        // @ts-ignore
        endAt: moment(auction.endAt) ?? '',
        // @ts-ignore
        pickupStartAt: moment(auction.pickupStartAt) ?? '',
        // @ts-ignore
        pickupEndAt: moment(auction.pickupEndAt) ?? '',
        templateDescriptionShort: String(
          auction.locationByLocationId?.shortDescription,
        ),
        templateDescriptionFull: String(
          auction.locationByLocationId?.fullDescription,
        ),
        lpudNumDays: auction.lpudNumDays,
      });
      setReady(true);
    }
  }, [data]);

  const onSubmit = useCallback(
    async (values: AuctionSchema) => {
      const snackId = startSnackbar('Updating auction');

      const newValues = {
        name: values.name,
        number: Number(values.number),
        locationId: values.location.id,
        descriptionShort: values.descriptionShort,
        descriptionFull: values.descriptionFull,
        tagline: String(values.tagline ?? ''),
        premium: values.premium,
        tax: values.tax,
        startAt: values.startAt,
        endAt: values.endAt,
        pickupStartAt: values.pickupStartAt,
        pickupEndAt: values.pickupEndAt,
        lpudNumDays: values.lpudNumDays,
      };

      try {
        await updateAuction({
          variables: {
            id: auctionId,
            values: newValues,
          },
        });
        stopSnackbar('Auction updated', snackId);
        history.push(`/auctions/${auctionId}`);
      } catch (e) {
        logError(e);
        stopSnackbar(`Auction failed to update ${e.message}`, snackId);
      }
    },
    [auctionId],
  );

  return (
    <Box>
      {!ready && <ModalLoading />}
      {ready && (
        <Form
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
          render={({
            handleSubmit,
            submitting,
            pristine,
            submitError,
            hasValidationErrors,
            initialValues: formInitialValues,
            form: { change },
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={10}>
                <Grid item xs={12} sm={7}>
                  <Paper className={classes.paper} elevation={0}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          required
                          label="Auction Number"
                          id="number"
                          name="number"
                          parseType="integer"
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Autocomplete
                          label="Location"
                          name="location"
                          required
                          query={GetLocationsDocument}
                          defaultValue={formInitialValues?.location}
                          getOptionValue={(option: any) => ({
                            id: option.id,
                            name: option.name,
                            fullAddress: option.fullAddress,
                          })}
                          getOptionLabel={(option: any) => option?.name}
                          isOptionEqualToValue={(option: any, value: any) =>
                            option?.id === value?.id
                          }
                        />
                      </Grid>
                    </Grid>
                    <TextField
                      required
                      label="Name"
                      id="name"
                      name="name"
                      variant="outlined"
                    />
                    <TextField
                      required
                      label="Short Description"
                      id="descriptionShort"
                      name="descriptionShort"
                      multiline
                      rows={4}
                      variant="outlined"
                    />
                    <TextField
                      required
                      label="Full Description"
                      id="descriptionFull"
                      name="descriptionFull"
                      multiline
                      rows={8}
                      variant="outlined"
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={5}>
                  <Paper className={classes.paper} elevation={0}>
                    <DateTimePicker
                      required
                      label="Auction Start Date"
                      name="startAt"
                    />
                    <DateTimePicker
                      required
                      label="Auction End Date"
                      name="endAt"
                    />
                    <DateTimePicker
                      required
                      label="Pickup Start Date"
                      name="pickupStartAt"
                    />
                    <DateTimePicker
                      required
                      label="Pickup End Date"
                      name="pickupEndAt"
                    />
                    <TextField
                      required
                      label="Hold items"
                      id="lpudNumDays"
                      name="lpudNumDays"
                      parseType="integer"
                      variant="outlined"
                      helperText="Hold items for [X] number of days after Pickup End Date"
                    />
                    <WatchPickupEndDateAndLocation />
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={7}>
                  <Paper className={classes.paper} elevation={0}>
                    <TextField
                      label="Tagline"
                      id="tagline"
                      name="tagline"
                      variant="outlined"
                    />
                    <TextField
                      required
                      label="Premium"
                      id="premium"
                      name="premium"
                      parseType="percentage"
                      variant="outlined"
                    />
                    <TextField
                      required
                      label="Tax"
                      id="tax"
                      name="tax"
                      parseType="percentage"
                      variant="outlined"
                    />
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={7}>
                  <FormError error={submitError} />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <Box display="flex" alignItems="center" flexDirection="row">
                    <Button
                      text="Save"
                      type="submit"
                      onClick={handleSubmit}
                      disabled={submitting || pristine || hasValidationErrors}
                    />
                    <Button
                      text="Populate Template Placeholders"
                      onClick={open}
                      disabled={submitting || pristine || hasValidationErrors}
                      style={{ marginLeft: 8 }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <ConfirmAlert
                isOpen={visible}
                title="Populate Template"
                description="This will populate the short and full description placeholders with values from this screen. Are you sure you want to do this?"
                onAgree={() => {
                  change(
                    'descriptionShort',
                    fillAuctionTemplate(
                      values.templateDescriptionShort,
                      createValuesObject(values),
                    ),
                  );
                  change(
                    'descriptionFull',
                    fillAuctionTemplate(
                      values.templateDescriptionFull,
                      createValuesObject(values),
                    ),
                  );
                  close();
                }}
                onDiscard={close}
                agreeText="Yes"
                disagreeText="Cancel"
              />
            </form>
          )}
        />
      )}
    </Box>
  );
};

export default AuctionForm;
