import React from 'react';
import { useNotCompletedPickupQueueByLocationLazyQuery } from 'graphql/graphql-types';

export const useNotCompletedPickupQueueByLocation = () => {
  const [isRunning, setIsRunning] = React.useState(false);
  const [fetch, { data, loading, error, refetch, stopPolling }] =
    useNotCompletedPickupQueueByLocationLazyQuery({
      pollInterval: 2 * 1000,
    });
  const handleStart = (locationId: number) => {
    setIsRunning(true);
    fetch({
      variables: {
        locationId,
      },
    });
  };
  const handleStop = () => {
    setIsRunning(false);
    stopPolling();
  };

  return {
    fetch,
    handleStart,
    isRunning,
    handleStop,
    loading,
    error,
    refetch,
    assigned: data?.pickupAssignedItems?.nodes ?? [],
    unassigned: data?.pickupUnassignedItems?.nodes ?? [],
  };
};
