import { Button, ClickAwayListener, Tooltip } from '@mui/material';
import React, { FunctionComponent, useState } from 'react';
import { useClipboard } from 'use-clipboard-copy';

type Props = {
  data: any;
};
const CopyTextButton: FunctionComponent<Props> = ({ data, children }) => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState<'Copy' | 'Copied'>('Copy');
  const clipboard = useClipboard();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (action: 'Copy' | 'Copied') => {
    setText(action);
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={!!open}
        disableFocusListener
        disableTouchListener
        title={text}
        onOpen={() => handleTooltipOpen('Copy')}
      >
        <Button
          size="small"
          onClick={() => {
            clipboard.copy(data);
            handleTooltipOpen('Copied');
          }}
          style={{
            padding: '0 0',
            textTransform: 'inherit',
            overflowWrap: 'anywhere',
            textAlign: 'left',
          }}
        >
          {children}
        </Button>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default CopyTextButton;
