import { DocumentNode, useQuery } from '@apollo/client';
import TextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import MuiAutocomplete, {
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteRenderInputParams as MuiAutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import debounce from 'debounce';
import React, { SyntheticEvent, ReactNode, useCallback, useRef } from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

export type AutocompleteData = {
  [key: string]: any | null;
};

interface AutocompleteWrapperProps
  extends FieldRenderProps<MuiTextFieldProps, HTMLElement> {
  label: ReactNode;
  required?: boolean;
  multiple?: boolean;
  textFieldProps?: Partial<MuiTextFieldProps>;
  getOptionValue?: (option: any) => any;
}

const AutocompleteWrapper = (props: AutocompleteWrapperProps) => {
  const {
    input: { name, onChange, value, ...restInput },
    meta: { submitError, dirtySinceLastSubmit, error, touched, modified },
    options,
    label,
    required,
    multiple,
    textFieldProps,
    getOptionValue,
    defaultValue,
    ...rest
  } = props;

  function getValue(values: any) {
    if (!getOptionValue) {
      return values;
    }

    // ternary hell...
    // eslint-disable-next-line no-nested-ternary
    return multiple
      ? values
        ? values.map(getOptionValue)
        : null
      : values
      ? getOptionValue(values)
      : null;
  }

  const { helperText, ...lessrest } = rest;
  const { variant, ...restTextFieldProps } = (textFieldProps as any) || {};

  const onChangeFunc = (
    _e: SyntheticEvent<Element, Event>,
    values: any | any[],
  ) => {
    onChange(getValue(values));
  };

  const isError = !!(
    ((submitError && !dirtySinceLastSubmit) || error) &&
    (touched || modified)
  );

  return (
    <MuiAutocomplete
      multiple={multiple}
      onChange={onChangeFunc}
      options={options}
      defaultValue={defaultValue}
      renderInput={(params: MuiAutocompleteRenderInputParams) => (
        <TextField
          label={label}
          required={required}
          // fullWidth
          error={isError}
          helperText={helperText}
          // variant={variant}
          variant="outlined"
          {...params}
          {...restInput}
          {...restTextFieldProps}
        />
      )}
      {...lessrest}
    />
  );
};

export interface AutocompleteProps
  extends Partial<Omit<MuiAutocompleteProps<any, any, any, any>, 'onChange'>> {
  name: string;
  label: ReactNode;
  helperText?: string;
  required?: boolean;
  multiple?: boolean;
  getOptionValue?: (option: any) => any;
  options: AutocompleteData[];
  fieldProps?: Partial<FieldProps<any, any>>;
  textFieldProps?: Partial<MuiTextFieldProps>;
  defaultValue?: any;
}

export const AutocompleteField = (props: AutocompleteProps) => {
  const { name, fieldProps, ...rest } = props;

  return (
    <Field
      name={name}
      render={(fieldRenderProps) => (
        <AutocompleteWrapper {...fieldRenderProps} {...rest} />
      )}
      {...fieldProps}
    />
  );
};

interface Props extends Omit<AutocompleteProps, 'options'> {
  query: DocumentNode;
  condition?: any;
  name: string;
  label: string;
}

const Autocomplete = ({ query, condition, defaultValue, ...props }: Props) => {
  const [value, setValue] = React.useState('');

  const { loading, data } = useQuery(query, {
    variables: { value, ...(condition !== undefined && condition) },
    fetchPolicy: 'cache-and-network',
  });

  const setValueDebounce = useRef(
    debounce((event: any, newInputValue: any, reason: string) => {
      if (reason === 'input') {
        setValue(newInputValue);
      }
    }, 200),
  );

  const handleInputChange = useCallback(
    (event: any, newInputValue: any, reason: string) => {
      setValueDebounce.current(event, newInputValue, reason);
    },
    [],
  );

  const options =
    data && Object.keys(data).length ? data[Object.keys(data)[0]].nodes : [];

  return (
    <AutocompleteField
      defaultValue={defaultValue}
      freeSolo={false}
      autoComplete={false}
      includeInputInList
      options={options}
      filterOptions={(x: any) => x}
      loading={loading}
      onInputChange={handleInputChange}
      {...props}
    />
  );
};

export default Autocomplete;
