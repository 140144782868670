import { Grid, Typography } from '@mui/material';
import ContentHeader from 'components/ContentHeader';
import Hyperlink from 'components/Hyperlink';
import LineItemProgress from 'components/LineItemProgress';
import LineItems from 'components/LineItems';
import { errorNotification, successNotification } from 'components/SnackBar';
import UploadEditImage from 'components/UploadEditImage';
import { ViewScreen } from 'components/ViewScreen';
import {
  useGetTruckloadQuery,
  useUploadTruckloadBillOfLadingMutation,
} from 'graphql/graphql-types';
import { useSelectedValueModal } from 'hooks/modal';
import React, { FunctionComponent, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { formatPercentage } from 'utils/general';
import { logError } from 'utils/logging';
import { formatCentsToDollars } from 'utils/money';
import { formatDateTime } from 'utils/time';
import TruckloadForm from './TruckloadForm';

const TruckloadViewScreen: FunctionComponent = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data, loading, error } = useGetTruckloadQuery({
    variables: {
      id,
    },
  });
  const truckload = data?.truckloadById;
  const truckloadReport = data?.getTruckloadData;
  const [uploadBOL] = useUploadTruckloadBillOfLadingMutation();
  const { selectedValue, open, close } = useSelectedValueModal<string>();

  const goLocation = useCallback(() => {
    if (truckload?.locationByReceivingLocation?.id) {
      history.push(`/locations/${truckload?.locationByReceivingLocation?.id}`);
    }
  }, [truckload?.locationByReceivingLocation]);

  const goUser = useCallback(() => {
    if (truckload?.userByCreatedBy?.id) {
      history.push(`/users/${truckload?.userByCreatedBy?.id}`);
    }
  }, [truckload?.userByCreatedBy]);

  const openEdit = useCallback(() => {
    if (id) {
      open(id);
    }
  }, [id]);

  const closEdit = () => close();

  const handleUpload = useCallback(
    async (image: Blob) => {
      try {
        await uploadBOL({
          variables: {
            input: {
              truckloadId: id,
              billOfLading: image,
            },
          },
        });
        successNotification('Uploaded BOL');
      } catch (err: any) {
        logError(err);
        errorNotification(err?.message ?? 'Failed to upload BOL');
      }
    },
    [id],
  );

  const name = truckload?.num ?? '';
  const loadIdentifier = truckload?.loadIdentifier;
  const facilityName = truckload?.facilityName;
  const receivingLocation = truckload?.locationByReceivingLocation?.name ?? '';
  const dateReceived = truckload?.dateReceived;
  const itemsOnManifest = truckload?.itemsOnManifest;
  const createdBy = truckload?.userByCreatedBy?.name ?? '';
  const createdAt = truckload?.createdAt;
  const bolImageUrl = truckload?.bolImageUrl;
  const itemsSold = truckloadReport?.countItemsSold ?? 0;
  const itemsNotSold = truckloadReport?.countItemsUnsold ?? 0;
  const itemsSoldPercent = formatPercentage(
    truckloadReport?.itemsSoldPercent ?? 0,
  );
  const itemsSoldPercent2 = Number(truckloadReport?.itemsSoldPercent ?? 0);
  const unsoldTotalRetailPrice = formatCentsToDollars(
    truckloadReport?.unsoldTotalRetailPrice,
  );
  const soldTotalRetailPrice = formatCentsToDollars(
    truckloadReport?.soldTotalRetailPrice,
  );
  const retailPercent = formatPercentage(truckloadReport?.retailPercent ?? 0);
  const returnRatePercent = formatPercentage(
    truckloadReport?.returnRatePercent ?? 0,
  );
  const totalReturnAmount = formatCentsToDollars(
    truckloadReport?.totalReturnAmount,
  );
  const inventoryItemCount = truckloadReport?.countInventoriedItems ?? 0;
  const totalSaleAmount = formatCentsToDollars(
    truckloadReport?.totalSaleAmount,
  );
  const retailPercent2 = Number(truckloadReport?.retailPercent ?? 0);
  const returnRatePercent2 = Number(truckloadReport?.returnRatePercent ?? 0);
  const itemsReturned = truckloadReport?.countItemsReturned ?? 0;

  return (
    <ViewScreen
      loading={loading}
      error={error}
      header={{
        dividerProps: {
          hide: true,
        },
        leftProps: {
          title: `Truckload ${name}`,
        },
        rightProps: {
          buttons: [
            {
              text: 'Edit',
              onClick: openEdit,
            },
          ],
        },
      }}
    >
      <Grid container spacing={4} style={{ paddingBottom: 40 }}>
        <Grid item xs={4}>
          <LineItemProgress
            description="Items sold"
            text={`${itemsSold} / ${inventoryItemCount}`}
            progress={itemsSoldPercent2}
          />
        </Grid>
        <Grid item xs={4}>
          <LineItemProgress
            description="Retail price"
            text={`${totalSaleAmount} / ${soldTotalRetailPrice}`}
            progress={retailPercent2}
          />
        </Grid>
        <Grid item xs={4}>
          <LineItemProgress
            description="Return rate"
            text={`${itemsReturned} / ${itemsSold}`}
            progress={returnRatePercent2}
          />
        </Grid>
      </Grid>
      <ContentHeader title="Details" size="large" />
      <Grid container spacing={4} style={{ paddingBottom: 40 }}>
        <Grid item xs={6}>
          <LineItems description="Number" value={name} />
          <LineItems description="Load Identifier" value={loadIdentifier} />
          <LineItems description="Facility Name" value={facilityName} />
          <LineItems description="Items on Manifest" value={itemsOnManifest} />
          <LineItems
            description="Receiving Location"
            value={<Hyperlink text={receivingLocation} onClick={goLocation} />}
          />
          <LineItems
            description="Date Received"
            value={formatDateTime(dateReceived)}
          />
          <LineItems
            description="Created By"
            value={<Hyperlink text={createdBy} onClick={goUser} />}
          />
          <LineItems description="Created" value={formatDateTime(createdAt)} />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ color: '#757575' }}>Bill of Lading</Typography>
          <UploadEditImage imageUrl={bolImageUrl} handleUpload={handleUpload} />
        </Grid>
      </Grid>
      <br />
      <ContentHeader title="Report" size="large" />
      <LineItems description="Items sold" value={itemsSold} />
      <LineItems description="Items not sold" value={itemsNotSold} />
      <LineItems description="Items sold percent" value={itemsSoldPercent} />
      <br />
      <LineItems
        description="Items sold retail price"
        value={soldTotalRetailPrice}
      />
      <LineItems
        description="Items not sold retail price"
        value={unsoldTotalRetailPrice}
      />
      <LineItems description="Retail price percent" value={retailPercent} />
      <br />
      <LineItems description="Return rate percent" value={returnRatePercent} />
      <LineItems description="Total return amount" value={totalReturnAmount} />
      <LineItems description="Items returned" value={itemsReturned} />
      <br />
      <LineItems
        description="Inventory item count"
        value={inventoryItemCount}
      />
      <LineItems description="Total sales" value={totalSaleAmount} />
      <TruckloadForm
        visible={!!selectedValue}
        type="update"
        truckloadId={id}
        handleClose={closEdit}
        handleSuccess={closEdit}
      />
    </ViewScreen>
  );
};

export default TruckloadViewScreen;
