import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import CircleLoading from 'components/CircleLoading';
import Hyperlink from 'components/Hyperlink';
import {
  useGetMyNotificationsQuery,
  UserNotificationChangedDocument,
  UserNotificationChangedSubscription,
  UserNotificationEventType,
  UserNotificationMetaTypeEnum,
  UserNotificationResponse,
} from 'graphql/graphql-types';
import {
  useClearAllNotifications,
  useClearNotification,
} from 'hooks/notification';
import React, { FunctionComponent, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { openInNewTab } from 'utils/general';
import { formatDateTime } from 'utils/time';

const NotCompletedText: FunctionComponent<{
  isCompleted: boolean;
  primary: string;
  secondary: string;
}> = ({ isCompleted, primary, secondary }) =>
  !isCompleted ? (
    <ListItemText
      style={{
        marginTop: 0,
      }}
      primary={primary}
      secondary={formatDateTime(secondary)}
    />
  ) : null;

const IsCompletedText: FunctionComponent<{
  type: UserNotificationMetaTypeEnum | null | undefined;
  isCompleted: boolean;
  message: string;
  downloadUrl?: string | null;
  updatedAt: string;
}> = ({ type, isCompleted, updatedAt, message, downloadUrl }) => {
  if (!isCompleted) {
    return null;
  }
  if (type === UserNotificationMetaTypeEnum.Download && downloadUrl) {
    return (
      <Box>
        <Hyperlink text={message} onClick={() => openInNewTab(downloadUrl)} />
        <ListItemText
          style={{
            marginTop: 0,
          }}
          secondary={formatDateTime(updatedAt)}
        />
      </Box>
    );
  }
  return (
    <ListItemText
      style={{
        marginTop: 0,
      }}
      primary={message}
      secondary={formatDateTime(updatedAt)}
    />
  );
};

type Props = {
  isVisible: boolean;
  onClose: () => void;
};
const NotificationDrawer: FunctionComponent<Props> = ({
  isVisible,
  onClose,
}) => {
  const history = useHistory();
  const { data, loading, subscribeToMore } = useGetMyNotificationsQuery();
  const { clearNotification } = useClearNotification();
  const { clearAllNotifications } = useClearAllNotifications();

  useLayoutEffect(() => {
    const unsubscribe = subscribeToMore<UserNotificationChangedSubscription>({
      document: UserNotificationChangedDocument,
      // @ts-ignore
      updateQuery: (prev, { subscriptionData }) => {
        const newItem =
          subscriptionData.data.UserNotificationEvent?.notification;
        const action = subscriptionData.data.UserNotificationEvent?.event;

        if (!newItem) {
          return prev;
        }

        let nodes = prev.allUserNotifications?.nodes ?? [];

        if (action === UserNotificationEventType.Added) {
          nodes = [{ ...newItem }, ...nodes];
        }
        if (action === UserNotificationEventType.Updated) {
          nodes = nodes.map((n) =>
            n.id === newItem.id ? { ...n, ...newItem } : n,
          );
        }
        if (action === UserNotificationEventType.Deleted) {
          nodes = nodes.filter((n) => n.id !== newItem.id);
        }

        return {
          ...prev,
          allUserNotifications: {
            ...prev.allUserNotifications,
            nodes,
          },
        };
      },
    });
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  const notifications = data?.allUserNotifications?.nodes ?? [];

  return (
    <Drawer
      open={isVisible}
      anchor="right"
      onClose={onClose}
      style={{
        width: 400,
      }}
    >
      <div style={{ width: 400 }}>
        {loading && <CircleLoading />}
        {!loading && (
          <List dense>
            <ListItem>
              <IconButton onClick={clearAllNotifications} size="large">
                <ClearAllIcon />
              </IconButton>
              <Typography marginLeft={1}>Notifications</Typography>
            </ListItem>
            <Divider />
            <br />
            {!notifications.length && (
              <ListItem>
                <ListItemText primary="All caught up" />
              </ListItem>
            )}
            {notifications.map((notification) => (
              <ListItem key={String(notification.id)}>
                <ListItemIcon>
                  {!notification.isCompleted && <CircleLoading />}
                  {notification.isCompleted &&
                    notification.result === UserNotificationResponse.Ok && (
                      <CheckCircleOutlineIcon />
                    )}
                  {notification.isCompleted &&
                    notification.result === UserNotificationResponse.Error && (
                      <ErrorOutlineIcon />
                    )}
                </ListItemIcon>
                <Box>
                  {notification?.meta?.text && (
                    <Hyperlink
                      text={notification?.meta?.text}
                      onClick={() => {
                        const id = notification?.meta?.id;
                        const type = notification.meta?.type;
                        if (type === UserNotificationMetaTypeEnum.Auction) {
                          return history.push(`/auctions/${id}`);
                        }
                        if (type === UserNotificationMetaTypeEnum.Invoice) {
                          return history.push(`/invoices/${id}`);
                        }
                        if (type === UserNotificationMetaTypeEnum.Customer) {
                          return history.push(`/customers/${id}`);
                        }
                        if (type === UserNotificationMetaTypeEnum.Payment) {
                          return history.push(`/payments/${id}`);
                        }
                        if (
                          type ===
                          UserNotificationMetaTypeEnum.ReturnFromInvoice
                        ) {
                          return history.push(`/invoices/${id}`);
                        }
                        if (
                          type === UserNotificationMetaTypeEnum.ReturnFromQueue
                        ) {
                          return history.push(`/returnQueue/${id}`);
                        }
                        if (
                          type === UserNotificationMetaTypeEnum.Download &&
                          notification.isCompleted &&
                          notification?.after?.url
                        ) {
                          return openInNewTab(notification.after.url);
                        }
                        return null;
                      }}
                    />
                  )}
                  {!notification?.meta?.text && (
                    <Typography variant="subtitle2">General Action</Typography>
                  )}
                  <IsCompletedText
                    type={notification?.meta?.type}
                    message={notification.after?.message ?? ''}
                    downloadUrl={notification?.after?.url}
                    isCompleted={notification.isCompleted}
                    updatedAt={notification.updatedAt}
                  />
                  <NotCompletedText
                    isCompleted={notification.isCompleted}
                    primary={notification.before?.message ?? ''}
                    secondary={formatDateTime(notification.createdAt)}
                  />
                </Box>
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    disabled={notification.isCleared}
                    onClick={() => clearNotification(notification.id)}
                    size="large"
                  >
                    <ClearIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        )}
      </div>
    </Drawer>
  );
};

export default NotificationDrawer;
