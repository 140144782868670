import ReportPeriodSelect, { Option } from 'components/ReportPeriodSelect';
import ReportView from 'components/ReportView';
import { ViewScreen } from 'components/ViewScreen';
import {
  RunTaxReportRecord,
  useRunTaxReportLazyQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { logError } from 'utils/logging';
import { formatCentsToDollars } from 'utils/money';

type Row = {
  row: RunTaxReportRecord;
};

const columns = [
  {
    label: 'Location',
    name: 'location',
    render: ({ row }: Row) => row.locationName,
  },
  {
    label: 'Tax %',
    name: 'locationTax',
    render: ({ row }: Row) => row.locationTax,
  },
  {
    label: 'Auction Sales',
    name: 'auctionSales',
    align: 'right' as const,
    render: ({ row }: Row) => formatCentsToDollars(row.auctionSales),
  },
  {
    label: 'Credit Card Refunds',
    name: 'creditCardRefunds',
    align: 'right' as const,
    render: ({ row }: Row) => formatCentsToDollars(row.creditCardRefunds),
  },
  {
    label: 'Reported Gross Sales',
    name: 'reportedGrossSales',
    align: 'right' as const,
    render: ({ row }: Row) => formatCentsToDollars(row.reportedGrossSales),
  },
  {
    label: 'Tax Exempt Sales',
    name: 'taxExemptSales',
    align: 'right' as const,
    render: ({ row }: Row) => formatCentsToDollars(row.taxExemptSales),
  },
  {
    label: 'Total Taxable Sales',
    name: 'totalTaxableSales',
    align: 'right' as const,
    render: ({ row }: Row) => formatCentsToDollars(row.totalTaxableSales),
  },
  {
    label: 'Sales Tax Due',
    name: 'salesTaxDue',
    align: 'right' as const,
    render: ({ row }: Row) => formatCentsToDollars(row.salesTaxDue),
  },
];

const TaxReportScreen: FunctionComponent = () => {
  const [runQuery, { data, loading, error, called }] = useRunTaxReportLazyQuery(
    {
      fetchPolicy: 'network-only',
    },
  );

  const onPeriodChange = ({ start, end }: Option) => {
    try {
      runQuery({
        variables: {
          startDate: start.toISOString(),
          endDate: end.toISOString(),
        },
      });
    } catch (e) {
      logError(e);
    }
  };

  const rows = data?.runTaxReport?.nodes ?? [];

  return (
    <ViewScreen
      loading={false}
      header={{
        leftProps: {
          title: 'Tax Reporting',
        },
        bottomProps: {
          component: <ReportPeriodSelect onChange={onPeriodChange} />,
        },
      }}
    >
      <ReportView
        loading={loading}
        error={error}
        called={called}
        rows={rows}
        columns={columns}
      />
    </ViewScreen>
  );
};

export default TaxReportScreen;
