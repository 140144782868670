import React from 'react';
import { useEmployeeStatsLazyQuery } from 'graphql/graphql-types';

export const useEmployeeStatsByLocation = () => {
  const [isRunning, setIsRunning] = React.useState(false);
  const [fetch, { data: results, loading, error, refetch, stopPolling }] =
    useEmployeeStatsLazyQuery({
      pollInterval: 2 * 1000,
    });
  const handleStart = (locationId: number) => {
    setIsRunning(true);
    fetch({
      variables: {
        locationId,
      },
    });
  };
  const handleStop = () => {
    setIsRunning(false);
    stopPolling();
  };

  const data = results?.allEmployeeStats?.nodes ?? [];

  return {
    fetch,
    handleStart,
    isRunning,
    handleStop,
    loading,
    error,
    refetch,
    data,
  };
};
