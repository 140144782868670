import { Box, Button, Grid, Typography } from '@mui/material';
import CircleLoading from 'components/CircleLoading';
import DeleteItemFromInvoiceModal from 'components/DeleteItemFromInvoiceModal';
import Hyperlink from 'components/Hyperlink';
import { formatBin, formatRackLocation } from 'components/ItemHistoryItem';
import ItemHistoryModal from 'components/ItemHistoryModal';
import ItemRelistModal from 'components/ItemRelistModal';
import ItemRemoveTaxModal from 'components/ItemRemoveTaxModal';
import ItemReturnModal from 'components/ItemReturn/ItemReturnModal';
import ItemReturnList from 'components/ItemReturnList';
import ItemSetAsideModal from 'components/ItemSetAsideModal';
import LineItems from 'components/LineItems';
import SetAsideNote from 'components/SetAsideNote';
import { useGetItemLazyQuery } from 'graphql/graphql-types';
import { useModal } from 'hooks/modal';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { openInNewTab } from 'utils/general';
import { formatCentsToDollars } from 'utils/money';

type Props = {
  itemId: null | string | number;
  onDeleteItemComplete: () => void;
  refreshInvoice: () => void;
  isMer: boolean;
};
const ItemDetailsPanel: FunctionComponent<Props> = ({
  itemId,
  onDeleteItemComplete,
  refreshInvoice,
  isMer,
}) => {
  const history = useHistory();
  const [getItemDetails, { data, loading, refetch }] = useGetItemLazyQuery();
  const [show, setShow] = useState(false);
  const [openReturnModal, setOpenReturnModal] = useState<boolean>(false);
  const {
    visible: deleteItemVisible,
    open: openDeleteItem,
    close: closeDeleteItem,
  } = useModal();
  const {
    visible: relistItemVisible,
    open: openRelistItem,
    close: closeRelistItem,
  } = useModal();
  const {
    visible: actionHistoryVisible,
    open: openActionHistory,
    close: closeActionHistory,
  } = useModal();
  const {
    visible: setAsideVisible,
    open: openSetAside,
    close: closeSetAside,
  } = useModal();
  const {
    visible: visibleRemoveTax,
    open: openRemoveTax,
    close: closeRemoveTax,
  } = useModal();

  const item = data?.itemById;
  const invId = item?.invId;

  useEffect(() => {
    if (itemId) {
      getItemDetails({ variables: { id: Number(itemId) } });
      setShow(true);
    } else {
      setShow(false);
    }
  }, [itemId]);
  const handleOpenReturnModal = useCallback(() => {
    setOpenReturnModal(true);
  }, [itemId]);
  const handleCloseReturnModal = useCallback(() => {
    setOpenReturnModal(false);
  }, [itemId]);
  const handleOpenDeleteItemModal = useCallback(() => {
    openDeleteItem();
  }, [itemId]);
  const handleOpenActionHistoryModal = useCallback(() => {
    openActionHistory();
  }, [itemId]);
  const handleOpenSetAsideModal = useCallback(() => {
    openSetAside();
  }, [itemId]);
  const handleOpenRemoveTaxModal = useCallback(() => {
    openRemoveTax();
  }, [itemId]);
  const handleOpenInventory = useCallback(() => {
    if (invId) {
      openInNewTab(`https://olaapps.com/item-${invId}`);
    }
  }, [invId]);

  const itemNumber = item?.number;
  const description = item?.description;
  const condition = item?.condition;
  const retailPrice = formatCentsToDollars(item?.retailPrice);
  const bidAmount = item?.bidAmount;
  const premium = item?.premiumAmount;
  const tax = item?.taxAmount;
  const total = item?.totalAmount;
  const rackLocation = item?.itemRackLocationByItemId?.rackLocation;
  const bin = item?.itemRackLocationByItemId?.bin;
  const setAsideNote = item?.setAsideNote;
  const setAsideAt = item?.setAsideAt;
  const setAsideBy = item?.userBySetAsideBy?.name;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      {loading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '20rem',
          }}
        >
          <CircleLoading />
        </Box>
      )}
      {!loading && !show && <Typography>Select an item</Typography>}
      {!loading && show && item && (
        <>
          <Grid item xs={12}>
            <Box fontWeight={500} m="1">
              <Hyperlink
                text={String(itemNumber)}
                variant="h6"
                onClick={() => history.push(`/items/${itemId}`)}
                bold
              />
            </Box>
          </Grid>
          <Grid
            container
            spacing={1}
            alignItems="center"
            justifyContent="flex-start"
            item
            paddingBottom={1}
          >
            {!isMer && (
              <>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleOpenReturnModal}
                  >
                    Return
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={openRelistItem}
                  >
                    Relist item
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleOpenDeleteItemModal}
                  >
                    Delete item
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleOpenActionHistoryModal}
                  >
                    View history
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleOpenSetAsideModal}
                  >
                    Set aside
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    size="small"
                    variant="outlined"
                    disabled={!tax}
                    onClick={handleOpenRemoveTaxModal}
                  >
                    Remove tax
                  </Button>
                </Grid>
              </>
            )}
            <Grid item>
              <Button
                size="small"
                variant="outlined"
                disabled={!invId}
                onClick={handleOpenInventory}
              >
                Inventory
              </Button>
            </Grid>
          </Grid>
          {setAsideNote ? (
            <Grid item xs={12}>
              <SetAsideNote
                note={setAsideNote}
                by={setAsideBy}
                at={setAsideAt}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <LineItems description="Description" value={description} stacked />
          </Grid>
          <Grid item xs={12}>
            <LineItems description="Retail price" value={retailPrice} stacked />
          </Grid>
          <Grid item xs={12}>
            <LineItems description="Condition" value={condition} stacked />
          </Grid>
          <Grid item xs={12}>
            <LineItems
              description="Rack"
              value={formatRackLocation(rackLocation)}
            />
            <LineItems description="Bin" value={formatBin(bin)} />
            <LineItems
              description="Bid"
              value={formatCentsToDollars(bidAmount)}
            />
            <LineItems
              description="Premium"
              value={formatCentsToDollars(premium)}
            />
            <LineItems description="Tax" value={formatCentsToDollars(tax)} />
            <LineItems
              description="Total"
              value={formatCentsToDollars(total)}
            />
          </Grid>
          <Grid item xs={12}>
            <ItemReturnList itemId={Number(itemId)} />
          </Grid>
          <ItemReturnModal
            itemId={Number(itemId)}
            open={openReturnModal}
            onClose={handleCloseReturnModal}
            onSuccess={() => {
              refetch?.();
              refreshInvoice?.();
              handleCloseReturnModal();
            }}
          />
          <DeleteItemFromInvoiceModal
            itemId={Number(itemId)}
            isOpen={deleteItemVisible}
            onClose={closeDeleteItem}
            onSuccess={() => {
              refetch?.();
              onDeleteItemComplete();
              closeDeleteItem();
            }}
          />
          <ItemRelistModal
            itemId={Number(itemId)}
            isOpen={relistItemVisible}
            onClose={closeRelistItem}
            onSuccess={() => {
              refetch?.();
              refreshInvoice?.();
              closeRelistItem();
            }}
          />
          <ItemHistoryModal
            itemId={Number(itemId)}
            isOpen={actionHistoryVisible}
            handleClose={closeActionHistory}
          />
          <ItemSetAsideModal
            itemId={Number(itemId)}
            isOpen={setAsideVisible}
            handleClose={closeSetAside}
          />
          <ItemRemoveTaxModal
            itemId={Number(itemId)}
            isOpen={visibleRemoveTax}
            onClose={closeRemoveTax}
            onSuccess={() => {
              refetch?.();
              refreshInvoice?.();
              closeRemoveTax();
            }}
          />
        </>
      )}
    </Box>
  );
};

export default ItemDetailsPanel;
