import LoadingButton from '@mui/lab/LoadingButton';
import { ButtonProps } from '@mui/material';
import { useCurrentUserQuery, UserRoles } from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import SplitButton, { Props as SplitButtonProps } from 'components/SplitButton';

export type Props = {
  text: string;
  buttonType?: 'normal' | 'split';
  splitButtons?: SplitButtonProps;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  loading?: boolean;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  accessRoles?: UserRoles[];
} & ButtonProps;
const Button: FunctionComponent<Props> = ({
  text,
  buttonType = 'normal',
  splitButtons,
  onClick,
  disabled = false,
  loading = false,
  startIcon,
  endIcon,
  accessRoles,
  ...buttonProps
}: Props) => {
  const { data: user } = useCurrentUserQuery();
  const userRole = user?.currentUser?.role;
  if (accessRoles && userRole && !accessRoles.includes(userRole)) {
    return null;
  }
  if (buttonType === 'split' && splitButtons) {
    return <SplitButton {...splitButtons} />;
  }
  if (buttonProps.href) {
    return (
      <LoadingButton
        loading={loading}
        variant="outlined"
        size="small"
        startIcon={startIcon}
        endIcon={endIcon}
        disabled={disabled || loading}
        disableElevation
        component={RouterLink}
        to={buttonProps.href}
      >
        {text}
      </LoadingButton>
    );
  }
  return (
    <LoadingButton
      loading={loading}
      variant="outlined"
      size="small"
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      disabled={disabled || loading}
      disableElevation
      {...buttonProps}
    >
      {text}
    </LoadingButton>
  );
};

export default Button;
