import {
  createDefaultImageReader,
  createDefaultImageWriter,
  createDefaultShapePreprocessor,
  // editor
  locale_en_gb,
  markup_editor_defaults,
  markup_editor_locale_en_gb,
  plugin_annotate,
  plugin_annotate_locale_en_gb,
  plugin_crop,
  plugin_crop_locale_en_gb,
  plugin_filter,
  plugin_filter_defaults,
  plugin_filter_locale_en_gb,
  plugin_finetune,
  plugin_finetune_defaults,
  plugin_finetune_locale_en_gb,
  // plugins
  setPlugins,
} from 'pintura';
import 'pintura/pintura.css';
import React, { FunctionComponent } from 'react';
import { PinturaEditorModal } from 'react-pintura';

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);

const editorDefaults = {
  imageReader: createDefaultImageReader(),
  imageWriter: createDefaultImageWriter({
    mimeType: 'image/jpeg',
  }),
  shapePreprocessor: createDefaultShapePreprocessor(),
  ...plugin_finetune_defaults,
  ...plugin_filter_defaults,
  ...markup_editor_defaults,
  locale: {
    ...locale_en_gb,
    ...plugin_crop_locale_en_gb,
    ...plugin_finetune_locale_en_gb,
    ...plugin_filter_locale_en_gb,
    ...plugin_annotate_locale_en_gb,
    ...markup_editor_locale_en_gb,
  },
};

type Props = {
  image: string | null;
  onSubmit: (data: File) => void;
  onClose: () => void;
};
const ImageEditor: FunctionComponent<Props> = ({ image, onSubmit, onClose }) =>
  image ? (
    // @ts-ignore
    <PinturaEditorModal
      {...editorDefaults}
      src={image}
      onHide={onClose}
      onProcess={({ dest }: { dest: File }) => {
        onSubmit(dest);
      }}
      cropEnableButtonRotateRight
      enableButtonExport
      enableDropImage
      enablePasteImage
      cropSelectPresetOptions={[
        [
          'Crop',
          [
            [undefined, 'Custom'],
            [1, 'Square'],
            [4 / 3, 'Landscape'],
            [3 / 4, 'Portrait'],
          ],
        ],
        [
          'Size',
          [
            [[180, 180], 'Profile Picture'],
            [[1200, 600], 'Header Image'],
            [[800, 400], 'Timeline Photo'],
          ],
        ],
      ]}
    />
  ) : null;

export default ImageEditor;
