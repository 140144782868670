import { Box } from '@mui/material';
import { SimpleTable } from 'components/Table';
import {
  PickupQueueEntryFragment,
  PickupQueueItemEntryFragment,
  PickupQueueStatusFilter,
  usePickupQueueItemLazyQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent, useEffect } from 'react';
import ClearPickupItem from './ClearPickupItem';
import DeletePickupItem from './DeletePickupItem';

const columns = [
  {
    label: 'Invoice',
    name: 'invoice',
    render: ({ row }: { row: PickupQueueEntryFragment }) =>
      row.itemByItemId?.invoiceByInvoiceId?.uid,
    align: 'left' as const,
    width: '20%',
  },
  {
    label: '#',
    name: 'item',
    render: ({ row }: { row: PickupQueueEntryFragment }) =>
      row.itemByItemId?.number,
    align: 'left' as const,
    width: '5%',
  },
  {
    label: 'Desc',
    name: 'desc',
    render: ({ row }: { row: PickupQueueEntryFragment }) => (
      <p
        style={{
          marginTop: 0,
          marginBottom: 0,
          maxWidth: '250px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {row.itemByItemId?.description}
      </p>
    ),
    align: 'left' as const,
    width: '70%',
  },
  {
    label: '',
    name: 'actions',
    render: ({ row }: { row: PickupQueueEntryFragment }) => (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <ClearPickupItem id={row.id} disabled={!row.userByAssignedUserId?.id} />
        <DeletePickupItem id={row.id} />
      </Box>
    ),
    width: '5%',
  },
];

type Props = {
  locationId: number | undefined;
  isAssigned: boolean;
  row: PickupQueueItemEntryFragment;
  status: PickupQueueStatusFilter;
};

const PickupItemExpanded: FunctionComponent<Props> = ({
  locationId,
  row,
  isAssigned,
  status,
}) => {
  const customerId = row?.customerId;
  const [get, { data }] = usePickupQueueItemLazyQuery();
  const rows = data?.allPickupQueues?.nodes ?? [];

  useEffect(() => {
    if (customerId && locationId && status && isAssigned !== undefined) {
      get({
        variables: {
          locationId,
          customerId,
          isAssignedNull: !isAssigned,
          status,
        },
      });
    }
  }, [customerId, locationId, status, isAssigned]);

  return (
    <Box
      style={{
        backgroundColor: '#f7fafc',
      }}
    >
      <SimpleTable columns={columns} rows={rows} />
    </Box>
  );
};

export default PickupItemExpanded;
