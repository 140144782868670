import DeleteIcon from '@mui/icons-material/Delete';
import NoteIcon from '@mui/icons-material/Note';
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CircleLoading from 'components/CircleLoading';
import ContentHeader from 'components/ContentHeader';
import Markdown from 'components/Markdown';
import {
  useDeleteInvoiceNoteMutation,
  useGetInvoiceNotesByInvoiceIdQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import 'react-markdown-editor-lite/lib/index.css';
import { logError } from 'utils/logging';
import { formatDateTime } from 'utils/time';

const useStyles = makeStyles((theme: Theme) => ({
  listTitle: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  listItem: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  paymentAmount: {
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },
  paymentDate: {
    color: theme.palette.grey[600],
  },
}));

type Props = {
  invoiceId: number;
};
const InvoiceNoteList: FunctionComponent<Props> = ({ invoiceId }) => {
  const classes = useStyles();
  const { loading, data, error } = useGetInvoiceNotesByInvoiceIdQuery({
    variables: { id: Number(invoiceId) },
  });
  const [deleteNote] = useDeleteInvoiceNoteMutation();

  const onDelete = async (id: number) => {
    try {
      deleteNote({
        variables: {
          invoiceNoteId: id,
        },
        update(cache) {
          const normalizedId = cache.identify({
            id,
            __typename: 'InvoiceNote',
          });
          cache.evict({ id: normalizedId });
          cache.gc();
        },
      });
    } catch (e) {
      logError(e);
    }
  };

  const notes = data?.allInvoiceNotes?.nodes ?? [];

  return (
    <>
      <ContentHeader title="Notes" size="large" />
      {loading && <CircleLoading />}
      {!loading && !notes.length && !error && (
        <Typography className={classes.listTitle} component="div">
          <Box className={classes.paymentDate}>No notes</Box>
        </Typography>
      )}
      {!loading && !!notes.length && !error && (
        <List>
          {notes.map((note) => (
            <ListItem className={classes.listItem} key={String(note?.id)}>
              <ListItemAvatar>
                <Avatar variant="rounded">
                  <NoteIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<Markdown text={note?.note} />}
                secondary={`By ${
                  note?.userByCreatedBy?.name
                } on ${formatDateTime(note?.createdAt)}`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => onDelete(note.id)}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default InvoiceNoteList;
