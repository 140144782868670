import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Box,
  IconButton,
  ImageListItem,
  Skeleton,
  Typography,
} from '@mui/material';
import ImageEditor from 'components/ImageEditor';
import { errorNotification } from 'components/SnackBar';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { openInNewTab } from 'utils/general';

const baseStyle: React.CSSProperties = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '176px',
  padding: '4.87rem 0px',
  borderWidth: 2,
  borderRadius: 8,
  borderColor: '#e3e8ee',
  borderStyle: 'dashed',
  backgroundColor: '#f8fafc',
  color: '#3f5672',
  outline: 'none',
  width: '12rem',
  transition: 'border .24s ease-in-out',
};

type Props = {
  imageUrl: string | null | undefined;
  handleUpload: (image: Blob) => void;
};
const UploadEditImage: FunctionComponent<Props> = ({
  imageUrl,
  handleUpload,
}) => {
  const [displayImageUrl, setDisplayImageUrl] = useState<string | null>(null);
  const [editImage, setEditImage] = useState<string | null>(null);
  const style = useMemo(
    () => ({
      ...baseStyle,
    }),
    [],
  );

  useEffect(() => {
    if (imageUrl) {
      setDisplayImageUrl(imageUrl);
    }
  }, [imageUrl]);

  const onDownload = useCallback(() => {
    if (displayImageUrl) {
      openInNewTab(displayImageUrl);
    }
  }, [displayImageUrl]);

  const onDropAccepted = useCallback((files: File[]) => {
    const file = files[0];
    if (file) {
      setEditImage(URL.createObjectURL(file));
    }
  }, []);

  const onDropRejected = useCallback((files: FileRejection[]) => {
    const errMsg = files?.[0].errors?.[0];
    errorNotification(errMsg?.message ?? 'General error');
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDropAccepted,
    onDropRejected,
    accept: 'image/jpeg, image/png',
  });

  const onUpload = (uploadImage: Blob) => handleUpload(uploadImage);

  const handleClose = () => setEditImage(null);

  return (
    <Box>
      <ImageEditor
        image={editImage}
        onSubmit={onUpload}
        onClose={handleClose}
      />
      {!displayImageUrl ? (
        <Box
          display="flex"
          justifyContent="center"
          alignSelf="center"
          height={175}
          width={175}
        >
          <Box {...getRootProps({ style })}>
            <input {...getInputProps()} />
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignSelf="center"
              alignItems="center"
            >
              <AddIcon style={{ marginBottom: '0.75rem' }} />
              <Typography
                variant="body2"
                style={{ margin: '0 2rem', textAlign: 'center' }}
              >
                Drop or select image
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        <ImageListItem
          style={{
            backgroundColor: '#f7fafc',
            boxShadow:
              '0px 2px 1px -1px #e3e8ee, 0px 1px 1px 0px #e3e8ee, 0px 1px 3px 0px #e3e8ee',
            borderRadius: '4px',
          }}
        >
          <LazyLoadImage
            alt=""
            src={displayImageUrl}
            draggable={false}
            onClick={() => setEditImage(displayImageUrl)}
            visibleByDefault
            placeholder={
              <Skeleton animation={false} width={175} height={175} />
            }
            style={{
              borderRadius: '4px',
              boxShadow: '0px 0px 3px -1px #e3e8ee',
              maxHeight: 350,
              maxWidth: 350,
              cursor: 'pointer',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
            style={{ padding: '0.75rem 0.5rem', width: '11rem' }}
          >
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onDownload();
              }}
              aria-label="download"
            >
              <DownloadIcon fontSize="small" style={{ color: '#3F5673' }} />
            </IconButton>
          </Box>
        </ImageListItem>
      )}
    </Box>
  );
};

export default UploadEditImage;
