import LinearWithValueLabel from 'components/LinearProgress';
import ListScreen from 'components/ListScreen';
import { SortTypes } from 'components/Table';
import {
  AllTruckloadsDocument,
  AllTruckloadsEntryFragment,
} from 'graphql/graphql-types';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { formatPercentage } from 'utils/general';
import { formatCentsToDollars } from 'utils/money';
import { formatDateTime } from 'utils/time';

type Row = {
  row: AllTruckloadsEntryFragment;
};

const TruckloadListScreen: FunctionComponent = () => {
  const history = useHistory();
  return (
    <ListScreen
      title="Truckloads"
      query={AllTruckloadsDocument}
      defaultOrder="num"
      defaultSort={SortTypes.DESC}
      onRowClick={(row: Row['row']) => {
        history.push(`/truckloads/${row.id}`);
      }}
      columns={[
        {
          label: 'ID',
          name: 'num',
          makeFilterQuery: (value) => ({
            num: {
              equalTo: Number(value),
            },
          }),
        },
        {
          label: 'Identifier',
          name: 'loadIdentifier',
        },
        {
          label: 'Facility',
          name: 'facilityName',
        },
        {
          label: 'Received',
          name: 'dateReceived',
          type: 'date',
          render: ({ row }: Row) => formatDateTime(row?.dateReceived),
        },
        {
          label: 'Sales % of Retail Price',
          name: 'retailPercent',
          disableFilter: true,
          render: ({ row }: Row) =>
            formatPercentage(row?.truckloadDatumById?.retailPercent ?? 0),
        },
        {
          label: 'Return Rate %',
          name: 'returnRatePercent',
          disableFilter: true,
          render: ({ row }: Row) =>
            formatPercentage(row?.truckloadDatumById?.returnRatePercent ?? 0),
        },
        {
          label: 'Total Sales',
          name: 'totalSaleAmount',
          disableFilter: true,
          render: ({ row }: Row) =>
            formatCentsToDollars(row?.truckloadDatumById?.totalSaleAmount ?? 0),
        },
        {
          label: 'Items Sold',
          name: 'itemsSoldPercent',
          disableFilter: true,
          render: ({ row }: Row) => (
            <>
              {`${row?.truckloadDatumById?.countItemsSold ?? 0} / ${
                row?.truckloadDatumById?.countInventoriedItems ?? 0
              }`}
              <LinearWithValueLabel
                progress={row?.truckloadDatumById?.itemsSoldPercent ?? 0}
              />
            </>
          ),
        },
      ]}
    />
  );
};

export default TruckloadListScreen;
