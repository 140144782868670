import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box, SvgIconProps } from '@mui/material';
import React, { FunctionComponent } from 'react';

const Container: FunctionComponent = ({ children }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    }}
  >
    {children}
  </Box>
);
export const PriorityIcon: FunctionComponent<SvgIconProps> = (props) => (
  <Container>
    <StarIcon fontSize="small" sx={{ color: '#ebc600' }} {...props} />
  </Container>
);

export const NotPriorityIcon: FunctionComponent<SvgIconProps> = (props) => (
  <Container>
    <StarBorderIcon fontSize="small" sx={{ color: '#d1d5db' }} {...props} />
  </Container>
);
