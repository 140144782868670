import { Box, Typography } from '@mui/material';
import React, { ReactElement } from 'react';

export type Props = {
  text?: string;
  component?: ReactElement;
};
export default ({ text, component }: Props) => (
  <Box display="flex" alignItems="center" flexGrow={1}>
    {text && (
      <Typography>
        <Box>{text}</Box>
      </Typography>
    )}
    {component}
  </Box>
);
