import Button from 'components/Button';
import { GrayDot, GreenDot } from 'components/Dot';
import ListScreen from 'components/ListScreen';
import {
  AllReturnOptionsDocument,
  ReturnOptionEntryFragment,
} from 'graphql/graphql-types';
import { useModal, useSelectedValueModal } from 'hooks/modal';
import React, { FunctionComponent } from 'react';
import { ADMINISTRATOR } from 'utils/auth';
import ReturnOptionForm from './ReturnOptionForm';

type Row = {
  row: ReturnOptionEntryFragment;
};

interface TableActionsProps {
  createOnClick: () => void;
}

const TableActions = ({ createOnClick }: TableActionsProps) => (
  <Button
    color="primary"
    text="Create"
    onClick={createOnClick}
    accessRoles={[ADMINISTRATOR]}
  />
);

const ReturnSettingsScreen: FunctionComponent = () => {
  const { visible, open: openInsert, close: closeInsert } = useModal();
  const {
    selectedValue,
    open: openUpdate,
    close: closeUpdate,
  } = useSelectedValueModal<string>();

  return (
    <>
      <ListScreen
        title="Return Options"
        query={AllReturnOptionsDocument}
        headerExtra={<TableActions createOnClick={openInsert} />}
        onRowClick={(row: Row['row']) => {
          openUpdate(row.id);
        }}
        columns={[
          { label: 'Name', name: 'name', width: 500 },
          {
            label: 'Active',
            name: 'isActive',
            type: 'boolean',
            width: '5%',
            render: ({ row }: Row) =>
              row.isActive ? <GreenDot /> : <GrayDot />,
          },
        ]}
      />
      <ReturnOptionForm
        optionId={undefined}
        visible={visible}
        type="create"
        handleClose={closeInsert}
        handleSuccess={closeInsert}
      />
      <ReturnOptionForm
        optionId={selectedValue}
        visible={!!selectedValue}
        type="update"
        handleClose={closeUpdate}
        handleSuccess={closeUpdate}
      />
    </>
  );
};

export default ReturnSettingsScreen;
