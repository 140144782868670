import {
  GRAPHQL_SERVER_URL,
  GRAPHQL_SERVER_URL_WS,
  IS_PRODUCTION,
} from 'utils/env';

const commonConfig = {
  graphqlServerUrl: GRAPHQL_SERVER_URL,
  graphqlServerUrlWS: GRAPHQL_SERVER_URL_WS,
  idleLogoutTimeout: 1000 * 60 * 30,
};

const development = {};

const production = {};

let config; // eslint-disable-line

if (IS_PRODUCTION) {
  config = production;
} else {
  config = development;
}

export default {
  ...commonConfig,
  ...config,
} as any;
