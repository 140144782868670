import { Box, BoxProps, Divider, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    sectionTitle: { paddingBottom: theme.spacing(1) },
    chipStatus: {
      backgroundColor: '#cbf4c9',
      borderRadius: '4px',
      color: '#0e6245',
      fontWeight: 500,
      marginLeft: theme.spacing(1),
    },
    title: {
      fontWeight: 500,
    },
    removeGridPaddingBottom: {
      paddingBottom: '0 !important',
      marginBottom: theme.spacing(1),
    },
  }),
);

type Props = {
  title: string;
  size?: 'large' | 'normal';
  divider?: boolean;
  extra?: React.ReactNode;
  containerProps?: BoxProps;
};
const ContentHeader = ({
  title,
  extra,
  divider = true,
  size = 'normal',
  containerProps = {},
}: Props) => {
  const classes = useStyles({ size, title, divider });

  return (
    <Box pb={0} mb={2} {...containerProps}>
      <Box display="flex">
        <Box
          display="flex"
          flexGrow={1}
          alignItems="center"
          className={classes.sectionTitle}
        >
          <Typography variant={size === 'large' ? 'h5' : 'subtitle2'}>
            <Box className={classes.title}>{title}</Box>
          </Typography>
        </Box>
        {extra && (
          <Box
            display="flex"
            alignItems="center"
            className={classes.sectionTitle}
          >
            {extra}
          </Box>
        )}
      </Box>
      {divider && <Divider />}
    </Box>
  );
};

export default ContentHeader;
