import Button from 'components/Button';
import { GrayDot, GreenDot } from 'components/Dot';
import ListScreen from 'components/ListScreen';
import {
  AllLocationsDocument,
  LocationListEntryFragment,
} from 'graphql/graphql-types';
import { useModal } from 'hooks/modal';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { ADMINISTRATOR } from 'utils/auth';
import { formatPercentage } from 'utils/general';
import LocationForm from './LocationForm';

type Row = {
  row: LocationListEntryFragment;
};
interface TableActionsProps {
  createOnClick: () => void;
}

const TableActions = ({ createOnClick }: TableActionsProps) => (
  <Button
    color="primary"
    text="Create"
    onClick={createOnClick}
    accessRoles={[ADMINISTRATOR]}
  />
);

export default () => {
  const history = useHistory();
  const { visible, open, close } = useModal();

  return (
    <>
      <ListScreen
        title="Locations"
        query={AllLocationsDocument}
        headerExtra={<TableActions createOnClick={open} />}
        onRowClick={(row: Row['row']) => {
          history.push(`/locations/${row.id}`);
        }}
        columns={[
          { label: 'Name', name: 'name' },
          {
            label: 'Main address',
            name: 'addressMain',
          },
          {
            label: 'Premium',
            name: 'premium',
            type: 'decimal',
            render: ({ value }) => formatPercentage(value),
          },
          {
            label: 'Tax',
            name: 'tax',
            type: 'decimal',
            render: ({ value }) => formatPercentage(value),
          },
          {
            label: 'Active',
            name: 'isDeleted',
            type: 'boolean',
            render: ({ row }: Row) =>
              row.isDeleted ? <GrayDot /> : <GreenDot />,
          },
        ]}
      />
      <LocationForm
        visible={visible}
        type="create"
        handleClose={close}
        handleSuccess={close}
      />
    </>
  );
};
