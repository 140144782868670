import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import CircleLoading from 'components/CircleLoading';
import {
  GetLocationsEntryFragment,
  useGetAllLocationsQuery,
} from 'graphql/graphql-types';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

export type Option = GetLocationsEntryFragment;

type Props = {
  initialValue?: number;
  onChange: (option: Option) => void;
};
const LocationSelect: FunctionComponent<Props> = ({
  initialValue,
  onChange,
}) => {
  const { data, loading } = useGetAllLocationsQuery();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [selected, setSelected] = useState<Option | null>(null);

  const options = data?.allLocations?.nodes ?? [];

  const onSelect = (option: Option) => {
    setSelected(option);
    onChange(option);
    setOpen(false);
  };

  useEffect(() => {
    const nodes = data?.allLocations?.nodes;
    if (nodes?.length && initialValue) {
      const i = nodes.find((n) => n.id === initialValue);
      if (i) {
        onSelect(i);
      }
    }
  }, [data, initialValue]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box flex={1}>
      {loading ? (
        <CircleLoading />
      ) : (
        <>
          <Button
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            disableElevation
            size="small"
            variant="outlined"
          >
            {selected?.name ?? 'Select location'}
          </Button>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 999 }}
            placement="bottom"
            modifiers={[
              {
                name: 'flip',
                enabled: false,
              },
              {
                name: 'arrow',
                enabled: true,
              },
            ]}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      {options.map((option) => (
                        <MenuItem
                          key={option.id}
                          onClick={() => onSelect(option)}
                        >
                          {option.name}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </Box>
  );
};

export default LocationSelect;
