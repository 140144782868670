import { Grid } from '@mui/material';
import Autocomplete from 'components/Forms/Autocomplete';
import TextField from 'components/Forms/TextField';
import { makeValidate } from 'components/Forms/Validation';
import Modal from 'components/Modal';
import ModalActions from 'components/ModalActions';
import ModalLoading from 'components/ModalLoading';
import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import {
  AllPrintersEntryFragmentDoc,
  GetLocationsDocument,
  useCreatePrinterMutation,
  useGetPrinterLazyQuery,
  useUpdatePrinterMutation,
} from 'graphql/graphql-types';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Form } from 'react-final-form';
import { logError } from 'utils/logging';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const Schema = Yup.object({
  name: Yup.string().required('Required'),
  ipAddress: Yup.string().required('Required'),
  port: Yup.string().required('Required'),
  location: Yup.object({
    id: Yup.number().required('Required'),
    name: Yup.string().required('Required'),
  }),
});

type PrinterSchema = Asserts<typeof Schema>;
const validate = makeValidate(Schema);

type Props = {
  visible: boolean;
  type: 'create' | 'update';
  printerId?: number;
  handleClose: () => void;
  handleSuccess: () => void;
};
const PrinterForm: FunctionComponent<Props> = ({
  type,
  visible,
  printerId,
  handleClose,
  handleSuccess,
}) => {
  const [ready, setReady] = useState(false);
  const [initialValues, setInitialValues] = useState<PrinterSchema>();
  const [get, { data }] = useGetPrinterLazyQuery();
  useEffect(() => {
    if (visible && type === 'create') {
      setReady(true);
    }
    if (visible && type === 'update' && printerId) {
      get({
        variables: {
          id: printerId,
        },
      });
    }
  }, [visible, type, printerId]);
  useEffect(() => {
    if (data?.printerById) {
      const printer = data.printerById;
      setInitialValues({
        name: printer.name,
        ipAddress: printer.ipAddress,
        port: String(printer.port),
        location: {
          id: Number(printer.locationByLocationId?.id),
          name: String(printer.locationByLocationId?.name),
        },
      });
      setReady(true);
    }
  }, [data]);
  const [createPrinter] = useCreatePrinterMutation({
    update(cache, { data: printerData }) {
      cache.modify({
        fields: {
          allPrinters(existing = []) {
            const newRef = cache.writeFragment({
              data: printerData?.createPrinter?.printer,
              fragment: AllPrintersEntryFragmentDoc,
            });
            return { ...existing, nodes: [...existing.nodes, newRef] };
          },
        },
      });
    },
  });
  const [updatePrinter] = useUpdatePrinterMutation({
    update(cache, { data: printerData }) {
      cache.modify({
        fields: {
          allPrinters(existing = []) {
            const newRef = cache.writeFragment({
              data: printerData?.updatePrinterById?.printer,
              fragment: AllPrintersEntryFragmentDoc,
            });
            return { ...existing, nodes: [...existing.nodes, newRef] };
          },
        },
      });
    },
  });

  const onSubmit = useCallback(
    async (values: PrinterSchema) => {
      const snackId = startSnackbar(
        `${printerId ? 'Saving' : 'Creating'} printer`,
      );
      try {
        const { location, ...finalValues } = {
          ...values,
          port: Number(values.port),
          locationId: Number(values.location.id),
        };
        if (printerId) {
          await updatePrinter({
            variables: {
              id: Number(printerId),
              values: finalValues,
            },
          });
          handleSuccess();
          stopSnackbar('Saved printer', snackId);
        } else {
          await createPrinter({
            variables: {
              values: finalValues,
            },
          });
          handleSuccess();
          stopSnackbar('Created printer', snackId);
        }
      } catch (e) {
        logError(e);
        stopSnackbar(
          `Failed to ${printerId ? 'save' : 'create'} printer: ${e.message}`,
          snackId,
        );
      }
    },
    [printerId],
  );

  return (
    <Modal
      open={visible}
      title={type === 'create' ? 'New Printer' : 'Update Printer'}
      handleClose={handleClose}
      maxWidth="md"
    >
      {!ready && <ModalLoading />}
      {ready && (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          render={({
            handleSubmit,
            initialValues: formInitialValues,
            ...props
          }) => (
            <form>
              <TextField
                required
                label="Name"
                id="name"
                name="name"
                variant="outlined"
              />
              <Autocomplete
                label="Location"
                name="location"
                required
                query={GetLocationsDocument}
                defaultValue={formInitialValues?.location}
                getOptionValue={(option: any) => ({
                  id: option.id,
                  name: option.name,
                })}
                getOptionLabel={(option: any) => option?.name}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?.id === value?.id
                }
              />
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextField
                    required
                    label="IP address"
                    id="ipAddress"
                    name="ipAddress"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Port"
                    id="port"
                    name="port"
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <ModalActions
                buttons={[
                  {
                    text: 'Cancel',
                    onClick: handleClose,
                    variant: 'text',
                  },
                  {
                    text: 'Save',
                    onClick: handleSubmit,
                    type: 'submit',
                    disabled:
                      props.submitting ||
                      props.pristine ||
                      props.hasValidationErrors ||
                      (props.hasSubmitErrors && !props.modifiedSinceLastSubmit),
                    variant: 'contained',
                    color: 'primary',
                  },
                ]}
              />
            </form>
          )}
        />
      )}
    </Modal>
  );
};

export default PrinterForm;
