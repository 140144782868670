import { Box, Stack } from '@mui/material';
import { Switches } from 'components/Forms/Switches';
import TextField from 'components/Forms/TextField';
import { makeValidate } from 'components/Forms/Validation';
import Modal from 'components/Modal';
import ModalActions from 'components/ModalActions';
import ModalLoading from 'components/ModalLoading';
import { errorNotification } from 'components/SnackBar';
import {
  AllReturnOptionsDocument,
  useReturnOptionByIdLazyQuery,
  useUpsertReturnOptionMutation,
} from 'graphql/graphql-types';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Form } from 'react-final-form';
import { logError } from 'utils/logging';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const schema = Yup.object({
  name: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
  accessSupport: Yup.boolean().required('Required'),
  accessApp: Yup.boolean().required('Required'),
  requireNote: Yup.boolean().required('Required'),
  isActive: Yup.boolean().required('Required'),
  countAgainstUser: Yup.boolean().required('Required'),
  countAgainstCompany: Yup.boolean().required('Required'),
});

type Schema = Asserts<typeof schema>;
const validate = makeValidate(schema);

type Props = {
  visible: boolean;
  type: 'create' | 'update';
  optionId: string | undefined;
  handleClose: () => void;
  handleSuccess: () => void;
};
const ReturnOptionForm: FunctionComponent<Props> = ({
  type,
  visible,
  optionId,
  handleClose,
  handleSuccess,
}) => {
  const [ready, setReady] = useState(false);
  const [initialValues, setInitialValues] = useState<Schema>();
  const [get, { data }] = useReturnOptionByIdLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [upsert] = useUpsertReturnOptionMutation({
    refetchQueries: [AllReturnOptionsDocument],
  });

  useEffect(() => {
    if (visible && type === 'create') {
      setReady(true);
    }
    if (visible && type === 'update' && optionId) {
      get({
        variables: {
          id: optionId,
        },
      });
    }
  }, [visible, type, optionId]);

  useEffect(() => {
    if (data?.returnOptionById) {
      const returnOption = data.returnOptionById;
      setInitialValues({
        name: returnOption.name,
        description: returnOption.description,
        requireNote: returnOption.requireNote,
        accessSupport: returnOption.accessSupport,
        accessApp: returnOption.accessApp,
        isActive: returnOption.isActive,
        countAgainstUser: returnOption.countAgainstUser,
        countAgainstCompany: returnOption.countAgainstCompany,
      });
      setReady(true);
    }
  }, [data]);

  const onSubmit = useCallback(
    async (values: Schema) => {
      try {
        await upsert({
          variables: {
            input: {
              returnOption: {
                id: optionId,
                name: values.name,
                description: values.description,
                requireNote: values.requireNote,
                accessApp: values.accessApp,
                accessSupport: values.accessSupport,
                isActive: values.isActive,
                countAgainstUser: values.countAgainstUser,
                countAgainstCompany: values.countAgainstCompany,
              },
            },
          },
        });
        handleSuccess();
      } catch (e: any) {
        logError(e);
        errorNotification(e?.message ?? 'Unknown error');
      }
    },
    [optionId],
  );

  return (
    <Modal
      open={visible}
      title={
        type === 'create' ? 'Create Return Option' : 'Update Return Option'
      }
      handleClose={handleClose}
      maxWidth="sm"
    >
      {!ready && <ModalLoading />}
      {ready && (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={initialValues}
          render={({
            handleSubmit,
            initialValues: formInitialValues,
            ...props
          }) => (
            <form>
              <Stack>
                <TextField
                  required
                  label="Name"
                  id="name"
                  name="name"
                  variant="outlined"
                  helperText="Short name"
                />
                <TextField
                  required
                  label="Description"
                  id="description"
                  name="description"
                  multiline
                  rows={4}
                  variant="outlined"
                  helperText="Detailed explanation"
                />
                <Box>
                  <Switches
                    name="requireNote"
                    required
                    data={{ label: 'Require notes', value: true }}
                    fieldProps={{
                      defaultValue: false,
                    }}
                    formControlProps={{
                      style: {
                        margin: 0,
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Switches
                    name="accessSupport"
                    required
                    data={{ label: 'Visible for Support users', value: true }}
                    fieldProps={{
                      defaultValue: false,
                    }}
                    formControlProps={{
                      style: {
                        margin: 0,
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Switches
                    name="accessApp"
                    required
                    data={{ label: 'Visible for App users', value: true }}
                    fieldProps={{
                      defaultValue: false,
                    }}
                    formControlProps={{
                      style: {
                        margin: 0,
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                    borderBottomColor: '#eef3fb',
                    mb: 0.5,
                    pb: 0.5,
                  }}
                >
                  <Switches
                    name="isActive"
                    required
                    data={{ label: 'Active return option', value: true }}
                    fieldProps={{
                      defaultValue: true,
                    }}
                    formControlProps={{
                      style: {
                        margin: 0,
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Switches
                    name="countAgainstUser"
                    required
                    data={{ label: 'Count against user', value: true }}
                    fieldProps={{
                      defaultValue: true,
                    }}
                    formControlProps={{
                      style: {
                        margin: 0,
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Switches
                    name="countAgainstCompany"
                    required
                    data={{ label: 'Count against company', value: true }}
                    fieldProps={{
                      defaultValue: true,
                    }}
                    formControlProps={{
                      style: {
                        margin: 0,
                      },
                    }}
                  />
                </Box>
              </Stack>
              <ModalActions
                buttons={[
                  {
                    text: 'Cancel',
                    onClick: handleClose,
                    variant: 'text',
                  },
                  {
                    text: 'Save',
                    onClick: handleSubmit,
                    type: 'submit',
                    disabled:
                      props.submitting ||
                      props.pristine ||
                      props.hasValidationErrors ||
                      (props.hasSubmitErrors && !props.modifiedSinceLastSubmit),
                    variant: 'contained',
                    color: 'primary',
                  },
                ]}
              />
            </form>
          )}
        />
      )}
    </Modal>
  );
};

export default ReturnOptionForm;
