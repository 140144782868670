import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import EditorField from 'components/Forms/EditorField';
import { useDeleteCreditFromReturnMutation } from 'graphql/graphql-types';
import { makeValidate } from 'components/Forms/Validation';
import React, { FunctionComponent, useCallback } from 'react';
import { Form } from 'react-final-form';
import { logError } from 'utils/logging';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const formId = 'delete-credit-from-item-return-modal-form';

const Schema = Yup.object({
  reason: Yup.string().required('Required'),
});
const validate = makeValidate(Schema);

const initialValues = {
  reason: '',
};

type Props = {
  returnId: number;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};
const DeleteCreditFromItemReturnModal: FunctionComponent<Props> = ({
  returnId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [commit] = useDeleteCreditFromReturnMutation();

  const onSubmit = useCallback(
    async (values: Asserts<typeof Schema>) => {
      try {
        await commit({
          variables: {
            input: {
              returnId,
              reason: values.reason,
            },
          },
        });
        onSuccess();
      } catch (e) {
        logError(e);
      }
    },
    [returnId],
  );

  return (
    <Dialog
      fullScreen={false}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
    >
      <DialogTitle>Delete item credit return</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validate}
          render={({ handleSubmit, ...props }) => (
            <form id={formId} onSubmit={handleSubmit}>
              <Typography>
                Credit will be deleted from customer&apos;s account.
              </Typography>
              <EditorField
                required
                label="Reason"
                id="reason"
                name="reason"
                config={{
                  view: {
                    menu: true,
                    md: true,
                    html: false,
                  },
                  canView: {
                    fullScreen: false,
                    hideMenu: false,
                  },
                }}
                plugins={[
                  'header',
                  'font-bold',
                  'font-italic',
                  'font-underline',
                  'font-strikethrough',
                  'list-unordered',
                  'list-ordered',
                  'table',
                  'clear',
                  'logger',
                ]}
              />
              <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  disabled={
                    props.submitting ||
                    props.pristine ||
                    props.hasValidationErrors ||
                    (props.hasSubmitErrors && !props.modifiedSinceLastSubmit)
                  }
                  type="submit"
                  onClick={handleSubmit}
                  color="primary"
                >
                  Delete
                </Button>
              </DialogActions>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
};

export default DeleteCreditFromItemReturnModal;
