import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import TextField from 'components/Forms/TextField';
import { makeValidate } from 'components/Forms/Validation';
import LineItems from 'components/LineItems';
import ModalLoading from 'components/ModalLoading';
import { errorNotification } from 'components/SnackBar';
import {
  GetInvoiceByIdDocument,
  useApplyCreditToInvoiceMutation,
  useGetInvoiceCustomerDataForApplyCreditsToInvoiceLazyQuery,
} from 'graphql/graphql-types';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Form } from 'react-final-form';
import { logError } from 'utils/logging';
import { formatCentsToDollars, makeWholeCents } from 'utils/money';
import type { Asserts } from 'yup';
import * as Yup from 'yup';

const formId = 'apply-credits-to-invoice-form';

type Props = {
  invoiceId: number;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};
const ApplyCreditsToInvoiceModal: FunctionComponent<Props> = ({
  invoiceId,
  open,
  onClose,
  onSuccess,
}) => {
  const [ready, setReady] = useState(false);
  const [getDetails, { data }] =
    useGetInvoiceCustomerDataForApplyCreditsToInvoiceLazyQuery({
      fetchPolicy: 'network-only',
    });
  const [applyCredits] = useApplyCreditToInvoiceMutation({
    refetchQueries: [
      {
        query: GetInvoiceByIdDocument,
        variables: {
          id: invoiceId,
        },
      },
    ],
  });

  const [amountDue, setAmountDue] = useState(0);
  const [availableCredits, setAvailableCredits] = useState(0);
  const [initalAmount, setInitialAmount] = useState(0);
  const [maxAmount, setMaxAmount] = useState(0);

  useEffect(() => {
    if (open && invoiceId) {
      getDetails({ variables: { invoiceId: Number(invoiceId) } });
    }
  }, [open, invoiceId]);

  useEffect(() => {
    if (data?.invoiceById && open && invoiceId) {
      const amountDue2 = Number(data?.invoiceById?.amountDue ?? 0);
      const availableCredits2 = Number(
        data?.invoiceById?.bidderByBidderId?.creditBalance ?? 0,
      );

      setAmountDue(amountDue2);
      setAvailableCredits(availableCredits2);
      setInitialAmount(
        (availableCredits2 > amountDue2 ? amountDue2 : availableCredits2) / 100,
      );
      setMaxAmount(
        (availableCredits2 > amountDue2 ? amountDue2 : availableCredits2) / 100,
      );
      setReady(true);
    }
  }, [data, open, invoiceId]);

  const processReturn = useCallback(
    async (values: Asserts<typeof Schema>) => {
      try {
        await applyCredits({
          variables: {
            input: {
              invoiceId,
              creditAmount: makeWholeCents(values.amount),
            },
          },
        });
        onSuccess();
      } catch (e) {
        errorNotification(e.message);
        logError(e);
      }
    },
    [invoiceId],
  );

  const initialValues = {
    amount: undefined,
  };

  const Schema = Yup.object({
    amount: Yup.number()
      .positive('Must be positive')
      .min(0)
      .max(maxAmount)
      .required('Required'),
  });

  const validate = makeValidate(Schema);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="Apply Credits to Invoice"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Apply Credits to Invoice</DialogTitle>
      <DialogContent>
        {!ready && <ModalLoading />}
        {ready && (
          <Form
            onSubmit={processReturn}
            initialValues={initialValues}
            validate={validate}
            render={({ handleSubmit, ...props }) => (
              <form id={formId} onSubmit={handleSubmit}>
                <Alert severity="warning">
                  Applying credits to a paid invoice is not allowed and will
                  error out.
                </Alert>
                <br />
                <LineItems
                  description="Amount due"
                  value={formatCentsToDollars(amountDue)}
                />
                <LineItems
                  description="Available customer credits"
                  value={formatCentsToDollars(availableCredits)}
                />
                <br />
                <TextField
                  required
                  variant="outlined"
                  label="Apply"
                  id="amount"
                  name="amount"
                  parseType="money"
                  type="number"
                  validateInitialValue
                  helperText="Apply credits to invoice by taking from customer credit balance"
                  fieldProps={{
                    defaultValue: 0,
                    initialValue: initalAmount ?? 0,
                  }}
                />
                <br />
                <br />
                <DialogActions>
                  <Button onClick={onClose}>close</Button>
                  <Button
                    disabled={
                      props.submitting ||
                      (props.error && props.pristine) ||
                      props.hasValidationErrors ||
                      (props.hasSubmitErrors && !props.modifiedSinceLastSubmit)
                    }
                    type="submit"
                    onClick={handleSubmit}
                    color="primary"
                  >
                    Apply
                  </Button>
                </DialogActions>
              </form>
            )}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ApplyCreditsToInvoiceModal;
