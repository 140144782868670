import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import {
  useCommitAuctionMutation,
  useEmailAuctionInvoicesMutation,
  useProcessAuctionInvoicePaymentsMutation,
} from 'graphql/graphql-types';
import { useCallback, useState } from 'react';
import { logError } from 'utils/logging';

export const useHandleProcessInvoices = (auctionId: string | number) => {
  const [processInvoicesLoading, setLoading] = useState(false);
  const [process] = useProcessAuctionInvoicePaymentsMutation();

  const handleProcessInvoices = useCallback(() => {
    const handle = async () => {
      setLoading(true);
      const snackId = startSnackbar('Starting to process auction invoices');
      try {
        await process({
          variables: { id: Number(auctionId) },
          update(cache) {
            cache.modify({
              id: cache.identify({
                __typename: 'Auction',
                id: Number(auctionId),
              }),
              fields: {
                isProcessingInvoicePayments: () => true,
              },
            });
          },
        });
        stopSnackbar(
          'Processing invoice payments associated with this auction',
          snackId,
        );
      } catch (e: any) {
        logError(e);
        stopSnackbar(`Failed to process invoices: ${e.message}`, snackId);
      } finally {
        setLoading(false);
      }
    };
    if (auctionId) {
      handle();
    }
  }, [auctionId]);

  return { processInvoicesLoading, handleProcessInvoices };
};

export const useHandleEmailInvoices = (auctionId: string | number) => {
  const [emailInvoicesLoading, setLoading] = useState(false);
  const [process] = useEmailAuctionInvoicesMutation();

  const handleEmailInvoices = useCallback(() => {
    const handle = async () => {
      setLoading(true);
      const snackId = startSnackbar('Starting email invoices');
      try {
        await process({
          variables: { id: Number(auctionId) },
          update(cache) {
            cache.modify({
              id: cache.identify({
                __typename: 'Auction',
                id: Number(auctionId),
              }),
              fields: {
                isEmailingInvoices: () => true,
              },
            });
          },
        });
        stopSnackbar('Queued emailing invoices', snackId);
      } catch (e: any) {
        logError(e);
        stopSnackbar(`Failed to email invoice: ${e.message}`, snackId);
      } finally {
        setLoading(false);
      }
    };
    if (auctionId) {
      handle();
    }
  }, [auctionId]);

  return { emailInvoicesLoading, handleEmailInvoices };
};

export const useHandleCommitAuction = (auctionId: string | number) => {
  const [commitAuctionLoading, setLoading] = useState(false);
  const [process] = useCommitAuctionMutation();

  const handleCommitAuction = useCallback(
    (publish: boolean) => {
      const handle = async () => {
        setLoading(true);
        const snackId = startSnackbar('Pushing auction');
        try {
          const result = await process({
            variables: {
              input: {
                auctionId: Number(auctionId),
                publish,
              },
            },
            update(cache) {
              cache.modify({
                id: cache.identify({
                  __typename: 'Auction',
                  id: Number(auctionId),
                }),
                fields: {
                  isPushed: () => false,
                  isPushing: () => true,
                },
              });
            },
          });
          stopSnackbar(
            result.data?.commitAuction?.willCommit
              ? 'Queued pushing auction'
              : 'Failed to push',
            snackId,
          );
        } catch (e: any) {
          logError(e);
          stopSnackbar(`Failed to push auction: ${e.message}`, snackId);
        } finally {
          setLoading(false);
        }
      };
      if (auctionId) {
        handle();
      }
    },
    [auctionId],
  );

  return { commitAuctionLoading, handleCommitAuction };
};
