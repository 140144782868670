import { AppBar as AppBarMaterial, Box, Toolbar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import AppBarMenu from './AppBarMenu';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  appBar: {
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#ffffff',
    boxShadow: '0 1px #e3e8ee',
    // borderBottom: '1px solid #d9dcdd',
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  toolbar: {
    boxShadow: '0 1px #e3e8ee',
  },
}));

const AppBar = () => {
  const classes = useStyles();
  return (
    <AppBarMaterial position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box display="flex" flexGrow={1}>
          <Box display="flex" flexGrow={1} />
          <AppBarMenu />
        </Box>
      </Toolbar>
    </AppBarMaterial>
  );
};

export default AppBar;
