import NeutralChip from 'components/Chips/NeutralChip';
import SuccessChip from 'components/Chips/SuccessChip';
import React, { FunctionComponent } from 'react';

interface Props {
  type: 'paid' | 'unpaid';
}
const PaymentStatusChip: FunctionComponent<Props> = ({ type }) =>
  type === 'paid' ? (
    <SuccessChip icon={false} label="Paid" />
  ) : (
    <NeutralChip text="Unpaid" />
  );

export default PaymentStatusChip;
