import Icon from '@mui/icons-material/Done';
import { Chip } from '@mui/material';
import { green } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles(() => ({
  status: {
    backgroundColor: green[50],
    borderRadius: 5,
    color: green[900],
    fontWeight: 500,
  },
}));

type Props = {
  label?: string;
  icon?: boolean;
};
const SuccessChip: FunctionComponent<Props> = ({
  label = 'Successful',
  icon = true,
}) => {
  const classes = useStyles();

  return (
    <Chip
      size="small"
      label={label}
      className={classes.status}
      icon={icon ? <Icon /> : undefined}
    />
  );
};

export default SuccessChip;
