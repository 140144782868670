import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  List,
  ListItem,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import FailChip from 'components/Chips/FailChip';
import NeutralChip from 'components/Chips/NeutralChip';
import SuccessChip from 'components/Chips/SuccessChip';
import CircleLoading from 'components/CircleLoading';
import DeleteCreditFromItemReturnModal from 'components/DeleteCreditFromItemReturnModal';
import {
  InvoiceItemReturnStatusType,
  useGetReturnsByItemIdQuery,
} from 'graphql/graphql-types';
import { useSelectedValueModal } from 'hooks/modal';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { formatCentsToDollars } from 'utils/money';
import { formatDateTime } from 'utils/time';

const useStyles = makeStyles((theme) => ({
  listTitle: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  listItem: {
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}));

type Props = {
  itemId: number;
};
const ItemReturnList: FunctionComponent<Props> = ({ itemId }) => {
  const classes = useStyles();
  const history = useHistory();
  const { loading, data, error } = useGetReturnsByItemIdQuery({
    variables: { id: Number(itemId) },
  });
  const { selectedValue: returnId, open, close } = useSelectedValueModal();

  const returns = data?.allInvoiceItemReturns?.nodes ?? [];

  return (
    <>
      <Typography variant="h6">Returns</Typography>
      {loading && <CircleLoading />}
      {!loading && !returns.length && !error && (
        <Typography className={classes.listTitle} component="div">
          No returns
        </Typography>
      )}
      {!loading && !!returns.length && !error && (
        <List>
          {returns.map((entry) => {
            const status = entry?.status;
            const isPending = status !== InvoiceItemReturnStatusType.Completed;
            const queueId = entry?.queueId;
            const wasSuccessful = entry?.wasSuccessful;
            const errorMessage = entry?.errorMessage ?? undefined;
            const credits = entry?.creditAmount;
            const returnHistories =
              entry?.invoiceItemReturnHistoriesByReturnId?.nodes ?? [];
            return (
              <ListItem className={classes.listItem} key={String(entry?.id)}>
                <Accordion
                  style={{ width: '100%' }}
                  variant="outlined"
                  TransitionProps={{ unmountOnExit: true }}
                >
                  <AccordionSummary
                    expandIcon={
                      returnHistories.length ? <ExpandMoreIcon /> : null
                    }
                    style={{ width: '100%' }}
                  >
                    <Box>
                      <Box display="flex" alignItems="center">
                        <Typography variant="body1">
                          {entry?.returnOptionByOptionId?.name ?? ''}
                        </Typography>
                      </Box>
                      {entry?.note ? (
                        <Box display="flex" alignItems="center">
                          <Typography variant="body2">{entry?.note}</Typography>
                        </Box>
                      ) : null}
                      <Box>
                        <Typography variant="caption" component="div">
                          {`By ${entry.userByCreatedBy
                            ?.name} on ${formatDateTime(entry?.createdAt)}`}
                        </Typography>
                      </Box>
                      <Box display="flex" alignItems="center">
                        {isPending && <NeutralChip text="Pending" />}
                        {!isPending && wasSuccessful && (
                          <SuccessChip icon={false} label="Success" />
                        )}
                        {!isPending && !wasSuccessful && (
                          <FailChip
                            icon={false}
                            label="Error"
                            toolTipMessage={errorMessage}
                          />
                        )}
                        {credits ? (
                          <Box paddingLeft={1}>
                            <NeutralChip
                              text={`Credit ${formatCentsToDollars(credits)}`}
                            />
                          </Box>
                        ) : null}
                        {entry?.paymentAmount ? (
                          <Box paddingLeft={1}>
                            <NeutralChip
                              text={`Refund ${formatCentsToDollars(
                                entry?.paymentAmount,
                              )}`}
                            />
                          </Box>
                        ) : null}
                        {entry?.alternatePaymentAmount ? (
                          <Box paddingLeft={1}>
                            <NeutralChip
                              text={`Alternate ${formatCentsToDollars(
                                entry?.alternatePaymentAmount,
                              )}`}
                            />
                          </Box>
                        ) : null}
                        {queueId ? (
                          <Box paddingLeft={1}>
                            <NeutralChip
                              text="Queue Entry"
                              onClick={() =>
                                history.push(`/returnQueue/${queueId}`)
                              }
                            />
                          </Box>
                        ) : null}
                        {entry?.canDeleteCredit ? (
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              e?.stopPropagation();
                              open(entry?.id);
                            }}
                            style={{ marginLeft: 8 }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        ) : null}
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {returnHistories?.map((returnHistory) => (
                      <Box key={String(returnHistory?.id)}>
                        <Box display="flex" alignItems="center">
                          <Typography>{returnHistory.reason}</Typography>
                        </Box>
                        <Box>
                          <Typography variant="caption" component="div">
                            {`By ${returnHistory?.userByBy
                              ?.name} on ${formatDateTime(returnHistory?.ts)}`}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <NeutralChip text={returnHistory?.action} />
                        </Box>
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </ListItem>
            );
          })}
        </List>
      )}
      <DeleteCreditFromItemReturnModal
        returnId={Number(returnId)}
        isOpen={!!returnId}
        onClose={close}
        onSuccess={close}
      />
    </>
  );
};

export default ItemReturnList;
