import { Alert, Box, Container } from '@mui/material';
import React, { FunctionComponent } from 'react';

const NotFoundScreen: FunctionComponent = () => (
  <Box
    component="main"
    sx={{
      alignItems: 'center',
      display: 'flex',
      flexGrow: 1,
      minHeight: '100%',
    }}
  >
    <Container maxWidth="md">
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Alert severity="error">
          The page you are looking for is not here.
        </Alert>
      </Box>
    </Container>
  </Box>
);

export default NotFoundScreen;
