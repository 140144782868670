import { usePicklistQueueByLocationLazyQuery } from 'graphql/graphql-types';
import React from 'react';

export const usePicklistQueueByLocation = () => {
  const [isRunning, setIsRunning] = React.useState(false);
  const [fetch, { data, loading, error, refetch, stopPolling }] =
    usePicklistQueueByLocationLazyQuery({
      pollInterval: 2 * 1000,
    });
  const handleStart = (locationId: number) => {
    setIsRunning(true);
    fetch({
      variables: {
        locationId,
      },
    });
  };
  const handleStop = () => {
    setIsRunning(false);
    stopPolling();
  };

  const assigned = data?.picklistAssignedItems?.nodes ?? [];
  const unassigned = data?.picklistUnassignedItems?.nodes ?? [];

  const assignedCount = data?.picklistQueueCounts?.assignedCount ?? 0;
  const unassignedCount = data?.picklistQueueCounts?.unassignedCount ?? 0;
  const totalCount = data?.picklistQueueCounts?.totalCount ?? 0;

  return {
    fetch,
    handleStart,
    isRunning,
    handleStop,
    loading,
    error,
    refetch,
    assigned,
    unassigned,
    unassignedCount,
    assignedCount,
    totalCount,
  };
};
