import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { Box, IconButton } from '@mui/material';
import ConfirmAlert from 'components/Alerts/ConfirmAlert';
import ApplyCreditsToInvoiceModal from 'components/ApplyCreditsToInvoiceModal';
import CollectPaymentSwitch from 'components/CollectPaymentSwitch';
import ContentHeader from 'components/ContentHeader';
import CustomerNotesModal from 'components/CustomerNotesModal';
import Hyperlink from 'components/Hyperlink';
import InvoiceMarkPaidModal from 'components/InvoiceMarkPaidModal';
import InvoiceNoteAddModal from 'components/InvoiceNote/InvoiceNoteAddModal';
import InvoiceNoteList from 'components/InvoiceNote/InvoiceNoteList';
import LineItems from 'components/LineItems';
import PaymentStatusChip from 'components/PaymentStatusChip';
import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import { ViewScreen } from 'components/ViewScreen';
import {
  GetInvoiceByIdEntryFragment,
  useEmailInvoiceMutation,
  useGetInvoiceByIdQuery,
  useProcessInvoicePaymentMutation,
} from 'graphql/graphql-types';
import { useModal } from 'hooks/modal';
import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CUSTOMER_DASHBOARD_URL } from 'utils/env';
import { openInNewTab } from 'utils/general';
import { logError } from 'utils/logging';
import { formatCentsToDollars } from 'utils/money';
import { formatDate } from 'utils/time';
import InvoiceItemsTable from './InvoiceItemsTable';
import InvoicePaymentList from './InvoicePaymentList';

const InvoiceViewScreen: FunctionComponent = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const {
    visible: visibleCustomerNotes,
    open: openCustomerNotes,
    close: closeCustomerNotes,
  } = useModal();
  const {
    visible: collectPaymentModalVisible,
    close: closeCollectPaymentModal,
    open: openCollectPaymentModal,
  } = useModal();
  const {
    visible: addNoteModalVisible,
    close: closeAddNoteModal,
    open: openAddNoteModal,
  } = useModal();
  const {
    visible: emailInvoiceConfirmationModalVisible,
    close: closeEmailInvoiceConfirmationModal,
    open: openEmailInvoiceConfirmationModal,
  } = useModal();
  const {
    visible: lastPickupDateModalVisible,
    close: closeLastPickupDateModal,
    open: openLastPickupDateModal,
  } = useModal();
  const {
    visible: markPaidModalVisible,
    close: closeMarkPaidModal,
    open: openMarkPaidModal,
  } = useModal();
  const {
    visible: visibleApplyCredits,
    close: closeApplyCredits,
    open: openApplyCredits,
  } = useModal();
  const [setProcessInvoicePayment] = useProcessInvoicePaymentMutation();
  const [sendEmailInvoice] = useEmailInvoiceMutation();

  const { loading, data, refetch, error } = useGetInvoiceByIdQuery({
    variables: {
      id: Number(id),
    },
  });

  const invoice = data?.invoiceById as GetInvoiceByIdEntryFragment;
  const merId = invoice?.merId;
  const isMer = !!merId;
  const uid = String(invoice?.uid ?? '');
  const publicToken = invoice?.publicToken;
  const elevatedToken = invoice?.elevatedToken;
  const total = invoice?.total;
  const auction = invoice?.auctionByAuctionId;
  const customer = invoice?.bidderByBidderId;
  const activeAllNotesCount = customer?.activeAllNotesCount;

  const onSubmitCollectPayment = useCallback(
    async (values: any) => {
      const snackId = startSnackbar('Updating collect payment');
      try {
        await setProcessInvoicePayment({
          variables: {
            invoiceId: invoice?.id,
            processPayment: !invoice?.processPayment,
            reason: values.reason,
          },
        });
        closeCollectPaymentModal();
        stopSnackbar('Updated collect payment', snackId);
      } catch (e: any) {
        logError(e);
        stopSnackbar(
          `Failed to update collect payment: ${e?.message ?? 'Unknown'}`,
          snackId,
        );
      }
    },
    [invoice],
  );

  const goToMer = useCallback(() => {
    if (merId) {
      // TODO
    }
  }, [merId]);

  const openAuction = useCallback(() => {
    if (auction?.id) {
      history.push(`/auctions/${auction.id}`);
    }
  }, [auction]);

  const openCustomer = useCallback(() => {
    if (customer?.id) {
      history.push(`/customers/${customer.id}`);
    }
  }, [customer]);

  const previewCustomerInvoice = useCallback(
    (elevated?: boolean) => {
      if (uid && publicToken) {
        openInNewTab(
          `${CUSTOMER_DASHBOARD_URL}/customers/invoice/${invoice.uid}/${
            invoice.publicToken
          }${elevated && elevatedToken ? `?t=${elevatedToken}` : ''}`,
        );
      }
    },
    [uid, publicToken],
  );

  const emailInvoice = useCallback(async () => {
    if (id) {
      const snackId = startSnackbar('Starting to send email');
      try {
        await sendEmailInvoice({
          variables: {
            id: Number(id),
          },
        });
        stopSnackbar('Sent email', snackId);
      } catch (e: any) {
        logError(e);
        stopSnackbar(
          `Failed to send email: ${e?.message ?? 'Unknown'}`,
          snackId,
        );
      }
    }
  }, [id]);

  const invoiceId = Number(invoice?.id);
  const pickupDate = invoice?.auctionByAuctionId?.pickupEndAt;
  const lastPickupDate = invoice?.lastPickupDate;
  const isPaid = invoice?.isPaid ?? false;

  const buttons = useMemo(() => {
    if (isMer) {
      return [
        {
          text: 'Mercury',
          onClick: goToMer,
          disabled: !merId,
        },
        {
          text: 'Add Note',
          onClick: openAddNoteModal,
        },
      ];
    }
    return [
      {
        text: 'Add Note',
        onClick: openAddNoteModal,
      },
      {
        text: 'Email Invoice',
        onClick: openEmailInvoiceConfirmationModal,
      },
      {
        text: 'Customer Invoice',
        buttonType: 'split' as const,
        splitButtons: {
          firstButton: {
            text: 'View Customer Invoice',
            onClick: () => previewCustomerInvoice(false),
          },
          buttons: [
            {
              text: 'Signature',
              onClick: () => previewCustomerInvoice(true),
            },
          ],
        },
      },
      {
        text: 'Mark Paid',
        onClick: openMarkPaidModal,
        disabled: isPaid,
      },
      {
        text: 'Apply Credits',
        onClick: openApplyCredits,
        disabled: isPaid,
      },
    ];
  }, [isMer, isPaid]);

  return (
    <ViewScreen
      loading={loading}
      error={error}
      header={{
        leftProps: { title: uid },
        centerProps: {
          component: (
            <Box>
              <PaymentStatusChip type={isPaid ? 'paid' : 'unpaid'} />
            </Box>
          ),
        },
        bottomProps: {
          subtitle: formatCentsToDollars(total),
          buttons,
        },
        dividerProps: {
          hide: true,
        },
      }}
    >
      <ContentHeader title="Details" size="large" />
      <LineItems
        description="Auction"
        value={
          <Hyperlink text={String(auction?.name ?? '')} onClick={openAuction} />
        }
      />
      <LineItems
        description="Customer"
        value={
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            flexGrow={1}
          >
            <Box>
              <Hyperlink
                onClick={openCustomer}
                text={String(customer?.name ?? '')}
              />
            </Box>
            {activeAllNotesCount ? (
              <Box paddingLeft={1}>
                <IconButton size="small" onClick={openCustomerNotes}>
                  <PriorityHighIcon />
                </IconButton>
              </Box>
            ) : null}
          </Box>
        }
      />
      <LineItems description="Pickup date" value={formatDate(pickupDate)} />
      <LineItems
        description="Last pickup date"
        value={
          <Hyperlink
            onClick={openLastPickupDateModal}
            text={formatDate(lastPickupDate)}
            type="inline"
          />
        }
      />
      {!isMer && (
        <CollectPaymentSwitch
          collectPayment={invoice?.processPayment}
          collectPaymentReason={invoice?.processPaymentReason ?? ''}
          collectPaymentChangedBy={
            invoice?.userByProcessPaymentChangedBy?.name ?? ''
          }
          collectPaymentChangedAt={invoice?.processPaymentChangedAt ?? ''}
          onSubmit={onSubmitCollectPayment}
          isOpen={collectPaymentModalVisible}
          setOpen={openCollectPaymentModal}
          setClose={closeCollectPaymentModal}
          labelText="Charge invoice payment"
        />
      )}
      <Box style={{ margin: 4 }} />
      <InvoiceItemsTable
        invoiceId={invoiceId}
        invoice={invoice}
        refetchInvoice={refetch}
        isMer={isMer}
      />
      <Box style={{ paddingTop: 48 }} />
      {!isMer && <InvoicePaymentList invoiceId={invoiceId} />}
      <InvoiceNoteList invoiceId={invoiceId} />
      <InvoiceNoteAddModal
        open={addNoteModalVisible}
        invoiceId={invoiceId}
        onClose={closeAddNoteModal}
        onSuccess={closeAddNoteModal}
      />
      <ConfirmAlert
        isOpen={emailInvoiceConfirmationModalVisible}
        title="Email Invoice to Customer"
        description="This will send email the customer their invoice. Are you sure you want to do this?"
        onAgree={() => {
          emailInvoice();
          closeEmailInvoiceConfirmationModal();
        }}
        onDiscard={closeEmailInvoiceConfirmationModal}
        agreeText="Yes"
        disagreeText="Cancel"
      />
      <InvoiceMarkPaidModal
        invoiceId={invoiceId}
        isOpen={markPaidModalVisible}
        onClose={closeMarkPaidModal}
        onSuccess={closeMarkPaidModal}
      />
      <CustomerNotesModal
        isOpen={visibleCustomerNotes}
        customerId={Number(customer?.id)}
        onOk={closeCustomerNotes}
      />
      <ApplyCreditsToInvoiceModal
        invoiceId={invoiceId}
        open={visibleApplyCredits}
        onClose={closeApplyCredits}
        onSuccess={closeApplyCredits}
      />
    </ViewScreen>
  );
};

export default InvoiceViewScreen;
