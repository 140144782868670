import {
  ItemOverallPickupStatus,
  ItemPaidStatus,
  PaymentEntryFragment,
  QueuedProcessingType,
  StatusType,
  UserRoles,
  ReturnQueueType,
} from 'graphql/graphql-types';
import React from 'react';
import { formatEnum } from 'utils/general';
import BlueChip from './BlueChip';
import FailChip from './FailChip';
import NeutralChip from './NeutralChip';
import SuccessChip from './SuccessChip';

export const PaymentChargeStatusChip = ({
  status,
}: {
  status: StatusType | null | undefined;
}) => {
  if (status === StatusType.Error) {
    return <FailChip icon={false} label="Error" />;
  }
  if (status === StatusType.Success) {
    return <SuccessChip icon={false} label="Success" />;
  }
  return null;
};

export const PaymentOverallStatusChip = ({
  isVoided,
  isCharged,
  refundedCreditAmount,
  refundedPaymentAmount,
  amount,
  chargeStatus,
}: Pick<
  PaymentEntryFragment,
  | 'isVoided'
  | 'isCharged'
  | 'refundedCreditAmount'
  | 'refundedPaymentAmount'
  | 'amount'
  | 'chargeStatus'
>) => {
  if (isVoided) {
    return <NeutralChip text="Voided" />;
  }

  if (isCharged && (refundedCreditAmount > 0 || refundedPaymentAmount > 0)) {
    return (
      <NeutralChip
        text={
          refundedCreditAmount + refundedPaymentAmount === amount
            ? 'Refunded'
            : 'Partial refund'
        }
      />
    );
  }

  return <PaymentChargeStatusChip status={chargeStatus} />;
};

export const QueuedProcessingStatusChip = ({
  status,
}: {
  status: QueuedProcessingType | null | undefined;
}) => <NeutralChip text={formatEnum(status)} />;

export const RoleChip = ({ role }: { role?: UserRoles }) => (
  <NeutralChip text={formatEnum(role)} />
);

export const ItemStatusChip = ({
  status,
}: {
  status?: ItemOverallPickupStatus | null;
}) => {
  const text = formatEnum(status);
  if (
    status === ItemOverallPickupStatus.Paid ||
    status === ItemOverallPickupStatus.PickupCompleted
  ) {
    return <SuccessChip icon={false} label={text} />;
  }
  return <NeutralChip text={text} />;
};

export const ItemPaidStatusChip = ({
  status,
}: {
  status?: ItemPaidStatus | null;
}) =>
  status === ItemPaidStatus.Paid ? (
    <SuccessChip icon={false} label="Paid" />
  ) : (
    <NeutralChip text="Unpaid" />
  );

export const TaskTypeChip = ({ data }: { data: any }) => {
  const credit = data?.credit;
  const refund = data?.refund;
  if (credit) {
    return <BlueChip text="Credit" />;
  }
  if (refund) {
    return <FailChip label="Refund" />;
  }
  return <NeutralChip text="General" />;
};

export const ReturnQueueTypeChip = ({ type }: { type?: ReturnQueueType }) => {
  if (type === ReturnQueueType.Credit) {
    return <BlueChip text="Credit" />;
  }
  if (type === ReturnQueueType.Refund) {
    return <FailChip label="Refund" />;
  }
  return <NeutralChip text="General" />;
};
