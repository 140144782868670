import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import NeutralChip from 'components/Chips/NeutralChip';
import ContentHeader from 'components/ContentHeader';
import Hyperlink from 'components/Hyperlink';
import JsonView from 'components/JsonView';
import LineItems from 'components/LineItems';
import { ViewScreen } from 'components/ViewScreen';
import { useGetTransactionQuery } from 'graphql/graphql-types';
import React, { useCallback, FunctionComponent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { formatEnum } from 'utils/general';
import { formatCentsToDollars } from 'utils/money';
import { formatDateTime } from 'utils/time';

const useStyles = makeStyles(() => ({
  accordionRoot: {
    backgroundColor: '#ffffff',
    boxShadow:
      '1px 0px #e0e0e0, 0px 1px #e0e0e0, -1px 0px #e0e0e0, 0px -1px #e0e0e0',
  },
  accordionExpanded: { margin: '0!important' },
}));

const TransactionViewScreen: FunctionComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data, loading } = useGetTransactionQuery({
    variables: {
      id: Number(id),
    },
  });

  const transaction = data?.transactionById;
  const paymentProcessId = transaction?.paymentProcessorId;
  const type = transaction?.type;
  const amount = transaction?.amount;
  const createdAt = transaction?.createdAt;
  const status = transaction?.status;
  const response = JSON.stringify(transaction?.response ?? {}, null, 2);
  const paymentId = transaction?.paymentByPaymentId?.id ?? '';
  const invoiceId = transaction?.paymentByPaymentId?.invoiceByInvoiceId?.id;
  const invoiceUid =
    transaction?.paymentByPaymentId?.invoiceByInvoiceId?.uid ?? '';
  const customerId =
    transaction?.paymentByPaymentId?.invoiceByInvoiceId?.bidderByBidderId?.id;
  const customerName =
    transaction?.paymentByPaymentId?.invoiceByInvoiceId?.bidderByBidderId
      ?.name ?? '';

  const openPayment = useCallback(() => {
    history.push(`/payments/${paymentId}`);
  }, [paymentId]);

  const openInvoice = useCallback(() => {
    history.push(`/invoices/${invoiceId}`);
  }, [invoiceId]);

  const openCustomer = useCallback(() => {
    history.push(`/customers/${customerId}`);
  }, [customerId]);

  return (
    <ViewScreen
      loading={loading}
      header={{
        leftProps: {
          title: `Transaction ${id}`,
        },
        bottomProps: {
          subtitle: formatCentsToDollars(amount),
        },
        centerProps: {
          component: <NeutralChip text={formatEnum(type)} />,
        },
        dividerProps: {
          hide: true,
        },
      }}
    >
      <ContentHeader title="Summary" size="large" />
      <LineItems description="Transaction ID" value={paymentProcessId} />
      <LineItems description="Status" value={status} />
      <LineItems
        description="Payment"
        value={<Hyperlink text={String(paymentId)} onClick={openPayment} />}
      />
      <LineItems
        description="Customer"
        value={<Hyperlink text={customerName} onClick={openCustomer} />}
      />
      <LineItems
        description="Invoice"
        value={<Hyperlink text={invoiceUid} onClick={openInvoice} />}
      />
      <LineItems description="Time" value={formatDateTime(createdAt)} />
      <Box mt={4}>
        <Accordion
          defaultExpanded={false}
          classes={{
            root: classes.accordionRoot,
            expanded: classes.accordionExpanded,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Response</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <JsonView data={response} />
          </AccordionDetails>
        </Accordion>
      </Box>
    </ViewScreen>
  );
};

export default TransactionViewScreen;
