import { Card as MuiCard, Switch, Tab, Tabs } from '@mui/material';
import Button from 'components/Button';
import Card from 'components/Card';
import CollectPaymentSwitch from 'components/CollectPaymentSwitch';
import LineItems from 'components/LineItems';
import { startSnackbar, stopSnackbar } from 'components/SnackBar';
import SplitButton from 'components/SplitButton';
import { ViewScreen } from 'components/ViewScreen';
import {
  CustomerNotesConnection,
  useGetBidderByIdQuery,
  useResyncCustomerFromBidwranglerMutation,
  useSetCollectPaymentBiddersMutation,
  useUpdateBidderMutation,
} from 'graphql/graphql-types';
import { useModal } from 'hooks/modal';
import isEmpty from 'lodash.isempty';
import React, {
  ChangeEvent,
  FunctionComponent,
  useCallback,
  useMemo,
} from 'react';
import { Link, Route, useParams, useRouteMatch } from 'react-router-dom';
import NoteList from 'screens/Note/NoteList';
import { openInNewTab } from 'utils/general';
import { logError } from 'utils/logging';
import { formatCentsToDollars } from 'utils/money';
import CustomerCreditHistoryTable from './CustomerCreditHistoryTable';
import CustomerInvoiceTable from './CustomerInvoiceTable';
import CustomerItemTable from './CustomerItemTable';

const CustomerViewScreen: FunctionComponent = () => {
  const routeMatch = useRouteMatch([
    '/customers/:id/invoices',
    '/customers/:id/items',
    '/customers/:id/notes',
    '/customers/:id/credits',
    '/customers/:id',
  ]);
  const { id } = useParams<{ id: string }>();
  const { visible, open, close } = useModal();
  const [setProcessInvoicePayment] = useSetCollectPaymentBiddersMutation();
  const [resyncCustomerFromBW] = useResyncCustomerFromBidwranglerMutation();
  const [updateBidder] = useUpdateBidderMutation();

  const { loading, data, error, refetch } = useGetBidderByIdQuery({
    variables: {
      id: Number(id),
    },
  });

  const currentTab = routeMatch?.path;

  const bidder = data?.bidderById;
  const bwId = bidder?.bwId;
  const merId = bidder?.merId;
  const isMer = !!merId;
  const authorizePaymentId = bidder?.paymentId;
  const notes = bidder?.customerNotesByCustomerId as CustomerNotesConnection;
  const name = `${bidder?.firstName} ${bidder?.lastName}`;
  const email = bidder?.email ?? '';
  const phone = bidder?.phoneNumber ?? '';
  const mobile = bidder?.mobileNumber ?? '';
  const creditBalance = bidder?.creditBalance ?? 0;
  const isTaxExempt = bidder?.isTaxExempt;
  const locationData = bidder?.location;
  const location = isEmpty(locationData)
    ? ''
    : `${locationData?.street ?? ''}, ${locationData?.city ?? ''}, ${
        locationData?.state ?? ''
      } ${locationData?.zip ?? ''}`;

  const goToMer = useCallback(() => {
    if (merId) {
      // TODO
    }
  }, [merId]);

  const goToBw = useCallback(() => {
    if (bwId) {
      openInNewTab(
        `https://bid.onlineliquidationauction.com/admin/user/${bwId}`,
      );
    }
  }, [bwId]);

  const goToAuthorize = useCallback(() => {
    if (authorizePaymentId) {
      openInNewTab(
        `https://account.authorize.net/UI/themes/anet/CustomerProfile/ViewHistory.aspx?ProfileID=${authorizePaymentId}`,
      );
    }
  }, [authorizePaymentId]);

  const resyncCustomer = useCallback(() => {
    if (bwId) {
      resyncCustomerFromBW({
        variables: {
          input: {
            customerBwId: bwId,
          },
        },
      });
    }
  }, [bwId]);

  const onSubmitBidderChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const snackId = startSnackbar('Updating customer');
      try {
        await updateBidder({
          variables: {
            id: Number(id),
            values: {
              isTaxExempt: e.target.checked,
            },
          },
        });
        await refetch();
        stopSnackbar('Updated customer', snackId);
      } catch (onSubmitError: any) {
        logError(onSubmitError);
        stopSnackbar(
          `Failed to update customer: ${onSubmitError.message}`,
          snackId,
        );
      }
    },
    [id],
  );

  const onSubmitCollectPayment = useCallback(
    async (values: any) => {
      const snackId = startSnackbar('Updating collect payment option');
      try {
        await setProcessInvoicePayment({
          variables: {
            bidderId: Number(id),
            processInvoicePayment: !bidder?.processInvoicePayment,
            reason: values.reason,
          },
        });
        close();
        stopSnackbar('Updated collect payment option', snackId);
      } catch (e: any) {
        logError(e);
        stopSnackbar(
          `Failed to update collect payment option: ${e.message}`,
          snackId,
        );
      }
    },
    [bidder],
  );

  const buttons = useMemo(() => {
    if (merId) {
      return <Button text="Mercury" onClick={goToMer} disabled={!merId} />;
    }
    return (
      <>
        <SplitButton
          firstButton={{
            text: 'BidWrangler',
            onClick: goToBw,
            disabled: !bwId,
          }}
          buttons={[
            {
              text: 'Resync from BidWrangler',
              onClick: resyncCustomer,
              disabled: !bwId,
            },
          ]}
        />
        <Button
          sx={{ marginLeft: 1 }}
          text="Authorize"
          onClick={goToAuthorize}
          disabled={!authorizePaymentId}
        />
      </>
    );
  }, [merId, bwId, authorizePaymentId]);

  return (
    <ViewScreen
      loading={loading}
      error={error}
      header={{
        dividerProps: {
          hide: true,
        },
        leftProps: {
          title: name,
        },
        bottomProps: {
          component: buttons,
        },
        centerProps: {
          text: email,
        },
      }}
    >
      <Card disableMargin disablePadding>
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          variant="standard"
        >
          <Tab
            label="Details"
            value="/customers/:id"
            to={`/customers/${id}`}
            component={Link}
          />
          <Tab
            label="Invoices"
            value="/customers/:id/invoices"
            to={`/customers/${id}/invoices`}
            component={Link}
          />
          <Tab
            label="Items"
            value="/customers/:id/items"
            to={`/customers/${id}/items`}
            component={Link}
          />
          <Tab
            label="Notes"
            value="/customers/:id/notes"
            to={`/customers/${id}/notes`}
            component={Link}
          />
          {!isMer && (
            <Tab
              label="Credits"
              value="/customers/:id/credits"
              to={`/customers/${id}/credits`}
              component={Link}
            />
          )}
        </Tabs>
      </Card>

      {/* Nested Routes. */}
      <Route exact path="/customers/:id">
        <MuiCard
          sx={{
            padding: 4,
          }}
        >
          <LineItems description="Name" value={name} />
          <LineItems description="Email" value={email} />
          {!isMer && (
            <LineItems
              description="Credits"
              value={formatCentsToDollars(creditBalance)}
            />
          )}
          <LineItems description="Mobile" value={mobile} />
          <LineItems description="Phone" value={phone} />
          {!isMer && (
            <>
              <LineItems description="Location" value={location} />
              <LineItems
                description="Tax exempt"
                value={
                  <Switch
                    size="small"
                    checked={!!isTaxExempt}
                    onChange={onSubmitBidderChange}
                  />
                }
              />
              <CollectPaymentSwitch
                collectPayment={bidder?.processInvoicePayment}
                collectPaymentReason={bidder?.processInvoicePaymentReason ?? ''}
                collectPaymentChangedBy={
                  bidder?.userByProcessInvoicePaymentChangedBy?.name ?? ''
                }
                collectPaymentChangedAt={
                  bidder?.processInvoicePaymentChangedAt ?? ''
                }
                onSubmit={onSubmitCollectPayment}
                isOpen={visible}
                setOpen={open}
                setClose={close}
              />
            </>
          )}
        </MuiCard>
      </Route>
      <Route exact path="/customers/:id/invoices">
        <CustomerInvoiceTable customerId={Number(id)} />
      </Route>
      <Route exact path="/customers/:id/items">
        <CustomerItemTable customerId={Number(id)} />
      </Route>
      <Route exact path="/customers/:id/notes">
        <NoteList customerId={Number(id)} data={notes} />
      </Route>
      {!isMer && (
        <Route exact path="/customers/:id/credits">
          <CustomerCreditHistoryTable customerId={Number(id)} />
        </Route>
      )}
    </ViewScreen>
  );
};

export default CustomerViewScreen;
