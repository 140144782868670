import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Switch,
  Typography,
} from '@mui/material';
import EditorField from 'components/Forms/EditorField';
import { makeValidate } from 'components/Forms/Validation';
import React, { FunctionComponent, useCallback } from 'react';
import { Form } from 'react-final-form';
import { formatDateTime } from 'utils/time';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  reason: Yup.string().required(),
});

const validate = makeValidate(schema);

type Props = {
  collectPayment?: boolean;
  collectPaymentReason?: string;
  collectPaymentChangedBy?: string;
  collectPaymentChangedAt?: string;
  onSubmit: (values: any) => void;
  isOpen: boolean;
  setOpen: () => void;
  setClose: () => void;
  labelText?: string;
};

const CollectPaymentSwitch: FunctionComponent<Props> = ({
  collectPayment,
  collectPaymentReason,
  collectPaymentChangedBy,
  collectPaymentChangedAt,
  onSubmit,
  isOpen,
  setOpen,
  setClose,
  labelText = 'Charge invoice payments',
}) => {
  const handleSave = useCallback(() => {
    document
      .getElementById('collect-payment-form')
      ?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
  }, []);

  return (
    <>
      <FormControl component="fieldset">
        <FormLabel component="legend">{labelText}</FormLabel>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={collectPayment}
                onChange={setOpen}
                size="small"
              />
            }
            label={
              <Typography display="inline" color="textSecondary">
                {collectPayment ? 'Yes' : 'No'}
              </Typography>
            }
          />
        </FormGroup>
        {collectPaymentChangedAt && (
          <FormHelperText>
            <Typography display="block" variant="caption">
              <Typography
                display="inline"
                color="textSecondary"
                variant="caption"
              >
                {`Changed by: ${collectPaymentChangedBy ?? ''}`}
              </Typography>
            </Typography>
            <Typography display="block" variant="caption">
              <Typography
                display="inline"
                variant="caption"
                color="textSecondary"
              >
                {`Changed at: ${formatDateTime(collectPaymentChangedAt)}`}
              </Typography>
            </Typography>
            <Typography display="block" variant="caption">
              <Typography
                display="inline"
                variant="caption"
                color="textSecondary"
              >
                {`Reason: ${collectPaymentReason}`}
              </Typography>
            </Typography>
          </FormHelperText>
        )}
      </FormControl>
      <Dialog
        onClose={setClose}
        aria-labelledby="simple-dialog-title"
        open={isOpen}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Update Invoice Process Payments</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enabled by default. If disabled, invoice payment will never be
            collected through payment processor. It is intended for cash only
            customers. You must leave a note if default is changed.
          </DialogContentText>
          <Form
            onSubmit={onSubmit}
            // @ts-ignore
            validate={validate}
            render={({ handleSubmit }) => (
              <form id="collect-payment-form" onSubmit={handleSubmit}>
                <EditorField
                  required
                  label="Reason"
                  id="reason"
                  name="reason"
                  config={{
                    view: {
                      menu: true,
                      md: true,
                      html: false,
                    },
                    canView: {
                      fullScreen: false,
                      hideMenu: false,
                    },
                  }}
                  plugins={[
                    'header',
                    'font-bold',
                    'font-italic',
                    'font-underline',
                    'font-strikethrough',
                    'list-unordered',
                    'list-ordered',
                    'table',
                    'clear',
                    'logger',
                  ]}
                />
              </form>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={setClose}>Cancel</Button>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CollectPaymentSwitch;
