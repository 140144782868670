import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { FunctionComponent, ReactNode } from 'react';

type Props = {
  isOpen: boolean;
  onAgree: () => void;
  onDiscard: () => void;
  title: string;
  description?: string;
  content?: ReactNode;
  agreeText?: string;
  disagreeText?: string;
};

const ConfirmAlert: FunctionComponent<Props> = ({
  title,
  description,
  isOpen,
  onAgree,
  onDiscard,
  content,
  agreeText = 'Agree',
  disagreeText = 'Disagree',
}) => (
  <Dialog
    open={isOpen}
    onClose={onDiscard}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
    <DialogContent>
      {description && (
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      )}
      {content}
    </DialogContent>
    <DialogActions>
      <Button onClick={onDiscard}>{disagreeText}</Button>
      <Button onClick={onAgree} color="primary" autoFocus>
        {agreeText}
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmAlert;
