import { Divider, Drawer, List } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  useTotalActiveReturnQueueQuery,
  useCurrentUserQuery,
} from 'graphql/graphql-types';
import React from 'react';
import {
  MainListItems,
  SecondaryListItems,
  TertiaryListItems,
} from './drawerItems';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: '#f7fafc',
    boxShadow: '1px 0 #e3e8ee',
    border: '0',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const AppDrawer: React.FunctionComponent = () => {
  const classes = useStyles();
  const { data: user } = useCurrentUserQuery();
  const userRole = user?.currentUser?.role;
  const { data: returnQueueData } = useTotalActiveReturnQueueQuery({
    pollInterval: 2 * 1000,
  });

  const totalReturnQueue = returnQueueData?.totalActiveReturnQueue ?? 0;

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      <div className={classes.toolbar} />
      <List component="nav">
        <MainListItems
          totalReturnQueue={totalReturnQueue}
          userRole={userRole}
        />
      </List>
      <Divider />
      <List component="nav">
        <SecondaryListItems userRole={userRole} />
      </List>
      <Divider />
      <List component="nav">
        <TertiaryListItems userRole={userRole} />
      </List>
    </Drawer>
  );
};

export default AppDrawer;
