import { ApolloError } from '@apollo/client';
import { FilterElements } from '@digitus-data/material-ui-table-apollo';
import { GroupedItems } from 'components/Forms/GroupedSelectField';
import { GraphQLError } from 'graphql';

export const mapEnumToArray = (values: any) => {
  const options: string[] = [];
  Object.entries(values).forEach((v) => {
    options.push(String(v[1]));
  });
  return options;
};

type Option = {
  label: string;
  value: string;
};
export const mapEnumToSelectOptions = (values: any) => {
  const options: Option[] = [];
  Object.entries(values).forEach((v) => {
    options.push({
      label: String(v[0]),
      value: String(v[1]),
    });
  });
  return options;
};

export const mapEnumToGroupSelectOptions = (values: any): GroupedItems[] => {
  const options: GroupedItems[] = [];
  Object.entries(values).forEach((v) => {
    const value = String(v[1]);
    const splitValue = value.split('_');
    const groupName = splitValue[0];
    splitValue.shift();
    const label = splitValue.join(' ');

    const i = options.findIndex((o) => o.group === groupName);
    if (i !== -1) {
      options[i].items.push({
        label,
        value,
      });
    } else {
      options.push({
        group: groupName,
        items: [
          {
            label,
            value,
          },
        ],
      });
    }
  });
  return options;
};

export const mapEnumToFilterElements = (values: any): FilterElements[] =>
  [
    {
      text: 'All',
      value: '',
    },
  ].concat(
    Object.entries(values).map((v) => ({
      text: String(v[0])
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2'),
      value: String(v[1]),
    })),
  );

export const parseCustomerNoteType = (type: string): string => {
  const splitValue = type.split('_');
  const groupName = splitValue[0];
  splitValue.shift();
  const label = splitValue.join(' ');
  return `${groupName}: ${label}`;
};

export function extractError(error: null): null;
export function extractError(error: Error): Error;
export function extractError(error: ApolloError): GraphQLError;
export function extractError(error: GraphQLError): GraphQLError;
export function extractError(
  error: null | Error | ApolloError | GraphQLError,
): null | Error | GraphQLError;
export function extractError(
  error: null | Error | ApolloError | GraphQLError,
): null | Error | GraphQLError {
  return (
    (error &&
      'graphQLErrors' in error &&
      error.graphQLErrors &&
      error.graphQLErrors.length &&
      error.graphQLErrors[0]) ||
    error
  );
}

export function getExceptionFromError(
  error: null | Error | ApolloError | GraphQLError,
): Error | null {
  // @ts-ignore
  const graphqlError: GraphQLError = extractError(error);
  const exception =
    graphqlError &&
    graphqlError.extensions &&
    graphqlError.extensions.exception;
  return exception || graphqlError || error;
}

export function getCodeFromError(
  error: null | Error | ApolloError | GraphQLError,
): null | string {
  const err = getExceptionFromError(error);
  // @ts-ignore
  return (err && err?.code) || null;
}
