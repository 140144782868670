import ContentHeader from 'components/ContentHeader';
import LineItems from 'components/LineItems';
import { ViewScreen } from 'components/ViewScreen';
import { useGetLocationQuery } from 'graphql/graphql-types';
import { useModal } from 'hooks/modal';
import React, { FunctionComponent } from 'react';
import { useParams } from 'react-router-dom';
import { ADMINISTRATOR } from 'utils/auth';
import { formatPercentage } from 'utils/general';
import LocationForm from './LocationForm';

const LocationViewScreen: FunctionComponent = () => {
  const { visible, open, close } = useModal();
  const { id } = useParams<{ id: string }>();
  const { loading, data, error } = useGetLocationQuery({
    variables: {
      id: Number(id),
    },
  });

  const location = data?.locationById;
  const name = String(location?.name);
  const invoicePrefix = location?.invoicePrefix;
  const timezone = location?.timezone;
  const address = location?.fullAddress;
  const premium = location?.premium;
  const tax = location?.tax;
  const shortDescription = location?.shortDescription;
  const fullDescription = location?.fullDescription;
  const lpudMon = location?.lpudMon;
  const lpudTue = location?.lpudTue;
  const lpudWed = location?.lpudWed;
  const lpudThu = location?.lpudThu;
  const lpudFri = location?.lpudFri;
  const lpudSat = location?.lpudSat;
  const lpudSun = location?.lpudSun;

  return (
    <ViewScreen
      loading={loading}
      error={error}
      header={{
        leftProps: {
          title: name,
        },
        bottomProps: {
          buttons: [
            {
              text: 'Edit',
              onClick: open,
              disabled: !id,
              accessRoles: [ADMINISTRATOR],
            },
          ],
        },
        dividerProps: {
          hide: true,
        },
      }}
    >
      <ContentHeader title="Details" size="large" />
      <LineItems description="Name" value={name} />
      <LineItems description="Invoice Prefix" value={invoicePrefix} />
      <LineItems description="Address" value={address} />
      <LineItems description="Premium" value={formatPercentage(premium)} />
      <LineItems description="Tax" value={formatPercentage(tax)} />
      <LineItems description="Timezone" value={timezone} />
      <br />
      <ContentHeader title="Description Templates" size="large" />
      <LineItems
        description="Short description"
        value={shortDescription}
        stacked
      />
      <LineItems
        description="Full description"
        value={fullDescription}
        stacked
      />
      <br />
      <ContentHeader title="Last Pickup Date Settings" size="large" />
      <LineItems description="Monday" value={lpudMon} />
      <LineItems description="Tuesday" value={lpudTue} />
      <LineItems description="Wednesday" value={lpudWed} />
      <LineItems description="Thursday" value={lpudThu} />
      <LineItems description="Friday" value={lpudFri} />
      <LineItems description="Saturday" value={lpudSat} />
      <LineItems description="Sunday" value={lpudSun} />
      <LocationForm
        visible={visible}
        type="update"
        locationId={Number(id)}
        handleClose={close}
        handleSuccess={close}
      />
    </ViewScreen>
  );
};

export default LocationViewScreen;
