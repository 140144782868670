import React, { FunctionComponent } from 'react';
import {
  RackLocationListDocument,
  RackLocationListEntryFragment,
} from 'graphql/graphql-types';
import ListScreen from 'components/ListScreen';
import { useHistory } from 'react-router-dom';

type Row = {
  row: RackLocationListEntryFragment;
};

const RackLocationListScreen: FunctionComponent = () => {
  const history = useHistory();
  return (
    <ListScreen
      title="Rack Locations"
      query={RackLocationListDocument}
      onRowClick={(row: Row['row']) => {
        history.push(`/rackLocations/${row.rackLocation}`);
      }}
      columns={[
        { label: 'Rack Location', name: 'rackLocation' },
        {
          label: 'Item Count',
          name: 'itemCount',
        },
      ]}
    />
  );
};

export default RackLocationListScreen;
